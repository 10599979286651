import React, { ReactElement, useEffect, useState } from 'react'
import PageHeader from '../components/PageHeader'
import Table from '../components/Table'
import Radom, { Payout } from '../state/Radom'
import { observer } from 'mobx-react'
import { PrimaryButton, IconButton } from '../components/Button'
import { formatCurrency, useTestMode, infoToast } from '../util/Util'
import { Container } from '../components/Animations'
import Pagination from '../components/Pagination'
import PayoutModal from './Funds/PayoutModal'
import TimeText from '../components/TimeText'
import { StatusBadge } from './CheckoutSessions'
import { camelCase } from 'lodash'
import { Link } from 'react-router-dom'
import { TextInput } from '../components/Input'
import Copy from '../icons/Copy'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Checkbox from '../components/Checkbox'


enum PayoutStatusFilter {
  AwaitingConfirmation = 'AwaitingConfirmation',
  Pending = 'Pending',
  Complete = 'Complete',
  Expired = 'Expired'
}

const Payouts = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const pageSize = 10
  const [curPage, setCurPage] = useState(0)
  const [payouts, setPayouts] = useState<Payout[]>([])
  const [totalPayoutsSize, setTotalPayoutsSize] = useState(0)
  const inputRefs = {}
  const [testMode] = useTestMode()

  const [selectedPayoutId, setSelectedPayoutId] = useState<string>()
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false)

  const [payoutStatusFilter, setPayoutStatusFilter] = useState<PayoutStatusFilter[]>([
    PayoutStatusFilter.AwaitingConfirmation,
    PayoutStatusFilter.Complete,
    PayoutStatusFilter.Pending
  ])

  const loadPayouts = async (): Promise<void> => {
    setIsLoading(true)
    Radom.listPayouts(curPage * pageSize, pageSize, testMode, payoutStatusFilter)
      .then(res => {
        setIsLoading(false)
        setPayouts(res.data)
        setTotalPayoutsSize(res.total)
      })
      .catch(() => { })
  }

  useEffect(() => {
    loadPayouts()
  }, [testMode, curPage, payoutStatusFilter])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Payouts' subTitle='Manage and view your payouts' />

        <Link to="/payout/create" style={{ textDecoration: 'none' }}>
          <PrimaryButton style={{ gap: 8 }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create payout</span>
          </PrimaryButton>
        </Link>
      </div>

      <div style={{ display: 'flex' }}>
        <Dropdown
          selectedContent={<div style={{ fontSize: 14 }}>{'Payout status'}</div>}
          dropdownContent={<div>
            {[PayoutStatusFilter.AwaitingConfirmation, PayoutStatusFilter.Pending, PayoutStatusFilter.Complete, PayoutStatusFilter.Expired].map(s =>
              <DropdownItem
                key={s}
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                onClick={() => {
                  if (payoutStatusFilter.includes(s)) {
                    setPayoutStatusFilter(payoutStatusFilter.filter(st => st !== s))
                  } else {
                    setPayoutStatusFilter([...payoutStatusFilter, s])
                  }
                }}>
                <Checkbox size={15} checked={payoutStatusFilter.includes(s)} />
                <span>{s}</span>
              </DropdownItem>
            )}
          </div>} />
      </div>

      <Table
        onClickRow={(i) => {
          setSelectedPayoutId(payouts[i].id)
          setIsPayoutModalOpen(true)
        }}
        borderless
        headers={['ID', 'Status', 'Total payout', 'Gas fee', 'Completed', 'Created'].map(h => <span key={h}>{h}</span>)}
        items={
          payouts.map((p, i) => [
            <div key={p.id} style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
              <TextInput ref={r => r && (inputRefs[p.id] = r)} value={p.id} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
              <IconButton style={{ padding: 0 }}>
                <Copy style={{ width: 20 }} onClick={(e) => {
                  inputRefs[p.id].select()
                  inputRefs[p.id].setSelectionRange(0, 99999)
                  navigator.clipboard.writeText(inputRefs[p.id].value)
                  infoToast('Copied payout ID!')
                  e.stopPropagation()
                }} />
              </IconButton>
            </div>,
            <div key={p.status}>
              <StatusBadge status={camelCase(p.status)}>{p.status}</StatusBadge>
            </div>,
            <div key={p.id}>{formatCurrency(p.totalPayoutUsd, 'USD')}</div>,
            <div key={p.id}>{formatCurrency(p.estimatedFeeUsd, 'USD')}</div>,
            <div key={p.id}>{p.completedAt ? <TimeText date={new Date(p.completedAt)} /> : '-'}</div>,
            <div key={p.id}><TimeText date={new Date(p.createdAt)} /></div>
          ])
        }
        isLoading={isLoading}
        emptyPlaceholderText='No payouts yet'
      />

      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalPayoutsSize / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }

    </div>

    {
      isPayoutModalOpen && selectedPayoutId &&
      <PayoutModal
        payoutId={selectedPayoutId}
        visible={isPayoutModalOpen}
        onPayoutConfirmed={async () => {
          await loadPayouts()
          setIsPayoutModalOpen(false)
        }}
        onClose={() => setIsPayoutModalOpen(false)} />
    }
  </Container>
})

export default Payouts

import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import LoadingBar from './LoadingBar'
import Spinner from './Spinner'
import { Chevron } from '../icons/Chevron'

const TableContainer = styled.div<{ numColumns: number, borderless?: boolean }>`
  ${({ borderless }) => !borderless && `
    border-radius: 5px;
    border: 1px solid;
    border-color: ${RADOM_COLORS.GRAY_DARK};
    box-shadow: 0 1px 3px rgb(226 228 239 / 50%);
  `};
  width: 100%;
  border-spacing: 0;
  display: grid;
  grid-template-columns: ${({ numColumns }) => `repeat(${numColumns}, minmax(100px, auto));`}
`

const TableHeader = styled.div<{ sortable: boolean, borderless?: boolean }>`
  border-bottom: 1px solid;
  border-color: ${RADOM_COLORS.GRAY_MED};
  // background-color: rgb(248 248 251 / 50%);
  gap: 10px;
  padding: 15px 20px;
  text-align: left;
  box-shadow: 0 1px 1px rgb(226 228 239 / 25%);
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;

  ${({ sortable }) => sortable && `
    cursor: pointer;
    user-select: none;
  `}

  &:first-child {
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
  }
`

const TableRow = styled.div<{ $clickable?: boolean, $disableHoverStyle?: boolean }>`
  cursor: pointer;
  display: contents;
  color: ${RADOM_COLORS.BLACK};

  ${({ $clickable }) => $clickable && `
    &:active {
      > div {
        opacity: 0.5;
      }
    }
  `}

  ${({ $disableHoverStyle }) => $disableHoverStyle
    ? `
      cursor: initial !important;
    `
    : `
      &:hover {
        > div {
          background-color: #fafafc;
        }
      }
  `}

  &:nth-last-child(2) {
    > div {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 0;
    }
  }
`

const TableCell = styled.div`
  font-size: 14px;
  padding: 20px;
  border-bottom: 1px solid;
  border-color: ${RADOM_COLORS.GRAY_DARK};
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
`

interface HeaderItem {
  key: string
  element: ReactElement
  sortable?: boolean
}

interface IProps {
  headers: Array<ReactElement | HeaderItem>
  items: ReactElement[][]
  isLoading: boolean
  emptyPlaceholderText?: string
  onClickRow?: (i: number) => void
  onSortChange?: (sorts: Sorts) => void
  disableOnClickState?: boolean
  disableHoverStyle?: boolean
  tableContainerStyle?: CSSProperties
  borderless?: boolean
  initialSorts?: Sorts
}

export interface Sorts {
  [headerKey: string]: SortType
}

export enum SortType {
  None,
  Descending,
  Ascending
}

function nextSortType(sortType?: SortType): SortType {
  if (sortType === undefined) {
    return SortType.Descending
  }

  switch (sortType) {
  case SortType.None:
    return SortType.Descending
  case SortType.Descending:
    return SortType.Ascending
  case SortType.Ascending:
    return SortType.Descending
  }
}

function rotationFromSortType(sortType?: SortType): number {
  switch (sortType) {
  case SortType.Descending:
    return 0
  case SortType.Ascending:
    return 180
  }

  return 0
}

const Table = (props: IProps): React.ReactElement => {
  const rowRefs: HTMLDivElement[] = []

  const [sorts, setSorts] = useState<Sorts>(props.initialSorts ?? {})

  const handleRowClick = (i: number): void => {
    if (props.onClickRow) {
      props.onClickRow(i)
    }
  }

  return <TableContainer
    borderless={props.borderless}
    style={props.tableContainerStyle} numColumns={props.headers.length || 1}>
    {
      props.headers.map((header, index) =>
        <TableHeader
          borderless={props.borderless}
          key={index}
          sortable={!!header.sortable}
          onClick={() => {
            if (header.sortable) {
              const newSorts = {
                [header.key]: nextSortType(sorts[header.key])
              }
              setSorts(newSorts)
              props.onSortChange?.(newSorts)
            }
          }}>
          <span>
            {header.element ? header.element : header}
          </span>
          {
            header.sortable &&
            <Chevron style={{
              opacity: !sorts[header.key] ? 0.25 : 1,
              transition: '0.2s ease all',
              transform: `rotate(${rotationFromSortType(sorts[header.key])}deg`
            }} />
          }
        </TableHeader>
      )
    }

    {
      !props.isLoading && props.items.length <= 0 &&
        <div style={{ gridColumn: `span ${props.headers.length}` }}>
          <div style={{ padding: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKER }}>{props.emptyPlaceholderText}</span>
          </div>
        </div>
    }

    {
      props.isLoading && props.items.length <= 0 &&
        Array.from(Array(8)).map(() => props.headers.map((_, i) =>
          <div key={i} style={{ padding: 10 }}>
            <LoadingBar style={{ height: 30, width: '100%' }} />
          </div>
        ))
    }

    {
      props.items.map((tableRow, index) =>
        <TableRow
          key={index}
          ref={ref => { if (ref) rowRefs[index] = ref }}
          $clickable={!!props.onClickRow && !props.disableOnClickState}
          $disableHoverStyle={!!props.disableHoverStyle}>
          {
            tableRow.map((item, i) =>
              <TableCell key={i} onClick={e => {
                if (rowRefs[index].contains(e.target as any)) {
                  handleRowClick(index)
                }
              }}>
                {item}
              </TableCell>
            )
          }
        </TableRow>
      )
    }

    {
      <div style={{
        gridColumn: `span ${props.headers.length}`,
        height: props.isLoading && props.items.length > 0 ? 50 : 0,
        overflow: 'hidden',
        borderTop: props.isLoading && props.items.length > 0 ? `1px solid ${RADOM_COLORS.GRAY_DARK}` : 0,
        transition: '0.5s ease height'
      }}>
        <div style={{ padding: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      </div>
    }
  </TableContainer>
}

export default Table

import React, { ReactElement, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import { LegalAndTaxDetails as State } from '../../state/Radom'
import { BusinessTaxNumberTypes } from './TaxNumberTypes'
import SearchMultiselect from '../../components/SearchMultiselect'
import Tooltip from '../../components/Tooltip'
import { AccountPurpose, SourceOfFunds } from './Util'
import { snakeCase, startCase } from 'lodash'

interface IProps {
  state: State
  countryCode: string
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const LegalAndTaxDetails = ({ state, countryCode, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const canSubmit = useMemo(() =>
    state.accountPurpose &&
    state.sourceOfFunds &&
    state.taxIdentificationNumberType &&
    state.taxIdentificationNumber &&
    state.taxIdentificationNumber !== '',
    [state]
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
      <div style={{ marginBottom: 20 }}>
        <PageHeader title="Share your legal details" subTitle="Provide your legal and tax information" />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        <InputLabel>Account purpose</InputLabel>
        <SearchMultiselect
          isSingleSelect
          placeholder="Select account purpose"
          dropdownPlaceholder="No account purpose found"
          itemArray={AccountPurpose.map(a => ({ key: snakeCase(a), value: startCase(a) })) ?? []}
          selectedItems={state.accountPurpose ?
            [{
              key: state.accountPurpose,
              value: startCase(state.accountPurpose)
            }] :
            []}
          setSelectedItems={(items) => {
            const selected = items[0]
            if (selected) {
              onUpdate({ ...state, accountPurpose: snakeCase(selected.value) })
            }
          }}
          keyExtractor={(country) => country.key}
          labelExtractor={(country) => country.value}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        <InputLabel>Source of funds</InputLabel>
        <SearchMultiselect
          isSingleSelect
          placeholder="Select source of funds"
          dropdownPlaceholder="No source of funds found"
          itemArray={SourceOfFunds.map(a => ({ key: snakeCase(a), value: startCase(a) })) ?? []}
          selectedItems={state.sourceOfFunds ?
            [{
              key: state.sourceOfFunds,
              value: startCase(state.sourceOfFunds)
            }] :
            []}
          setSelectedItems={(items) => {
            const selected = items[0]
            if (selected) {
              onUpdate({ ...state, sourceOfFunds: snakeCase(selected.value) })
            }
          }}
          keyExtractor={(country) => country.key}
          labelExtractor={(country) => country.value}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <InputLabel>
            Business material intermediary ownership
          </InputLabel>
          <Tooltip message='The business has at least one intermediate legal entity owner with 25% or more ownership.' />
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
          onClick={() => onUpdate({ ...state, hasMaterialIntermediaryOwnership: !state.hasMaterialIntermediaryOwnership })}>
          <Checkbox checked={!!state.hasMaterialIntermediaryOwnership} />
          <span>The business has at least 25% intermediary ownership</span>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        <InputLabel>Tax identification number type</InputLabel>
        <SearchMultiselect
          isSingleSelect
          placeholder="Select tax number type"
          dropdownPlaceholder="No tax number types found"
          itemArray={BusinessTaxNumberTypes[countryCode]?.map(a => ({ key: a.description, value: a.value })) ?? []}
          selectedItems={state.taxIdentificationNumberType ?
            [{
              key: state.taxIdentificationNumberType,
              value: state.taxIdentificationNumberType
            }] :
            []}
          setSelectedItems={(items) => {
            const selected = items[0]
            if (selected) {
              onUpdate({ ...state, taxIdentificationNumberType: selected.value })
            }
          }}
          keyExtractor={(country) => country.value}
          labelExtractor={(country) => country.key}
        />
      </div>


      <TextInputWithLabel
        required
        label="Tax identification number"
        placeholder="Enter your tax identification number"
        value={state.taxIdentificationNumber}
        onChange={(event) => {
          const eventTarget = event.target as HTMLInputElement
          onUpdate({ ...state, taxIdentificationNumber: eventTarget.value })
        }}
      />

      <PrimaryButton disabled={!canSubmit} isLoading={isSubmitting} onClick={onSubmit}>
        Continue
      </PrimaryButton>
    </div>
  )
}

export default LegalAndTaxDetails

import { observer } from 'mobx-react'
import React, { ReactElement, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import Modal, { ModalProps } from '../../components/Modal'
import Radom, { FiatAccountDetails } from '../../state/Radom'
import User from '../../state/User'
import { RADOM_COLORS } from '../../util/Constants'
import { DepositMethods } from '../../util/Managed'
import { errorToast, successToast, useTestMode } from '../../util/Util'
import { filter, flattenDeep } from 'lodash'

type AccountModalProps = ModalProps & {
  onAccountCreated: () => void
}

enum AccountType {
  Crypto,
  Fiat
}

enum FiatAccountType {
  US = 'US',
  IBAN = 'EU (IBAN)',
  SWIFT = 'SWIFT',
}

const CreateDepositAccountModal = observer(({ ...props }: AccountModalProps): ReactElement => {
  const [accountName, setAccountName] = useState('')
  const [accountType, setAccountType] = useState<AccountType>(AccountType.Crypto)

  // Crypto options
  const [selectedDepositMethods, setSelectedDepositMethods] = useState<string[]>([])

  // Fiat options
  const [accountOwnerName, setAccountOwnerName] = useState<string>()
  const [fiatAccountType, setFiatAccountType] = useState<FiatAccountType>()
  const [accountOwnerAddress, setAccountOwnerAddress] = useState<string>()
  const [iban, setIban] = useState<string>()
  const [bic, setBic] = useState<string>()
  const [bankName, setBankName] = useState<string>()
  const [accountNumber, setAccountNumber] = useState<string>()
  const [routingNumber, setRoutingNumber] = useState<string>()

  const [isLoading, setIsLoading] = useState(false)
  const [testMode] = useTestMode()

  const createCryptoAccount = async (): Promise<void> => {
    if (selectedDepositMethods.length <= 0) {
      return
    }

    setIsLoading(true)
    try {
      await Radom.createDepositAccount({
        name: accountName,
        accountDetails: {
          Crypto: {
            depositMethods: selectedDepositMethods
              .filter(m => m.includes('_'))
              .map(m => ({
                network: m.split('_')[0],
                token: m.split('_')[1] == 'undefined' ? undefined : m.split('_')[1]
              }))
          }
        }
      })
      props.onAccountCreated()
      successToast('Deposit account created')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const createFiatAccount = async (): Promise<void> => {
    if (
      !fiatAccountType ||
      !accountOwnerName ||
      !accountOwnerAddress
    ) {
      errorToast('')
      return
    }

    if (fiatAccountType === FiatAccountType.IBAN && (!iban || !bic)) {
      return
    }

    if (fiatAccountType === FiatAccountType.US && (!accountName || !routingNumber || !bankName)) {
      return
    }

    let accountType: FiatAccountDetails | undefined
    if (fiatAccountType === FiatAccountType.IBAN && iban && bic) {
      accountType = {
        iban: {
          iban,
          bic,
          address: accountOwnerAddress
        }
      }
    } else if (fiatAccountType === FiatAccountType.US && accountNumber && routingNumber && bankName) {
      accountType = {
        us: {
          accountNumber,
          routingNumber,
          bankName,
          address: accountOwnerAddress
        }
      }
    }

    if (!accountType) {
      return
    }

    setIsLoading(true)
    try {
      // await Radom.createWithdrawalAccount({
      //   name: accountName,
      //   accountDetails: {
      //     Fiat: {
      //       accountOwnerName,
      //       accountOwnerType,
      //       accountType
      //     }
      //   }
      // })
      // props.onAccountCreated()
      successToast('Deposit account created')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const createAccount = async (): Promise<void> => {
    if (accountType === AccountType.Crypto) {
      return await createCryptoAccount()
    } else {
      return await createFiatAccount()
    }
  }

  const renderCryptoForm = (): ReactElement => (
    <>
      <TextInputWithLabel
        label='Name'
        placeholder='Enter a name for this account'
        tooltip='Enter an identifiable name, e.g. your user&apos;s unique ID'
        disabled={isLoading}
        required
        value={accountName}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountName(value)
        }}
      />
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
        <InputLabel>Deposit methods</InputLabel>
        <Dropdown
          innerStyle={{ maxHeight: 300 }}
          disabled={isLoading}
          selectedContent={
            selectedDepositMethods.length > 0
              ? <span style={{ fontSize: 14 }}>{selectedDepositMethods.filter(t => t.includes('_')).length} methods selected</span>
              : <span style={{ fontSize: 14 }}>Select deposit methods</span>
          }
          dropdownContent={
            DepositMethods
              .filter(t => testMode ? t.isTestnet : !t.isTestnet)
              .map(m =>
                <>
                  <DropdownItem
                    onClick={() => {
                      const methods = filter(flattenDeep([m.name, m.subMethods?.map(m => [
                        m.method ? `${m.method.network}_${m.method.token}` : m.name,
                        m.subMethods?.map(n => `${n.method.network}_${n.method.token}`)])
                      ]), m => !!m);
                      let methodsToRemove = methods, methodsToAdd = methods

                      if (selectedDepositMethods.includes(m.name)) {
                        methodsToRemove = methods
                        methodsToAdd = []
                      }

                      setSelectedDepositMethods(
                        [...selectedDepositMethods.filter(m => !methodsToRemove.includes(m)), ...methodsToAdd]
                      )
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>

                      <Checkbox checked={selectedDepositMethods.includes(m.name)} />
                      <div
                        style={{
                          height: 25,
                          width: 25,
                          backgroundImage: `url(${m.logo})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          position: 'relative'
                        }} />

                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontSize: 14 }}>{m.name}</span>
                        <span style={{ fontSize: 12, opacity: 0.5 }}>{m.description}</span>
                      </div>
                    </div>
                  </DropdownItem>
                  {
                    m.subMethods?.map(m => {
                      const method = m.method ? `${m.method.network}_${m.method.token}` : m.name

                      return <div>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          height: 45
                        }}>
                          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, height: '100%', }}>
                            <div style={{ width: 1, height: '100%', backgroundColor: RADOM_COLORS.GRAY8 }} />
                            <div style={{ width: 10, height: 1, backgroundColor: RADOM_COLORS.GRAY8, }} />
                          </div>

                          <DropdownItem
                            onClick={() => {
                              const methods = filter(flattenDeep([method, m.subMethods?.map(n => `${n.method.network}_${n.method.token}`)]), m => !!m);
                              let methodsToAdd = methods, methodsToRemove = methods

                              if (selectedDepositMethods.includes(method)) {
                                methodsToRemove = methods
                                methodsToAdd = []
                              }

                              setSelectedDepositMethods(
                                [...selectedDepositMethods.filter(m => !methodsToRemove.includes(m)), ...methodsToAdd]
                              )
                            }}
                            style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                              <Checkbox checked={selectedDepositMethods.includes(method)} />

                              <div
                                style={{
                                  height: 25,
                                  width: 25,
                                  backgroundImage: `url(${m.logo})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  position: 'relative'
                                }} />

                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontSize: 14 }}>{m.name}</span>
                                <span style={{ fontSize: 12, opacity: 0.5 }}>{m.ticker}</span>
                              </div>

                            </div>
                          </DropdownItem>



                        </div>

                        {
                          m.subMethods?.map(n => {
                            const method = `${n.method.network}_${n.method.token}`;

                            return <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              height: 48
                            }}>
                              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, height: '100%' }}>
                                <div style={{ width: 1, height: '100%', backgroundColor: RADOM_COLORS.GRAY8, }} />
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25, height: '100%' }}>
                                <div style={{ width: 1, height: '100%', backgroundColor: RADOM_COLORS.GRAY8, }} />
                                <div style={{ width: 10, height: 1, backgroundColor: RADOM_COLORS.GRAY8, }} />
                              </div>

                              <DropdownItem
                                onClick={() => {
                                  const methods = [method];
                                  const methodsToRemove = selectedDepositMethods.filter(m => methods.includes(m))
                                  const methodsToAdd = methods.filter(m => !selectedDepositMethods.includes(m))

                                  setSelectedDepositMethods(
                                    [...selectedDepositMethods.filter(m => !methodsToRemove.includes(m)), ...methodsToAdd]
                                  )
                                }}
                                style={{ width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                  <Checkbox checked={selectedDepositMethods.includes(method)} />

                                  <div
                                    style={{
                                      height: 25,
                                      width: 25,
                                      backgroundImage: `url(${n.logo})`,
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'contain',
                                      backgroundPosition: 'center',
                                      position: 'relative'
                                    }} />

                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: 14 }}>{n.name}</span>
                                    <span style={{ fontSize: 12, opacity: 0.5 }}>{n.ticker}</span>
                                  </div>
                                </div>
                              </DropdownItem>

                            </div>
                          })}

                      </div>
                    }
                    )}
                </>


              )
            // getPaymentMethods()
            //   .filter(t => t.isDepositAddressEnabled)
            //   .filter(t => !t.hostChain)
            //   .filter(t => t.name !== selectedCryptoType?.name)
            //   .filter(t => !t.isDisabled)
            //   .filter(t => testMode ? t?.isTestnet : !t?.isTestnet)
            //   .filter(t => !t.isFiat)
            //   .map(t => {
            //     const tokens = [...new Set([...getPaymentMethods().filter(m => m.hostChain?.name == t.name).map(m => m.logo)])].slice(0, 2);

            //     return <DropdownItem
            //       key={t.name}
            //       onClick={() => {
            //         setSelectedCryptoType(t)
            //       }}
            //       style={{
            //         fontSize: 14,
            //         alignItems: 'center',
            //         display: 'flex',
            //         gap: 10
            //       }}>

            //       <div
            //         style={{
            //           height: 25,
            //           width: 25,
            //           backgroundImage: `url(${t.logo})`,
            //           backgroundRepeat: 'no-repeat',
            //           backgroundSize: 'contain',
            //           backgroundPosition: 'center',
            //           position: 'relative'
            //         }}>

            //         <div style={{ position: 'absolute', bottom: -3, right: -3 }}>
            //           {
            //             tokens.map((m, i) =>
            //               <img src={m} style={{ width: 'auto', height: 10, marginLeft: i > 0 ? -5 : 0 }} />
            //             )
            //           }
            //         </div>
            //       </div>

            //       <div style={{ display: 'flex', flexDirection: 'column' }}>
            //         <div>
            //           {t.name}
            //         </div>

            //         <div style={{ fontSize: 12, opacity: 0.5 }}>
            //           Deposit {t.ticker} {tokens.length > 0 && 'and supported tokens'}
            //         </div>
            //       </div>
            //     </DropdownItem>
            //   })
          } />
      </div>
    </>
  )

  const renderBankForm = (): ReactElement => (
    <>
      <TextInputWithLabel
        label='Account nickname'
        placeholder='Enter a nickname for this account'
        disabled={isLoading}
        required
        value={accountName}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountName(value)
        }}
      />

      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
        <InputLabel>Account type</InputLabel>
        <Dropdown
          key={fiatAccountType}
          disabled={isLoading}
          selectedContent={
            fiatAccountType
              ? <div style={{
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
                gap: 10
              }}>
                {fiatAccountType}
              </div>
              : <span style={{ fontSize: 14 }}>Select bank account type</span>
          }
          dropdownContent={
            [FiatAccountType.IBAN, FiatAccountType.US].map(c =>
              <DropdownItem
                key={c}
                onClick={() => {
                  setFiatAccountType(c)
                }}
                style={{
                  fontSize: 14,
                  alignItems: 'center',
                  display: 'flex',
                  gap: 10
                }}>

                {c}
              </DropdownItem>
            )
          } />
      </div>

      {
        fiatAccountType === FiatAccountType.IBAN && <>
          <TextInputWithLabel
            label={'IBAN'}
            placeholder={'Enter IBAN'}
            disabled={isLoading}
            required
            value={iban}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setIban(value)
            }}
          />

          <TextInputWithLabel
            label={'BIC'}
            placeholder={'Enter BIC'}
            disabled={isLoading}
            required
            value={bic}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setBic(value)
            }}
          />
        </>
      }

      {
        fiatAccountType === FiatAccountType.US && <>
          <TextInputWithLabel
            label={'Bank name'}
            placeholder={'Enter bank name, e.g. Chase'}
            disabled={isLoading}
            required
            value={bankName}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setBankName(value)
            }}
          />

          <TextInputWithLabel
            label={'Account number'}
            placeholder={'Enter account number'}
            disabled={isLoading}
            required
            value={accountNumber}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setAccountNumber(value)
            }}
          />

          <TextInputWithLabel
            label={'Routing number'}
            placeholder={'Enter routing number'}
            disabled={isLoading}
            required
            value={routingNumber}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setRoutingNumber(value)
            }}
          />
        </>
      }

      <TextInputWithLabel
        label={'Account owner address'}
        placeholder={'Enter account owner address'}
        disabled={isLoading}
        required
        value={accountOwnerAddress}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountOwnerAddress(value)
        }}
      />
    </>
  )

  const renderFiatBanner = (): ReactElement => {
    if (User.organization?.verificationStatus !== 'Verified') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your organization is unverified</span>
                <span style={{ opacity: 0.5 }}>
                  Your organization is unverified. To enable production payments, please verify your organization.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return <Modal {...props} innerStyles={{ width: 550 }}>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px'
    }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Account type</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => setAccountType(AccountType.Crypto)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: accountType === AccountType.Crypto ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={accountType === AccountType.Crypto} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Crypto</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  Deposit into a crypto address
                </span>
              </div>
            </SecondaryButton>

            <SecondaryButton
              disabled
              onClick={() => setAccountType(AccountType.Fiat)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: accountType === AccountType.Fiat ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={accountType === AccountType.Fiat} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Fiat</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  Deposit into a bank account
                </span>
              </div>
            </SecondaryButton>

          </div>
        </div>

        {accountType === AccountType.Crypto && renderCryptoForm()}

        {
          accountType === AccountType.Fiat &&
          User.organization?.verificationStatus === 'Verified' &&
          renderBankForm()
        }
      </div>
    </div>

    {
      accountType === AccountType.Crypto && <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}>
        <PrimaryButton
          isLoading={isLoading}
          disabled={!accountName || selectedDepositMethods.length <= 0}
          style={{ width: '100%' }}
          onClick={() => {
            createAccount()
          }}>
          Create account
        </PrimaryButton>
      </div>
    }

    {
      accountType === AccountType.Fiat &&
      User.organization?.verificationStatus === 'Verified' &&
      <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: 'sticky',
        bottom: 0,
        background: 'linear-gradient(transparent, white 20%)'
      }}>
        <PrimaryButton
          disabled={
            !fiatAccountType ||
            !accountOwnerName ||
            !accountOwnerAddress ||
            (fiatAccountType === FiatAccountType.IBAN && (!iban || !bic)) ||
            (fiatAccountType === FiatAccountType.US && (!accountNumber || !routingNumber || !bankName))
          }
          isLoading={isLoading}
          style={{ width: '100%' }}
          onClick={() => {
            createAccount()
          }}>
          Create account
        </PrimaryButton>
      </div>
    }

    {accountType === AccountType.Fiat && renderFiatBanner()}
  </Modal>
})

export default CreateDepositAccountModal

export const AccountPurpose = [
  "CharitableDonations",
  "EcommerceRetailPayments",
  "InvestmentPurposes",
  "Other",
  "PaymentsToFriendsOrFamilyAbroad",
  "Payroll",
  "PersonalOrLivingExpenses",
  "ProtectWealth",
  "PurchaseGoodsAndServices",
  "ReceivePaymentsForGoodsAndServices",
  "TaxOptimization",
  "ThirdPartyMoneyTransmission",
  "TreasuryManagement"
]

export const SourceOfFunds = [
  "BusinessLoans",
  "Grants",
  "InterCompanyFunds",
  "InvestmentProceeds",
  "LegalSettlement",
  "OwnersCapital",
  "PensionRetirement",
  "SaleOfAssets",
  "SalesOfGoodsAndServices",
  "TaxRefund",
  "ThirdPartyFunds",
  "TreasuryReserves",
]
import React, { ReactElement, useEffect, useState } from 'react'
import Radom, { Deposit } from '../../state/Radom'
import TimeText from '../../components/TimeText'
import { Container } from '../../components/Animations'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import { getMethod } from '../../util/Managed'
import BreadCrumbs from '../../components/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { Section, SectionContent, SectionItem, SubTitle } from '../../components/PaymentDetails'
import CopyButton from '../../components/CopyButton'
import { RADOM_COLORS } from '../../util/Constants'
import Spinner from '../../components/Spinner'

const Deposit = (): ReactElement => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [deposit, setDeposit] = useState<Deposit>()

  const [refundModalOpen, setRefundModalOpen] = useState(false)

  useEffect(() => {
    if (!params.id) {
      return
    }

    setIsLoading(true)
    Radom.getDeposit(params.id)
      .then(s => setDeposit(s))
      .catch(e => console.error('Failed to look up deposit', e))
      .finally(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (!deposit) {
    return <></>
  }

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <BreadCrumbs items={[
          { item: 'Deposits', to: '/deposits' },
          { item: deposit.id, to: '.' }
        ]} />
      </div>

      <Section>
        <SubTitle style={{ fontSize: 18 }}>
          Deposit details
        </SubTitle>

        <SectionContent>
          <SectionItem>
            <SubTitle>ID</SubTitle>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: 14 }}>
              <span>{deposit.id}</span>
              <CopyButton text={deposit.id} />
            </div>

          </SectionItem>


          <SectionItem>
            <SubTitle>Amount</SubTitle>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: 14 }}>
              {
                deposit.depositMethod.Crypto && <>
                  <PaymentMethodDisplay
                    style={{ marginRight: 5 }}
                    method={{ network: deposit.depositMethod.Crypto.network, token: deposit.depositMethod.Crypto.token }}
                    excludeName
                    disableTooltip={false} />
                  <div style={{ display: 'flex', alignItems: 'center', gap: 5, fontSize: 14 }}>
                    <span>{deposit.netAmount}</span>
                    <span>{getMethod(deposit.depositMethod.Crypto.network, deposit.depositMethod.Crypto.token).ticker}</span>
                  </div>
                </>
              }
            </div>
          </SectionItem>


          <SectionItem>
            <SubTitle>Deposited at</SubTitle>
            <div>
              <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(deposit.depositedAt)} />
            </div>
          </SectionItem>
        </SectionContent>
      </Section>

    </div>
  </Container>
}

export default Deposit

import { parseISO } from 'date-fns'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { IconButton, PrimaryButton, SecondaryButton } from '../../components/Button'
import DateSelector from '../../components/DateSelector'
import FileInput from '../../components/FileInput'
import { InputLabel, OptionalLabel, TextInputWithLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import SearchMultiselect from '../../components/SearchMultiselect'
import Close from '../../icons/Close'
import Edit from '../../icons/Edit'
import { Ubo } from '../../state/Radom'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, } from '../../util/Util'
import { AddressForm } from './AddressForm'
import { IndividualGovernmentIdTypes, IndividualTaxNumberTypes } from './TaxNumberTypes'
import Checkbox from '../../components/Checkbox'
import Tooltip from '../../components/Tooltip'
import { Countries } from './Countries'
import PDFFileUpload from './PDFFileUpload'

export interface UboForm extends Ubo {
  govIdImageFile?: File
  proofOfAddressFile?: File
}

interface IProps {
  state: {
    ubos: UboForm[]
    ubo: UboForm
  }
  isSubmitting: boolean
  canSubmit: boolean
  onUpdate: (value: any) => void
  onAdd: (ubo: UboForm) => Promise<void>
  onEdit: (index: number) => void
  onStopEdit: () => void
  onRemove: (index: number) => void
  onSubmit: () => void
}

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: 1px solid ${RADOM_COLORS.GRAY8};
  border-radius: 10px;
  display: flex;
  list-style: none;
  margin: 16px 0;
  padding: 12px 20px;
  gap: 16px;

  &:first-child {
    margin-top: 0;
  }
    
  &:last-child {
    margin-bottom: 0;
  }
`

const StickingFooter = styled.div`
  position: fixed;
  padding: 20px;
  width: 100vw;
  background-color: white;
  border-top: 1px solid ${RADOM_COLORS.GRAY_DARK};
  top: calc(100vh - 80px);
  left: 0;
`

const Ubos = ({ state, isSubmitting, canSubmit, ...props }: IProps): ReactElement => {
  const [ubo, setUbo] = useState<UboForm>(state.ubo)
  const [isEditing, setIsEditing] = useState(false)

  const isValid = useMemo(() => state.ubos.length > 0, [state.ubos])

  const canSave = useMemo(() => {
    return !!ubo.firstName && !!ubo.lastName && !!ubo.birthDate &&
      !!ubo.email && !!ubo.phone &&
      !!ubo.taxIdentificationNumber && !!ubo.residentialAddress.city &&
      !!ubo.residentialAddress.subdivision && !!ubo.residentialAddress.country &&
      !!ubo.relationshipEstablishedAt && (!!ubo.govIdImageFile || !!ubo.govIdImage) &&
      (!!ubo.proofOfAddress || !!ubo.proofOfAddressFile)
  }, [ubo])

  useEffect(() => {
    setUbo(state.ubo)
  }, [state.ubo])

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>, property: 'govIdImageFile' | 'proofOfAddressFile'): Promise<void> => {
    const fileList = event.target.files

    switch (property) {
      case 'govIdImageFile':
        if (fileList && fileList.length > 0 && fileList[0].type.startsWith('image')) {
          setUbo({ ...ubo, [property]: fileList[0] })
        } else {
          errorToast('Invalid file type. Please upload an image file.')
        }
        break

      case 'proofOfAddressFile':
        if (fileList && fileList.length > 0 && fileList[0].type === 'application/pdf') {
          setUbo({ ...ubo, [property]: fileList[0] })
        } else {
          errorToast('Invalid file type. Please upload an image file.')
        }
    }
  }

  const onSave = (): void => {
    props.onAdd(ubo)
      .then(() => {
        setIsEditing(false)
      }).catch(() => {

      })
  }

  const renderUboForm = (): ReactElement => {
    return (<div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: '100px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <span style={{ fontSize: 16 }}>Edit associated person details</span>
        <IconButton onClick={() => {
          setIsEditing(false)
          props.onStopEdit()
        }}>
          <Close style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
        </IconButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <span style={{ fontSize: 18 }}>Basic details</span>

          <TextInputWithLabel
            label="First name"
            required
            value={ubo.firstName}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              setUbo({ ...ubo, firstName: eventTarget.value })
            }}
          />
          <TextInputWithLabel
            label="Last name"
            required
            value={ubo.lastName}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              setUbo({ ...ubo, lastName: eventTarget.value })
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>Birth date</InputLabel>
            <DateSelector onChange={(date) => setUbo({ ...ubo, birthDate: date?.toISOString().split('T')[0] })} value={ubo.birthDate ? parseISO(ubo.birthDate) : undefined} />
          </div>

          <div style={{ display: 'flex', gap: 20 }}>
            <TextInputWithLabel
              style={{ flexGrow: 1 }}
              label="Email"
              required
              value={ubo.email}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, email: eventTarget.value })
              }}
            />
            <TextInputWithLabel
              style={{ flexGrow: 1 }}
              label="Phone"
              required
              value={ubo.phone}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, phone: eventTarget.value })
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>Government ID type</InputLabel>
            <SearchMultiselect
              isSingleSelect
              placeholder="Select government ID type"
              dropdownPlaceholder="No government ID types found"
              itemArray={IndividualGovernmentIdTypes.map(a => ({ key: a.description, value: a.value }))}
              selectedItems={ubo.govIdImageType ?
                [{
                  key: ubo.govIdImageType,
                  value: ubo.govIdImageType
                }] :
                []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  setUbo({ ...ubo, govIdImageType: selected.value })
                }
              }}
              keyExtractor={(country) => country.value}
              labelExtractor={(country) => country.key}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>Government ID country</InputLabel>
            <SearchMultiselect
              isSingleSelect
              placeholder="Select country"
              dropdownPlaceholder="No countries found"
              itemArray={Countries.map(s => ({ key: s.name, value: s.alpha3 }))}
              selectedItems={ubo.govIdImageCountry ?
                [{
                  key: Countries.find(s => s.alpha3 === ubo.govIdImageCountry)?.name,
                  value: ubo.govIdImageCountry
                }] :
                []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  setUbo({ ...ubo, govIdImageCountry: selected.value })
                }
              }}
              keyExtractor={(country) => country.value}
              labelExtractor={(country) => country.key}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5, }}>
              <InputLabel>
                Government ID
              </InputLabel>
              <OptionalLabel>Image</OptionalLabel>
            </div>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <FileInput
                value={ubo.govIdImageFile}
                onChange={async (evt) => await onFileChange(evt, 'govIdImageFile')}
              />
              {ubo.govIdImageUrl && (
                <img src={ubo.govIdImageUrl} alt="Government ID" style={{ width: 'auto', height: 70, borderRadius: 5 }} />
              )}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <span style={{ fontSize: 18 }}>Residental address</span>
          <AddressForm state={ubo.residentialAddress} onUpdate={v => setUbo({ ...ubo, residentialAddress: v })} />

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5, }}>
              <InputLabel>
                Proof of address
              </InputLabel>
              <OptionalLabel>PDF</OptionalLabel>
            </div>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <PDFFileUpload
                file={ubo.proofOfAddressUrl ?? ubo.proofOfAddressFile}
                onFile={f => setUbo({ ...ubo, proofOfAddressFile: f })}
                onRemove={() => {
                  setUbo({ ...ubo, proofOfAddressUrl: undefined, proofOfAddressFile: undefined })
                }} />
            </div>
          </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <span style={{ fontSize: 18 }}>Legal details</span>


          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>Tax identification number type</InputLabel>
            <SearchMultiselect
              isSingleSelect
              placeholder="Select tax number type"
              dropdownPlaceholder="No tax number types found"
              itemArray={IndividualTaxNumberTypes[ubo.residentialAddress.country]?.map(a => ({ key: a.description, value: a.value })) ?? []}
              selectedItems={ubo.taxIdentificationNumberType ?
                [{
                  key: ubo.taxIdentificationNumberType,
                  value: ubo.taxIdentificationNumberType
                }] :
                []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  setUbo({ ...ubo, taxIdentificationNumberType: selected.value })
                }
              }}
              keyExtractor={(country) => country.value}
              labelExtractor={(country) => country.key}
            />
          </div>

          <TextInputWithLabel
            label="Tax identification number"
            required
            value={ubo.taxIdentificationNumber}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              setUbo({ ...ubo, taxIdentificationNumber: eventTarget.value })
            }}
          />

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <InputLabel>
                Has ownership
              </InputLabel>
              <Tooltip message='True if this person has at least 25% ownership of the business.' />
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
              onClick={() => setUbo({ ...ubo, hasOwnership: !ubo.hasOwnership })}>
              <Checkbox checked={ubo.hasOwnership} />
              <span>Has at least 25% ownership of the business</span>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <InputLabel>
                Has control
              </InputLabel>
              <Tooltip message='True if this is the control person of the company, having significant responsibility to control, manage or influence the activities of the business entity. At least one control person must be specified.' />
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
              onClick={() => setUbo({ ...ubo, hasControl: !ubo.hasControl })}>
              <Checkbox checked={ubo.hasControl} />
              <span>Is the control person of the company</span>
            </div>
          </div>

          {
            ubo.hasControl &&
            <TextInputWithLabel
              label="Title"
              tooltip='The title of this beneficial owner at the company, e.g. CEO, CFO, etc.'
              required
              value={ubo.title}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, title: eventTarget.value })
              }}
            />
          }

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <InputLabel>
                Is signer
              </InputLabel>
              <Tooltip message='True if this person is able to authorize transactions on behalf of the business. At least one signer must be specified.' />
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
              onClick={() => setUbo({ ...ubo, isSigner: !ubo.isSigner })}>
              <Checkbox checked={ubo.isSigner} />
              <span>Is able to authorize transactions on behalf of the business</span>
            </div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>Join date</InputLabel>
            <DateSelector
              onChange={(date) => setUbo({
                ...ubo,
                relationshipEstablishedAt: date?.toISOString().split('T')[0]
              })}
              value={ubo.relationshipEstablishedAt ? parseISO(ubo.relationshipEstablishedAt) : undefined}
            />
          </div>

        </div>
      </div>
    </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
      <div style={{ marginBottom: 20 }}>
        <PageHeader title="Ownership structure" subTitle="Introduce the key individuals who own or control your business" />
      </div>
      {state.ubos.length > 0 && !isEditing && (
        <div style={{ width: '100%', padding: 0 }}>
          {state.ubos.map((ubo, index) => (
            <ListItem key={ubo.email}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <span>{ubo.firstName} {ubo.lastName}</span>
                <span style={{ fontSize: '12px', color: RADOM_COLORS.GRAY4 }}>{ubo.email}</span>
                <span style={{ fontSize: '12px', color: RADOM_COLORS.GRAY4 }}>
                  {
                    [
                      ubo.residentialAddress.streetLine1,
                      ubo.residentialAddress.city,
                      ubo.residentialAddress.subdivision,
                      ubo.residentialAddress.postalCode,
                      ubo.residentialAddress.country
                    ].join(', ')
                  }
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <IconButton style={{ padding: 0 }} onClick={() => {
                  setIsEditing(true)
                  props.onEdit(index)
                }}>
                  <Edit style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
                </IconButton>
                <IconButton style={{ padding: 0 }} onClick={() => props.onRemove(index)}>
                  <Close style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
                </IconButton>
              </div>
            </ListItem>
          ))}
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, overflow: 'scroll' }}>
        {isEditing
          ? renderUboForm()
          : (
            <SecondaryButton onClick={() => setIsEditing(true)}>
              Add associated person
            </SecondaryButton>
          )}
      </div>
      {isEditing
        ? (
          <StickingFooter>
            <PrimaryButton disabled={!canSave} onClick={onSave}>
              Save
            </PrimaryButton>
          </StickingFooter>
        )
        : (

          <PrimaryButton disabled={!isValid || !canSubmit} isLoading={isSubmitting} onClick={props.onSubmit}>
            Submit verification
          </PrimaryButton>
        )}
    </div>
  )
}

export default Ubos

import React, { } from 'react'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import SearchMultiselect from '../../components/SearchMultiselect'
import { Address } from '../../state/Radom'
import { Countries } from './Countries'

interface AddressProps {
  state: Address
  onUpdate: (value: Address) => void
}

export const AddressForm = ({ state, onUpdate }: AddressProps) => {
  const selectedCountry = Countries.find(s => s.alpha3 === state.country);
  const subdivisons = selectedCountry?.subdivisions || []

  return <>
    <div style={{ display: 'flex', gap: 10 }}>
      <div style={{ flex: 1 }}>
        <TextInputWithLabel
          label="Street line 1"
          required
          value={state.streetLine1}
          onChange={(e) => {
            const eventTarget = e.target as HTMLInputElement
            onUpdate({ ...state, streetLine1: eventTarget.value })
          }}
        />
      </div>
      <TextInputWithLabel
        label="Street line 2"
        value={state.streetLine2}
        onChange={(e) => {
          const eventTarget = e.target as HTMLInputElement
          onUpdate({ ...state, streetLine2: eventTarget.value })
        }}
      />
    </div>
    <div style={{ display: 'flex', gap: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        <InputLabel>Country</InputLabel>
        <SearchMultiselect
          isSingleSelect
          placeholder="Select country"
          dropdownPlaceholder="No countries found"
          itemArray={Countries.slice().map(s => ({ key: s.name, value: s.alpha3 }))}
          selectedItems={state.country ?
            [{
              key: selectedCountry?.name,
              value: state.country
            }] :
            []}
          setSelectedItems={(items) => {
            const selected = items[0]
            if (selected) {
              onUpdate({ ...state, country: selected.value, subdivision: '', postalCode: '' })
            }
          }}
          keyExtractor={(country) => country.value}
          labelExtractor={(country) => country.key}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
          <InputLabel>Subdivision</InputLabel>
          <SearchMultiselect
            isSingleSelect
            disabled={!state.country || subdivisons.length <= 0}
            placeholder={!!state.country && subdivisons.length === 0 ? '-' : 'Select a subdivision'}
            dropdownPlaceholder="No subdivisions found"
            itemArray={subdivisons.slice().map(s => ({ key: s.name, value: s.code }))}
            selectedItems={state.subdivision ? [{ key: subdivisons.find(s => s.code == state.subdivision)?.name, value: state.subdivision }] : []}
            setSelectedItems={(items) => {
              const selected = items[0]
              if (selected) {
                onUpdate({ ...state, subdivision: selected.value })
              }
            }}
            keyExtractor={(state) => state.value}
            labelExtractor={(state) => state.key}
          />
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', gap: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        <TextInputWithLabel
          label="City"
          required
          value={state.city}
          onChange={(e) => {
            const eventTarget = e.target as HTMLInputElement
            onUpdate({ ...state, city: eventTarget.value })
          }}
        />
      </div>
      {
        selectedCountry?.postal_code_format && <div style={{ flex: 1 }}>
          <TextInputWithLabel
            label="Postal code"
            required
            value={state.postalCode}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              onUpdate({ ...state, postalCode: eventTarget.value })
            }}
          />
        </div>
      }
    </div>
  </>
}
import React, { type ReactElement, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import FileInput from '../../components/FileInput'
import { InputLabel, OptionalLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import PDFFileUpload from './PDFFileUpload'
import Tooltip from '../../components/Tooltip'

interface IProps {
  state: {
    formationDocument: string
    ownershipDocument: string
    formationDocumentFile?: File
    ownershipDocumentFile?: File
  }
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const Documents = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const isValid = useMemo(() => {
    return (!!state.formationDocument || !!state.formationDocumentFile) &&
      (!!state.ownershipDocument || !!state.ownershipDocumentFile)
  }, [state])

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>, prop: string): Promise<void> => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      onUpdate({ [prop]: fileList[0] })
    }
  }

  return (<div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14, paddingBottom: 30 }}>
    <div style={{ marginBottom: 20 }}>
      <PageHeader title="Document submission" subTitle="Upload required documents for verification" />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5, }}>
        <InputLabel>
          Formation document
        </InputLabel>
        <OptionalLabel>PDF</OptionalLabel>
      </div>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <PDFFileUpload
          file={state.formationDocumentUrl ?? state.formationDocumentFile}
          onFile={f => onUpdate({ ...state, formationDocumentFile: f })}
          onRemove={() => {
            onUpdate({ ...state, formationDocumentUrl: undefined, formationDocumentFile: undefined })
          }} />
      </div>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5, }}>
        <InputLabel>
          Ownership document
        </InputLabel>
        <OptionalLabel>PDF</OptionalLabel>
        <Tooltip message='Provide a document confirming the shareholders of the company and their ownership percentages.' />
      </div>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <PDFFileUpload
          file={state.ownershipDocumentUrl ?? state.ownershipDocumentFile}
          onFile={f => onUpdate({ ...state, ownershipDocumentFile: f })}
          onRemove={() => {
            onUpdate({ ...state, ownershipDocumentUrl: undefined, ownershipDocumentFile: undefined })
          }} />
      </div>
    </div>

    <PrimaryButton disabled={!isValid} isLoading={isSubmitting} onClick={onSubmit}>
      Continue
    </PrimaryButton>
  </div>
  )
}

export default Documents

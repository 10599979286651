import React, { ReactElement, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import Radom from '../../state/Radom'
import { InputLabel, TextInput } from '../../components/Input'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, successToast } from '../../util/Util'
import PageHeader from '../../components/PageHeader'
import { useNavigate } from 'react-router-dom'
import PermissionSelector, { Permissions } from '../../components/PermissionSelector'

const CreateAPIRole = (): ReactElement => {
  const navigate = useNavigate()
  const [isCreatingRole, setIsCreatingRole] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [permissions, setPermissions] = useState(
    Permissions.reduce((t, c) => {
      if (!c.writeOnly) {
        t[`Read${c.resource}`] = false
      }

      if (!c.readOnly) {
        t[`Write${c.resource}`] = false
      }

      return t
    }, {})
  )

  const createAPIRole = async (): Promise<void> => {
    setIsCreatingRole(true)
    Radom.createPermissionRole({
      name: roleName,
      permissions: Object.entries(permissions)
        .filter(([_, b]) => !!b)
        .reduce<string[]>((t, [permissionName]) => {
        t.push(permissionName)
        return t
      }, [])
    })
      .then(() => {
        successToast('Successfully created role')
        navigate('/team/roles')
      })
      .catch((err) => {
        const msg = err.response
          ? typeof err.response.data === 'object'
            ? err.response.data.error
            : err.response.data
          : err.message
        errorToast(msg)
      })
      .finally(() => {
        setIsCreatingRole(false)
      })
  }

  return (
    <div
      style={{
        maxWidth: 960,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '40px auto'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: 30
        }}
      >
        <PageHeader title="Create role" subTitle="Create a custom permission role to assign to users" />
      </div>

      <form
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        onSubmit={(e) => {
          e.preventDefault()
          createAPIRole()
        }}
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
          <div style={{ display: 'flex', maxWidth: 500, flexDirection: 'column', gap: 10 }}>
            <InputLabel>Role name</InputLabel>
            <TextInput placeholder="E.g. Admin" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>Permission set</InputLabel>
            <PermissionSelector
              permissions={permissions}
              onPermissionsChange={p => setPermissions(p)}
            />
          </div>
        </div>
        <div
          style={{
            padding: '20px 0',
            borderTop: `1px solid ${RADOM_COLORS.GRAY_LIGHTEST}`,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <PrimaryButton isLoading={isCreatingRole}>Create role</PrimaryButton>
        </div>
      </form>
    </div>
  )
}

export default CreateAPIRole

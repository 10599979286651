import React from 'react'
import styled, { keyframes } from 'styled-components'

const Dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const AnimatedPath = styled.path<{ animationDelay: string, animationSpeed: string }>`
  animation: ${Dash} ${({ animationSpeed }) => animationSpeed} cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
  animation-delay: ${({ animationDelay }) => animationDelay};
`

interface CheckMarkProps extends React.SVGProps<SVGSVGElement> {
  animationDelay?: string
  animationSpeed?: string
}

function Checkmark({ animationDelay = '0.3s', animationSpeed = '0.5s', ...props }: CheckMarkProps): React.ReactElement {
  return <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={5} {...props}>
    <AnimatedPath
      animationDelay={animationDelay}
      animationSpeed={animationSpeed}
      stroke='currentColor'
      strokeDashoffset={100}
      strokeDasharray={100}
      d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
}

export default Checkmark

import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.60376" width="48" height="48" rx="8" fill="#EB3B5A" />
    <path d="M32.6038 16L16.6038 32" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.6038 16L32.6038 32" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
}

export default S

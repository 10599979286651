export const Countries = [
  {
    "name": "Afghanistan",
    "alpha3": "AFG",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Balkh",
        "code": "BAL"
      },
      {
        "name": "Bamian",
        "code": "BAM"
      },
      {
        "name": "Badghis",
        "code": "BDG"
      },
      {
        "name": "Badakhshan",
        "code": "BDS"
      },
      {
        "name": "Baghlan",
        "code": "BGL"
      },
      {
        "name": "Daykondi",
        "code": "DAY"
      },
      {
        "name": "Farah",
        "code": "FRA"
      },
      {
        "name": "Faryab",
        "code": "FYB"
      },
      {
        "name": "Ghazni",
        "code": "GHA"
      },
      {
        "name": "Ghowr",
        "code": "GHO"
      },
      {
        "name": "Helmand",
        "code": "HEL"
      },
      {
        "name": "Herat",
        "code": "HER"
      },
      {
        "name": "Jowzjan",
        "code": "JOW"
      },
      {
        "name": "Kabul [Kabol]",
        "code": "KAB"
      },
      {
        "name": "Kandahar",
        "code": "KAN"
      },
      {
        "name": "Kapisa",
        "code": "KAP"
      },
      {
        "name": "Kondoz [Kunduz]",
        "code": "KDZ"
      },
      {
        "name": "Khowst",
        "code": "KHO"
      },
      {
        "name": "Konar [Kunar]",
        "code": "KNR"
      },
      {
        "name": "Laghman",
        "code": "LAG"
      },
      {
        "name": "لوګر ولايت",
        "code": "LOG"
      },
      {
        "name": "Nangrahar [Nangarhar]",
        "code": "NAN"
      },
      {
        "name": "Nimruz",
        "code": "NIM"
      },
      {
        "name": "Nurestan",
        "code": "NUR"
      },
      {
        "name": "Panjshir",
        "code": "PAN"
      },
      {
        "name": "Parwan",
        "code": "PAR"
      },
      {
        "name": "Paktia",
        "code": "PIA"
      },
      {
        "name": "Paktika",
        "code": "PKA"
      },
      {
        "name": "Samangan",
        "code": "SAM"
      },
      {
        "name": "Sar-e Pol",
        "code": "SAR"
      },
      {
        "name": "Takhar",
        "code": "TAK"
      },
      {
        "name": "روزګان ولايت",
        "code": "URU"
      },
      {
        "name": "Wardak [Wardag]",
        "code": "WAR"
      },
      {
        "name": "Zabol [Zabul]",
        "code": "ZAB"
      }
    ]
  },
  {
    "name": "Åland Islands",
    "alpha3": "ALA",
    "postal_code_format": "\\A22\\d{3}\\Z",
    "subdivisions": []
  },
  {
    "name": "Albania",
    "alpha3": "ALB",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Berat",
        "code": "01"
      },
      {
        "name": "Durrës",
        "code": "02"
      },
      {
        "name": "Elbasan",
        "code": "03"
      },
      {
        "name": "Fier",
        "code": "04"
      },
      {
        "name": "Gjirokastër",
        "code": "05"
      },
      {
        "name": "Korçë",
        "code": "06"
      },
      {
        "name": "Kukës",
        "code": "07"
      },
      {
        "name": "Lezhë",
        "code": "08"
      },
      {
        "name": "Dibër",
        "code": "09"
      },
      {
        "name": "Shkodër",
        "code": "10"
      },
      {
        "name": "Tiranë",
        "code": "11"
      },
      {
        "name": "Vlorë",
        "code": "12"
      }
    ]
  },
  {
    "name": "Algeria",
    "alpha3": "DZA",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Adrar",
        "code": "01"
      },
      {
        "name": "Chlef",
        "code": "02"
      },
      {
        "name": "Laghouat",
        "code": "03"
      },
      {
        "name": "Oum el Bouaghi",
        "code": "04"
      },
      {
        "name": "Batna",
        "code": "05"
      },
      {
        "name": "Béjaïa",
        "code": "06"
      },
      {
        "name": "Biskra",
        "code": "07"
      },
      {
        "name": "Béchar",
        "code": "08"
      },
      {
        "name": "Blida",
        "code": "09"
      },
      {
        "name": "Bouira",
        "code": "10"
      },
      {
        "name": "Tamanghasset",
        "code": "11"
      },
      {
        "name": "Tébessa",
        "code": "12"
      },
      {
        "name": "Tlemcen",
        "code": "13"
      },
      {
        "name": "Tiaret",
        "code": "14"
      },
      {
        "name": "Tizi Ouzou",
        "code": "15"
      },
      {
        "name": "Alger",
        "code": "16"
      },
      {
        "name": "Djelfa",
        "code": "17"
      },
      {
        "name": "Jijel",
        "code": "18"
      },
      {
        "name": "Sétif",
        "code": "19"
      },
      {
        "name": "Saïda",
        "code": "20"
      },
      {
        "name": "Skikda",
        "code": "21"
      },
      {
        "name": "Sidi Bel Abbès",
        "code": "22"
      },
      {
        "name": "Annaba",
        "code": "23"
      },
      {
        "name": "Guelma",
        "code": "24"
      },
      {
        "name": "Constantine",
        "code": "25"
      },
      {
        "name": "Médéa",
        "code": "26"
      },
      {
        "name": "Mostaganem",
        "code": "27"
      },
      {
        "name": "Msila",
        "code": "28"
      },
      {
        "name": "Mascara",
        "code": "29"
      },
      {
        "name": "Ouargla",
        "code": "30"
      },
      {
        "name": "Oran",
        "code": "31"
      },
      {
        "name": "El Bayadh",
        "code": "32"
      },
      {
        "name": "Illizi",
        "code": "33"
      },
      {
        "name": "Bordj Bou Arréridj",
        "code": "34"
      },
      {
        "name": "Boumerdès",
        "code": "35"
      },
      {
        "name": "El Tarf",
        "code": "36"
      },
      {
        "name": "Tindouf",
        "code": "37"
      },
      {
        "name": "Tissemsilt",
        "code": "38"
      },
      {
        "name": "El Oued",
        "code": "39"
      },
      {
        "name": "Khenchela",
        "code": "40"
      },
      {
        "name": "Souk Ahras",
        "code": "41"
      },
      {
        "name": "Tipaza",
        "code": "42"
      },
      {
        "name": "Mila",
        "code": "43"
      },
      {
        "name": "Aïn Defla",
        "code": "44"
      },
      {
        "name": "Naama",
        "code": "45"
      },
      {
        "name": "Aïn Témouchent",
        "code": "46"
      },
      {
        "name": "Ghardaïa",
        "code": "47"
      },
      {
        "name": "Relizane",
        "code": "48"
      }
    ]
  },
  {
    "name": "American Samoa",
    "alpha3": "ASM",
    "postal_code_format": "\\A(96799)(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": []
  },
  {
    "name": "Andorra",
    "alpha3": "AND",
    "postal_code_format": "\\AAD[1-7]0\\d\\Z",
    "subdivisions": [
      {
        "name": "Canillo",
        "code": "02"
      },
      {
        "name": "Encamp",
        "code": "03"
      },
      {
        "name": "La Massana",
        "code": "04"
      },
      {
        "name": "Ordino",
        "code": "05"
      },
      {
        "name": "Sant Julià de Lòria",
        "code": "06"
      },
      {
        "name": "Andorra la Vella",
        "code": "07"
      },
      {
        "name": "Escaldes-Engordany",
        "code": "08"
      }
    ]
  },
  {
    "name": "Angola",
    "alpha3": "AGO",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bengo",
        "code": "BGO"
      },
      {
        "name": "Benguela",
        "code": "BGU"
      },
      {
        "name": "Bié",
        "code": "BIE"
      },
      {
        "name": "Cabinda",
        "code": "CAB"
      },
      {
        "name": "Cuando-Cubango",
        "code": "CCU"
      },
      {
        "name": "Cunene",
        "code": "CNN"
      },
      {
        "name": "Cuanza Norte",
        "code": "CNO"
      },
      {
        "name": "Cuanza Sul",
        "code": "CUS"
      },
      {
        "name": "Huambo",
        "code": "HUA"
      },
      {
        "name": "Huíla",
        "code": "HUI"
      },
      {
        "name": "Lunda Norte",
        "code": "LNO"
      },
      {
        "name": "Lunda Sul",
        "code": "LSU"
      },
      {
        "name": "Luanda",
        "code": "LUA"
      },
      {
        "name": "Malange",
        "code": "MAL"
      },
      {
        "name": "Moxico",
        "code": "MOX"
      },
      {
        "name": "Namibe",
        "code": "NAM"
      },
      {
        "name": "Uíge",
        "code": "UIG"
      },
      {
        "name": "Zaire",
        "code": "ZAI"
      }
    ]
  },
  {
    "name": "Anguilla",
    "alpha3": "AIA",
    "postal_code_format": "\\A(?:AI-)?2640\\Z",
    "subdivisions": []
  },
  {
    "name": "Antarctica",
    "alpha3": "ATA",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Antigua and Barbuda",
    "alpha3": "ATG",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Saint George",
        "code": "03"
      },
      {
        "name": "Saint John’s",
        "code": "04"
      },
      {
        "name": "Saint Mary",
        "code": "05"
      },
      {
        "name": "Saint Paul",
        "code": "06"
      },
      {
        "name": "Saint Peter",
        "code": "07"
      },
      {
        "name": "Saint Philip",
        "code": "08"
      },
      {
        "name": "Barbuda",
        "code": "10"
      },
      {
        "name": "Redonda",
        "code": "11"
      }
    ]
  },
  {
    "name": "Argentina",
    "alpha3": "ARG",
    "postal_code_format": "\\A((?:[A-HJ-NP-Z])?\\d{4})([A-Z]{3})?\\Z",
    "subdivisions": [
      {
        "name": "Salta",
        "code": "A"
      },
      {
        "name": "Buenos Aires",
        "code": "B"
      },
      {
        "name": "Capital federal",
        "code": "C"
      },
      {
        "name": "San Luis",
        "code": "D"
      },
      {
        "name": "Entre Ríos",
        "code": "E"
      },
      {
        "name": "La Rioja",
        "code": "F"
      },
      {
        "name": "Santiago del Estero",
        "code": "G"
      },
      {
        "name": "Chaco",
        "code": "H"
      },
      {
        "name": "San Juan",
        "code": "J"
      },
      {
        "name": "Catamarca",
        "code": "K"
      },
      {
        "name": "La Pampa",
        "code": "L"
      },
      {
        "name": "Mendoza",
        "code": "M"
      },
      {
        "name": "Misiones",
        "code": "N"
      },
      {
        "name": "Formosa",
        "code": "P"
      },
      {
        "name": "Neuquén",
        "code": "Q"
      },
      {
        "name": "Río Negro",
        "code": "R"
      },
      {
        "name": "Santa Fe",
        "code": "S"
      },
      {
        "name": "Tucumán",
        "code": "T"
      },
      {
        "name": "Chubut",
        "code": "U"
      },
      {
        "name": "Tierra del Fuego",
        "code": "V"
      },
      {
        "name": "Corrientes",
        "code": "W"
      },
      {
        "name": "Córdoba",
        "code": "X"
      },
      {
        "name": "Jujuy",
        "code": "Y"
      },
      {
        "name": "Santa Cruz",
        "code": "Z"
      }
    ]
  },
  {
    "name": "Armenia",
    "alpha3": "ARM",
    "postal_code_format": "\\A(?:37)?\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Aragacotn",
        "code": "AG"
      },
      {
        "name": "Ararat",
        "code": "AR"
      },
      {
        "name": "Armavir",
        "code": "AV"
      },
      {
        "name": "Erevan",
        "code": "ER"
      },
      {
        "name": "Gegark'unik'",
        "code": "GR"
      },
      {
        "name": "Kotayk'",
        "code": "KT"
      },
      {
        "name": "Lory",
        "code": "LO"
      },
      {
        "name": "Širak",
        "code": "SH"
      },
      {
        "name": "Syunik'",
        "code": "SU"
      },
      {
        "name": "Tavuš",
        "code": "TV"
      },
      {
        "name": "Vayoc Jor",
        "code": "VD"
      }
    ]
  },
  {
    "name": "Aruba",
    "alpha3": "ABW",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Australia",
    "alpha3": "AUS",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Australian Capital Territory",
        "code": "ACT"
      },
      {
        "name": "New South Wales",
        "code": "NSW"
      },
      {
        "name": "Northern Territory",
        "code": "NT"
      },
      {
        "name": "Queensland",
        "code": "QLD"
      },
      {
        "name": "South Australia",
        "code": "SA"
      },
      {
        "name": "Tasmania",
        "code": "TAS"
      },
      {
        "name": "Victoria",
        "code": "VIC"
      },
      {
        "name": "Western Australia",
        "code": "WA"
      }
    ]
  },
  {
    "name": "Austria",
    "alpha3": "AUT",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Burgenland",
        "code": "1"
      },
      {
        "name": "Kärnten",
        "code": "2"
      },
      {
        "name": "Niederösterreich",
        "code": "3"
      },
      {
        "name": "Oberösterreich",
        "code": "4"
      },
      {
        "name": "Salzburg",
        "code": "5"
      },
      {
        "name": "Steiermark",
        "code": "6"
      },
      {
        "name": "Tirol",
        "code": "7"
      },
      {
        "name": "Vorarlberg",
        "code": "8"
      },
      {
        "name": "Wien",
        "code": "9"
      }
    ]
  },
  {
    "name": "Azerbaijan",
    "alpha3": "AZE",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Abseron",
        "code": "ABS"
      },
      {
        "name": "Agstafa",
        "code": "AGA"
      },
      {
        "name": "Agcabädi",
        "code": "AGC"
      },
      {
        "name": "Agdam",
        "code": "AGM"
      },
      {
        "name": "Agdas",
        "code": "AGS"
      },
      {
        "name": "Agsu",
        "code": "AGU"
      },
      {
        "name": "Astara",
        "code": "AST"
      },
      {
        "name": "Baki",
        "code": "BA"
      },
      {
        "name": "Babäk",
        "code": "BAB"
      },
      {
        "name": "Balakän",
        "code": "BAL"
      },
      {
        "name": "Bärdä",
        "code": "BAR"
      },
      {
        "name": "Beyläqan",
        "code": "BEY"
      },
      {
        "name": "Biläsuvar",
        "code": "BIL"
      },
      {
        "name": "Cäbrayil",
        "code": "CAB"
      },
      {
        "name": "Cälilabab",
        "code": "CAL"
      },
      {
        "name": "Culfa",
        "code": "CUL"
      },
      {
        "name": "Daskäsän",
        "code": "DAS"
      },
      {
        "name": "Füzuli",
        "code": "FUZ"
      },
      {
        "name": "Gäncä",
        "code": "GA"
      },
      {
        "name": "Gädäbäy",
        "code": "GAD"
      },
      {
        "name": "Goranboy",
        "code": "GOR"
      },
      {
        "name": "Göyçay",
        "code": "GOY"
      },
      {
        "name": "Göygöl",
        "code": "GYG"
      },
      {
        "name": "Haciqabul",
        "code": "HAC"
      },
      {
        "name": "Imisli",
        "code": "IMI"
      },
      {
        "name": "Ismayilli",
        "code": "ISM"
      },
      {
        "name": "Kälbäcär",
        "code": "KAL"
      },
      {
        "name": "Kəngərli",
        "code": "KAN"
      },
      {
        "name": "Kürdämir",
        "code": "KUR"
      },
      {
        "name": "Länkäran City",
        "code": "LA"
      },
      {
        "name": "Laçin",
        "code": "LAC"
      },
      {
        "name": "Länkäran",
        "code": "LAN"
      },
      {
        "name": "Lerik",
        "code": "LER"
      },
      {
        "name": "Masalli",
        "code": "MAS"
      },
      {
        "name": "Mingäçevir",
        "code": "MI"
      },
      {
        "name": "Naftalan",
        "code": "NA"
      },
      {
        "name": "Neftçala",
        "code": "NEF"
      },
      {
        "name": "Naxçıvan",
        "code": "NV"
      },
      {
        "name": "Naxçivan",
        "code": "NX"
      },
      {
        "name": "Oguz",
        "code": "OGU"
      },
      {
        "name": "Ordubad",
        "code": "ORD"
      },
      {
        "name": "Qäbälä",
        "code": "QAB"
      },
      {
        "name": "Qax",
        "code": "QAX"
      },
      {
        "name": "Qazax",
        "code": "QAZ"
      },
      {
        "name": "Quba",
        "code": "QBA"
      },
      {
        "name": "Qubadli",
        "code": "QBI"
      },
      {
        "name": "Qobustan",
        "code": "QOB"
      },
      {
        "name": "Qusar",
        "code": "QUS"
      },
      {
        "name": "Säki City",
        "code": "SA"
      },
      {
        "name": "Sabirabad",
        "code": "SAB"
      },
      {
        "name": "Sädäräk",
        "code": "SAD"
      },
      {
        "name": "Sahbuz",
        "code": "SAH"
      },
      {
        "name": "Säki",
        "code": "SAK"
      },
      {
        "name": "Salyan",
        "code": "SAL"
      },
      {
        "name": "Särur",
        "code": "SAR"
      },
      {
        "name": "Saatli",
        "code": "SAT"
      },
      {
        "name": "Şabran (rayon)",
        "code": "SBN"
      },
      {
        "name": "Siyäzän",
        "code": "SIY"
      },
      {
        "name": "Sämkir",
        "code": "SKR"
      },
      {
        "name": "Sumqayit",
        "code": "SM"
      },
      {
        "name": "Samaxi",
        "code": "SMI"
      },
      {
        "name": "Samux",
        "code": "SMX"
      },
      {
        "name": "Şirvan",
        "code": "SR"
      },
      {
        "name": "Susa",
        "code": "SUS"
      },
      {
        "name": "Tärtär",
        "code": "TAR"
      },
      {
        "name": "Tovuz",
        "code": "TOV"
      },
      {
        "name": "Ucar",
        "code": "UCA"
      },
      {
        "name": "Xankändi",
        "code": "XA"
      },
      {
        "name": "Xaçmaz",
        "code": "XAC"
      },
      {
        "name": "Xocali",
        "code": "XCI"
      },
      {
        "name": "Xizi",
        "code": "XIZ"
      },
      {
        "name": "Xocavänd",
        "code": "XVD"
      },
      {
        "name": "Yardimli",
        "code": "YAR"
      },
      {
        "name": "Yevlax City",
        "code": "YE"
      },
      {
        "name": "Yevlax",
        "code": "YEV"
      },
      {
        "name": "Zängilan",
        "code": "ZAN"
      },
      {
        "name": "Zaqatala",
        "code": "ZAQ"
      },
      {
        "name": "Zärdab",
        "code": "ZAR"
      }
    ]
  },
  {
    "name": "Bahamas",
    "alpha3": "BHS",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Acklins",
        "code": "AK"
      },
      {
        "name": "Bimini",
        "code": "BI"
      },
      {
        "name": "Black Point",
        "code": "BP"
      },
      {
        "name": "Berry Islands",
        "code": "BY"
      },
      {
        "name": "Central Eleuthera",
        "code": "CE"
      },
      {
        "name": "Cat Island",
        "code": "CI"
      },
      {
        "name": "Crooked Island",
        "code": "CK"
      },
      {
        "name": "Central Abaco",
        "code": "CO"
      },
      {
        "name": "Central Andros",
        "code": "CS"
      },
      {
        "name": "East Grand Bahama",
        "code": "EG"
      },
      {
        "name": "Exuma",
        "code": "EX"
      },
      {
        "name": "Freeport",
        "code": "FP"
      },
      {
        "name": "Grand Cay",
        "code": "GC"
      },
      {
        "name": "Harbour Island",
        "code": "HI"
      },
      {
        "name": "Hope Town",
        "code": "HT"
      },
      {
        "name": "Inagua",
        "code": "IN"
      },
      {
        "name": "Long Island",
        "code": "LI"
      },
      {
        "name": "Mangrove Cay",
        "code": "MC"
      },
      {
        "name": "Mayaguana",
        "code": "MG"
      },
      {
        "name": "Moore’s Island",
        "code": "MI"
      },
      {
        "name": "North Eleuthera",
        "code": "NE"
      },
      {
        "name": "North Abaco",
        "code": "NO"
      },
      {
        "name": "New Providence",
        "code": "NP"
      },
      {
        "name": "North Andros",
        "code": "NS"
      },
      {
        "name": "Rum Cay",
        "code": "RC"
      },
      {
        "name": "Ragged Island",
        "code": "RI"
      },
      {
        "name": "South Andros",
        "code": "SA"
      },
      {
        "name": "South Eleuthera",
        "code": "SE"
      },
      {
        "name": "South Abaco",
        "code": "SO"
      },
      {
        "name": "San Salvador",
        "code": "SS"
      },
      {
        "name": "Spanish Wells",
        "code": "SW"
      },
      {
        "name": "West Grand Bahama",
        "code": "WG"
      }
    ]
  },
  {
    "name": "Bahrain",
    "alpha3": "BHR",
    "postal_code_format": "\\A(?:\\d|1[0-2])\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Al Manamah (Al ‘Asimah)",
        "code": "13"
      },
      {
        "name": "Al Janubiyah",
        "code": "14"
      },
      {
        "name": "Al Muharraq",
        "code": "15"
      },
      {
        "name": "Ash Shamaliyah",
        "code": "17"
      }
    ]
  },
  {
    "name": "Bangladesh",
    "alpha3": "BGD",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Bandarban zila",
        "code": "01"
      },
      {
        "name": "Barguna zila",
        "code": "02"
      },
      {
        "name": "Bogra zila",
        "code": "03"
      },
      {
        "name": "Brahmanbaria zila",
        "code": "04"
      },
      {
        "name": "Bagerhat zila",
        "code": "05"
      },
      {
        "name": "Barisal zila",
        "code": "06"
      },
      {
        "name": "Bhola zila",
        "code": "07"
      },
      {
        "name": "Comilla zila",
        "code": "08"
      },
      {
        "name": "Chandpur zila",
        "code": "09"
      },
      {
        "name": "Chittagong zila",
        "code": "10"
      },
      {
        "name": "Cox's Bazar zila",
        "code": "11"
      },
      {
        "name": "Chuadanga zila",
        "code": "12"
      },
      {
        "name": "Dhaka zila",
        "code": "13"
      },
      {
        "name": "Dinajpur zila",
        "code": "14"
      },
      {
        "name": "Faridpur zila",
        "code": "15"
      },
      {
        "name": "Feni zila",
        "code": "16"
      },
      {
        "name": "Gopalganj zila",
        "code": "17"
      },
      {
        "name": "Gazipur zila",
        "code": "18"
      },
      {
        "name": "Gaibandha zila",
        "code": "19"
      },
      {
        "name": "Habiganj zila",
        "code": "20"
      },
      {
        "name": "Jamalpur zila",
        "code": "21"
      },
      {
        "name": "Jessore zila",
        "code": "22"
      },
      {
        "name": "Jhenaidah zila",
        "code": "23"
      },
      {
        "name": "Jaipurhat zila",
        "code": "24"
      },
      {
        "name": "Jhalakati zila",
        "code": "25"
      },
      {
        "name": "Kishoreganj zila",
        "code": "26"
      },
      {
        "name": "Khulna zila",
        "code": "27"
      },
      {
        "name": "Kurigram zila",
        "code": "28"
      },
      {
        "name": "Khagrachari zila",
        "code": "29"
      },
      {
        "name": "Kushtia zila",
        "code": "30"
      },
      {
        "name": "Lakshmipur zila",
        "code": "31"
      },
      {
        "name": "Lalmonirhat zila",
        "code": "32"
      },
      {
        "name": "Manikganj zila",
        "code": "33"
      },
      {
        "name": "Mymensingh zila",
        "code": "34"
      },
      {
        "name": "Munshiganj zila",
        "code": "35"
      },
      {
        "name": "Madaripur zila",
        "code": "36"
      },
      {
        "name": "Magura zila",
        "code": "37"
      },
      {
        "name": "Moulvibazar zila",
        "code": "38"
      },
      {
        "name": "Meherpur zila",
        "code": "39"
      },
      {
        "name": "Narayanganj zila",
        "code": "40"
      },
      {
        "name": "Netrakona zila",
        "code": "41"
      },
      {
        "name": "Narsingdi zila",
        "code": "42"
      },
      {
        "name": "Narail zila",
        "code": "43"
      },
      {
        "name": "Natore zila",
        "code": "44"
      },
      {
        "name": "Nawabganj zila",
        "code": "45"
      },
      {
        "name": "Nilphamari zila",
        "code": "46"
      },
      {
        "name": "Noakhali zila",
        "code": "47"
      },
      {
        "name": "Naogaon zila",
        "code": "48"
      },
      {
        "name": "Pabna zila",
        "code": "49"
      },
      {
        "name": "Pirojpur zila",
        "code": "50"
      },
      {
        "name": "Patuakhali zila",
        "code": "51"
      },
      {
        "name": "Panchagarh zila",
        "code": "52"
      },
      {
        "name": "Rajbari zila",
        "code": "53"
      },
      {
        "name": "Rajshahi zila",
        "code": "54"
      },
      {
        "name": "Rangpur zila",
        "code": "55"
      },
      {
        "name": "Rangamati zila",
        "code": "56"
      },
      {
        "name": "Sherpur zila",
        "code": "57"
      },
      {
        "name": "Satkhira zila",
        "code": "58"
      },
      {
        "name": "Sirajganj zila",
        "code": "59"
      },
      {
        "name": "Sylhet zila",
        "code": "60"
      },
      {
        "name": "Sunamganj zila",
        "code": "61"
      },
      {
        "name": "Shariatpur zila",
        "code": "62"
      },
      {
        "name": "Tangail zila",
        "code": "63"
      },
      {
        "name": "Thakurgaon zila",
        "code": "64"
      },
      {
        "name": "বরিশাল বিভাগ",
        "code": "A"
      },
      {
        "name": "চট্টগ্রাম বিভাগ",
        "code": "B"
      },
      {
        "name": "ঢাকা বিভাগ",
        "code": "C"
      },
      {
        "name": "খুলনা বিভাগ",
        "code": "D"
      },
      {
        "name": "রাজশাহী বিভাগ",
        "code": "E"
      },
      {
        "name": "রংপুর বিভাগ",
        "code": "F"
      },
      {
        "name": "সিলেট বিভাগ",
        "code": "G"
      },
      {
        "name": "ময়মনসিংহ বিভাগ",
        "code": "H"
      }
    ]
  },
  {
    "name": "Barbados",
    "alpha3": "BRB",
    "postal_code_format": "\\ABB\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Christ Church",
        "code": "01"
      },
      {
        "name": "Saint Andrew",
        "code": "02"
      },
      {
        "name": "Saint George",
        "code": "03"
      },
      {
        "name": "Saint James",
        "code": "04"
      },
      {
        "name": "Saint John",
        "code": "05"
      },
      {
        "name": "Saint Joseph",
        "code": "06"
      },
      {
        "name": "Saint Lucy",
        "code": "07"
      },
      {
        "name": "Saint Michael",
        "code": "08"
      },
      {
        "name": "Saint Peter",
        "code": "09"
      },
      {
        "name": "Saint Philip",
        "code": "10"
      },
      {
        "name": "Saint Thomas",
        "code": "11"
      }
    ]
  },
  {
    "name": "Belarus",
    "alpha3": "BLR",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Brestskaya voblasts' (be) Brestskaya oblast' (ru)",
        "code": "BR"
      },
      {
        "name": "Мінск",
        "code": "HM"
      },
      {
        "name": "Homyel'skaya voblasts' (be) Gomel'skaya oblast' (ru)",
        "code": "HO"
      },
      {
        "name": "Hrodzenskaya voblasts' (be) Grodnenskaya oblast' (ru)",
        "code": "HR"
      },
      {
        "name": "Mahilyowskaya voblasts' (be) Mogilevskaya oblast' (ru)",
        "code": "MA"
      },
      {
        "name": "Minskaya voblasts' (be) Minskaya oblast' (ru)",
        "code": "MI"
      },
      {
        "name": "Vitsyebskaya voblasts' (be) Vitebskaya oblast' (ru)",
        "code": "VI"
      }
    ]
  },
  {
    "name": "Belgium",
    "alpha3": "BEL",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Brussels",
        "code": "BRU"
      },
      {
        "name": "Antwerpen (nl)",
        "code": "VAN"
      },
      {
        "name": "Vlaams Brabant (nl)",
        "code": "VBR"
      },
      {
        "name": "Vlaams Gewest",
        "code": "VLG"
      },
      {
        "name": "Limburg (nl)",
        "code": "VLI"
      },
      {
        "name": "Oost-Vlaanderen (nl)",
        "code": "VOV"
      },
      {
        "name": "West-Vlaanderen (nl)",
        "code": "VWV"
      },
      {
        "name": "Wallonië",
        "code": "WAL"
      },
      {
        "name": "Brabant Wallon (fr)",
        "code": "WBR"
      },
      {
        "name": "Hainaut (fr)",
        "code": "WHT"
      },
      {
        "name": "Liège (fr)",
        "code": "WLG"
      },
      {
        "name": "Luxembourg (fr)",
        "code": "WLX"
      },
      {
        "name": "Namur (fr)",
        "code": "WNA"
      }
    ]
  },
  {
    "name": "Belize",
    "alpha3": "BLZ",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Belize",
        "code": "BZ"
      },
      {
        "name": "Cayo",
        "code": "CY"
      },
      {
        "name": "Corozal",
        "code": "CZL"
      },
      {
        "name": "Orange Walk",
        "code": "OW"
      },
      {
        "name": "Stann Creek",
        "code": "SC"
      },
      {
        "name": "Toledo",
        "code": "TOL"
      }
    ]
  },
  {
    "name": "Benin",
    "alpha3": "BEN",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Atakora",
        "code": "AK"
      },
      {
        "name": "Alibori",
        "code": "AL"
      },
      {
        "name": "Atlantique",
        "code": "AQ"
      },
      {
        "name": "Borgou",
        "code": "BO"
      },
      {
        "name": "Collines",
        "code": "CO"
      },
      {
        "name": "Donga",
        "code": "DO"
      },
      {
        "name": "Kouffo",
        "code": "KO"
      },
      {
        "name": "Littoral",
        "code": "LI"
      },
      {
        "name": "Mono",
        "code": "MO"
      },
      {
        "name": "Ouémé",
        "code": "OU"
      },
      {
        "name": "Plateau",
        "code": "PL"
      },
      {
        "name": "Zou",
        "code": "ZO"
      }
    ]
  },
  {
    "name": "Bermuda",
    "alpha3": "BMU",
    "postal_code_format": "\\A[A-Z]{2} ?[A-Z0-9]{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Bhutan",
    "alpha3": "BTN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Paro",
        "code": "11"
      },
      {
        "name": "Chhukha",
        "code": "12"
      },
      {
        "name": "Ha",
        "code": "13"
      },
      {
        "name": "Samtse",
        "code": "14"
      },
      {
        "name": "Thimphu",
        "code": "15"
      },
      {
        "name": "Tsirang",
        "code": "21"
      },
      {
        "name": "Dagana",
        "code": "22"
      },
      {
        "name": "Punakha",
        "code": "23"
      },
      {
        "name": "Wangdue Phodrang",
        "code": "24"
      },
      {
        "name": "Sarpang",
        "code": "31"
      },
      {
        "name": "Trongsa",
        "code": "32"
      },
      {
        "name": "Bumthang",
        "code": "33"
      },
      {
        "name": "Zhemgang",
        "code": "34"
      },
      {
        "name": "Trashigang",
        "code": "41"
      },
      {
        "name": "Monggar",
        "code": "42"
      },
      {
        "name": "Pemagatshel",
        "code": "43"
      },
      {
        "name": "Lhuentse",
        "code": "44"
      },
      {
        "name": "Samdrup Jongkha",
        "code": "45"
      },
      {
        "name": "Gasa",
        "code": "GA"
      },
      {
        "name": "Trashi Yangtse",
        "code": "TY"
      }
    ]
  },
  {
    "name": "Bolivia (Plurinational State of)",
    "alpha3": "BOL",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "El Beni",
        "code": "B"
      },
      {
        "name": "Cochabamba",
        "code": "C"
      },
      {
        "name": "Chuquisaca",
        "code": "H"
      },
      {
        "name": "La Paz",
        "code": "L"
      },
      {
        "name": "Pando",
        "code": "N"
      },
      {
        "name": "Oruro",
        "code": "O"
      },
      {
        "name": "Potosí",
        "code": "P"
      },
      {
        "name": "Santa Cruz",
        "code": "S"
      },
      {
        "name": "Tarija",
        "code": "T"
      }
    ]
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "alpha3": "BES",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bonaire",
        "code": "BO"
      },
      {
        "name": "Saba",
        "code": "SA"
      },
      {
        "name": "Sint Eustatius",
        "code": "SE"
      }
    ]
  },
  {
    "name": "Bosnia and Herzegovina",
    "alpha3": "BIH",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Federacija Bosna i Hercegovina",
        "code": "BIH"
      },
      {
        "name": "Brčko Distrikt",
        "code": "BRC"
      },
      {
        "name": "Republika Srpska",
        "code": "SRP"
      }
    ]
  },
  {
    "name": "Botswana",
    "alpha3": "BWA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Central",
        "code": "CE"
      },
      {
        "name": "Chobe",
        "code": "CH"
      },
      {
        "name": "Francistown",
        "code": "FR"
      },
      {
        "name": "Gaborone",
        "code": "GA"
      },
      {
        "name": "Ghanzi",
        "code": "GH"
      },
      {
        "name": "Jwaneng",
        "code": "JW"
      },
      {
        "name": "Kgalagadi",
        "code": "KG"
      },
      {
        "name": "Kgatleng",
        "code": "KL"
      },
      {
        "name": "Kweneng",
        "code": "KW"
      },
      {
        "name": "Lobatse",
        "code": "LO"
      },
      {
        "name": "North-East",
        "code": "NE"
      },
      {
        "name": "North-West",
        "code": "NW"
      },
      {
        "name": "South-East",
        "code": "SE"
      },
      {
        "name": "Southern",
        "code": "SO"
      },
      {
        "name": "Selibe Phikwe",
        "code": "SP"
      },
      {
        "name": "Sowa Town",
        "code": "ST"
      }
    ]
  },
  {
    "name": "Bouvet Island",
    "alpha3": "BVT",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Brazil",
    "alpha3": "BRA",
    "postal_code_format": "\\A\\d{5}-?\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Acre",
        "code": "AC"
      },
      {
        "name": "Alagoas",
        "code": "AL"
      },
      {
        "name": "Amazonas",
        "code": "AM"
      },
      {
        "name": "Amapá",
        "code": "AP"
      },
      {
        "name": "Bahia",
        "code": "BA"
      },
      {
        "name": "Ceará",
        "code": "CE"
      },
      {
        "name": "Distrito Federal",
        "code": "DF"
      },
      {
        "name": "Espírito Santo",
        "code": "ES"
      },
      {
        "name": "Goiás",
        "code": "GO"
      },
      {
        "name": "Maranhão",
        "code": "MA"
      },
      {
        "name": "Minas Gerais",
        "code": "MG"
      },
      {
        "name": "Mato Grosso do Sul",
        "code": "MS"
      },
      {
        "name": "Mato Grosso",
        "code": "MT"
      },
      {
        "name": "Pará",
        "code": "PA"
      },
      {
        "name": "Paraíba",
        "code": "PB"
      },
      {
        "name": "Pernambuco",
        "code": "PE"
      },
      {
        "name": "Piauí",
        "code": "PI"
      },
      {
        "name": "Paraná",
        "code": "PR"
      },
      {
        "name": "Rio de Janeiro",
        "code": "RJ"
      },
      {
        "name": "Rio Grande do Norte",
        "code": "RN"
      },
      {
        "name": "Rondônia",
        "code": "RO"
      },
      {
        "name": "Roraima",
        "code": "RR"
      },
      {
        "name": "Rio Grande do Sul",
        "code": "RS"
      },
      {
        "name": "Santa Catarina",
        "code": "SC"
      },
      {
        "name": "Sergipe",
        "code": "SE"
      },
      {
        "name": "São Paulo",
        "code": "SP"
      },
      {
        "name": "Tocantins",
        "code": "TO"
      }
    ]
  },
  {
    "name": "British Indian Ocean Territory",
    "alpha3": "IOT",
    "postal_code_format": "\\ABBND 1ZZ\\Z",
    "subdivisions": []
  },
  {
    "name": "Brunei Darussalam",
    "alpha3": "BRN",
    "postal_code_format": "\\A[A-Z]{2} ?\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Belait",
        "code": "BE"
      },
      {
        "name": "Brunei-Muara",
        "code": "BM"
      },
      {
        "name": "Temburong",
        "code": "TE"
      },
      {
        "name": "Tutong",
        "code": "TU"
      }
    ]
  },
  {
    "name": "Bulgaria",
    "alpha3": "BGR",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Blagoevgrad",
        "code": "01"
      },
      {
        "name": "Burgas",
        "code": "02"
      },
      {
        "name": "Varna",
        "code": "03"
      },
      {
        "name": "Veliko Tarnovo",
        "code": "04"
      },
      {
        "name": "Vidin",
        "code": "05"
      },
      {
        "name": "Vratsa",
        "code": "06"
      },
      {
        "name": "Gabrovo",
        "code": "07"
      },
      {
        "name": "Dobrich",
        "code": "08"
      },
      {
        "name": "Kardzhali",
        "code": "09"
      },
      {
        "name": "Kjustendil",
        "code": "10"
      },
      {
        "name": "Lovech",
        "code": "11"
      },
      {
        "name": "Montana",
        "code": "12"
      },
      {
        "name": "Pazardzhik",
        "code": "13"
      },
      {
        "name": "Pernik",
        "code": "14"
      },
      {
        "name": "Pleven",
        "code": "15"
      },
      {
        "name": "Plovdiv",
        "code": "16"
      },
      {
        "name": "Razgrad",
        "code": "17"
      },
      {
        "name": "Ruse",
        "code": "18"
      },
      {
        "name": "Silistra",
        "code": "19"
      },
      {
        "name": "Sliven",
        "code": "20"
      },
      {
        "name": "Smolyan",
        "code": "21"
      },
      {
        "name": "Sofia-Grad",
        "code": "22"
      },
      {
        "name": "Sofia",
        "code": "23"
      },
      {
        "name": "Stara Zagora",
        "code": "24"
      },
      {
        "name": "Targovishte",
        "code": "25"
      },
      {
        "name": "Haskovo",
        "code": "26"
      },
      {
        "name": "Šumen",
        "code": "27"
      },
      {
        "name": "Yambol",
        "code": "28"
      }
    ]
  },
  {
    "name": "Burkina Faso",
    "alpha3": "BFA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Boucle du Mouhoun",
        "code": "01"
      },
      {
        "name": "Cascades",
        "code": "02"
      },
      {
        "name": "Centre",
        "code": "03"
      },
      {
        "name": "Centre-Est",
        "code": "04"
      },
      {
        "name": "Centre-Nord",
        "code": "05"
      },
      {
        "name": "Centre-Ouest",
        "code": "06"
      },
      {
        "name": "Centre-Sud",
        "code": "07"
      },
      {
        "name": "Est",
        "code": "08"
      },
      {
        "name": "Hauts-Bassins",
        "code": "09"
      },
      {
        "name": "Nord",
        "code": "10"
      },
      {
        "name": "Plateau-Central",
        "code": "11"
      },
      {
        "name": "Sahel",
        "code": "12"
      },
      {
        "name": "Sud-Ouest",
        "code": "13"
      },
      {
        "name": "Balé",
        "code": "BAL"
      },
      {
        "name": "Bam",
        "code": "BAM"
      },
      {
        "name": "Banwa",
        "code": "BAN"
      },
      {
        "name": "Bazèga",
        "code": "BAZ"
      },
      {
        "name": "Bougouriba",
        "code": "BGR"
      },
      {
        "name": "Boulgou",
        "code": "BLG"
      },
      {
        "name": "Boulkiemdé",
        "code": "BLK"
      },
      {
        "name": "Comoé",
        "code": "COM"
      },
      {
        "name": "Ganzourgou",
        "code": "GAN"
      },
      {
        "name": "Gnagna",
        "code": "GNA"
      },
      {
        "name": "Gourma",
        "code": "GOU"
      },
      {
        "name": "Houet",
        "code": "HOU"
      },
      {
        "name": "Ioba",
        "code": "IOB"
      },
      {
        "name": "Kadiogo",
        "code": "KAD"
      },
      {
        "name": "Kénédougou",
        "code": "KEN"
      },
      {
        "name": "Komondjari",
        "code": "KMD"
      },
      {
        "name": "Kompienga",
        "code": "KMP"
      },
      {
        "name": "Koulpélogo",
        "code": "KOP"
      },
      {
        "name": "Kossi",
        "code": "KOS"
      },
      {
        "name": "Kouritenga",
        "code": "KOT"
      },
      {
        "name": "Kourwéogo",
        "code": "KOW"
      },
      {
        "name": "Léraba",
        "code": "LER"
      },
      {
        "name": "Loroum",
        "code": "LOR"
      },
      {
        "name": "Mouhoun",
        "code": "MOU"
      },
      {
        "name": "Namentenga",
        "code": "NAM"
      },
      {
        "name": "Nahouri",
        "code": "NAO"
      },
      {
        "name": "Nayala",
        "code": "NAY"
      },
      {
        "name": "Noumbiel",
        "code": "NOU"
      },
      {
        "name": "Oubritenga",
        "code": "OUB"
      },
      {
        "name": "Oudalan",
        "code": "OUD"
      },
      {
        "name": "Passoré",
        "code": "PAS"
      },
      {
        "name": "Poni",
        "code": "PON"
      },
      {
        "name": "Séno",
        "code": "SEN"
      },
      {
        "name": "Sissili",
        "code": "SIS"
      },
      {
        "name": "Sanmatenga",
        "code": "SMT"
      },
      {
        "name": "Sanguié",
        "code": "SNG"
      },
      {
        "name": "Soum",
        "code": "SOM"
      },
      {
        "name": "Sourou",
        "code": "SOR"
      },
      {
        "name": "Tapoa",
        "code": "TAP"
      },
      {
        "name": "Tui",
        "code": "TUI"
      },
      {
        "name": "Yagha",
        "code": "YAG"
      },
      {
        "name": "Yatenga",
        "code": "YAT"
      },
      {
        "name": "Ziro",
        "code": "ZIR"
      },
      {
        "name": "Zondoma",
        "code": "ZON"
      },
      {
        "name": "Zoundwéogo",
        "code": "ZOU"
      }
    ]
  },
  {
    "name": "Burundi",
    "alpha3": "BDI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bubanza",
        "code": "BB"
      },
      {
        "name": "Bujumbura",
        "code": "BJ"
      },
      {
        "name": "province de Bujumbura rural",
        "code": "BL"
      },
      {
        "name": "province de Bujumbura Mairie",
        "code": "BM"
      },
      {
        "name": "Bururi",
        "code": "BR"
      },
      {
        "name": "Cankuzo",
        "code": "CA"
      },
      {
        "name": "Cibitoke",
        "code": "CI"
      },
      {
        "name": "Gitega",
        "code": "GI"
      },
      {
        "name": "Kirundo",
        "code": "KI"
      },
      {
        "name": "Karuzi",
        "code": "KR"
      },
      {
        "name": "Kayanza",
        "code": "KY"
      },
      {
        "name": "Makamba",
        "code": "MA"
      },
      {
        "name": "Muramvya",
        "code": "MU"
      },
      {
        "name": "Mwaro",
        "code": "MW"
      },
      {
        "name": "Muyinga",
        "code": "MY"
      },
      {
        "name": "Ngozi",
        "code": "NG"
      },
      {
        "name": "Province de Rumonge",
        "code": "RM"
      },
      {
        "name": "Rutana",
        "code": "RT"
      },
      {
        "name": "Ruyigi",
        "code": "RY"
      }
    ]
  },
  {
    "name": "Cabo Verde",
    "alpha3": "CPV",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Ilhas de Barlavento",
        "code": "B"
      },
      {
        "name": "Brava",
        "code": "BR"
      },
      {
        "name": "Boa Vista",
        "code": "BV"
      },
      {
        "name": "Santa Catarina",
        "code": "CA"
      },
      {
        "name": "Santa Catarina do Fogo",
        "code": "CF"
      },
      {
        "name": "Santa Cruz",
        "code": "CR"
      },
      {
        "name": "Calheta de São Miguel",
        "code": "CS"
      },
      {
        "name": "Maio",
        "code": "MA"
      },
      {
        "name": "Mosteiros",
        "code": "MO"
      },
      {
        "name": "Paúl",
        "code": "PA"
      },
      {
        "name": "Porto Novo",
        "code": "PN"
      },
      {
        "name": "Praia",
        "code": "PR"
      },
      {
        "name": "Ribeira Brava",
        "code": "RB"
      },
      {
        "name": "Ribeira Grande",
        "code": "RG"
      },
      {
        "name": "Ribeira Grande de Santiago",
        "code": "RS"
      },
      {
        "name": "Ilhas de Sotavento",
        "code": "S"
      },
      {
        "name": "São Domingos",
        "code": "SD"
      },
      {
        "name": "São Filipe",
        "code": "SF"
      },
      {
        "name": "Sal",
        "code": "SL"
      },
      {
        "name": "São Miguel",
        "code": "SM"
      },
      {
        "name": "São Nicolau",
        "code": "SN"
      },
      {
        "name": "São Lourenço dos Órgãos",
        "code": "SO"
      },
      {
        "name": "São Salvador do Mundo",
        "code": "SS"
      },
      {
        "name": "São Vicente",
        "code": "SV"
      },
      {
        "name": "Tarrafal",
        "code": "TA"
      },
      {
        "name": "Tarrafal de São Nicolau",
        "code": "TS"
      }
    ]
  },
  {
    "name": "Cambodia",
    "alpha3": "KHM",
    "postal_code_format": "\\A\\d{5,6}\\Z",
    "subdivisions": [
      {
        "name": "Banteay Mean Chey [Bântéay Méanchey]",
        "code": "1"
      },
      {
        "name": "Kracheh [Krâchéh]",
        "code": "10"
      },
      {
        "name": "Mondol Kiri [Môndól Kiri]",
        "code": "11"
      },
      {
        "name": "Phnom Penh [Phnum Pénh]",
        "code": "12"
      },
      {
        "name": "Preah Vihear [Preah Vihéar]",
        "code": "13"
      },
      {
        "name": "Prey Veaeng [Prey Vêng]",
        "code": "14"
      },
      {
        "name": "Pousaat [Pouthisat]",
        "code": "15"
      },
      {
        "name": "Rotanak Kiri [Rôtânôkiri]",
        "code": "16"
      },
      {
        "name": "Siem Reab [Siemréab]",
        "code": "17"
      },
      {
        "name": "Krong Preah Sihanouk [Krong Preah Sihanouk]",
        "code": "18"
      },
      {
        "name": "Stueng Traeng",
        "code": "19"
      },
      {
        "name": "Baat Dambang [Batdâmbâng]",
        "code": "2"
      },
      {
        "name": "Svaay Rieng [Svay Rieng]",
        "code": "20"
      },
      {
        "name": "Taakaev [Takêv]",
        "code": "21"
      },
      {
        "name": "Otdar Mean Chey [Otdâr Méanchey] ",
        "code": "22"
      },
      {
        "name": "Krong Kep [Krong Kêb]",
        "code": "23"
      },
      {
        "name": "Krong Pailin [Krong Pailin]",
        "code": "24"
      },
      {
        "name": "Tbong Khmum",
        "code": "25"
      },
      {
        "name": "Kampong Chaam [Kâmpóng Cham]",
        "code": "3"
      },
      {
        "name": "Kampong Chhnang [Kâmpóng Chhnang]",
        "code": "4"
      },
      {
        "name": "Kampong Spueu [Kâmpóng Spœ]",
        "code": "5"
      },
      {
        "name": "Kampong Thum [Kâmpóng Thum]",
        "code": "6"
      },
      {
        "name": "Kampot [Kâmpôt]",
        "code": "7"
      },
      {
        "name": "Kandaal [Kândal]",
        "code": "8"
      },
      {
        "name": "Kaoh Kong [Kaôh Kong]",
        "code": "9"
      }
    ]
  },
  {
    "name": "Cameroon",
    "alpha3": "CMR",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Adamaoua",
        "code": "AD"
      },
      {
        "name": "Centre",
        "code": "CE"
      },
      {
        "name": "Far North",
        "code": "EN"
      },
      {
        "name": "East",
        "code": "ES"
      },
      {
        "name": "Littoral",
        "code": "LT"
      },
      {
        "name": "North",
        "code": "NO"
      },
      {
        "name": "North-West",
        "code": "NW"
      },
      {
        "name": "West",
        "code": "OU"
      },
      {
        "name": "South",
        "code": "SU"
      },
      {
        "name": "South-West",
        "code": "SW"
      }
    ]
  },
  {
    "name": "Canada",
    "alpha3": "CAN",
    "postal_code_format": "\\A[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z] ?\\d[ABCEGHJ-NPRSTV-Z]\\d\\Z",
    "subdivisions": [
      {
        "name": "Alberta",
        "code": "AB"
      },
      {
        "name": "British Columbia",
        "code": "BC"
      },
      {
        "name": "Manitoba",
        "code": "MB"
      },
      {
        "name": "New Brunswick",
        "code": "NB"
      },
      {
        "name": "Newfoundland and Labrador",
        "code": "NL"
      },
      {
        "name": "Nova Scotia",
        "code": "NS"
      },
      {
        "name": "Northwest Territories",
        "code": "NT"
      },
      {
        "name": "Nunavut",
        "code": "NU"
      },
      {
        "name": "Ontario",
        "code": "ON"
      },
      {
        "name": "Prince Edward Island",
        "code": "PE"
      },
      {
        "name": "Quebec",
        "code": "QC"
      },
      {
        "name": "Saskatchewan",
        "code": "SK"
      },
      {
        "name": "Yukon",
        "code": "YT"
      }
    ]
  },
  {
    "name": "Cayman Islands",
    "alpha3": "CYM",
    "postal_code_format": "\\AKY\\d-\\d{4}\\Z",
    "subdivisions": []
  },
  {
    "name": "Central African Republic",
    "alpha3": "CAF",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Ouham",
        "code": "AC"
      },
      {
        "name": "Bamingui-Bangoran",
        "code": "BB"
      },
      {
        "name": "Bangui",
        "code": "BGF"
      },
      {
        "name": "Basse-Kotto",
        "code": "BK"
      },
      {
        "name": "Haute-Kotto",
        "code": "HK"
      },
      {
        "name": "Haut-Mbomou",
        "code": "HM"
      },
      {
        "name": "Mambéré-Kadéï",
        "code": "HS"
      },
      {
        "name": "Nana-Grébizi",
        "code": "KB"
      },
      {
        "name": "Kémo",
        "code": "KG"
      },
      {
        "name": "Lobaye",
        "code": "LB"
      },
      {
        "name": "Mbomou",
        "code": "MB"
      },
      {
        "name": "Ombella-Mpoko",
        "code": "MP"
      },
      {
        "name": "Nana-Mambéré",
        "code": "NM"
      },
      {
        "name": "Ouham-Pendé",
        "code": "OP"
      },
      {
        "name": "Sangha-Mbaéré",
        "code": "SE"
      },
      {
        "name": "Ouaka",
        "code": "UK"
      },
      {
        "name": "Vakaga",
        "code": "VK"
      }
    ]
  },
  {
    "name": "Chad",
    "alpha3": "TCD",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Batha",
        "code": "BA"
      },
      {
        "name": "بحر الغزال",
        "code": "BG"
      },
      {
        "name": "منطقة بوركو",
        "code": "BO"
      },
      {
        "name": "Chari-Baguirmi",
        "code": "CB"
      },
      {
        "name": "إنيدي الشرقية",
        "code": "EE"
      },
      {
        "name": "إنيدي الغربية",
        "code": "EO"
      },
      {
        "name": "Guéra",
        "code": "GR"
      },
      {
        "name": "Hadjer Lamis",
        "code": "HL"
      },
      {
        "name": "Kanem",
        "code": "KA"
      },
      {
        "name": "Lac",
        "code": "LC"
      },
      {
        "name": "Logone-Occidental",
        "code": "LO"
      },
      {
        "name": "Logone-Oriental",
        "code": "LR"
      },
      {
        "name": "Mandoul",
        "code": "MA"
      },
      {
        "name": "Moyen-Chari",
        "code": "MC"
      },
      {
        "name": "Mayo-Kébbi-Est",
        "code": "ME"
      },
      {
        "name": "Mayo-Kébbi-Ouest",
        "code": "MO"
      },
      {
        "name": "Ndjamena",
        "code": "ND"
      },
      {
        "name": "Ouaddaï",
        "code": "OD"
      },
      {
        "name": "Salamat",
        "code": "SA"
      },
      {
        "name": "منطقة سيلا",
        "code": "SI"
      },
      {
        "name": "Tandjilé",
        "code": "TA"
      },
      {
        "name": "منطقة تبستي",
        "code": "TI"
      },
      {
        "name": "Wadi Fira",
        "code": "WF"
      }
    ]
  },
  {
    "name": "Chile",
    "alpha3": "CHL",
    "postal_code_format": "\\A\\d{7}\\Z",
    "subdivisions": [
      {
        "name": "Aisén del General Carlos Ibáñez del Campo",
        "code": "AI"
      },
      {
        "name": "Antofagasta",
        "code": "AN"
      },
      {
        "name": "Arica y Parinacota",
        "code": "AP"
      },
      {
        "name": "Araucanía",
        "code": "AR"
      },
      {
        "name": "Atacama",
        "code": "AT"
      },
      {
        "name": "Bío-Bío",
        "code": "BI"
      },
      {
        "name": "Coquimbo",
        "code": "CO"
      },
      {
        "name": "Libertador General Bernardo O'Higgins",
        "code": "LI"
      },
      {
        "name": "Los Lagos",
        "code": "LL"
      },
      {
        "name": "Los Ríos",
        "code": "LR"
      },
      {
        "name": "Magallanes",
        "code": "MA"
      },
      {
        "name": "Maule",
        "code": "ML"
      },
      {
        "name": "Región de Ñuble",
        "code": "NB"
      },
      {
        "name": "Región Metropolitana de Santiago",
        "code": "RM"
      },
      {
        "name": "Tarapacá",
        "code": "TA"
      },
      {
        "name": "Valparaíso",
        "code": "VS"
      }
    ]
  },
  {
    "name": "China",
    "alpha3": "CHN",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Anhui",
        "code": "AH"
      },
      {
        "name": "Beijing",
        "code": "BJ"
      },
      {
        "name": "Chongqing",
        "code": "CQ"
      },
      {
        "name": "Fujian",
        "code": "FJ"
      },
      {
        "name": "Guangdong",
        "code": "GD"
      },
      {
        "name": "Gansu",
        "code": "GS"
      },
      {
        "name": "Guangxi",
        "code": "GX"
      },
      {
        "name": "Guizhou",
        "code": "GZ"
      },
      {
        "name": "Henan",
        "code": "HA"
      },
      {
        "name": "Hubei",
        "code": "HB"
      },
      {
        "name": "Hebei",
        "code": "HE"
      },
      {
        "name": "Hainan",
        "code": "HI"
      },
      {
        "name": "Xianggang (zh) **",
        "code": "HK"
      },
      {
        "name": "Heilongjiang",
        "code": "HL"
      },
      {
        "name": "Hunan",
        "code": "HN"
      },
      {
        "name": "Jilin",
        "code": "JL"
      },
      {
        "name": "Jiangsu",
        "code": "JS"
      },
      {
        "name": "Jiangxi",
        "code": "JX"
      },
      {
        "name": "Liaoning",
        "code": "LN"
      },
      {
        "name": "Aomen (zh) ***",
        "code": "MO"
      },
      {
        "name": "Nei Mongol (mn)",
        "code": "NM"
      },
      {
        "name": "Ningxia",
        "code": "NX"
      },
      {
        "name": "Qinghai",
        "code": "QH"
      },
      {
        "name": "Sichuan",
        "code": "SC"
      },
      {
        "name": "Shandong",
        "code": "SD"
      },
      {
        "name": "Shanghai",
        "code": "SH"
      },
      {
        "name": "Shaanxi",
        "code": "SN"
      },
      {
        "name": "Shanxi",
        "code": "SX"
      },
      {
        "name": "Tianjin",
        "code": "TJ"
      },
      {
        "name": "Taiwan *",
        "code": "TW"
      },
      {
        "name": "Xinjiang",
        "code": "XJ"
      },
      {
        "name": "Xizang",
        "code": "XZ"
      },
      {
        "name": "Yunnan",
        "code": "YN"
      },
      {
        "name": "Zhejiang",
        "code": "ZJ"
      }
    ]
  },
  {
    "name": "Christmas Island",
    "alpha3": "CXR",
    "postal_code_format": "\\A6798\\Z",
    "subdivisions": []
  },
  {
    "name": "Cocos (Keeling) Islands",
    "alpha3": "CCK",
    "postal_code_format": "\\A6799\\Z",
    "subdivisions": []
  },
  {
    "name": "Colombia",
    "alpha3": "COL",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Amazonas",
        "code": "AMA"
      },
      {
        "name": "Antioquia",
        "code": "ANT"
      },
      {
        "name": "Arauca",
        "code": "ARA"
      },
      {
        "name": "Atlántico",
        "code": "ATL"
      },
      {
        "name": "Bolívar",
        "code": "BOL"
      },
      {
        "name": "Boyacá",
        "code": "BOY"
      },
      {
        "name": "Caldas",
        "code": "CAL"
      },
      {
        "name": "Caquetá",
        "code": "CAQ"
      },
      {
        "name": "Casanare",
        "code": "CAS"
      },
      {
        "name": "Cauca",
        "code": "CAU"
      },
      {
        "name": "Cesar",
        "code": "CES"
      },
      {
        "name": "Chocó",
        "code": "CHO"
      },
      {
        "name": "Córdoba",
        "code": "COR"
      },
      {
        "name": "Cundinamarca",
        "code": "CUN"
      },
      {
        "name": "Distrito Capital de Bogotá",
        "code": "DC"
      },
      {
        "name": "Guainía",
        "code": "GUA"
      },
      {
        "name": "Guaviare",
        "code": "GUV"
      },
      {
        "name": "Huila",
        "code": "HUI"
      },
      {
        "name": "La Guajira",
        "code": "LAG"
      },
      {
        "name": "Magdalena",
        "code": "MAG"
      },
      {
        "name": "Meta",
        "code": "MET"
      },
      {
        "name": "Nariño",
        "code": "NAR"
      },
      {
        "name": "Norte de Santander",
        "code": "NSA"
      },
      {
        "name": "Putumayo",
        "code": "PUT"
      },
      {
        "name": "Quindío",
        "code": "QUI"
      },
      {
        "name": "Risaralda",
        "code": "RIS"
      },
      {
        "name": "Santander",
        "code": "SAN"
      },
      {
        "name": "San Andrés, Providencia y Santa Catalina",
        "code": "SAP"
      },
      {
        "name": "Sucre",
        "code": "SUC"
      },
      {
        "name": "Tolima",
        "code": "TOL"
      },
      {
        "name": "Valle del Cauca",
        "code": "VAC"
      },
      {
        "name": "Vaupés",
        "code": "VAU"
      },
      {
        "name": "Vichada",
        "code": "VID"
      }
    ]
  },
  {
    "name": "Comoros",
    "alpha3": "COM",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Anjouan",
        "code": "A"
      },
      {
        "name": "Grande Comore",
        "code": "G"
      },
      {
        "name": "Mohéli",
        "code": "M"
      }
    ]
  },
  {
    "name": "Congo",
    "alpha3": "COG",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bouenza",
        "code": "11"
      },
      {
        "name": "Pool",
        "code": "12"
      },
      {
        "name": "Sangha",
        "code": "13"
      },
      {
        "name": "Plateaux",
        "code": "14"
      },
      {
        "name": "Cuvette-Ouest",
        "code": "15"
      },
      {
        "name": "Pointe-Noire",
        "code": "16"
      },
      {
        "name": "Lékoumou",
        "code": "2"
      },
      {
        "name": "Kouilou",
        "code": "5"
      },
      {
        "name": "Likouala",
        "code": "7"
      },
      {
        "name": "Cuvette",
        "code": "8"
      },
      {
        "name": "Niari",
        "code": "9"
      },
      {
        "name": "Brazzaville",
        "code": "BZV"
      }
    ]
  },
  {
    "name": "Congo (Democratic Republic of the)",
    "alpha3": "COD",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bas-Congo",
        "code": "BC"
      },
      {
        "name": "Bas-Uele",
        "code": "BU"
      },
      {
        "name": "Équateur",
        "code": "EQ"
      },
      {
        "name": "Haut-Katanga",
        "code": "HK"
      },
      {
        "name": "Haut-Lomami",
        "code": "HL"
      },
      {
        "name": "Haut-Uele",
        "code": "HU"
      },
      {
        "name": "Ituri",
        "code": "IT"
      },
      {
        "name": "Lulua",
        "code": "KC"
      },
      {
        "name": "Kasai-Oriental",
        "code": "KE"
      },
      {
        "name": "Kwango",
        "code": "KG"
      },
      {
        "name": "Kwilu",
        "code": "KL"
      },
      {
        "name": "Kinshasa",
        "code": "KN"
      },
      {
        "name": "Kasaï",
        "code": "KS"
      },
      {
        "name": "Kabinda",
        "code": "LO"
      },
      {
        "name": "Lualaba",
        "code": "LU"
      },
      {
        "name": "Maniema",
        "code": "MA"
      },
      {
        "name": "Mai-Ndombe",
        "code": "MN"
      },
      {
        "name": "Mongala",
        "code": "MO"
      },
      {
        "name": "Nord-Kivu",
        "code": "NK"
      },
      {
        "name": "Nord-Ubangi",
        "code": "NU"
      },
      {
        "name": "Sankuru",
        "code": "SA"
      },
      {
        "name": "Sud-Kivu",
        "code": "SK"
      },
      {
        "name": "Sud-Ubangi",
        "code": "SU"
      },
      {
        "name": "Tanganyika",
        "code": "TA"
      },
      {
        "name": "Tshopo",
        "code": "TO"
      },
      {
        "name": "Tshuapa",
        "code": "TU"
      }
    ]
  },
  {
    "name": "Cook Islands",
    "alpha3": "COK",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Costa Rica",
    "alpha3": "CRI",
    "postal_code_format": "\\A\\d{4,5}|\\d{3}-\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Alajuela",
        "code": "A"
      },
      {
        "name": "Cartago",
        "code": "C"
      },
      {
        "name": "Guanacaste",
        "code": "G"
      },
      {
        "name": "Heredia",
        "code": "H"
      },
      {
        "name": "Limón",
        "code": "L"
      },
      {
        "name": "Puntarenas",
        "code": "P"
      },
      {
        "name": "San José",
        "code": "SJ"
      }
    ]
  },
  {
    "name": "Côte d'Ivoire",
    "alpha3": "CIV",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Abidjan",
        "code": "AB"
      },
      {
        "name": "District du Bas-Sassandra",
        "code": "BS"
      },
      {
        "name": "District de la Comoé",
        "code": "CM"
      },
      {
        "name": "Denguélé",
        "code": "DN"
      },
      {
        "name": "Gôh-Djiboua",
        "code": "GD"
      },
      {
        "name": "District des Lacs",
        "code": "LC"
      },
      {
        "name": "District des Lagunes",
        "code": "LG"
      },
      {
        "name": "District des Montagnes",
        "code": "MG"
      },
      {
        "name": "District du Sassandra-Marahoué",
        "code": "SM"
      },
      {
        "name": "Savanes",
        "code": "SV"
      },
      {
        "name": "Vallée du Bandama",
        "code": "VB"
      },
      {
        "name": "District du Woroba",
        "code": "WR"
      },
      {
        "name": "Yamoussoukro",
        "code": "YM"
      },
      {
        "name": "Zanzan",
        "code": "ZZ"
      }
    ]
  },
  {
    "name": "Croatia",
    "alpha3": "HRV",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Zagrebačka županija",
        "code": "01"
      },
      {
        "name": "Krapinsko-zagorska županija",
        "code": "02"
      },
      {
        "name": "Sisačko-moslavačka županija",
        "code": "03"
      },
      {
        "name": "Karlovačka županija",
        "code": "04"
      },
      {
        "name": "Varaždinska županija",
        "code": "05"
      },
      {
        "name": "Koprivničko-križevačka županija",
        "code": "06"
      },
      {
        "name": "Bjelovarsko-bilogorska županija",
        "code": "07"
      },
      {
        "name": "Primorsko-goranska županija",
        "code": "08"
      },
      {
        "name": "Ličko-senjska županija",
        "code": "09"
      },
      {
        "name": "Virovitičko-podravska županija",
        "code": "10"
      },
      {
        "name": "Požeško-slavonska županija",
        "code": "11"
      },
      {
        "name": "Brodsko-posavska županija",
        "code": "12"
      },
      {
        "name": "Zadarska županija",
        "code": "13"
      },
      {
        "name": "Osječko-baranjska županija",
        "code": "14"
      },
      {
        "name": "Šibensko-kninska županija",
        "code": "15"
      },
      {
        "name": "Vukovarsko-srijemska županija",
        "code": "16"
      },
      {
        "name": "Splitsko-dalmatinska županija",
        "code": "17"
      },
      {
        "name": "Istarska županija",
        "code": "18"
      },
      {
        "name": "Dubrovačko-neretvanska županija",
        "code": "19"
      },
      {
        "name": "Međimurska županija",
        "code": "20"
      },
      {
        "name": "Grad Zagreb",
        "code": "21"
      }
    ]
  },
  {
    "name": "Cuba",
    "alpha3": "CUB",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Pinar del Río",
        "code": "01"
      },
      {
        "name": "La Habana",
        "code": "03"
      },
      {
        "name": "Matanzas",
        "code": "04"
      },
      {
        "name": "Villa Clara",
        "code": "05"
      },
      {
        "name": "Cienfuegos",
        "code": "06"
      },
      {
        "name": "Sancti Spíritus",
        "code": "07"
      },
      {
        "name": "Ciego de Ávila",
        "code": "08"
      },
      {
        "name": "Camagüey",
        "code": "09"
      },
      {
        "name": "Las Tunas",
        "code": "10"
      },
      {
        "name": "Holguín",
        "code": "11"
      },
      {
        "name": "Granma",
        "code": "12"
      },
      {
        "name": "Santiago de Cuba",
        "code": "13"
      },
      {
        "name": "Guantánamo",
        "code": "14"
      },
      {
        "name": "Artemisa",
        "code": "15"
      },
      {
        "name": "Mayabeque",
        "code": "16"
      },
      {
        "name": "Isla de la Juventud",
        "code": "99"
      }
    ]
  },
  {
    "name": "Curaçao",
    "alpha3": "CUW",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Cyprus",
    "alpha3": "CYP",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Lefkosia",
        "code": "01"
      },
      {
        "name": "Lemesos",
        "code": "02"
      },
      {
        "name": "Larnaka",
        "code": "03"
      },
      {
        "name": "Ammochostos",
        "code": "04"
      },
      {
        "name": "Pafos",
        "code": "05"
      },
      {
        "name": "Keryneia",
        "code": "06"
      }
    ]
  },
  {
    "name": "Czechia",
    "alpha3": "CZE",
    "postal_code_format": "\\A\\d{3} ?\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Praha, Hlavní město",
        "code": "10"
      },
      {
        "name": "Středočeský kraj",
        "code": "20"
      },
      {
        "name": "Benešov",
        "code": "201"
      },
      {
        "name": "Beroun",
        "code": "202"
      },
      {
        "name": "Kladno",
        "code": "203"
      },
      {
        "name": "Kolín",
        "code": "204"
      },
      {
        "name": "Kutná Hora",
        "code": "205"
      },
      {
        "name": "Mělník",
        "code": "206"
      },
      {
        "name": "Mladá Boleslav",
        "code": "207"
      },
      {
        "name": "Nymburk",
        "code": "208"
      },
      {
        "name": "Praha-východ",
        "code": "209"
      },
      {
        "name": "Praha-západ",
        "code": "20A"
      },
      {
        "name": "Příbram",
        "code": "20B"
      },
      {
        "name": "Rakovník",
        "code": "20C"
      },
      {
        "name": "Jihočeský kraj",
        "code": "31"
      },
      {
        "name": "České Budějovice",
        "code": "311"
      },
      {
        "name": "Český Krumlov",
        "code": "312"
      },
      {
        "name": "Jindřichův Hradec",
        "code": "313"
      },
      {
        "name": "Písek",
        "code": "314"
      },
      {
        "name": "Prachatice",
        "code": "315"
      },
      {
        "name": "Strakonice",
        "code": "316"
      },
      {
        "name": "Tábor",
        "code": "317"
      },
      {
        "name": "Plzeňský kraj",
        "code": "32"
      },
      {
        "name": "Domažlice",
        "code": "321"
      },
      {
        "name": "Klatovy",
        "code": "322"
      },
      {
        "name": "Plzeň-město",
        "code": "323"
      },
      {
        "name": "Plzeň-jih",
        "code": "324"
      },
      {
        "name": "Plzeň-sever",
        "code": "325"
      },
      {
        "name": "Rokycany",
        "code": "326"
      },
      {
        "name": "Tachov",
        "code": "327"
      },
      {
        "name": "Karlovarský kraj",
        "code": "41"
      },
      {
        "name": "Cheb",
        "code": "411"
      },
      {
        "name": "Karlovy Vary",
        "code": "412"
      },
      {
        "name": "Sokolov",
        "code": "413"
      },
      {
        "name": "Ústecký kraj",
        "code": "42"
      },
      {
        "name": "Děčín",
        "code": "421"
      },
      {
        "name": "Chomutov",
        "code": "422"
      },
      {
        "name": "Litoměřice",
        "code": "423"
      },
      {
        "name": "Louny",
        "code": "424"
      },
      {
        "name": "Most",
        "code": "425"
      },
      {
        "name": "Teplice",
        "code": "426"
      },
      {
        "name": "Ústí nad Labem",
        "code": "427"
      },
      {
        "name": "Liberecký kraj",
        "code": "51"
      },
      {
        "name": "Česká Lípa",
        "code": "511"
      },
      {
        "name": "Jablonec nad Nisou",
        "code": "512"
      },
      {
        "name": "Liberec",
        "code": "513"
      },
      {
        "name": "Semily",
        "code": "514"
      },
      {
        "name": "Královéhradecký kraj",
        "code": "52"
      },
      {
        "name": "Hradec Králové",
        "code": "521"
      },
      {
        "name": "Jičín",
        "code": "522"
      },
      {
        "name": "Náchod",
        "code": "523"
      },
      {
        "name": "Rychnov nad Kněžnou",
        "code": "524"
      },
      {
        "name": "Trutnov",
        "code": "525"
      },
      {
        "name": "Pardubický kraj",
        "code": "53"
      },
      {
        "name": "Chrudim",
        "code": "531"
      },
      {
        "name": "Pardubice",
        "code": "532"
      },
      {
        "name": "Svitavy",
        "code": "533"
      },
      {
        "name": "Ústí nad Orlicí",
        "code": "534"
      },
      {
        "name": "Kraj Vysočina",
        "code": "63"
      },
      {
        "name": "Havlíčkův Brod",
        "code": "631"
      },
      {
        "name": "Jihlava",
        "code": "632"
      },
      {
        "name": "Pelhřimov",
        "code": "633"
      },
      {
        "name": "Třebíč",
        "code": "634"
      },
      {
        "name": "Žďár nad Sázavou",
        "code": "635"
      },
      {
        "name": "Jihomoravský kraj",
        "code": "64"
      },
      {
        "name": "Blansko",
        "code": "641"
      },
      {
        "name": "Brno-město",
        "code": "642"
      },
      {
        "name": "Brno-venkov",
        "code": "643"
      },
      {
        "name": "Břeclav",
        "code": "644"
      },
      {
        "name": "Hodonín",
        "code": "645"
      },
      {
        "name": "Vyškov",
        "code": "646"
      },
      {
        "name": "Znojmo",
        "code": "647"
      },
      {
        "name": "Olomoucký kraj",
        "code": "71"
      },
      {
        "name": "Jeseník",
        "code": "711"
      },
      {
        "name": "Olomouc",
        "code": "712"
      },
      {
        "name": "Prostějov",
        "code": "713"
      },
      {
        "name": "Přerov",
        "code": "714"
      },
      {
        "name": "Šumperk",
        "code": "715"
      },
      {
        "name": "Zlínský kraj",
        "code": "72"
      },
      {
        "name": "Kroměříž",
        "code": "721"
      },
      {
        "name": "Uherské Hradiště",
        "code": "722"
      },
      {
        "name": "Vsetín",
        "code": "723"
      },
      {
        "name": "Zlín",
        "code": "724"
      },
      {
        "name": "Moravskoslezský kraj",
        "code": "80"
      },
      {
        "name": "Bruntál",
        "code": "801"
      },
      {
        "name": "Frýdek-Místek",
        "code": "802"
      },
      {
        "name": "Karviná",
        "code": "803"
      },
      {
        "name": "Nový Jičín",
        "code": "804"
      },
      {
        "name": "Opava",
        "code": "805"
      },
      {
        "name": "Ostrava-město",
        "code": "806"
      }
    ]
  },
  {
    "name": "Denmark",
    "alpha3": "DNK",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Nordjylland",
        "code": "81"
      },
      {
        "name": "Midtjylland",
        "code": "82"
      },
      {
        "name": "Syddanmark",
        "code": "83"
      },
      {
        "name": "Hovedstaden",
        "code": "84"
      },
      {
        "name": "Sjælland",
        "code": "85"
      }
    ]
  },
  {
    "name": "Djibouti",
    "alpha3": "DJI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Arta",
        "code": "AR"
      },
      {
        "name": "Ali Sabieh",
        "code": "AS"
      },
      {
        "name": "Dikhil",
        "code": "DI"
      },
      {
        "name": "Djibouti",
        "code": "DJ"
      },
      {
        "name": "Obock",
        "code": "OB"
      },
      {
        "name": "Tadjourah",
        "code": "TA"
      }
    ]
  },
  {
    "name": "Dominica",
    "alpha3": "DMA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Saint Andrew",
        "code": "02"
      },
      {
        "name": "Saint David",
        "code": "03"
      },
      {
        "name": "Saint George",
        "code": "04"
      },
      {
        "name": "Saint John",
        "code": "05"
      },
      {
        "name": "Saint Joseph",
        "code": "06"
      },
      {
        "name": "Saint Luke",
        "code": "07"
      },
      {
        "name": "Saint Mark",
        "code": "08"
      },
      {
        "name": "Saint Patrick",
        "code": "09"
      },
      {
        "name": "Saint Paul",
        "code": "10"
      },
      {
        "name": "Saint Peter",
        "code": "11"
      }
    ]
  },
  {
    "name": "Dominican Republic",
    "alpha3": "DOM",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Distrito Nacional (Santo Domingo)",
        "code": "01"
      },
      {
        "name": "Azua",
        "code": "02"
      },
      {
        "name": "Bahoruco",
        "code": "03"
      },
      {
        "name": "Barahona",
        "code": "04"
      },
      {
        "name": "Dajabón",
        "code": "05"
      },
      {
        "name": "Duarte",
        "code": "06"
      },
      {
        "name": "La Estrelleta [Elías Piña]",
        "code": "07"
      },
      {
        "name": "El Seybo [El Seibo]",
        "code": "08"
      },
      {
        "name": "Espaillat",
        "code": "09"
      },
      {
        "name": "Independencia",
        "code": "10"
      },
      {
        "name": "La Altagracia",
        "code": "11"
      },
      {
        "name": "La Romana",
        "code": "12"
      },
      {
        "name": "La Vega",
        "code": "13"
      },
      {
        "name": "María Trinidad Sánchez",
        "code": "14"
      },
      {
        "name": "Monte Cristi",
        "code": "15"
      },
      {
        "name": "Pedernales",
        "code": "16"
      },
      {
        "name": "Peravia",
        "code": "17"
      },
      {
        "name": "Puerto Plata",
        "code": "18"
      },
      {
        "name": "Salcedo",
        "code": "19"
      },
      {
        "name": "Samaná",
        "code": "20"
      },
      {
        "name": "San Cristóbal",
        "code": "21"
      },
      {
        "name": "San Juan",
        "code": "22"
      },
      {
        "name": "San Pedro de Macorís",
        "code": "23"
      },
      {
        "name": "Sánchez Ramírez",
        "code": "24"
      },
      {
        "name": "Santiago",
        "code": "25"
      },
      {
        "name": "Santiago Rodríguez",
        "code": "26"
      },
      {
        "name": "Valverde",
        "code": "27"
      },
      {
        "name": "Monseñor Nouel",
        "code": "28"
      },
      {
        "name": "Monte Plata",
        "code": "29"
      },
      {
        "name": "Hato Mayor",
        "code": "30"
      },
      {
        "name": "San Jose de Ocoa",
        "code": "31"
      },
      {
        "name": "Santo Domingo",
        "code": "32"
      },
      {
        "name": "Cibao Nordeste",
        "code": "33"
      },
      {
        "name": "Cibao Noroeste",
        "code": "34"
      },
      {
        "name": "Cibao Norte",
        "code": "35"
      },
      {
        "name": "Cibao Sur",
        "code": "36"
      },
      {
        "name": "El Valle",
        "code": "37"
      },
      {
        "name": "Enriquillo",
        "code": "38"
      },
      {
        "name": "Higüamo",
        "code": "39"
      },
      {
        "name": "Ozama",
        "code": "40"
      },
      {
        "name": "Valdesia",
        "code": "41"
      },
      {
        "name": "Yuma",
        "code": "42"
      }
    ]
  },
  {
    "name": "Ecuador",
    "alpha3": "ECU",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Azuay",
        "code": "A"
      },
      {
        "name": "Bolívar",
        "code": "B"
      },
      {
        "name": "Carchi",
        "code": "C"
      },
      {
        "name": "Orellana",
        "code": "D"
      },
      {
        "name": "Esmeraldas",
        "code": "E"
      },
      {
        "name": "Cañar",
        "code": "F"
      },
      {
        "name": "Guayas",
        "code": "G"
      },
      {
        "name": "Chimborazo",
        "code": "H"
      },
      {
        "name": "Imbabura",
        "code": "I"
      },
      {
        "name": "Loja",
        "code": "L"
      },
      {
        "name": "Manabí",
        "code": "M"
      },
      {
        "name": "Napo",
        "code": "N"
      },
      {
        "name": "El Oro",
        "code": "O"
      },
      {
        "name": "Pichincha",
        "code": "P"
      },
      {
        "name": "Los Ríos",
        "code": "R"
      },
      {
        "name": "Morona-Santiago",
        "code": "S"
      },
      {
        "name": "Santo Domingo de los Tsachilas",
        "code": "SD"
      },
      {
        "name": "Santa Elena",
        "code": "SE"
      },
      {
        "name": "Tungurahua",
        "code": "T"
      },
      {
        "name": "Sucumbíos",
        "code": "U"
      },
      {
        "name": "Galápagos",
        "code": "W"
      },
      {
        "name": "Cotopaxi",
        "code": "X"
      },
      {
        "name": "Pastaza",
        "code": "Y"
      },
      {
        "name": "Zamora-Chinchipe",
        "code": "Z"
      }
    ]
  },
  {
    "name": "Egypt",
    "alpha3": "EGY",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Al Iskandariyah",
        "code": "ALX"
      },
      {
        "name": "Aswan",
        "code": "ASN"
      },
      {
        "name": "Asyut",
        "code": "AST"
      },
      {
        "name": "Al Bahr al Ahmar",
        "code": "BA"
      },
      {
        "name": "Al Buhayrah",
        "code": "BH"
      },
      {
        "name": "Bani Suwayf",
        "code": "BNS"
      },
      {
        "name": "Al Qahirah",
        "code": "C"
      },
      {
        "name": "Ad Daqahliyah",
        "code": "DK"
      },
      {
        "name": "Dumyat",
        "code": "DT"
      },
      {
        "name": "Al Fayyum",
        "code": "FYM"
      },
      {
        "name": "Al Gharbiyah",
        "code": "GH"
      },
      {
        "name": "Al Jizah",
        "code": "GZ"
      },
      {
        "name": "Al Ismā`īlīyah",
        "code": "IS"
      },
      {
        "name": "Janub Sina'",
        "code": "JS"
      },
      {
        "name": "Al Qalyubiyah",
        "code": "KB"
      },
      {
        "name": "Kafr ash Shaykh",
        "code": "KFS"
      },
      {
        "name": "Qina",
        "code": "KN"
      },
      {
        "name": "al-Uqsur",
        "code": "LX"
      },
      {
        "name": "Al Minya",
        "code": "MN"
      },
      {
        "name": "Al Minufiyah",
        "code": "MNF"
      },
      {
        "name": "Matrūh",
        "code": "MT"
      },
      {
        "name": "Būr Sa`īd",
        "code": "PTS"
      },
      {
        "name": "Suhaj",
        "code": "SHG"
      },
      {
        "name": "Ash Sharqiyah",
        "code": "SHR"
      },
      {
        "name": "Shamal Sina'",
        "code": "SIN"
      },
      {
        "name": "As Suways",
        "code": "SUZ"
      },
      {
        "name": "Al Wadi al Jadid",
        "code": "WAD"
      }
    ]
  },
  {
    "name": "El Salvador",
    "alpha3": "SLV",
    "postal_code_format": "\\A(CP(-| )?)?[1-3][1-7][0-2]\\d\\Z",
    "subdivisions": [
      {
        "name": "Ahuachapán",
        "code": "AH"
      },
      {
        "name": "Cabañas",
        "code": "CA"
      },
      {
        "name": "Chalatenango",
        "code": "CH"
      },
      {
        "name": "Cuscatlán",
        "code": "CU"
      },
      {
        "name": "La Libertad",
        "code": "LI"
      },
      {
        "name": "Morazán",
        "code": "MO"
      },
      {
        "name": "La Paz",
        "code": "PA"
      },
      {
        "name": "Santa Ana",
        "code": "SA"
      },
      {
        "name": "San Miguel",
        "code": "SM"
      },
      {
        "name": "Sonsonate",
        "code": "SO"
      },
      {
        "name": "San Salvador",
        "code": "SS"
      },
      {
        "name": "San Vicente",
        "code": "SV"
      },
      {
        "name": "La Unión",
        "code": "UN"
      },
      {
        "name": "Usulután",
        "code": "US"
      }
    ]
  },
  {
    "name": "Equatorial Guinea",
    "alpha3": "GNQ",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Annobón",
        "code": "AN"
      },
      {
        "name": "Bioko Norte",
        "code": "BN"
      },
      {
        "name": "Bioko Sur",
        "code": "BS"
      },
      {
        "name": "Región Continental",
        "code": "C"
      },
      {
        "name": "Centro Sur",
        "code": "CS"
      },
      {
        "name": "Djibloho",
        "code": "DJ"
      },
      {
        "name": "Región Insular",
        "code": "I"
      },
      {
        "name": "Kie-Ntem",
        "code": "KN"
      },
      {
        "name": "Litoral",
        "code": "LI"
      },
      {
        "name": "Wele-Nzás",
        "code": "WN"
      }
    ]
  },
  {
    "name": "Eritrea",
    "alpha3": "ERI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Anseba",
        "code": "AN"
      },
      {
        "name": "Debubawi Keyih Bahri [Debub-Keih-Bahri]",
        "code": "DK"
      },
      {
        "name": "Debub",
        "code": "DU"
      },
      {
        "name": "Gash-Barka",
        "code": "GB"
      },
      {
        "name": "Maakel [Maekel]",
        "code": "MA"
      },
      {
        "name": "Semenawi Keyih Bahri [Semien-Keih-Bahri]",
        "code": "SK"
      }
    ]
  },
  {
    "name": "Estonia",
    "alpha3": "EST",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Alutaguse",
        "code": "130"
      },
      {
        "name": "Anija",
        "code": "141"
      },
      {
        "name": "Antsla",
        "code": "142"
      },
      {
        "name": "Elva",
        "code": "171"
      },
      {
        "name": "Haapsalu",
        "code": "184"
      },
      {
        "name": "Haljala",
        "code": "191"
      },
      {
        "name": "Harku",
        "code": "198"
      },
      {
        "name": "Hiiumaa",
        "code": "205"
      },
      {
        "name": "Häädemeeste",
        "code": "214"
      },
      {
        "name": "Jõelähtme",
        "code": "245"
      },
      {
        "name": "Jõgeva",
        "code": "247"
      },
      {
        "name": "Jõhvi",
        "code": "251"
      },
      {
        "name": "Järva",
        "code": "255"
      },
      {
        "name": "Kadrina",
        "code": "272"
      },
      {
        "name": "Kambja",
        "code": "283"
      },
      {
        "name": "Kanepi",
        "code": "284"
      },
      {
        "name": "Kastre",
        "code": "291"
      },
      {
        "name": "Kehtna",
        "code": "293"
      },
      {
        "name": "Keila",
        "code": "296"
      },
      {
        "name": "Kihnu",
        "code": "303"
      },
      {
        "name": "Kiili",
        "code": "305"
      },
      {
        "name": "Kohila",
        "code": "317"
      },
      {
        "name": "Kohtla-Järve",
        "code": "321"
      },
      {
        "name": "Kose",
        "code": "338"
      },
      {
        "name": "Kuusalu",
        "code": "353"
      },
      {
        "name": "Harjumaa",
        "code": "37"
      },
      {
        "name": "Hiiumaa",
        "code": "39"
      },
      {
        "name": "Loksa",
        "code": "424"
      },
      {
        "name": "Lääneranna",
        "code": "430"
      },
      {
        "name": "Lääne-Harju",
        "code": "431"
      },
      {
        "name": "Luunja",
        "code": "432"
      },
      {
        "name": "Lääne-Nigula",
        "code": "441"
      },
      {
        "name": "Lüganuse",
        "code": "442"
      },
      {
        "name": "Maardu",
        "code": "446"
      },
      {
        "name": "Ida-Virumaa",
        "code": "45"
      },
      {
        "name": "Muhu",
        "code": "478"
      },
      {
        "name": "Mulgi",
        "code": "480"
      },
      {
        "name": "Mustvee",
        "code": "486"
      },
      {
        "name": "Jõgevamaa",
        "code": "50"
      },
      {
        "name": "Märjamaa",
        "code": "503"
      },
      {
        "name": "Narva",
        "code": "511"
      },
      {
        "name": "Narva-Jõesuu",
        "code": "514"
      },
      {
        "name": "Järvamaa",
        "code": "52"
      },
      {
        "name": "Nõo",
        "code": "528"
      },
      {
        "name": "Otepää",
        "code": "557"
      },
      {
        "name": "Läänemaa",
        "code": "56"
      },
      {
        "name": "Paide",
        "code": "567"
      },
      {
        "name": "Peipsiääre",
        "code": "586"
      },
      {
        "name": "Lääne-Virumaa",
        "code": "60"
      },
      {
        "name": "Põhja-Sakala",
        "code": "615"
      },
      {
        "name": "Põltsamaa",
        "code": "618"
      },
      {
        "name": "Põlva",
        "code": "622"
      },
      {
        "name": "Pärnu",
        "code": "624"
      },
      {
        "name": "Põhja-Pärnumaa",
        "code": "638"
      },
      {
        "name": "Põlvamaa",
        "code": "64"
      },
      {
        "name": "Raasiku",
        "code": "651"
      },
      {
        "name": "Rae",
        "code": "653"
      },
      {
        "name": "Rakvere",
        "code": "661"
      },
      {
        "name": "Rakvere",
        "code": "663"
      },
      {
        "name": "Rapla",
        "code": "668"
      },
      {
        "name": "Pärnumaa",
        "code": "68"
      },
      {
        "name": "Ruhnu",
        "code": "689"
      },
      {
        "name": "Rõuge",
        "code": "698"
      },
      {
        "name": "Räpina",
        "code": "708"
      },
      {
        "name": "Raplamaa",
        "code": "71"
      },
      {
        "name": "Saarde",
        "code": "712"
      },
      {
        "name": "Saaremaa",
        "code": "714"
      },
      {
        "name": "Saku",
        "code": "719"
      },
      {
        "name": "Saue",
        "code": "726"
      },
      {
        "name": "Setomaa",
        "code": "732"
      },
      {
        "name": "Sillamäe",
        "code": "735"
      },
      {
        "name": "Saaremaa",
        "code": "74"
      },
      {
        "name": "Tallinn",
        "code": "784"
      },
      {
        "name": "Tartumaa",
        "code": "79"
      },
      {
        "name": "Tapa",
        "code": "792"
      },
      {
        "name": "Tartu",
        "code": "793"
      },
      {
        "name": "Tartu",
        "code": "796"
      },
      {
        "name": "Toila",
        "code": "803"
      },
      {
        "name": "Tori",
        "code": "809"
      },
      {
        "name": "Valgamaa",
        "code": "81"
      },
      {
        "name": "Tõrva",
        "code": "824"
      },
      {
        "name": "Türi",
        "code": "834"
      },
      {
        "name": "Viljandimaa",
        "code": "84"
      },
      {
        "name": "Valga",
        "code": "855"
      },
      {
        "name": "Võrumaa",
        "code": "87"
      },
      {
        "name": "Viimsi",
        "code": "890"
      },
      {
        "name": "Viljandi",
        "code": "897"
      },
      {
        "name": "Viljandi",
        "code": "899"
      },
      {
        "name": "Vinni",
        "code": "901"
      },
      {
        "name": "Viru-Nigula",
        "code": "903"
      },
      {
        "name": "Vormsi",
        "code": "907"
      },
      {
        "name": "Võru",
        "code": "917"
      },
      {
        "name": "Võru",
        "code": "919"
      },
      {
        "name": "Väike-Maarja",
        "code": "928"
      }
    ]
  },
  {
    "name": "Eswatini",
    "alpha3": "SWZ",
    "postal_code_format": "\\A[HLMS]\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Hhohho",
        "code": "HH"
      },
      {
        "name": "Lubombo",
        "code": "LU"
      },
      {
        "name": "Manzini",
        "code": "MA"
      },
      {
        "name": "Shiselweni",
        "code": "SH"
      }
    ]
  },
  {
    "name": "Ethiopia",
    "alpha3": "ETH",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Adis Abeba",
        "code": "AA"
      },
      {
        "name": "Afar",
        "code": "AF"
      },
      {
        "name": "Amara",
        "code": "AM"
      },
      {
        "name": "Binshangul Gumuz",
        "code": "BE"
      },
      {
        "name": "Dire Dawa",
        "code": "DD"
      },
      {
        "name": "Gambela Hizboch",
        "code": "GA"
      },
      {
        "name": "Hareri Hizb",
        "code": "HA"
      },
      {
        "name": "Oromiya",
        "code": "OR"
      },
      {
        "name": "YeDebub Biheroch Bihereseboch na Hizboch",
        "code": "SN"
      },
      {
        "name": "Sumale",
        "code": "SO"
      },
      {
        "name": "Tigray",
        "code": "TI"
      }
    ]
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "alpha3": "FLK",
    "postal_code_format": "\\AFIQQ 1ZZ\\Z",
    "subdivisions": []
  },
  {
    "name": "Faroe Islands",
    "alpha3": "FRO",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": []
  },
  {
    "name": "Fiji",
    "alpha3": "FJI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Ba",
        "code": "01"
      },
      {
        "name": "Bua",
        "code": "02"
      },
      {
        "name": "Cakaudrove",
        "code": "03"
      },
      {
        "name": "Kadavu",
        "code": "04"
      },
      {
        "name": "Lau",
        "code": "05"
      },
      {
        "name": "Lomaiviti",
        "code": "06"
      },
      {
        "name": "Macuata",
        "code": "07"
      },
      {
        "name": "Nadroga-Navosa",
        "code": "08"
      },
      {
        "name": "Naitasiri",
        "code": "09"
      },
      {
        "name": "Namosi",
        "code": "10"
      },
      {
        "name": "Ra",
        "code": "11"
      },
      {
        "name": "Rewa",
        "code": "12"
      },
      {
        "name": "Serua",
        "code": "13"
      },
      {
        "name": "Tailevu",
        "code": "14"
      },
      {
        "name": "Central",
        "code": "C"
      },
      {
        "name": "Eastern",
        "code": "E"
      },
      {
        "name": "Northern",
        "code": "N"
      },
      {
        "name": "Rotuma",
        "code": "R"
      },
      {
        "name": "Western",
        "code": "W"
      }
    ]
  },
  {
    "name": "Finland",
    "alpha3": "FIN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Ahvenanmaan maakunta",
        "code": "01"
      },
      {
        "name": "Etelä-Karjala",
        "code": "02"
      },
      {
        "name": "Etelä-Pohjanmaa",
        "code": "03"
      },
      {
        "name": "Etelä-Savo",
        "code": "04"
      },
      {
        "name": "Kainuu",
        "code": "05"
      },
      {
        "name": "Kanta-Häme",
        "code": "06"
      },
      {
        "name": "Keski-Pohjanmaa",
        "code": "07"
      },
      {
        "name": "Keski-Suomi",
        "code": "08"
      },
      {
        "name": "Kymenlaakso",
        "code": "09"
      },
      {
        "name": "Lappi",
        "code": "10"
      },
      {
        "name": "Pirkanmaa",
        "code": "11"
      },
      {
        "name": "Pohjanmaa",
        "code": "12"
      },
      {
        "name": "Pohjois-Karjala",
        "code": "13"
      },
      {
        "name": "Pohjois-Pohjanmaa",
        "code": "14"
      },
      {
        "name": "Pohjois-Savo",
        "code": "15"
      },
      {
        "name": "Päijät-Häme",
        "code": "16"
      },
      {
        "name": "Satakunta",
        "code": "17"
      },
      {
        "name": "Uusimaa",
        "code": "18"
      },
      {
        "name": "Varsinais-Suomi",
        "code": "19"
      }
    ]
  },
  {
    "name": "France",
    "alpha3": "FRA",
    "postal_code_format": "\\A\\d{2} ?\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Ain",
        "code": "01"
      },
      {
        "name": "Aisne",
        "code": "02"
      },
      {
        "name": "Allier",
        "code": "03"
      },
      {
        "name": "Alpes-de-Haute-Provence",
        "code": "04"
      },
      {
        "name": "Hautes-Alpes",
        "code": "05"
      },
      {
        "name": "Alpes-Maritimes",
        "code": "06"
      },
      {
        "name": "Ardèche",
        "code": "07"
      },
      {
        "name": "Ardennes",
        "code": "08"
      },
      {
        "name": "Ariège",
        "code": "09"
      },
      {
        "name": "Aube",
        "code": "10"
      },
      {
        "name": "Aude",
        "code": "11"
      },
      {
        "name": "Aveyron",
        "code": "12"
      },
      {
        "name": "Bouches-du-Rhône",
        "code": "13"
      },
      {
        "name": "Calvados",
        "code": "14"
      },
      {
        "name": "Cantal",
        "code": "15"
      },
      {
        "name": "Charente",
        "code": "16"
      },
      {
        "name": "Charente-Maritime",
        "code": "17"
      },
      {
        "name": "Cher",
        "code": "18"
      },
      {
        "name": "Corrèze",
        "code": "19"
      },
      {
        "name": "Corse",
        "code": "20R"
      },
      {
        "name": "Côte-d'Or",
        "code": "21"
      },
      {
        "name": "Côtes-d'Armor",
        "code": "22"
      },
      {
        "name": "Creuse",
        "code": "23"
      },
      {
        "name": "Dordogne",
        "code": "24"
      },
      {
        "name": "Doubs",
        "code": "25"
      },
      {
        "name": "Drôme",
        "code": "26"
      },
      {
        "name": "Eure",
        "code": "27"
      },
      {
        "name": "Eure-et-Loir",
        "code": "28"
      },
      {
        "name": "Finistère",
        "code": "29"
      },
      {
        "name": "Corse-du-Sud",
        "code": "2A"
      },
      {
        "name": "Haute-Corse",
        "code": "2B"
      },
      {
        "name": "Gard",
        "code": "30"
      },
      {
        "name": "Haute-Garonne",
        "code": "31"
      },
      {
        "name": "Gers",
        "code": "32"
      },
      {
        "name": "Gironde",
        "code": "33"
      },
      {
        "name": "Hérault",
        "code": "34"
      },
      {
        "name": "Ille-et-Vilaine",
        "code": "35"
      },
      {
        "name": "Indre",
        "code": "36"
      },
      {
        "name": "Indre-et-Loire",
        "code": "37"
      },
      {
        "name": "Isère",
        "code": "38"
      },
      {
        "name": "Jura",
        "code": "39"
      },
      {
        "name": "Landes",
        "code": "40"
      },
      {
        "name": "Loir-et-Cher",
        "code": "41"
      },
      {
        "name": "Loire",
        "code": "42"
      },
      {
        "name": "Haute-Loire",
        "code": "43"
      },
      {
        "name": "Loire-Atlantique",
        "code": "44"
      },
      {
        "name": "Loiret",
        "code": "45"
      },
      {
        "name": "Lot",
        "code": "46"
      },
      {
        "name": "Lot-et-Garonne",
        "code": "47"
      },
      {
        "name": "Lozère",
        "code": "48"
      },
      {
        "name": "Maine-et-Loire",
        "code": "49"
      },
      {
        "name": "Manche",
        "code": "50"
      },
      {
        "name": "Marne",
        "code": "51"
      },
      {
        "name": "Haute-Marne",
        "code": "52"
      },
      {
        "name": "Mayenne",
        "code": "53"
      },
      {
        "name": "Meurthe-et-Moselle",
        "code": "54"
      },
      {
        "name": "Meuse",
        "code": "55"
      },
      {
        "name": "Morbihan",
        "code": "56"
      },
      {
        "name": "Moselle",
        "code": "57"
      },
      {
        "name": "Nièvre",
        "code": "58"
      },
      {
        "name": "Nord",
        "code": "59"
      },
      {
        "name": "Oise",
        "code": "60"
      },
      {
        "name": "Orne",
        "code": "61"
      },
      {
        "name": "Pas-de-Calais",
        "code": "62"
      },
      {
        "name": "Puy-de-Dôme",
        "code": "63"
      },
      {
        "name": "Pyrénées-Atlantiques",
        "code": "64"
      },
      {
        "name": "Hautes-Pyrénées",
        "code": "65"
      },
      {
        "name": "Pyrénées-Orientales",
        "code": "66"
      },
      {
        "name": "Bas-Rhin",
        "code": "67"
      },
      {
        "name": "Haut-Rhin",
        "code": "68"
      },
      {
        "name": "Rhône",
        "code": "69"
      },
      {
        "name": "Métropole de Lyon",
        "code": "69M"
      },
      {
        "name": "Alsace",
        "code": "6AE"
      },
      {
        "name": "Haute-Saône",
        "code": "70"
      },
      {
        "name": "Saône-et-Loire",
        "code": "71"
      },
      {
        "name": "Sarthe",
        "code": "72"
      },
      {
        "name": "Savoie",
        "code": "73"
      },
      {
        "name": "Haute-Savoie",
        "code": "74"
      },
      {
        "name": "Paris",
        "code": "75C"
      },
      {
        "name": "Seine-Maritime",
        "code": "76"
      },
      {
        "name": "Seine-et-Marne",
        "code": "77"
      },
      {
        "name": "Yvelines",
        "code": "78"
      },
      {
        "name": "Deux-Sèvres",
        "code": "79"
      },
      {
        "name": "Somme",
        "code": "80"
      },
      {
        "name": "Tarn",
        "code": "81"
      },
      {
        "name": "Tarn-et-Garonne",
        "code": "82"
      },
      {
        "name": "Var",
        "code": "83"
      },
      {
        "name": "Vaucluse",
        "code": "84"
      },
      {
        "name": "Vendée",
        "code": "85"
      },
      {
        "name": "Vienne",
        "code": "86"
      },
      {
        "name": "Haute-Vienne",
        "code": "87"
      },
      {
        "name": "Vosges",
        "code": "88"
      },
      {
        "name": "Yonne",
        "code": "89"
      },
      {
        "name": "Territoire de Belfort",
        "code": "90"
      },
      {
        "name": "Essonne",
        "code": "91"
      },
      {
        "name": "Hauts-de-Seine",
        "code": "92"
      },
      {
        "name": "Seine-Saint-Denis",
        "code": "93"
      },
      {
        "name": "Val-de-Marne",
        "code": "94"
      },
      {
        "name": "Val-d'Oise",
        "code": "95"
      },
      {
        "name": "Guadeloupe",
        "code": "971"
      },
      {
        "name": "Martinique",
        "code": "972"
      },
      {
        "name": "Guyane (française)",
        "code": "973"
      },
      {
        "name": "La Réunion",
        "code": "974"
      },
      {
        "name": "Mayotte",
        "code": "976"
      },
      {
        "name": "Auvergne-Rhône-Alpes",
        "code": "ARA"
      },
      {
        "name": "Bourgogne-Franche-Comté",
        "code": "BFC"
      },
      {
        "name": "Saint-Barthélemy",
        "code": "BL"
      },
      {
        "name": "Bretagne",
        "code": "BRE"
      },
      {
        "name": "Clipperton",
        "code": "CP"
      },
      {
        "name": "Centre-Val de Loire",
        "code": "CVL"
      },
      {
        "name": "Grand Est",
        "code": "GES"
      },
      {
        "name": "Hauts-de-France",
        "code": "HDF"
      },
      {
        "name": "Île-de-France",
        "code": "IDF"
      },
      {
        "name": "Saint-Martin",
        "code": "MF"
      },
      {
        "name": "Nouvelle-Aquitaine",
        "code": "NAQ"
      },
      {
        "name": "New Caledonia",
        "code": "NC"
      },
      {
        "name": "Normandie",
        "code": "NOR"
      },
      {
        "name": "Occitanie",
        "code": "OCC"
      },
      {
        "name": "Provence-Alpes-Côte d'Azur",
        "code": "PAC"
      },
      {
        "name": "Pays de la Loire",
        "code": "PDL"
      },
      {
        "name": "Polynésie française",
        "code": "PF"
      },
      {
        "name": "Saint-Pierre-et-Miquelon",
        "code": "PM"
      },
      {
        "name": "Terres Australes Françaises",
        "code": "TF"
      },
      {
        "name": "Wallis-et-Futuna",
        "code": "WF"
      }
    ]
  },
  {
    "name": "French Guiana",
    "alpha3": "GUF",
    "postal_code_format": "\\A9[78]3\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "French Polynesia",
    "alpha3": "PYF",
    "postal_code_format": "\\A987\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "French Southern Territories",
    "alpha3": "ATF",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Gabon",
    "alpha3": "GAB",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Estuaire",
        "code": "1"
      },
      {
        "name": "Haut-Ogooué",
        "code": "2"
      },
      {
        "name": "Moyen-Ogooué",
        "code": "3"
      },
      {
        "name": "Ngounié",
        "code": "4"
      },
      {
        "name": "Nyanga",
        "code": "5"
      },
      {
        "name": "Ogooué-Ivindo",
        "code": "6"
      },
      {
        "name": "Ogooué-Lolo",
        "code": "7"
      },
      {
        "name": "Ogooué-Maritime",
        "code": "8"
      },
      {
        "name": "Woleu-Ntem",
        "code": "9"
      }
    ]
  },
  {
    "name": "Gambia",
    "alpha3": "GMB",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Banjul",
        "code": "B"
      },
      {
        "name": "Lower River",
        "code": "L"
      },
      {
        "name": "MacCarthy Island",
        "code": "M"
      },
      {
        "name": "North Bank",
        "code": "N"
      },
      {
        "name": "Upper River",
        "code": "U"
      },
      {
        "name": "Western",
        "code": "W"
      }
    ]
  },
  {
    "name": "Georgia",
    "alpha3": "GEO",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Abkhazia",
        "code": "AB"
      },
      {
        "name": "Ajaria",
        "code": "AJ"
      },
      {
        "name": "Guria",
        "code": "GU"
      },
      {
        "name": "Imereti",
        "code": "IM"
      },
      {
        "name": "Kakheti",
        "code": "KA"
      },
      {
        "name": "Kvemo Kartli",
        "code": "KK"
      },
      {
        "name": "Mtskheta-Mtianeti",
        "code": "MM"
      },
      {
        "name": "Racha-Lechkhumi [and] Kvemo Svaneti",
        "code": "RL"
      },
      {
        "name": "Samtskhe-Javakheti",
        "code": "SJ"
      },
      {
        "name": "Shida Kartli",
        "code": "SK"
      },
      {
        "name": "Samegrelo-Zemo Svaneti",
        "code": "SZ"
      },
      {
        "name": "Tbilisi",
        "code": "TB"
      }
    ]
  },
  {
    "name": "Germany",
    "alpha3": "DEU",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Brandenburg",
        "code": "BB"
      },
      {
        "name": "Berlin",
        "code": "BE"
      },
      {
        "name": "Baden-Württemberg",
        "code": "BW"
      },
      {
        "name": "Bayern",
        "code": "BY"
      },
      {
        "name": "Bremen",
        "code": "HB"
      },
      {
        "name": "Hessen",
        "code": "HE"
      },
      {
        "name": "Hamburg",
        "code": "HH"
      },
      {
        "name": "Mecklenburg-Vorpommern",
        "code": "MV"
      },
      {
        "name": "Niedersachsen",
        "code": "NI"
      },
      {
        "name": "Nordrhein-Westfalen",
        "code": "NW"
      },
      {
        "name": "Rheinland-Pfalz",
        "code": "RP"
      },
      {
        "name": "Schleswig-Holstein",
        "code": "SH"
      },
      {
        "name": "Saarland",
        "code": "SL"
      },
      {
        "name": "Sachsen",
        "code": "SN"
      },
      {
        "name": "Sachsen-Anhalt",
        "code": "ST"
      },
      {
        "name": "Thüringen",
        "code": "TH"
      }
    ]
  },
  {
    "name": "Ghana",
    "alpha3": "GHA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Greater Accra",
        "code": "AA"
      },
      {
        "name": "Ahafo",
        "code": "AF"
      },
      {
        "name": "Ashanti",
        "code": "AH"
      },
      {
        "name": "Bono East",
        "code": "BE"
      },
      {
        "name": "Bono",
        "code": "BO"
      },
      {
        "name": "Central",
        "code": "CP"
      },
      {
        "name": "Eastern",
        "code": "EP"
      },
      {
        "name": "North East",
        "code": "NE"
      },
      {
        "name": "Northern",
        "code": "NP"
      },
      {
        "name": "Oti",
        "code": "OT"
      },
      {
        "name": "Savannah",
        "code": "SV"
      },
      {
        "name": "Volta",
        "code": "TV"
      },
      {
        "name": "Upper East",
        "code": "UE"
      },
      {
        "name": "Upper West",
        "code": "UW"
      },
      {
        "name": "Western North",
        "code": "WN"
      },
      {
        "name": "Western",
        "code": "WP"
      }
    ]
  },
  {
    "name": "Gibraltar",
    "alpha3": "GIB",
    "postal_code_format": "\\AGX11 1AA\\Z",
    "subdivisions": []
  },
  {
    "name": "Greece",
    "alpha3": "GRC",
    "postal_code_format": "\\A\\d{3} ?\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Ágion Óros",
        "code": "69"
      },
      {
        "name": "Anatolikí Makedonía kai Thráki",
        "code": "A"
      },
      {
        "name": "Kentrikí Makedonía",
        "code": "B"
      },
      {
        "name": "Dytikí Makedonía",
        "code": "C"
      },
      {
        "name": "Ípeiros",
        "code": "D"
      },
      {
        "name": "Thessalía",
        "code": "E"
      },
      {
        "name": "Ionía Nísia",
        "code": "F"
      },
      {
        "name": "Dytikí Elláda",
        "code": "G"
      },
      {
        "name": "Stereá Elláda",
        "code": "H"
      },
      {
        "name": "Attikí",
        "code": "I"
      },
      {
        "name": "Pelopónnisos",
        "code": "J"
      },
      {
        "name": "Vóreio Aigaío",
        "code": "K"
      },
      {
        "name": "Nótio Aigaío",
        "code": "L"
      },
      {
        "name": "Kríti",
        "code": "M"
      }
    ]
  },
  {
    "name": "Greenland",
    "alpha3": "GRL",
    "postal_code_format": "\\A39\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Avannaata Kommunia",
        "code": "AV"
      },
      {
        "name": "Kujalleq",
        "code": "KU"
      },
      {
        "name": "Qeqqata",
        "code": "QE"
      },
      {
        "name": "Kommune Qeqertalik",
        "code": "QT"
      },
      {
        "name": "Sermersooq",
        "code": "SM"
      }
    ]
  },
  {
    "name": "Grenada",
    "alpha3": "GRD",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Saint Andrew",
        "code": "01"
      },
      {
        "name": "Saint David",
        "code": "02"
      },
      {
        "name": "Saint George",
        "code": "03"
      },
      {
        "name": "Saint John",
        "code": "04"
      },
      {
        "name": "Saint Mark",
        "code": "05"
      },
      {
        "name": "Saint Patrick",
        "code": "06"
      },
      {
        "name": "Southern Grenadine Islands",
        "code": "10"
      }
    ]
  },
  {
    "name": "Guadeloupe",
    "alpha3": "GLP",
    "postal_code_format": "\\A9[78][01]\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Guam",
    "alpha3": "GUM",
    "postal_code_format": "\\A(969(?:[12]\\d|3[12]))(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": []
  },
  {
    "name": "Guatemala",
    "alpha3": "GTM",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Alta Verapaz",
        "code": "AV"
      },
      {
        "name": "Baja Verapaz",
        "code": "BV"
      },
      {
        "name": "Chimaltenango",
        "code": "CM"
      },
      {
        "name": "Chiquimula",
        "code": "CQ"
      },
      {
        "name": "Escuintla",
        "code": "ES"
      },
      {
        "name": "Guatemala",
        "code": "GU"
      },
      {
        "name": "Huehuetenango",
        "code": "HU"
      },
      {
        "name": "Izabal",
        "code": "IZ"
      },
      {
        "name": "Jalapa",
        "code": "JA"
      },
      {
        "name": "Jutiapa",
        "code": "JU"
      },
      {
        "name": "Petén",
        "code": "PE"
      },
      {
        "name": "El Progreso",
        "code": "PR"
      },
      {
        "name": "Quiché",
        "code": "QC"
      },
      {
        "name": "Quetzaltenango",
        "code": "QZ"
      },
      {
        "name": "Retalhuleu",
        "code": "RE"
      },
      {
        "name": "Sacatepéquez",
        "code": "SA"
      },
      {
        "name": "San Marcos",
        "code": "SM"
      },
      {
        "name": "Sololá",
        "code": "SO"
      },
      {
        "name": "Santa Rosa",
        "code": "SR"
      },
      {
        "name": "Suchitepéquez",
        "code": "SU"
      },
      {
        "name": "Totonicapán",
        "code": "TO"
      },
      {
        "name": "Zacapa",
        "code": "ZA"
      }
    ]
  },
  {
    "name": "Guernsey",
    "alpha3": "GGY",
    "postal_code_format": "\\AGY\\d[\\dA-Z]? ?\\d[ABD-HJLN-UW-Z]{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Guinea",
    "alpha3": "GIN",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Région de Boké",
        "code": "B"
      },
      {
        "name": "Beyla",
        "code": "BE"
      },
      {
        "name": "Boffa",
        "code": "BF"
      },
      {
        "name": "Boké",
        "code": "BK"
      },
      {
        "name": "Conakry",
        "code": "C"
      },
      {
        "name": "Coyah",
        "code": "CO"
      },
      {
        "name": "Région de Kindia",
        "code": "D"
      },
      {
        "name": "Dabola",
        "code": "DB"
      },
      {
        "name": "Dinguiraye",
        "code": "DI"
      },
      {
        "name": "Dalaba",
        "code": "DL"
      },
      {
        "name": "Dubréka",
        "code": "DU"
      },
      {
        "name": "Région de Faranah",
        "code": "F"
      },
      {
        "name": "Faranah",
        "code": "FA"
      },
      {
        "name": "Forécariah",
        "code": "FO"
      },
      {
        "name": "Fria",
        "code": "FR"
      },
      {
        "name": "Gaoual",
        "code": "GA"
      },
      {
        "name": "Guékédou",
        "code": "GU"
      },
      {
        "name": "Région de Kankan",
        "code": "K"
      },
      {
        "name": "Kankan",
        "code": "KA"
      },
      {
        "name": "Koubia",
        "code": "KB"
      },
      {
        "name": "Kindia",
        "code": "KD"
      },
      {
        "name": "Kérouané",
        "code": "KE"
      },
      {
        "name": "Koundara",
        "code": "KN"
      },
      {
        "name": "Kouroussa",
        "code": "KO"
      },
      {
        "name": "Kissidougou",
        "code": "KS"
      },
      {
        "name": "Région de Labé",
        "code": "L"
      },
      {
        "name": "Labé",
        "code": "LA"
      },
      {
        "name": "Lélouma",
        "code": "LE"
      },
      {
        "name": "Lola",
        "code": "LO"
      },
      {
        "name": "Région de Mamou",
        "code": "M"
      },
      {
        "name": "Macenta",
        "code": "MC"
      },
      {
        "name": "Mandiana",
        "code": "MD"
      },
      {
        "name": "Mali",
        "code": "ML"
      },
      {
        "name": "Mamou",
        "code": "MM"
      },
      {
        "name": "Région de Nzérékoré",
        "code": "N"
      },
      {
        "name": "Nzérékoré",
        "code": "NZ"
      },
      {
        "name": "Pita",
        "code": "PI"
      },
      {
        "name": "Siguiri",
        "code": "SI"
      },
      {
        "name": "Télimélé",
        "code": "TE"
      },
      {
        "name": "Tougué",
        "code": "TO"
      },
      {
        "name": "Yomou",
        "code": "YO"
      }
    ]
  },
  {
    "name": "Guinea-Bissau",
    "alpha3": "GNB",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Bafatá",
        "code": "BA"
      },
      {
        "name": "Bolama",
        "code": "BL"
      },
      {
        "name": "Biombo",
        "code": "BM"
      },
      {
        "name": "Bissau",
        "code": "BS"
      },
      {
        "name": "Cacheu",
        "code": "CA"
      },
      {
        "name": "Gabú",
        "code": "GA"
      },
      {
        "name": "Leste",
        "code": "L"
      },
      {
        "name": "Norte",
        "code": "N"
      },
      {
        "name": "Oio",
        "code": "OI"
      },
      {
        "name": "Quinara",
        "code": "QU"
      },
      {
        "name": "Sul",
        "code": "S"
      },
      {
        "name": "Tombali",
        "code": "TO"
      }
    ]
  },
  {
    "name": "Guyana",
    "alpha3": "GUY",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Barima-Waini",
        "code": "BA"
      },
      {
        "name": "Cuyuni-Mazaruni",
        "code": "CU"
      },
      {
        "name": "Demerara-Mahaica",
        "code": "DE"
      },
      {
        "name": "East Berbice-Corentyne",
        "code": "EB"
      },
      {
        "name": "Essequibo Islands-West Demerara",
        "code": "ES"
      },
      {
        "name": "Mahaica-Berbice",
        "code": "MA"
      },
      {
        "name": "Pomeroon-Supenaam",
        "code": "PM"
      },
      {
        "name": "Potaro-Siparuni",
        "code": "PT"
      },
      {
        "name": "Upper Demerara-Berbice",
        "code": "UD"
      },
      {
        "name": "Upper Takutu-Upper Essequibo",
        "code": "UT"
      }
    ]
  },
  {
    "name": "Haiti",
    "alpha3": "HTI",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Artibonite",
        "code": "AR"
      },
      {
        "name": "Centre",
        "code": "CE"
      },
      {
        "name": "Grande-Anse",
        "code": "GA"
      },
      {
        "name": "Nord",
        "code": "ND"
      },
      {
        "name": "Nord-Est",
        "code": "NE"
      },
      {
        "name": "Nippes",
        "code": "NI"
      },
      {
        "name": "Nord-Ouest",
        "code": "NO"
      },
      {
        "name": "Ouest",
        "code": "OU"
      },
      {
        "name": "Sud",
        "code": "SD"
      },
      {
        "name": "Sud-Est",
        "code": "SE"
      }
    ]
  },
  {
    "name": "Heard Island and McDonald Islands",
    "alpha3": "HMD",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": []
  },
  {
    "name": "Holy See",
    "alpha3": "VAT",
    "postal_code_format": "\\A00120\\Z",
    "subdivisions": []
  },
  {
    "name": "Honduras",
    "alpha3": "HND",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Atlántida",
        "code": "AT"
      },
      {
        "name": "Choluteca",
        "code": "CH"
      },
      {
        "name": "Colón",
        "code": "CL"
      },
      {
        "name": "Comayagua",
        "code": "CM"
      },
      {
        "name": "Copán",
        "code": "CP"
      },
      {
        "name": "Cortés",
        "code": "CR"
      },
      {
        "name": "El Paraíso",
        "code": "EP"
      },
      {
        "name": "Francisco Morazán",
        "code": "FM"
      },
      {
        "name": "Gracias a Dios",
        "code": "GD"
      },
      {
        "name": "Islas de la Bahía",
        "code": "IB"
      },
      {
        "name": "Intibucá",
        "code": "IN"
      },
      {
        "name": "Lempira",
        "code": "LE"
      },
      {
        "name": "La Paz",
        "code": "LP"
      },
      {
        "name": "Ocotepeque",
        "code": "OC"
      },
      {
        "name": "Olancho",
        "code": "OL"
      },
      {
        "name": "Santa Bárbara",
        "code": "SB"
      },
      {
        "name": "Valle",
        "code": "VA"
      },
      {
        "name": "Yoro",
        "code": "YO"
      }
    ]
  },
  {
    "name": "Hong Kong",
    "alpha3": "HKG",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Hungary",
    "alpha3": "HUN",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Baranya",
        "code": "BA"
      },
      {
        "name": "Békéscsaba",
        "code": "BC"
      },
      {
        "name": "Békés",
        "code": "BE"
      },
      {
        "name": "Bács-Kiskun",
        "code": "BK"
      },
      {
        "name": "Budapest",
        "code": "BU"
      },
      {
        "name": "Borsod-Abaúj-Zemplén",
        "code": "BZ"
      },
      {
        "name": "Csongrád",
        "code": "CS"
      },
      {
        "name": "Debrecen",
        "code": "DE"
      },
      {
        "name": "Dunaújváros",
        "code": "DU"
      },
      {
        "name": "Eger",
        "code": "EG"
      },
      {
        "name": "Érd",
        "code": "ER"
      },
      {
        "name": "Fejér",
        "code": "FE"
      },
      {
        "name": "Győr-Moson-Sopron",
        "code": "GS"
      },
      {
        "name": "Győr",
        "code": "GY"
      },
      {
        "name": "Hajdú-Bihar",
        "code": "HB"
      },
      {
        "name": "Heves",
        "code": "HE"
      },
      {
        "name": "Hódmezővásárhely",
        "code": "HV"
      },
      {
        "name": "Jász-Nagykun-Szolnok",
        "code": "JN"
      },
      {
        "name": "Komárom-Esztergom",
        "code": "KE"
      },
      {
        "name": "Kecskemét",
        "code": "KM"
      },
      {
        "name": "Kaposvár",
        "code": "KV"
      },
      {
        "name": "Miskolc",
        "code": "MI"
      },
      {
        "name": "Nagykanizsa",
        "code": "NK"
      },
      {
        "name": "Nógrád",
        "code": "NO"
      },
      {
        "name": "Nyíregyháza",
        "code": "NY"
      },
      {
        "name": "Pest",
        "code": "PE"
      },
      {
        "name": "Pécs",
        "code": "PS"
      },
      {
        "name": "Szeged",
        "code": "SD"
      },
      {
        "name": "Székesfehérvár",
        "code": "SF"
      },
      {
        "name": "Szombathely",
        "code": "SH"
      },
      {
        "name": "Szolnok",
        "code": "SK"
      },
      {
        "name": "Sopron",
        "code": "SN"
      },
      {
        "name": "Somogy",
        "code": "SO"
      },
      {
        "name": "Szekszárd",
        "code": "SS"
      },
      {
        "name": "Salgótarján",
        "code": "ST"
      },
      {
        "name": "Szabolcs-Szatmár-Bereg",
        "code": "SZ"
      },
      {
        "name": "Tatabánya",
        "code": "TB"
      },
      {
        "name": "Tolna",
        "code": "TO"
      },
      {
        "name": "Vas",
        "code": "VA"
      },
      {
        "name": "Veszprém",
        "code": "VE"
      },
      {
        "name": "Veszprém",
        "code": "VM"
      },
      {
        "name": "Zala",
        "code": "ZA"
      },
      {
        "name": "Zalaegerszeg",
        "code": "ZE"
      }
    ]
  },
  {
    "name": "Iceland",
    "alpha3": "ISL",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Reykjavík",
        "code": "0"
      },
      {
        "name": "Höfuðborgarsvæði utan Reykjavíkur",
        "code": "1"
      },
      {
        "name": "Suðurnes",
        "code": "2"
      },
      {
        "name": "Vesturland",
        "code": "3"
      },
      {
        "name": "Vestfirðir",
        "code": "4"
      },
      {
        "name": "Norðurland vestra",
        "code": "5"
      },
      {
        "name": "Norðurland eystra",
        "code": "6"
      },
      {
        "name": "Austurland",
        "code": "7"
      },
      {
        "name": "Suðurland",
        "code": "8"
      },
      {
        "name": "Akrahreppur",
        "code": "AKH"
      },
      {
        "name": "Akraneskaupstaður",
        "code": "AKN"
      },
      {
        "name": "Akureyrarbær",
        "code": "AKU"
      },
      {
        "name": "Árneshreppur",
        "code": "ARN"
      },
      {
        "name": "Ásahreppur",
        "code": "ASA"
      },
      {
        "name": "Bláskógabyggð",
        "code": "BLA"
      },
      {
        "name": "Blönduósbær",
        "code": "BLO"
      },
      {
        "name": "Borgarbyggð",
        "code": "BOG"
      },
      {
        "name": "Bolungarvíkurkaupstaður",
        "code": "BOL"
      },
      {
        "name": "Dalabyggð",
        "code": "DAB"
      },
      {
        "name": "Dalvíkurbyggð",
        "code": "DAV"
      },
      {
        "name": "Eyja- og Miklaholtshreppur",
        "code": "EOM"
      },
      {
        "name": "Eyjafjarðarsveit",
        "code": "EYF"
      },
      {
        "name": "Fjarðabyggð",
        "code": "FJD"
      },
      {
        "name": "Fjallabyggð",
        "code": "FJL"
      },
      {
        "name": "Flóahreppur",
        "code": "FLA"
      },
      {
        "name": "Fljótsdalshreppur",
        "code": "FLR"
      },
      {
        "name": "Garðabær",
        "code": "GAR"
      },
      {
        "name": "Grímsnes- og Grafningshreppur",
        "code": "GOG"
      },
      {
        "name": "Grindavíkurbær",
        "code": "GRN"
      },
      {
        "name": "Grundarfjarðarbær",
        "code": "GRU"
      },
      {
        "name": "Grýtubakkahreppur",
        "code": "GRY"
      },
      {
        "name": "Hafnarfjarðarkaupstaður",
        "code": "HAF"
      },
      {
        "name": "Helgafellssveit",
        "code": "HEL"
      },
      {
        "name": "Hörgársveit",
        "code": "HRG"
      },
      {
        "name": "Hrunamannahreppur",
        "code": "HRU"
      },
      {
        "name": "Húnavatnshreppur",
        "code": "HUT"
      },
      {
        "name": "Húnaþing vestra",
        "code": "HUV"
      },
      {
        "name": "Hvalfjarðarsveit",
        "code": "HVA"
      },
      {
        "name": "Hveragerðisbær",
        "code": "HVE"
      },
      {
        "name": "Ísafjarðarbær",
        "code": "ISA"
      },
      {
        "name": "Kaldrananeshreppur",
        "code": "KAL"
      },
      {
        "name": "Kjósarhreppur",
        "code": "KJO"
      },
      {
        "name": "Kópavogsbær",
        "code": "KOP"
      },
      {
        "name": "Langanesbyggð",
        "code": "LAN"
      },
      {
        "name": "Mosfellsbær",
        "code": "MOS"
      },
      {
        "name": "Múlaþing",
        "code": "MUL"
      },
      {
        "name": "Mýrdalshreppur",
        "code": "MYR"
      },
      {
        "name": "Norðurþing",
        "code": "NOR"
      },
      {
        "name": "Rangárþing eystra",
        "code": "RGE"
      },
      {
        "name": "Rangárþing ytra",
        "code": "RGY"
      },
      {
        "name": "Reykhólahreppur",
        "code": "RHH"
      },
      {
        "name": "Reykjanesbær",
        "code": "RKN"
      },
      {
        "name": "Reykjavíkurborg",
        "code": "RKV"
      },
      {
        "name": "Svalbarðshreppur",
        "code": "SBH"
      },
      {
        "name": "Svalbarðsstrandarhreppur",
        "code": "SBT"
      },
      {
        "name": "Suðurnesjabær",
        "code": "SDN"
      },
      {
        "name": "Súðavíkurhreppur",
        "code": "SDV"
      },
      {
        "name": "Seltjarnarnesbær",
        "code": "SEL"
      },
      {
        "name": "Sveitarfélagið Árborg",
        "code": "SFA"
      },
      {
        "name": "Sveitarfélagið Hornafjörður",
        "code": "SHF"
      },
      {
        "name": "Skaftárhreppur",
        "code": "SKF"
      },
      {
        "name": "Skagabyggð",
        "code": "SKG"
      },
      {
        "name": "Skorradalshreppur",
        "code": "SKO"
      },
      {
        "name": "Skútustaðahreppur",
        "code": "SKU"
      },
      {
        "name": "Snæfellsbær",
        "code": "SNF"
      },
      {
        "name": "Skeiða- og Gnúpverjahreppur",
        "code": "SOG"
      },
      {
        "name": "Sveitarfélagið Ölfus",
        "code": "SOL"
      },
      {
        "name": "Sveitarfélagið Skagafjörður",
        "code": "SSF"
      },
      {
        "name": "Sveitarfélagið Skagaströnd",
        "code": "SSS"
      },
      {
        "name": "Strandabyggð",
        "code": "STR"
      },
      {
        "name": "Stykkishólmsbær",
        "code": "STY"
      },
      {
        "name": "Sveitarfélagið Vogar",
        "code": "SVG"
      },
      {
        "name": "Tálknafjarðarhreppur",
        "code": "TAL"
      },
      {
        "name": "Þingeyjarsveit",
        "code": "THG"
      },
      {
        "name": "Tjörneshreppur",
        "code": "TJO"
      },
      {
        "name": "Vestmannaeyjabær",
        "code": "VEM"
      },
      {
        "name": "Vesturbyggð",
        "code": "VER"
      },
      {
        "name": "Vopnafjarðarhreppur",
        "code": "VOP"
      }
    ]
  },
  {
    "name": "India",
    "alpha3": "IND",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Andaman and Nicobar Islands",
        "code": "AN"
      },
      {
        "name": "Andhra Pradesh",
        "code": "AP"
      },
      {
        "name": "Arunachal Pradesh",
        "code": "AR"
      },
      {
        "name": "Assam",
        "code": "AS"
      },
      {
        "name": "Bihar",
        "code": "BR"
      },
      {
        "name": "Chandigarh",
        "code": "CH"
      },
      {
        "name": "Chhattisgarh",
        "code": "CG"
      },
      {
        "name": "Dādra and Nagar Haveli and Damān and Diu",
        "code": "DH"
      },
      {
        "name": "Delhi",
        "code": "DL"
      },
      {
        "name": "Goa",
        "code": "GA"
      },
      {
        "name": "Gujarat",
        "code": "GJ"
      },
      {
        "name": "Himachal Pradesh",
        "code": "HP"
      },
      {
        "name": "Haryana",
        "code": "HR"
      },
      {
        "name": "Jharkhand",
        "code": "JH"
      },
      {
        "name": "Jammu and Kashmir",
        "code": "JK"
      },
      {
        "name": "Karnataka",
        "code": "KA"
      },
      {
        "name": "Kerala",
        "code": "KL"
      },
      {
        "name": "Ladakh",
        "code": "LA"
      },
      {
        "name": "Lakshadweep",
        "code": "LD"
      },
      {
        "name": "Maharashtra",
        "code": "MH"
      },
      {
        "name": "Meghalaya",
        "code": "ML"
      },
      {
        "name": "Manipur",
        "code": "MN"
      },
      {
        "name": "Madhya Pradesh",
        "code": "MP"
      },
      {
        "name": "Mizoram",
        "code": "MZ"
      },
      {
        "name": "Nagaland",
        "code": "NL"
      },
      {
        "name": "Odisha",
        "code": "OD"
      },
      {
        "name": "Punjab",
        "code": "PB"
      },
      {
        "name": "Pondicherry",
        "code": "PY"
      },
      {
        "name": "Rajasthan",
        "code": "RJ"
      },
      {
        "name": "Sikkim",
        "code": "SK"
      },
      {
        "name": "तेलंगाना",
        "code": "TS"
      },
      {
        "name": "Tamil Nadu",
        "code": "TN"
      },
      {
        "name": "Tripura",
        "code": "TR"
      },
      {
        "name": "Uttar Pradesh",
        "code": "UP"
      },
      {
        "name": "उत्तराखण्ड",
        "code": "UK"
      },
      {
        "name": "West Bengal",
        "code": "WB"
      }
    ]
  },
  {
    "name": "Indonesia",
    "alpha3": "IDN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Aceh",
        "code": "AC"
      },
      {
        "name": "Bali",
        "code": "BA"
      },
      {
        "name": "Kepulauan Bangka Belitung",
        "code": "BB"
      },
      {
        "name": "Bengkulu",
        "code": "BE"
      },
      {
        "name": "Banten",
        "code": "BT"
      },
      {
        "name": "Gorontalo",
        "code": "GO"
      },
      {
        "name": "Jambi",
        "code": "JA"
      },
      {
        "name": "Jawa Barat",
        "code": "JB"
      },
      {
        "name": "Jawa Timur",
        "code": "JI"
      },
      {
        "name": "Daerah Khusus Ibukota Jakarta",
        "code": "JK"
      },
      {
        "name": "Jawa Tengah",
        "code": "JT"
      },
      {
        "name": "Jawa",
        "code": "JW"
      },
      {
        "name": "Kalimantan",
        "code": "KA"
      },
      {
        "name": "Kalimantan Barat",
        "code": "KB"
      },
      {
        "name": "Kalimantan Timur",
        "code": "KI"
      },
      {
        "name": "Kepulauan Riau",
        "code": "KR"
      },
      {
        "name": "Kalimantan Selatan",
        "code": "KS"
      },
      {
        "name": "Kalimantan Tengah",
        "code": "KT"
      },
      {
        "name": "Kalimantan Utara",
        "code": "KU"
      },
      {
        "name": "Lampung",
        "code": "LA"
      },
      {
        "name": "Maluku",
        "code": "MA"
      },
      {
        "name": "Kepulauan Maluku",
        "code": "ML"
      },
      {
        "name": "Maluku Utara",
        "code": "MU"
      },
      {
        "name": "Nusa Tenggara Barat",
        "code": "NB"
      },
      {
        "name": "Nusa Tenggara Timur",
        "code": "NT"
      },
      {
        "name": "Kepulauan Nusa Tenggara",
        "code": "NU"
      },
      {
        "name": "Papua",
        "code": "PA"
      },
      {
        "name": "Papua Barat",
        "code": "PB"
      },
      {
        "name": "Papua bagian barat",
        "code": "PP"
      },
      {
        "name": "Riau",
        "code": "RI"
      },
      {
        "name": "Sulawesi Utara",
        "code": "SA"
      },
      {
        "name": "Sumatera Barat",
        "code": "SB"
      },
      {
        "name": "Sulawesi Tenggara",
        "code": "SG"
      },
      {
        "name": "Sulawesi",
        "code": "SL"
      },
      {
        "name": "Sumatera",
        "code": "SM"
      },
      {
        "name": "Sulawesi Selatan",
        "code": "SN"
      },
      {
        "name": "Sulawesi Barat",
        "code": "SR"
      },
      {
        "name": "Sumatera Selatan",
        "code": "SS"
      },
      {
        "name": "Sulawesi Tengah",
        "code": "ST"
      },
      {
        "name": "Sumatera Utara",
        "code": "SU"
      },
      {
        "name": "Yogyakarta",
        "code": "YO"
      }
    ]
  },
  {
    "name": "Iran (Islamic Republic of)",
    "alpha3": "IRN",
    "postal_code_format": "\\A\\d{5}-?\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Markazi",
        "code": "00"
      },
      {
        "name": "Gilan",
        "code": "01"
      },
      {
        "name": "Mazandaran",
        "code": "02"
      },
      {
        "name": "Az¯arbayjan-e Sharqi",
        "code": "03"
      },
      {
        "name": "Az¯arbayjan-e Gharbi",
        "code": "04"
      },
      {
        "name": "Kermanshah",
        "code": "05"
      },
      {
        "name": "Khuzestan",
        "code": "06"
      },
      {
        "name": "Fars",
        "code": "07"
      },
      {
        "name": "Kerman",
        "code": "08"
      },
      {
        "name": "Khorasan-e Razavi",
        "code": "09"
      },
      {
        "name": "Esfahan",
        "code": "10"
      },
      {
        "name": "Sistan va Baluchestan",
        "code": "11"
      },
      {
        "name": "Kordestan",
        "code": "12"
      },
      {
        "name": "Hamadan",
        "code": "13"
      },
      {
        "name": "Chahar Mah¸all va Bakhtiari",
        "code": "14"
      },
      {
        "name": "Lorestan",
        "code": "15"
      },
      {
        "name": "Ilam",
        "code": "16"
      },
      {
        "name": "Kohkiluyeh va Buyer Ahmad",
        "code": "17"
      },
      {
        "name": "Bushehr",
        "code": "18"
      },
      {
        "name": "Zanjan",
        "code": "19"
      },
      {
        "name": "Semnan",
        "code": "20"
      },
      {
        "name": "Yazd",
        "code": "21"
      },
      {
        "name": "Hormozgan",
        "code": "22"
      },
      {
        "name": "Tehran",
        "code": "23"
      },
      {
        "name": "Ardabil",
        "code": "24"
      },
      {
        "name": "Qom",
        "code": "25"
      },
      {
        "name": "Qazvin",
        "code": "26"
      },
      {
        "name": "Golestan",
        "code": "27"
      },
      {
        "name": "Khorasan-e Shemali",
        "code": "28"
      },
      {
        "name": "Khorasan-e Janubi",
        "code": "29"
      },
      {
        "name": "استان البرز",
        "code": "30"
      }
    ]
  },
  {
    "name": "Iraq",
    "alpha3": "IRQ",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Al Anbar",
        "code": "AN"
      },
      {
        "name": "Arbil",
        "code": "AR"
      },
      {
        "name": "Al Basrah",
        "code": "BA"
      },
      {
        "name": "Babil",
        "code": "BB"
      },
      {
        "name": "Baghdad",
        "code": "BG"
      },
      {
        "name": "Dahuk",
        "code": "DA"
      },
      {
        "name": "Diyalá",
        "code": "DI"
      },
      {
        "name": "Dhi Qar",
        "code": "DQ"
      },
      {
        "name": "Karbala'",
        "code": "KA"
      },
      {
        "name": "كركوك",
        "code": "KI"
      },
      {
        "name": "إقليم كردستان",
        "code": "KR"
      },
      {
        "name": "Maysan",
        "code": "MA"
      },
      {
        "name": "Al Muthanná",
        "code": "MU"
      },
      {
        "name": "An Najaf",
        "code": "NA"
      },
      {
        "name": "Ninawá",
        "code": "NI"
      },
      {
        "name": "Al Qadisiyah",
        "code": "QA"
      },
      {
        "name": "Salah ad Din",
        "code": "SD"
      },
      {
        "name": "As Sulaymaniyah",
        "code": "SU"
      },
      {
        "name": "Wasit",
        "code": "WA"
      }
    ]
  },
  {
    "name": "Ireland",
    "alpha3": "IRL",
    "postal_code_format": "\\A[\\dA-Z]{3} ?[\\dA-Z]{4}\\Z",
    "subdivisions": [
      {
        "name": "Connacht",
        "code": "C"
      },
      {
        "name": "Clare",
        "code": "CE"
      },
      {
        "name": "Cavan",
        "code": "CN"
      },
      {
        "name": "Cork",
        "code": "CO"
      },
      {
        "name": "Carlow",
        "code": "CW"
      },
      {
        "name": "Dublin",
        "code": "D"
      },
      {
        "name": "Donegal",
        "code": "DL"
      },
      {
        "name": "Galway",
        "code": "G"
      },
      {
        "name": "Kildare",
        "code": "KE"
      },
      {
        "name": "Kilkenny",
        "code": "KK"
      },
      {
        "name": "Kerry",
        "code": "KY"
      },
      {
        "name": "Leinster",
        "code": "L"
      },
      {
        "name": "Longford",
        "code": "LD"
      },
      {
        "name": "Louth",
        "code": "LH"
      },
      {
        "name": "Limerick",
        "code": "LK"
      },
      {
        "name": "Leitrim",
        "code": "LM"
      },
      {
        "name": "Laois",
        "code": "LS"
      },
      {
        "name": "Munster",
        "code": "M"
      },
      {
        "name": "Meath",
        "code": "MH"
      },
      {
        "name": "Monaghan",
        "code": "MN"
      },
      {
        "name": "Mayo",
        "code": "MO"
      },
      {
        "name": "Offaly",
        "code": "OY"
      },
      {
        "name": "Roscommon",
        "code": "RN"
      },
      {
        "name": "Sligo",
        "code": "SO"
      },
      {
        "name": "Tipperary",
        "code": "TA"
      },
      {
        "name": "Ulster",
        "code": "U"
      },
      {
        "name": "Waterford",
        "code": "WD"
      },
      {
        "name": "Westmeath",
        "code": "WH"
      },
      {
        "name": "Wicklow",
        "code": "WW"
      },
      {
        "name": "Wexford",
        "code": "WX"
      }
    ]
  },
  {
    "name": "Isle of Man",
    "alpha3": "IMN",
    "postal_code_format": "\\AIM\\d[\\dA-Z]? ?\\d[ABD-HJLN-UW-Z]{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Israel",
    "alpha3": "ISR",
    "postal_code_format": "\\A\\d{5}(?:\\d{2})?\\Z",
    "subdivisions": [
      {
        "name": "HaDarom",
        "code": "D"
      },
      {
        "name": "Haifa",
        "code": "HA"
      },
      {
        "name": "Yerushalayim",
        "code": "JM"
      },
      {
        "name": "HaMerkaz",
        "code": "M"
      },
      {
        "name": "Tel-Aviv",
        "code": "TA"
      },
      {
        "name": "HaZafon",
        "code": "Z"
      }
    ]
  },
  {
    "name": "Italy",
    "alpha3": "ITA",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Piemonte",
        "code": "21"
      },
      {
        "name": "Valle d'Aosta",
        "code": "23"
      },
      {
        "name": "Lombardia",
        "code": "25"
      },
      {
        "name": "Trentino-Alto Adige",
        "code": "32"
      },
      {
        "name": "Veneto",
        "code": "34"
      },
      {
        "name": "Friuli Venezia Giulia",
        "code": "36"
      },
      {
        "name": "Liguria",
        "code": "42"
      },
      {
        "name": "Emilia-Romagna",
        "code": "45"
      },
      {
        "name": "Toscana",
        "code": "52"
      },
      {
        "name": "Umbria",
        "code": "55"
      },
      {
        "name": "Marche",
        "code": "57"
      },
      {
        "name": "Lazio",
        "code": "62"
      },
      {
        "name": "Abruzzo",
        "code": "65"
      },
      {
        "name": "Molise",
        "code": "67"
      },
      {
        "name": "Campania",
        "code": "72"
      },
      {
        "name": "Puglia",
        "code": "75"
      },
      {
        "name": "Basilicata",
        "code": "77"
      },
      {
        "name": "Calabria",
        "code": "78"
      },
      {
        "name": "Sicilia",
        "code": "82"
      },
      {
        "name": "Sardegna",
        "code": "88"
      },
      {
        "name": "Agrigento",
        "code": "AG"
      },
      {
        "name": "Alessandria",
        "code": "AL"
      },
      {
        "name": "Ancona",
        "code": "AN"
      },
      {
        "name": "Ascoli Piceno",
        "code": "AP"
      },
      {
        "name": "L'Aquila",
        "code": "AQ"
      },
      {
        "name": "Arezzo",
        "code": "AR"
      },
      {
        "name": "Asti",
        "code": "AT"
      },
      {
        "name": "Avellino",
        "code": "AV"
      },
      {
        "name": "Bari",
        "code": "BA"
      },
      {
        "name": "Bergamo",
        "code": "BG"
      },
      {
        "name": "Biella",
        "code": "BI"
      },
      {
        "name": "Belluno",
        "code": "BL"
      },
      {
        "name": "Benevento",
        "code": "BN"
      },
      {
        "name": "Bologna",
        "code": "BO"
      },
      {
        "name": "Brindisi",
        "code": "BR"
      },
      {
        "name": "Brescia",
        "code": "BS"
      },
      {
        "name": "Barletta-Andria-Trani",
        "code": "BT"
      },
      {
        "name": "Bolzano",
        "code": "BZ"
      },
      {
        "name": "Cagliari",
        "code": "CA"
      },
      {
        "name": "Campobasso",
        "code": "CB"
      },
      {
        "name": "Caserta",
        "code": "CE"
      },
      {
        "name": "Chieti",
        "code": "CH"
      },
      {
        "name": "Caltanissetta",
        "code": "CL"
      },
      {
        "name": "Cuneo",
        "code": "CN"
      },
      {
        "name": "Como",
        "code": "CO"
      },
      {
        "name": "Cremona",
        "code": "CR"
      },
      {
        "name": "Cosenza",
        "code": "CS"
      },
      {
        "name": "Catania",
        "code": "CT"
      },
      {
        "name": "Catanzaro",
        "code": "CZ"
      },
      {
        "name": "Enna",
        "code": "EN"
      },
      {
        "name": "Forlì-Cesena",
        "code": "FC"
      },
      {
        "name": "Ferrara",
        "code": "FE"
      },
      {
        "name": "Foggia",
        "code": "FG"
      },
      {
        "name": "Firenze",
        "code": "FI"
      },
      {
        "name": "Fermo",
        "code": "FM"
      },
      {
        "name": "Frosinone",
        "code": "FR"
      },
      {
        "name": "Genova",
        "code": "GE"
      },
      {
        "name": "Gorizia",
        "code": "GO"
      },
      {
        "name": "Grosseto",
        "code": "GR"
      },
      {
        "name": "Imperia",
        "code": "IM"
      },
      {
        "name": "Isernia",
        "code": "IS"
      },
      {
        "name": "Crotone",
        "code": "KR"
      },
      {
        "name": "Lecco",
        "code": "LC"
      },
      {
        "name": "Lecce",
        "code": "LE"
      },
      {
        "name": "Livorno",
        "code": "LI"
      },
      {
        "name": "Lodi",
        "code": "LO"
      },
      {
        "name": "Latina",
        "code": "LT"
      },
      {
        "name": "Lucca",
        "code": "LU"
      },
      {
        "name": "Monza e Brianza",
        "code": "MB"
      },
      {
        "name": "Macerata",
        "code": "MC"
      },
      {
        "name": "Messina",
        "code": "ME"
      },
      {
        "name": "Milano",
        "code": "MI"
      },
      {
        "name": "Mantova",
        "code": "MN"
      },
      {
        "name": "Modena",
        "code": "MO"
      },
      {
        "name": "Massa-Carrara",
        "code": "MS"
      },
      {
        "name": "Matera",
        "code": "MT"
      },
      {
        "name": "Napoli",
        "code": "NA"
      },
      {
        "name": "Novara",
        "code": "NO"
      },
      {
        "name": "Nuoro",
        "code": "NU"
      },
      {
        "name": "Oristano",
        "code": "OR"
      },
      {
        "name": "Palermo",
        "code": "PA"
      },
      {
        "name": "Piacenza",
        "code": "PC"
      },
      {
        "name": "Padova",
        "code": "PD"
      },
      {
        "name": "Pescara",
        "code": "PE"
      },
      {
        "name": "Perugia",
        "code": "PG"
      },
      {
        "name": "Pisa",
        "code": "PI"
      },
      {
        "name": "Pordenone",
        "code": "PN"
      },
      {
        "name": "Prato",
        "code": "PO"
      },
      {
        "name": "Parma",
        "code": "PR"
      },
      {
        "name": "Pistoia",
        "code": "PT"
      },
      {
        "name": "Pesaro e Urbino",
        "code": "PU"
      },
      {
        "name": "Pavia",
        "code": "PV"
      },
      {
        "name": "Potenza",
        "code": "PZ"
      },
      {
        "name": "Ravenna",
        "code": "RA"
      },
      {
        "name": "Reggio Calabria",
        "code": "RC"
      },
      {
        "name": "Reggio Emilia",
        "code": "RE"
      },
      {
        "name": "Ragusa",
        "code": "RG"
      },
      {
        "name": "Rieti",
        "code": "RI"
      },
      {
        "name": "Roma",
        "code": "RM"
      },
      {
        "name": "Rimini",
        "code": "RN"
      },
      {
        "name": "Rovigo",
        "code": "RO"
      },
      {
        "name": "Salerno",
        "code": "SA"
      },
      {
        "name": "Siena",
        "code": "SI"
      },
      {
        "name": "Sondrio",
        "code": "SO"
      },
      {
        "name": "La Spezia",
        "code": "SP"
      },
      {
        "name": "Siracusa",
        "code": "SR"
      },
      {
        "name": "Sassari",
        "code": "SS"
      },
      {
        "name": "Sud Sardegna",
        "code": "SU"
      },
      {
        "name": "Savona",
        "code": "SV"
      },
      {
        "name": "Taranto",
        "code": "TA"
      },
      {
        "name": "Teramo",
        "code": "TE"
      },
      {
        "name": "Trento",
        "code": "TN"
      },
      {
        "name": "Torino",
        "code": "TO"
      },
      {
        "name": "Trapani",
        "code": "TP"
      },
      {
        "name": "Terni",
        "code": "TR"
      },
      {
        "name": "Trieste",
        "code": "TS"
      },
      {
        "name": "Treviso",
        "code": "TV"
      },
      {
        "name": "Udine",
        "code": "UD"
      },
      {
        "name": "Varese",
        "code": "VA"
      },
      {
        "name": "Verbano-Cusio-Ossola",
        "code": "VB"
      },
      {
        "name": "Vercelli",
        "code": "VC"
      },
      {
        "name": "Venezia",
        "code": "VE"
      },
      {
        "name": "Vicenza",
        "code": "VI"
      },
      {
        "name": "Verona",
        "code": "VR"
      },
      {
        "name": "Viterbo",
        "code": "VT"
      },
      {
        "name": "Vibo Valentia",
        "code": "VV"
      }
    ]
  },
  {
    "name": "Jamaica",
    "alpha3": "JAM",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Kingston",
        "code": "01"
      },
      {
        "name": "Saint Andrew",
        "code": "02"
      },
      {
        "name": "Saint Thomas",
        "code": "03"
      },
      {
        "name": "Portland",
        "code": "04"
      },
      {
        "name": "Saint Mary",
        "code": "05"
      },
      {
        "name": "Saint Ann",
        "code": "06"
      },
      {
        "name": "Trelawny",
        "code": "07"
      },
      {
        "name": "Saint James",
        "code": "08"
      },
      {
        "name": "Hanover",
        "code": "09"
      },
      {
        "name": "Westmoreland",
        "code": "10"
      },
      {
        "name": "Saint Elizabeth",
        "code": "11"
      },
      {
        "name": "Manchester",
        "code": "12"
      },
      {
        "name": "Clarendon",
        "code": "13"
      },
      {
        "name": "Saint Catherine",
        "code": "14"
      }
    ]
  },
  {
    "name": "Japan",
    "alpha3": "JPN",
    "postal_code_format": "\\A\\d{3}-?\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Hokkaido",
        "code": "01"
      },
      {
        "name": "Aomori",
        "code": "02"
      },
      {
        "name": "Iwate",
        "code": "03"
      },
      {
        "name": "Miyagi",
        "code": "04"
      },
      {
        "name": "Akita",
        "code": "05"
      },
      {
        "name": "Yamagata",
        "code": "06"
      },
      {
        "name": "Fukushima",
        "code": "07"
      },
      {
        "name": "Ibaraki",
        "code": "08"
      },
      {
        "name": "Tochigi",
        "code": "09"
      },
      {
        "name": "Gunma",
        "code": "10"
      },
      {
        "name": "Saitama",
        "code": "11"
      },
      {
        "name": "Chiba",
        "code": "12"
      },
      {
        "name": "Tokyo",
        "code": "13"
      },
      {
        "name": "Kanagawa",
        "code": "14"
      },
      {
        "name": "Niigata",
        "code": "15"
      },
      {
        "name": "Toyama",
        "code": "16"
      },
      {
        "name": "Ishikawa",
        "code": "17"
      },
      {
        "name": "Fukui",
        "code": "18"
      },
      {
        "name": "Yamanashi",
        "code": "19"
      },
      {
        "name": "Nagano",
        "code": "20"
      },
      {
        "name": "Gifu",
        "code": "21"
      },
      {
        "name": "Shizuoka",
        "code": "22"
      },
      {
        "name": "Aichi",
        "code": "23"
      },
      {
        "name": "Mie",
        "code": "24"
      },
      {
        "name": "Shiga",
        "code": "25"
      },
      {
        "name": "Kyoto",
        "code": "26"
      },
      {
        "name": "Osaka",
        "code": "27"
      },
      {
        "name": "Hyogo",
        "code": "28"
      },
      {
        "name": "Nara",
        "code": "29"
      },
      {
        "name": "Wakayama",
        "code": "30"
      },
      {
        "name": "Tottori",
        "code": "31"
      },
      {
        "name": "Shimane",
        "code": "32"
      },
      {
        "name": "Okayama",
        "code": "33"
      },
      {
        "name": "Hiroshima",
        "code": "34"
      },
      {
        "name": "Yamaguchi",
        "code": "35"
      },
      {
        "name": "Tokushima",
        "code": "36"
      },
      {
        "name": "Kagawa",
        "code": "37"
      },
      {
        "name": "Ehime",
        "code": "38"
      },
      {
        "name": "Kochi",
        "code": "39"
      },
      {
        "name": "Fukuoka",
        "code": "40"
      },
      {
        "name": "Saga",
        "code": "41"
      },
      {
        "name": "Nagasaki",
        "code": "42"
      },
      {
        "name": "Kumamoto",
        "code": "43"
      },
      {
        "name": "Oita",
        "code": "44"
      },
      {
        "name": "Miyazaki",
        "code": "45"
      },
      {
        "name": "Kagoshima",
        "code": "46"
      },
      {
        "name": "Okinawa",
        "code": "47"
      }
    ]
  },
  {
    "name": "Jersey",
    "alpha3": "JEY",
    "postal_code_format": "\\AJE\\d[\\dA-Z]? ?\\d[ABD-HJLN-UW-Z]{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Jordan",
    "alpha3": "JOR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Ajlun",
        "code": "AJ"
      },
      {
        "name": "Amman",
        "code": "AM"
      },
      {
        "name": "Aqaba",
        "code": "AQ"
      },
      {
        "name": "At Tafilah",
        "code": "AT"
      },
      {
        "name": "Az Zarqa'",
        "code": "AZ"
      },
      {
        "name": "Al Balqa'",
        "code": "BA"
      },
      {
        "name": "Irbid",
        "code": "IR"
      },
      {
        "name": "Jarash",
        "code": "JA"
      },
      {
        "name": "Al Karak",
        "code": "KA"
      },
      {
        "name": "Al Mafraq",
        "code": "MA"
      },
      {
        "name": "Madaba",
        "code": "MD"
      },
      {
        "name": "Ma`an",
        "code": "MN"
      }
    ]
  },
  {
    "name": "Kazakhstan",
    "alpha3": "KAZ",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Aqmola oblysy",
        "code": "AKM"
      },
      {
        "name": "Aqtöbe oblysy",
        "code": "AKT"
      },
      {
        "name": "Almaty",
        "code": "ALA"
      },
      {
        "name": "Almaty oblysy",
        "code": "ALM"
      },
      {
        "name": "Astana",
        "code": "AST"
      },
      {
        "name": "Atyrau oblysy",
        "code": "ATY"
      },
      {
        "name": "Qaraghandy oblysy",
        "code": "KAR"
      },
      {
        "name": "Qostanay oblysy",
        "code": "KUS"
      },
      {
        "name": "Qyzylorda oblysy",
        "code": "KZY"
      },
      {
        "name": "Mangghystau oblysy",
        "code": "MAN"
      },
      {
        "name": "Pavlodar oblysy",
        "code": "PAV"
      },
      {
        "name": "Soltüstik Qazaqstan oblysy",
        "code": "SEV"
      },
      {
        "name": "Shymkent",
        "code": "SHY"
      },
      {
        "name": "Shyghys Qazaqstan oblysy",
        "code": "VOS"
      },
      {
        "name": "Ongtüstik Qazaqstan oblysy",
        "code": "YUZ"
      },
      {
        "name": "Batys Qazaqstan oblysy",
        "code": "ZAP"
      },
      {
        "name": "Zhambyl oblysy",
        "code": "ZHA"
      }
    ]
  },
  {
    "name": "Kenya",
    "alpha3": "KEN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Baringo",
        "code": "01"
      },
      {
        "name": "Bomet",
        "code": "02"
      },
      {
        "name": "Bungoma",
        "code": "03"
      },
      {
        "name": "Busia",
        "code": "04"
      },
      {
        "name": "Elgeyo/Marakwet",
        "code": "05"
      },
      {
        "name": "Embu",
        "code": "06"
      },
      {
        "name": "Garissa",
        "code": "07"
      },
      {
        "name": "Homa Bay",
        "code": "08"
      },
      {
        "name": "Isiolo",
        "code": "09"
      },
      {
        "name": "Kajiado",
        "code": "10"
      },
      {
        "name": "Kakamega",
        "code": "11"
      },
      {
        "name": "Kericho",
        "code": "12"
      },
      {
        "name": "Kiambu",
        "code": "13"
      },
      {
        "name": "Kilifi",
        "code": "14"
      },
      {
        "name": "Kirinyaga",
        "code": "15"
      },
      {
        "name": "Kisii",
        "code": "16"
      },
      {
        "name": "Kisumu",
        "code": "17"
      },
      {
        "name": "Kitui",
        "code": "18"
      },
      {
        "name": "Kwale",
        "code": "19"
      },
      {
        "name": "Laikipia",
        "code": "20"
      },
      {
        "name": "Lamu",
        "code": "21"
      },
      {
        "name": "Machakos",
        "code": "22"
      },
      {
        "name": "Makueni",
        "code": "23"
      },
      {
        "name": "Mandera",
        "code": "24"
      },
      {
        "name": "Marsabit",
        "code": "25"
      },
      {
        "name": "Meru",
        "code": "26"
      },
      {
        "name": "Migori",
        "code": "27"
      },
      {
        "name": "Mombasa",
        "code": "28"
      },
      {
        "name": "Murang’a",
        "code": "29"
      },
      {
        "name": "Nairobi City",
        "code": "30"
      },
      {
        "name": "Nakuru",
        "code": "31"
      },
      {
        "name": "Nandi",
        "code": "32"
      },
      {
        "name": "Narok",
        "code": "33"
      },
      {
        "name": "Nyamira",
        "code": "34"
      },
      {
        "name": "Nyandarua",
        "code": "35"
      },
      {
        "name": "Nyeri",
        "code": "36"
      },
      {
        "name": "Samburu",
        "code": "37"
      },
      {
        "name": "Siaya",
        "code": "38"
      },
      {
        "name": "Taita-Taveta",
        "code": "39"
      },
      {
        "name": "Tana River",
        "code": "40"
      },
      {
        "name": "Tharaka-Nithi",
        "code": "41"
      },
      {
        "name": "Trans-Nzoia",
        "code": "42"
      },
      {
        "name": "Turkana",
        "code": "43"
      },
      {
        "name": "Uasin Gishu",
        "code": "44"
      },
      {
        "name": "Vihiga",
        "code": "45"
      },
      {
        "name": "Wajir",
        "code": "46"
      },
      {
        "name": "West Pokot",
        "code": "47"
      }
    ]
  },
  {
    "name": "Kiribati",
    "alpha3": "KIR",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Gilbert Islands",
        "code": "G"
      },
      {
        "name": "Line Islands",
        "code": "L"
      },
      {
        "name": "Phoenix Islands",
        "code": "P"
      }
    ]
  },
  {
    "name": "Korea (Democratic People's Republic of)",
    "alpha3": "PRK",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "평양직할시",
        "code": "01"
      },
      {
        "name": "평안남도",
        "code": "02"
      },
      {
        "name": "평안북도",
        "code": "03"
      },
      {
        "name": "자강도",
        "code": "04"
      },
      {
        "name": "황해남도",
        "code": "05"
      },
      {
        "name": "황해북도",
        "code": "06"
      },
      {
        "name": "강원도",
        "code": "07"
      },
      {
        "name": "함경남도",
        "code": "08"
      },
      {
        "name": "함경북도",
        "code": "09"
      },
      {
        "name": "량강도",
        "code": "10"
      },
      {
        "name": "라선특별시",
        "code": "13"
      },
      {
        "name": "남포특별시",
        "code": "14"
      }
    ]
  },
  {
    "name": "Korea (Republic of)",
    "alpha3": "KOR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Seoul Teugbyeolsi [Seoul-T'ukpyolshi]",
        "code": "11"
      },
      {
        "name": "Busan Gwang'yeogsi [Pusan-Kwangyokshi]",
        "code": "26"
      },
      {
        "name": "Daegu Gwang'yeogsi [Taegu-Kwangyokshi]",
        "code": "27"
      },
      {
        "name": "Incheon Gwang'yeogsi [Inch'n-Kwangyokshi]",
        "code": "28"
      },
      {
        "name": "Gwangju Gwang'yeogsi [Kwangju-Kwangyokshi]",
        "code": "29"
      },
      {
        "name": "Daejeon Gwang'yeogsi [Taejon-Kwangyokshi]",
        "code": "30"
      },
      {
        "name": "Ulsan Gwang'yeogsi [Ulsan-Kwangyokshi]",
        "code": "31"
      },
      {
        "name": "Gyeonggido [Kyonggi-do]",
        "code": "41"
      },
      {
        "name": "Gang'weondo [Kang-won-do]",
        "code": "42"
      },
      {
        "name": "Chungcheongbugdo [Ch'ungch'ongbuk-do]",
        "code": "43"
      },
      {
        "name": "Chungcheongnamdo [Ch'ungch'ongnam-do]",
        "code": "44"
      },
      {
        "name": "Jeonrabugdo[Chollabuk-do]",
        "code": "45"
      },
      {
        "name": "Jeonranamdo [Chollanam-do]",
        "code": "46"
      },
      {
        "name": "Gyeongsangbugdo [Kyongsangbuk-do]",
        "code": "47"
      },
      {
        "name": "Gyeongsangnamdo [Kyongsangnam-do]",
        "code": "48"
      },
      {
        "name": "Jejudo [Cheju-do]",
        "code": "49"
      },
      {
        "name": "세종특별자치시",
        "code": "50"
      }
    ]
  },
  {
    "name": "Kuwait",
    "alpha3": "KWT",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Al Ahmadi",
        "code": "AH"
      },
      {
        "name": "Al Farwaniyah",
        "code": "FA"
      },
      {
        "name": "Hawalli",
        "code": "HA"
      },
      {
        "name": "Al Jahrah",
        "code": "JA"
      },
      {
        "name": "Al Kuwayt",
        "code": "KU"
      },
      {
        "name": "Mubarak al-Kabir",
        "code": "MU"
      }
    ]
  },
  {
    "name": "Kyrgyzstan",
    "alpha3": "KGZ",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Batken",
        "code": "B"
      },
      {
        "name": "Chü",
        "code": "C"
      },
      {
        "name": "Bishkek",
        "code": "GB"
      },
      {
        "name": "Ош",
        "code": "GO"
      },
      {
        "name": "Jalal-Abad",
        "code": "J"
      },
      {
        "name": "Naryn",
        "code": "N"
      },
      {
        "name": "Osh",
        "code": "O"
      },
      {
        "name": "Talas",
        "code": "T"
      },
      {
        "name": "Ysyk-Köl",
        "code": "Y"
      }
    ]
  },
  {
    "name": "Lao People's Democratic Republic",
    "alpha3": "LAO",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Attapu [Attopeu]",
        "code": "AT"
      },
      {
        "name": "Bokèo",
        "code": "BK"
      },
      {
        "name": "Bolikhamxai [Borikhane]",
        "code": "BL"
      },
      {
        "name": "Champasak [Champassak]",
        "code": "CH"
      },
      {
        "name": "Houaphan",
        "code": "HO"
      },
      {
        "name": "Khammouan",
        "code": "KH"
      },
      {
        "name": "Louang Namtha",
        "code": "LM"
      },
      {
        "name": "Louangphabang [Louang Prabang]",
        "code": "LP"
      },
      {
        "name": "Oudômxai [Oudomsai]",
        "code": "OU"
      },
      {
        "name": "Phôngsali [Phong Saly]",
        "code": "PH"
      },
      {
        "name": "Salavan [Saravane]",
        "code": "SL"
      },
      {
        "name": "Savannakhét",
        "code": "SV"
      },
      {
        "name": "Vientiane",
        "code": "VI"
      },
      {
        "name": "Vientiane Prefecture",
        "code": "VT"
      },
      {
        "name": "Xaignabouli [Sayaboury]",
        "code": "XA"
      },
      {
        "name": "Xékong [Sékong]",
        "code": "XE"
      },
      {
        "name": "Xiangkhoang [Xieng Khouang]",
        "code": "XI"
      },
      {
        "name": "ແຂວງໄຊສົມບູນ",
        "code": "XS"
      }
    ]
  },
  {
    "name": "Latvia",
    "alpha3": "LVA",
    "postal_code_format": "\\A(LV(-| )?)?\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Aizkraukles novads",
        "code": "002"
      },
      {
        "name": "Alūksnes novads",
        "code": "007"
      },
      {
        "name": "Ādažu novads",
        "code": "011"
      },
      {
        "name": "Balvu novads",
        "code": "015"
      },
      {
        "name": "Bauskas novads",
        "code": "016"
      },
      {
        "name": "Cēsu novads",
        "code": "022"
      },
      {
        "name": "Dobeles novads",
        "code": "026"
      },
      {
        "name": "Gulbenes novads",
        "code": "033"
      },
      {
        "name": "Jelgavas novads",
        "code": "041"
      },
      {
        "name": "Jēkabpils novads",
        "code": "042"
      },
      {
        "name": "Krāslavas novads",
        "code": "047"
      },
      {
        "name": "Kuldīgas novads",
        "code": "050"
      },
      {
        "name": "Ķekavas novads",
        "code": "052"
      },
      {
        "name": "Limbažu novads",
        "code": "054"
      },
      {
        "name": "Līvānu novads",
        "code": "056"
      },
      {
        "name": "Ludzas novads",
        "code": "058"
      },
      {
        "name": "Madonas novads",
        "code": "059"
      },
      {
        "name": "Mārupes novads",
        "code": "062"
      },
      {
        "name": "Ogres novads",
        "code": "067"
      },
      {
        "name": "Olaines novads",
        "code": "068"
      },
      {
        "name": "Preiļu novads",
        "code": "073"
      },
      {
        "name": "Rēzeknes novads",
        "code": "077"
      },
      {
        "name": "Ropažu novads",
        "code": "080"
      },
      {
        "name": "Salaspils novads",
        "code": "087"
      },
      {
        "name": "Saldus novads",
        "code": "088"
      },
      {
        "name": "Saulkrastu novads",
        "code": "089"
      },
      {
        "name": "Siguldas novads",
        "code": "091"
      },
      {
        "name": "Smiltenes novads",
        "code": "094"
      },
      {
        "name": "Talsu novads",
        "code": "097"
      },
      {
        "name": "Tukuma novads",
        "code": "099"
      },
      {
        "name": "Valkas novads",
        "code": "101"
      },
      {
        "name": "Varakļānu novads",
        "code": "102"
      },
      {
        "name": "Ventspils novads",
        "code": "106"
      },
      {
        "name": "Augšdaugavas novads",
        "code": "111"
      },
      {
        "name": "Dienvidkurzemes Novads",
        "code": "112"
      },
      {
        "name": "Valmieras Novads",
        "code": "113"
      },
      {
        "name": "Daugavpils",
        "code": "DGV"
      },
      {
        "name": "Jelgava",
        "code": "JEL"
      },
      {
        "name": "Jurmala",
        "code": "JUR"
      },
      {
        "name": "Liepaja",
        "code": "LPX"
      },
      {
        "name": "Rezekne",
        "code": "REZ"
      },
      {
        "name": "Riga",
        "code": "RIX"
      },
      {
        "name": "Ventspils",
        "code": "VEN"
      }
    ]
  },
  {
    "name": "Lebanon",
    "alpha3": "LBN",
    "postal_code_format": "\\A(?:\\d{4})(?: ?(?:\\d{4}))?\\Z",
    "subdivisions": [
      {
        "name": "محافظة عكار",
        "code": "AK"
      },
      {
        "name": "Loubnâne ech Chemâli",
        "code": "AS"
      },
      {
        "name": "Beirut",
        "code": "BA"
      },
      {
        "name": "محافظة بعلبك الهرمل",
        "code": "BH"
      },
      {
        "name": "El Béqaa",
        "code": "BI"
      },
      {
        "name": "Loubnâne ej Jnoûbi",
        "code": "JA"
      },
      {
        "name": "Jabal Loubnâne",
        "code": "JL"
      },
      {
        "name": "Nabatîyé",
        "code": "NA"
      }
    ]
  },
  {
    "name": "Lesotho",
    "alpha3": "LSO",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Maseru",
        "code": "A"
      },
      {
        "name": "Butha-Buthe",
        "code": "B"
      },
      {
        "name": "Leribe",
        "code": "C"
      },
      {
        "name": "Berea",
        "code": "D"
      },
      {
        "name": "Mafeteng",
        "code": "E"
      },
      {
        "name": "Mohale's Hoek",
        "code": "F"
      },
      {
        "name": "Quthing",
        "code": "G"
      },
      {
        "name": "Qacha's Nek",
        "code": "H"
      },
      {
        "name": "Mokhotlong",
        "code": "J"
      },
      {
        "name": "Thaba-Tseka",
        "code": "K"
      }
    ]
  },
  {
    "name": "Liberia",
    "alpha3": "LBR",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Bong",
        "code": "BG"
      },
      {
        "name": "Bomi",
        "code": "BM"
      },
      {
        "name": "Grand Cape Mount",
        "code": "CM"
      },
      {
        "name": "Grand Bassa",
        "code": "GB"
      },
      {
        "name": "Grand Gedeh",
        "code": "GG"
      },
      {
        "name": "Grand Kru",
        "code": "GK"
      },
      {
        "name": "Gbarpolu",
        "code": "GP"
      },
      {
        "name": "Lofa",
        "code": "LO"
      },
      {
        "name": "Margibi",
        "code": "MG"
      },
      {
        "name": "Montserrado",
        "code": "MO"
      },
      {
        "name": "Maryland",
        "code": "MY"
      },
      {
        "name": "Nimba",
        "code": "NI"
      },
      {
        "name": "River Gee",
        "code": "RG"
      },
      {
        "name": "Rivercess",
        "code": "RI"
      },
      {
        "name": "Sinoe",
        "code": "SI"
      },
      {
        "name": "Gbarpolu",
        "code": "X1~"
      },
      {
        "name": "River Gee",
        "code": "X2~"
      }
    ]
  },
  {
    "name": "Libya",
    "alpha3": "LBY",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Banghazi",
        "code": "BA"
      },
      {
        "name": "Al Butnan",
        "code": "BU"
      },
      {
        "name": "Darnah",
        "code": "DR"
      },
      {
        "name": "Ghat",
        "code": "GT"
      },
      {
        "name": "Al Jabal al Akhḑar",
        "code": "JA"
      },
      {
        "name": "Al Jabal al Gharbī",
        "code": "JG"
      },
      {
        "name": "Al Jifarah",
        "code": "JI"
      },
      {
        "name": "Al Jufrah",
        "code": "JU"
      },
      {
        "name": "Al Kufrah",
        "code": "KF"
      },
      {
        "name": "Al Murqub",
        "code": "MB"
      },
      {
        "name": "Mişrātah",
        "code": "MI"
      },
      {
        "name": "Al Marj",
        "code": "MJ"
      },
      {
        "name": "Murzuq",
        "code": "MQ"
      },
      {
        "name": "Nālūt",
        "code": "NL"
      },
      {
        "name": "An Nuqaţ al Khams",
        "code": "NQ"
      },
      {
        "name": "Sabhā",
        "code": "SB"
      },
      {
        "name": "Sirte",
        "code": "SR"
      },
      {
        "name": "Tarabulus",
        "code": "TB"
      },
      {
        "name": "Al Wahat",
        "code": "WA"
      },
      {
        "name": "Wādī al Ḩayāt",
        "code": "WD"
      },
      {
        "name": "Wādī ash Shāţiʾ",
        "code": "WS"
      },
      {
        "name": "Az Zawiyah",
        "code": "ZA"
      }
    ]
  },
  {
    "name": "Liechtenstein",
    "alpha3": "LIE",
    "postal_code_format": "\\A948[5-9]|949[0-8]\\Z",
    "subdivisions": [
      {
        "name": "Balzers",
        "code": "01"
      },
      {
        "name": "Eschen",
        "code": "02"
      },
      {
        "name": "Gamprin",
        "code": "03"
      },
      {
        "name": "Mauren",
        "code": "04"
      },
      {
        "name": "Planken",
        "code": "05"
      },
      {
        "name": "Ruggell",
        "code": "06"
      },
      {
        "name": "Schaan",
        "code": "07"
      },
      {
        "name": "Schellenberg",
        "code": "08"
      },
      {
        "name": "Triesen",
        "code": "09"
      },
      {
        "name": "Triesenberg",
        "code": "10"
      },
      {
        "name": "Vaduz",
        "code": "11"
      }
    ]
  },
  {
    "name": "Lithuania",
    "alpha3": "LTU",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Akmenės rajono savivaldybė",
        "code": "01"
      },
      {
        "name": "Alytaus miesto savivaldybė",
        "code": "02"
      },
      {
        "name": "Alytaus rajono savivaldybė",
        "code": "03"
      },
      {
        "name": "Anykščių rajono savivaldybė",
        "code": "04"
      },
      {
        "name": "Birštono savivaldybė",
        "code": "05"
      },
      {
        "name": "Biržų rajono savivaldybė",
        "code": "06"
      },
      {
        "name": "Druskininkų savivaldybė",
        "code": "07"
      },
      {
        "name": "Elektrėnų savivaldybė",
        "code": "08"
      },
      {
        "name": "Ignalinos rajono savivaldybė",
        "code": "09"
      },
      {
        "name": "Jonavos rajono savivaldybė",
        "code": "10"
      },
      {
        "name": "Joniškio rajono savivaldybė",
        "code": "11"
      },
      {
        "name": "Jurbarko rajono savivaldybė",
        "code": "12"
      },
      {
        "name": "Kaišiadorių rajono savivaldybė",
        "code": "13"
      },
      {
        "name": "Kalvarijos savivaldybė",
        "code": "14"
      },
      {
        "name": "Kauno miesto savivaldybė",
        "code": "15"
      },
      {
        "name": "Kauno rajono savivaldybė",
        "code": "16"
      },
      {
        "name": "Kazlų Rūdos savivaldybė",
        "code": "17"
      },
      {
        "name": "Kėdainių rajono savivaldybė",
        "code": "18"
      },
      {
        "name": "Kelmės rajono savivaldybė",
        "code": "19"
      },
      {
        "name": "Klaipėdos miesto savivaldybė",
        "code": "20"
      },
      {
        "name": "Klaipėdos rajono savivaldybė",
        "code": "21"
      },
      {
        "name": "Kretingos rajono savivaldybė",
        "code": "22"
      },
      {
        "name": "Kupiškio rajono savivaldybė",
        "code": "23"
      },
      {
        "name": "Lazdijų rajono savivaldybė",
        "code": "24"
      },
      {
        "name": "Marijampolės savivaldybė",
        "code": "25"
      },
      {
        "name": "Mažeikių rajono savivaldybė",
        "code": "26"
      },
      {
        "name": "Molėtų rajono savivaldybė",
        "code": "27"
      },
      {
        "name": "Neringos savivaldybė",
        "code": "28"
      },
      {
        "name": "Pagėgių savivaldybė",
        "code": "29"
      },
      {
        "name": "Pakruojo rajono savivaldybė",
        "code": "30"
      },
      {
        "name": "Palangos miesto savivaldybė",
        "code": "31"
      },
      {
        "name": "Panevėžio miesto savivaldybė",
        "code": "32"
      },
      {
        "name": "Panevėžio rajono savivaldybė",
        "code": "33"
      },
      {
        "name": "Pasvalio rajono savivaldybė",
        "code": "34"
      },
      {
        "name": "Plungės rajono savivaldybė",
        "code": "35"
      },
      {
        "name": "Prienų rajono savivaldybė",
        "code": "36"
      },
      {
        "name": "Radviliškio rajono savivaldybė",
        "code": "37"
      },
      {
        "name": "Raseinių rajono savivaldybė",
        "code": "38"
      },
      {
        "name": "Rietavo savivaldybė",
        "code": "39"
      },
      {
        "name": "Rokiškio rajono savivaldybė",
        "code": "40"
      },
      {
        "name": "Šakių rajono savivaldybė",
        "code": "41"
      },
      {
        "name": "Šalčininkų rajono savivaldybė",
        "code": "42"
      },
      {
        "name": "Šiaulių miesto savivaldybė",
        "code": "43"
      },
      {
        "name": "Šiaulių rajono savivaldybė",
        "code": "44"
      },
      {
        "name": "Šilalės rajono savivaldybė",
        "code": "45"
      },
      {
        "name": "Šilutės rajono savivaldybė",
        "code": "46"
      },
      {
        "name": "Širvintų rajono savivaldybė",
        "code": "47"
      },
      {
        "name": "Skuodo rajono savivaldybė",
        "code": "48"
      },
      {
        "name": "Švenčionių rajono savivaldybė",
        "code": "49"
      },
      {
        "name": "Tauragės rajono savivaldybė",
        "code": "50"
      },
      {
        "name": "Telšių rajono savivaldybė",
        "code": "51"
      },
      {
        "name": "Trakų rajono savivaldybė",
        "code": "52"
      },
      {
        "name": "Ukmergės rajono savivaldybė",
        "code": "53"
      },
      {
        "name": "Utenos rajono savivaldybė",
        "code": "54"
      },
      {
        "name": "Varėnos rajono savivaldybė",
        "code": "55"
      },
      {
        "name": "Vilkaviškio rajono savivaldybė",
        "code": "56"
      },
      {
        "name": "Vilniaus miesto savivaldybė",
        "code": "57"
      },
      {
        "name": "Vilniaus rajono savivaldybė",
        "code": "58"
      },
      {
        "name": "Visagino savivaldybė",
        "code": "59"
      },
      {
        "name": "Zarasų rajono savivaldybė",
        "code": "60"
      },
      {
        "name": "Alytaus Apskritis",
        "code": "AL"
      },
      {
        "name": "Klaipedos Apskritis",
        "code": "KL"
      },
      {
        "name": "Kauno Apskritis",
        "code": "KU"
      },
      {
        "name": "Marijampoles Apskritis",
        "code": "MR"
      },
      {
        "name": "Panevežio Apskritis",
        "code": "PN"
      },
      {
        "name": "Šiauliu Apskritis",
        "code": "SA"
      },
      {
        "name": "Taurages Apskritis",
        "code": "TA"
      },
      {
        "name": "Telšiu Apskritis",
        "code": "TE"
      },
      {
        "name": "Utenos Apskritis",
        "code": "UT"
      },
      {
        "name": "Vilniaus Apskritis",
        "code": "VL"
      }
    ]
  },
  {
    "name": "Luxembourg",
    "alpha3": "LUX",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Kapellen",
        "code": "CA"
      },
      {
        "name": "Klierf",
        "code": "CL"
      },
      {
        "name": "Diekrech",
        "code": "DI"
      },
      {
        "name": "Iechternach",
        "code": "EC"
      },
      {
        "name": "Esch-Uelzecht",
        "code": "ES"
      },
      {
        "name": "Gréivemaacher",
        "code": "GR"
      },
      {
        "name": "Lëtzebuerg",
        "code": "LU"
      },
      {
        "name": "Miersch",
        "code": "ME"
      },
      {
        "name": "Réiden-Atert",
        "code": "RD"
      },
      {
        "name": "Réimech",
        "code": "RM"
      },
      {
        "name": "Veianen",
        "code": "VD"
      },
      {
        "name": "Wolz",
        "code": "WI"
      }
    ]
  },
  {
    "name": "Macao",
    "alpha3": "MAC",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Madagascar",
    "alpha3": "MDG",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Toamasina",
        "code": "A"
      },
      {
        "name": "Antsiranana",
        "code": "D"
      },
      {
        "name": "Fianarantsoa",
        "code": "F"
      },
      {
        "name": "Mahajanga",
        "code": "M"
      },
      {
        "name": "Antananarivo",
        "code": "T"
      },
      {
        "name": "Toliara",
        "code": "U"
      }
    ]
  },
  {
    "name": "Malawi",
    "alpha3": "MWI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Balaka",
        "code": "BA"
      },
      {
        "name": "Blantyre",
        "code": "BL"
      },
      {
        "name": "Central",
        "code": "C"
      },
      {
        "name": "Chikwawa",
        "code": "CK"
      },
      {
        "name": "Chiradzulu",
        "code": "CR"
      },
      {
        "name": "Chitipa",
        "code": "CT"
      },
      {
        "name": "Dedza",
        "code": "DE"
      },
      {
        "name": "Dowa",
        "code": "DO"
      },
      {
        "name": "Karonga",
        "code": "KR"
      },
      {
        "name": "Kasungu",
        "code": "KS"
      },
      {
        "name": "Lilongwe",
        "code": "LI"
      },
      {
        "name": "Likoma Island",
        "code": "LK"
      },
      {
        "name": "Mchinji",
        "code": "MC"
      },
      {
        "name": "Mangochi",
        "code": "MG"
      },
      {
        "name": "Machinga",
        "code": "MH"
      },
      {
        "name": "Mulanje",
        "code": "MU"
      },
      {
        "name": "Mwanza",
        "code": "MW"
      },
      {
        "name": "Mzimba",
        "code": "MZ"
      },
      {
        "name": "Northern",
        "code": "N"
      },
      {
        "name": "Nkhata Bay",
        "code": "NB"
      },
      {
        "name": "Neno",
        "code": "NE"
      },
      {
        "name": "Ntchisi",
        "code": "NI"
      },
      {
        "name": "Nkhotakota",
        "code": "NK"
      },
      {
        "name": "Nsanje",
        "code": "NS"
      },
      {
        "name": "Ntcheu",
        "code": "NU"
      },
      {
        "name": "Phalombe",
        "code": "PH"
      },
      {
        "name": "Rumphi",
        "code": "RU"
      },
      {
        "name": "Southern",
        "code": "S"
      },
      {
        "name": "Salima",
        "code": "SA"
      },
      {
        "name": "Thyolo",
        "code": "TH"
      },
      {
        "name": "Zomba",
        "code": "ZO"
      }
    ]
  },
  {
    "name": "Malaysia",
    "alpha3": "MYS",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Johor",
        "code": "01"
      },
      {
        "name": "Kedah",
        "code": "02"
      },
      {
        "name": "Kelantan",
        "code": "03"
      },
      {
        "name": "Melaka",
        "code": "04"
      },
      {
        "name": "Negeri Sembilan",
        "code": "05"
      },
      {
        "name": "Pahang",
        "code": "06"
      },
      {
        "name": "Pulau Pinang",
        "code": "07"
      },
      {
        "name": "Perak",
        "code": "08"
      },
      {
        "name": "Perlis",
        "code": "09"
      },
      {
        "name": "Selangor",
        "code": "10"
      },
      {
        "name": "Terengganu",
        "code": "11"
      },
      {
        "name": "Sabah",
        "code": "12"
      },
      {
        "name": "Sarawak",
        "code": "13"
      },
      {
        "name": "Wilayah Persekutuan Kuala Lumpur",
        "code": "14"
      },
      {
        "name": "Wilayah Persekutuan Labuan",
        "code": "15"
      },
      {
        "name": "Wilayah Persekutuan Putrajaya",
        "code": "16"
      }
    ]
  },
  {
    "name": "Maldives",
    "alpha3": "MDV",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Ariatholhu Dhekunuburi",
        "code": "00"
      },
      {
        "name": "Addu",
        "code": "01"
      },
      {
        "name": "Ariatholhu Uthuruburi",
        "code": "02"
      },
      {
        "name": "Faadhippolhu",
        "code": "03"
      },
      {
        "name": "Felidheatholhu",
        "code": "04"
      },
      {
        "name": "Hahdhunmathi",
        "code": "05"
      },
      {
        "name": "Thiladhunmathee Uthuruburi",
        "code": "07"
      },
      {
        "name": "Kolhumadulu",
        "code": "08"
      },
      {
        "name": "Mulakatholhu",
        "code": "12"
      },
      {
        "name": "Maalhosmadulu Uthuruburi",
        "code": "13"
      },
      {
        "name": "Nilandheatholhu Uthuruburi",
        "code": "14"
      },
      {
        "name": "Nilandheatholhu Dhekunuburi",
        "code": "17"
      },
      {
        "name": "Maalhosmadulu Dhekunuburi",
        "code": "20"
      },
      {
        "name": "Thiladhunmathee Dhekunuburi",
        "code": "23"
      },
      {
        "name": "Miladhunmadulu Uthuruburi",
        "code": "24"
      },
      {
        "name": "Miladhunmadulu Dhekunuburi",
        "code": "25"
      },
      {
        "name": "Maaleatholhu",
        "code": "26"
      },
      {
        "name": "Huvadhuatholhu Uthuruburi",
        "code": "27"
      },
      {
        "name": "Huvadhuatholhu Dhekunuburi",
        "code": "28"
      },
      {
        "name": "Fuvammulah",
        "code": "29"
      },
      {
        "name": "Male",
        "code": "MLE"
      }
    ]
  },
  {
    "name": "Mali",
    "alpha3": "MLI",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Kayes",
        "code": "1"
      },
      {
        "name": "Région de Taoudénit",
        "code": "10"
      },
      {
        "name": "Koulikoro",
        "code": "2"
      },
      {
        "name": "Sikasso",
        "code": "3"
      },
      {
        "name": "Ségou",
        "code": "4"
      },
      {
        "name": "Mopti",
        "code": "5"
      },
      {
        "name": "Tombouctou",
        "code": "6"
      },
      {
        "name": "Gao",
        "code": "7"
      },
      {
        "name": "Kidal",
        "code": "8"
      },
      {
        "name": "Région de Ménaka",
        "code": "9"
      },
      {
        "name": "Bamako",
        "code": "BKO"
      }
    ]
  },
  {
    "name": "Malta",
    "alpha3": "MLT",
    "postal_code_format": "\\A[A-Z]{3} ?\\d{2,4}\\Z",
    "subdivisions": [
      {
        "name": "Attard",
        "code": "01"
      },
      {
        "name": "Balzan",
        "code": "02"
      },
      {
        "name": "Birgu",
        "code": "03"
      },
      {
        "name": "Birkirkara",
        "code": "04"
      },
      {
        "name": "Birżebbuġa",
        "code": "05"
      },
      {
        "name": "Bormla",
        "code": "06"
      },
      {
        "name": "Dingli",
        "code": "07"
      },
      {
        "name": "Fgura",
        "code": "08"
      },
      {
        "name": "Floriana",
        "code": "09"
      },
      {
        "name": "Fontana",
        "code": "10"
      },
      {
        "name": "Gudja",
        "code": "11"
      },
      {
        "name": "Gżira",
        "code": "12"
      },
      {
        "name": "Għajnsielem",
        "code": "13"
      },
      {
        "name": "Għarb",
        "code": "14"
      },
      {
        "name": "Għargħur",
        "code": "15"
      },
      {
        "name": "Għasri",
        "code": "16"
      },
      {
        "name": "Għaxaq",
        "code": "17"
      },
      {
        "name": "Ħamrun",
        "code": "18"
      },
      {
        "name": "Iklin",
        "code": "19"
      },
      {
        "name": "Isla",
        "code": "20"
      },
      {
        "name": "Kalkara",
        "code": "21"
      },
      {
        "name": "Kerċem",
        "code": "22"
      },
      {
        "name": "Kirkop",
        "code": "23"
      },
      {
        "name": "Lija",
        "code": "24"
      },
      {
        "name": "Luqa",
        "code": "25"
      },
      {
        "name": "Marsa",
        "code": "26"
      },
      {
        "name": "Marsaskala",
        "code": "27"
      },
      {
        "name": "Marsaxlokk",
        "code": "28"
      },
      {
        "name": "Mdina",
        "code": "29"
      },
      {
        "name": "Mellieħa",
        "code": "30"
      },
      {
        "name": "Mġarr",
        "code": "31"
      },
      {
        "name": "Mosta",
        "code": "32"
      },
      {
        "name": "Mqabba",
        "code": "33"
      },
      {
        "name": "Msida",
        "code": "34"
      },
      {
        "name": "Mtarfa",
        "code": "35"
      },
      {
        "name": "Munxar",
        "code": "36"
      },
      {
        "name": "Nadur",
        "code": "37"
      },
      {
        "name": "Naxxar",
        "code": "38"
      },
      {
        "name": "Paola",
        "code": "39"
      },
      {
        "name": "Pembroke",
        "code": "40"
      },
      {
        "name": "Pietà",
        "code": "41"
      },
      {
        "name": "Qala",
        "code": "42"
      },
      {
        "name": "Qormi",
        "code": "43"
      },
      {
        "name": "Qrendi",
        "code": "44"
      },
      {
        "name": "Rabat Gozo",
        "code": "45"
      },
      {
        "name": "Rabat Malta",
        "code": "46"
      },
      {
        "name": "Safi",
        "code": "47"
      },
      {
        "name": "Saint Julian's",
        "code": "48"
      },
      {
        "name": "Saint John",
        "code": "49"
      },
      {
        "name": "Saint Lawrence",
        "code": "50"
      },
      {
        "name": "Saint Paul's Bay",
        "code": "51"
      },
      {
        "name": "Sannat",
        "code": "52"
      },
      {
        "name": "Saint Lucia's",
        "code": "53"
      },
      {
        "name": "Santa Venera",
        "code": "54"
      },
      {
        "name": "Siġġiewi",
        "code": "55"
      },
      {
        "name": "Sliema",
        "code": "56"
      },
      {
        "name": "Swieqi",
        "code": "57"
      },
      {
        "name": "Ta' Xbiex",
        "code": "58"
      },
      {
        "name": "Tarxien",
        "code": "59"
      },
      {
        "name": "Valletta",
        "code": "60"
      },
      {
        "name": "Xagħra",
        "code": "61"
      },
      {
        "name": "Xewkija",
        "code": "62"
      },
      {
        "name": "Xgħajra",
        "code": "63"
      },
      {
        "name": "Żabbar",
        "code": "64"
      },
      {
        "name": "Żebbuġ Gozo",
        "code": "65"
      },
      {
        "name": "Żebbuġ Malta",
        "code": "66"
      },
      {
        "name": "Żejtun",
        "code": "67"
      },
      {
        "name": "Żurrieq",
        "code": "68"
      }
    ]
  },
  {
    "name": "Marshall Islands",
    "alpha3": "MHL",
    "postal_code_format": "\\A(969[67]\\d)(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": [
      {
        "name": "Ailuk",
        "code": "ALK"
      },
      {
        "name": "Ailinglapalap",
        "code": "ALL"
      },
      {
        "name": "Arno",
        "code": "ARN"
      },
      {
        "name": "Aur",
        "code": "AUR"
      },
      {
        "name": "Ebon",
        "code": "EBO"
      },
      {
        "name": "Eniwetok",
        "code": "ENI"
      },
      {
        "name": "Jabat",
        "code": "JAB"
      },
      {
        "name": "Jaluit",
        "code": "JAL"
      },
      {
        "name": "Kili",
        "code": "KIL"
      },
      {
        "name": "Kwajalein",
        "code": "KWA"
      },
      {
        "name": "Ralik Chain",
        "code": "L"
      },
      {
        "name": "Lae",
        "code": "LAE"
      },
      {
        "name": "Lib",
        "code": "LIB"
      },
      {
        "name": "Likiep",
        "code": "LIK"
      },
      {
        "name": "Majuro",
        "code": "MAJ"
      },
      {
        "name": "Maloelap",
        "code": "MAL"
      },
      {
        "name": "Mejit",
        "code": "MEJ"
      },
      {
        "name": "Mili",
        "code": "MIL"
      },
      {
        "name": "Namorik",
        "code": "NMK"
      },
      {
        "name": "Namu",
        "code": "NMU"
      },
      {
        "name": "Rongelap",
        "code": "RON"
      },
      {
        "name": "Ratak Chain",
        "code": "T"
      },
      {
        "name": "Ujae",
        "code": "UJA"
      },
      {
        "name": "Ujelang",
        "code": "UJL"
      },
      {
        "name": "Utirik",
        "code": "UTI"
      },
      {
        "name": "Wotho",
        "code": "WTH"
      },
      {
        "name": "Wotje",
        "code": "WTJ"
      }
    ]
  },
  {
    "name": "Martinique",
    "alpha3": "MTQ",
    "postal_code_format": "\\A9[78]2\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Mauritania",
    "alpha3": "MRT",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Hodh ech Chargui",
        "code": "01"
      },
      {
        "name": "Hodh el Gharbi",
        "code": "02"
      },
      {
        "name": "Assaba",
        "code": "03"
      },
      {
        "name": "Gorgol",
        "code": "04"
      },
      {
        "name": "Brakna",
        "code": "05"
      },
      {
        "name": "Trarza",
        "code": "06"
      },
      {
        "name": "Adrar",
        "code": "07"
      },
      {
        "name": "Dakhlet Nouâdhibou",
        "code": "08"
      },
      {
        "name": "Tagant",
        "code": "09"
      },
      {
        "name": "Guidimaka",
        "code": "10"
      },
      {
        "name": "Tiris Zemmour",
        "code": "11"
      },
      {
        "name": "Inchiri",
        "code": "12"
      },
      {
        "name": "Nouakchott Ouest",
        "code": "13"
      },
      {
        "name": "نواكشوط الشمالية",
        "code": "14"
      },
      {
        "name": "نواكشوط الجنوبية",
        "code": "15"
      }
    ]
  },
  {
    "name": "Mauritius",
    "alpha3": "MUS",
    "postal_code_format": "\\A\\d{3}(?:\\d{2}|[A-Z]{2}\\d{3})\\Z",
    "subdivisions": [
      {
        "name": "Agalega Islands",
        "code": "AG"
      },
      {
        "name": "Black River",
        "code": "BL"
      },
      {
        "name": "Cargados Carajos Shoals [Saint Brandon Islands]",
        "code": "CC"
      },
      {
        "name": "Flacq",
        "code": "FL"
      },
      {
        "name": "Grand Port",
        "code": "GP"
      },
      {
        "name": "Moka",
        "code": "MO"
      },
      {
        "name": "Pamplemousses",
        "code": "PA"
      },
      {
        "name": "Port Louis City",
        "code": "PL"
      },
      {
        "name": "Plaines Wilhems",
        "code": "PW"
      },
      {
        "name": "Rodrigues Island",
        "code": "RO"
      },
      {
        "name": "Rivière du Rempart",
        "code": "RR"
      },
      {
        "name": "Savanne",
        "code": "SA"
      }
    ]
  },
  {
    "name": "Mayotte",
    "alpha3": "MYT",
    "postal_code_format": "\\A976\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Mexico",
    "alpha3": "MEX",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Aguascalientes",
        "code": "AGU"
      },
      {
        "name": "Baja California",
        "code": "BCN"
      },
      {
        "name": "Baja California Sur",
        "code": "BCS"
      },
      {
        "name": "Campeche",
        "code": "CAM"
      },
      {
        "name": "Chihuahua",
        "code": "CHH"
      },
      {
        "name": "Chiapas",
        "code": "CHP"
      },
      {
        "name": "Ciudad de México",
        "code": "CMX"
      },
      {
        "name": "Coahuila",
        "code": "COA"
      },
      {
        "name": "Colima",
        "code": "COL"
      },
      {
        "name": "Durango",
        "code": "DUR"
      },
      {
        "name": "Guerrero",
        "code": "GRO"
      },
      {
        "name": "Guanajuato",
        "code": "GUA"
      },
      {
        "name": "Hidalgo",
        "code": "HID"
      },
      {
        "name": "Jalisco",
        "code": "JAL"
      },
      {
        "name": "México",
        "code": "MEX"
      },
      {
        "name": "Michoacán",
        "code": "MIC"
      },
      {
        "name": "Morelos",
        "code": "MOR"
      },
      {
        "name": "Nayarit",
        "code": "NAY"
      },
      {
        "name": "Nuevo León",
        "code": "NLE"
      },
      {
        "name": "Oaxaca",
        "code": "OAX"
      },
      {
        "name": "Puebla",
        "code": "PUE"
      },
      {
        "name": "Querétaro",
        "code": "QUE"
      },
      {
        "name": "Quintana Roo",
        "code": "ROO"
      },
      {
        "name": "Sinaloa",
        "code": "SIN"
      },
      {
        "name": "San Luis Potosí",
        "code": "SLP"
      },
      {
        "name": "Sonora",
        "code": "SON"
      },
      {
        "name": "Tabasco",
        "code": "TAB"
      },
      {
        "name": "Tamaulipas",
        "code": "TAM"
      },
      {
        "name": "Tlaxcala",
        "code": "TLA"
      },
      {
        "name": "Veracruz",
        "code": "VER"
      },
      {
        "name": "Yucatán",
        "code": "YUC"
      },
      {
        "name": "Zacatecas",
        "code": "ZAC"
      }
    ]
  },
  {
    "name": "Micronesia (Federated States of)",
    "alpha3": "FSM",
    "postal_code_format": "\\A(9694[1-4])(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": [
      {
        "name": "Kosrae",
        "code": "KSA"
      },
      {
        "name": "Pohnpei",
        "code": "PNI"
      },
      {
        "name": "Chuuk",
        "code": "TRK"
      },
      {
        "name": "Yap",
        "code": "YAP"
      }
    ]
  },
  {
    "name": "Moldova (Republic of)",
    "alpha3": "MDA",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Anenii Noi",
        "code": "AN"
      },
      {
        "name": "Balti",
        "code": "BA"
      },
      {
        "name": "Bender [Tighina]",
        "code": "BD"
      },
      {
        "name": "Briceni",
        "code": "BR"
      },
      {
        "name": "Basarabeasca",
        "code": "BS"
      },
      {
        "name": "Cahul",
        "code": "CA"
      },
      {
        "name": "Călărași",
        "code": "CL"
      },
      {
        "name": "Cimișlia",
        "code": "CM"
      },
      {
        "name": "Criuleni",
        "code": "CR"
      },
      {
        "name": "Căușeni",
        "code": "CS"
      },
      {
        "name": "Cantemir",
        "code": "CT"
      },
      {
        "name": "Chisinau",
        "code": "CU"
      },
      {
        "name": "Dondușeni",
        "code": "DO"
      },
      {
        "name": "Drochia",
        "code": "DR"
      },
      {
        "name": "Dubăsari",
        "code": "DU"
      },
      {
        "name": "Edinet",
        "code": "ED"
      },
      {
        "name": "Fălești",
        "code": "FA"
      },
      {
        "name": "Florești",
        "code": "FL"
      },
      {
        "name": "Gagauzia, Unitate Teritoriala Autonoma (UTAG)",
        "code": "GA"
      },
      {
        "name": "Glodeni",
        "code": "GL"
      },
      {
        "name": "Hîncești",
        "code": "HI"
      },
      {
        "name": "Ialoveni",
        "code": "IA"
      },
      {
        "name": "Leova",
        "code": "LE"
      },
      {
        "name": "Nisporeni",
        "code": "NI"
      },
      {
        "name": "Ocnița",
        "code": "OC"
      },
      {
        "name": "Orhei",
        "code": "OR"
      },
      {
        "name": "Rezina",
        "code": "RE"
      },
      {
        "name": "Rîșcani",
        "code": "RI"
      },
      {
        "name": "Șoldănești",
        "code": "SD"
      },
      {
        "name": "Sîngerei",
        "code": "SI"
      },
      {
        "name": "Stînga Nistrului, unitatea teritoriala din",
        "code": "SN"
      },
      {
        "name": "Soroca",
        "code": "SO"
      },
      {
        "name": "Strășeni",
        "code": "ST"
      },
      {
        "name": "Ștefan Vodă",
        "code": "SV"
      },
      {
        "name": "Taraclia",
        "code": "TA"
      },
      {
        "name": "Telenești",
        "code": "TE"
      },
      {
        "name": "Ungheni",
        "code": "UN"
      }
    ]
  },
  {
    "name": "Monaco",
    "alpha3": "MCO",
    "postal_code_format": "\\A980\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "La Colle",
        "code": "CL"
      },
      {
        "name": "La Condamine",
        "code": "CO"
      },
      {
        "name": "Fontvieille",
        "code": "FO"
      },
      {
        "name": "La Gare",
        "code": "GA"
      },
      {
        "name": "jardin exotique de Monaco",
        "code": "JE"
      },
      {
        "name": "Larvotto/Bas Moulins",
        "code": "LA"
      },
      {
        "name": "Malbousquet",
        "code": "MA"
      },
      {
        "name": "Monte-Carlo",
        "code": "MC"
      },
      {
        "name": "Moneghetti",
        "code": "MG"
      },
      {
        "name": "Monaco-Ville",
        "code": "MO"
      },
      {
        "name": "Moulins",
        "code": "MU"
      },
      {
        "name": "Port Hercule",
        "code": "PH"
      },
      {
        "name": "église Sainte-Dévote",
        "code": "SD"
      },
      {
        "name": "La Source",
        "code": "SO"
      },
      {
        "name": "Spélugues",
        "code": "SP"
      },
      {
        "name": "La Rousse",
        "code": "SR"
      },
      {
        "name": "Vallon de la Rousse",
        "code": "VR"
      }
    ]
  },
  {
    "name": "Mongolia",
    "alpha3": "MNG",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Orhon",
        "code": "035"
      },
      {
        "name": "Darhan uul",
        "code": "037"
      },
      {
        "name": "Hentiy",
        "code": "039"
      },
      {
        "name": "Hövsgöl",
        "code": "041"
      },
      {
        "name": "Hovd",
        "code": "043"
      },
      {
        "name": "Uvs",
        "code": "046"
      },
      {
        "name": "Töv",
        "code": "047"
      },
      {
        "name": "Selenge",
        "code": "049"
      },
      {
        "name": "Sühbaatar",
        "code": "051"
      },
      {
        "name": "Ömnögovi",
        "code": "053"
      },
      {
        "name": "Övörhangay",
        "code": "055"
      },
      {
        "name": "Dzavhan",
        "code": "057"
      },
      {
        "name": "Dundgovi",
        "code": "059"
      },
      {
        "name": "Dornod",
        "code": "061"
      },
      {
        "name": "Dornogovi",
        "code": "063"
      },
      {
        "name": "Govi-Sümber",
        "code": "064"
      },
      {
        "name": "Govi-Altay",
        "code": "065"
      },
      {
        "name": "Bulgan",
        "code": "067"
      },
      {
        "name": "Bayanhongor",
        "code": "069"
      },
      {
        "name": "Bayan-Ölgiy",
        "code": "071"
      },
      {
        "name": "Arhangay",
        "code": "073"
      },
      {
        "name": "Ulaanbaatar",
        "code": "1"
      }
    ]
  },
  {
    "name": "Montenegro",
    "alpha3": "MNE",
    "postal_code_format": "\\A8\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Andrijevica",
        "code": "01"
      },
      {
        "name": "Bar",
        "code": "02"
      },
      {
        "name": "Berane",
        "code": "03"
      },
      {
        "name": "Bijelo Polje",
        "code": "04"
      },
      {
        "name": "Budva",
        "code": "05"
      },
      {
        "name": "Cetinje",
        "code": "06"
      },
      {
        "name": "Danilovgrad",
        "code": "07"
      },
      {
        "name": "Herceg-Novi",
        "code": "08"
      },
      {
        "name": "Kolašin",
        "code": "09"
      },
      {
        "name": "Kotor",
        "code": "10"
      },
      {
        "name": "Mojkovac",
        "code": "11"
      },
      {
        "name": "Nikšic´",
        "code": "12"
      },
      {
        "name": "Plav",
        "code": "13"
      },
      {
        "name": "Pljevlja",
        "code": "14"
      },
      {
        "name": "Plužine",
        "code": "15"
      },
      {
        "name": "Podgorica",
        "code": "16"
      },
      {
        "name": "Rožaje",
        "code": "17"
      },
      {
        "name": "Šavnik",
        "code": "18"
      },
      {
        "name": "Tivat",
        "code": "19"
      },
      {
        "name": "Ulcinj",
        "code": "20"
      },
      {
        "name": "Žabljak",
        "code": "21"
      },
      {
        "name": "Општина Гусиње",
        "code": "22"
      },
      {
        "name": "Општина Петњица",
        "code": "23"
      },
      {
        "name": "Tuzi",
        "code": "24"
      }
    ]
  },
  {
    "name": "Montserrat",
    "alpha3": "MSR",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Morocco",
    "alpha3": "MAR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Tanger-Tétouan-Al Hoceïma",
        "code": "01"
      },
      {
        "name": "L'Oriental",
        "code": "02"
      },
      {
        "name": "Fès-Meknès",
        "code": "03"
      },
      {
        "name": "Rabat-Salé-Kénitra",
        "code": "04"
      },
      {
        "name": "Béni Mellal-Khénifra",
        "code": "05"
      },
      {
        "name": "Casablanca-Settat",
        "code": "06"
      },
      {
        "name": "Marrakech-Safi",
        "code": "07"
      },
      {
        "name": "Drâa-Tafilalet",
        "code": "08"
      },
      {
        "name": "Souss-Massa",
        "code": "09"
      },
      {
        "name": "Guelmim-Oued Noun (EH-partial)",
        "code": "10"
      },
      {
        "name": "Laâyoune-Sakia El Hamra (EH-partial)",
        "code": "11"
      },
      {
        "name": "Dakhla-Oued Ed-Dahab (EH)",
        "code": "12"
      },
      {
        "name": "Agadir*",
        "code": "AGD"
      },
      {
        "name": "Aousserd",
        "code": "AOU"
      },
      {
        "name": "Assa-Zag",
        "code": "ASZ"
      },
      {
        "name": "Azilal",
        "code": "AZI"
      },
      {
        "name": "Aït Baha",
        "code": "BAH"
      },
      {
        "name": "Beni Mellal",
        "code": "BEM"
      },
      {
        "name": "Berkane",
        "code": "BER"
      },
      {
        "name": "Ben Slimane",
        "code": "BES"
      },
      {
        "name": "Boujdour (EH)",
        "code": "BOD"
      },
      {
        "name": "Boulemane",
        "code": "BOM"
      },
      {
        "name": "Berrechid",
        "code": "BRR"
      },
      {
        "name": "Casablanca [Dar el Beïda]",
        "code": "CAS"
      },
      {
        "name": "Chefchaouene",
        "code": "CHE"
      },
      {
        "name": "Chichaoua",
        "code": "CHI"
      },
      {
        "name": "Chtouka-Ait Baha",
        "code": "CHT"
      },
      {
        "name": "Driouch",
        "code": "DRI"
      },
      {
        "name": "Errachidia",
        "code": "ERR"
      },
      {
        "name": "Essaouira",
        "code": "ESI"
      },
      {
        "name": "Es Smara (EH)",
        "code": "ESM"
      },
      {
        "name": "Fahs-Beni Makada",
        "code": "FAH"
      },
      {
        "name": "Fès",
        "code": "FES"
      },
      {
        "name": "Figuig",
        "code": "FIG"
      },
      {
        "name": "Fquih Ben Salah",
        "code": "FQH"
      },
      {
        "name": "Guelmim",
        "code": "GUE"
      },
      {
        "name": "Guercif",
        "code": "GUF"
      },
      {
        "name": "El Hajeb",
        "code": "HAJ"
      },
      {
        "name": "Al Haouz",
        "code": "HAO"
      },
      {
        "name": "Al Hoceïma",
        "code": "HOC"
      },
      {
        "name": "Ifrane",
        "code": "IFR"
      },
      {
        "name": "عمالة إنزكان آيت ملول",
        "code": "INE"
      },
      {
        "name": "El Jadida",
        "code": "JDI"
      },
      {
        "name": "Jerada",
        "code": "JRA"
      },
      {
        "name": "Kénitra",
        "code": "KEN"
      },
      {
        "name": "Kelaat Sraghna",
        "code": "KES"
      },
      {
        "name": "Khemisset",
        "code": "KHE"
      },
      {
        "name": "Khenifra",
        "code": "KHN"
      },
      {
        "name": "Khouribga",
        "code": "KHO"
      },
      {
        "name": "Laâyoune* (EH)",
        "code": "LAA"
      },
      {
        "name": "Larache",
        "code": "LAR"
      },
      {
        "name": "Marrakech",
        "code": "MAR"
      },
      {
        "name": "M’diq-Fnideq",
        "code": "MDF"
      },
      {
        "name": "Médiouna",
        "code": "MED"
      },
      {
        "name": "Meknès*",
        "code": "MEK"
      },
      {
        "name": "Aït Melloul",
        "code": "MEL"
      },
      {
        "name": "Midelt",
        "code": "MID"
      },
      {
        "name": "مراكش",
        "code": "MMD"
      },
      {
        "name": "مراكش²",
        "code": "MMN"
      },
      {
        "name": "المحمدية",
        "code": "MOH"
      },
      {
        "name": "Moulay Yacoub",
        "code": "MOU"
      },
      {
        "name": "Nador",
        "code": "NAD"
      },
      {
        "name": "Nouaceur",
        "code": "NOU"
      },
      {
        "name": "Ouarzazate",
        "code": "OUA"
      },
      {
        "name": "Oued ed Dahab (EH)",
        "code": "OUD"
      },
      {
        "name": "Oujda-Angad",
        "code": "OUJ"
      },
      {
        "name": "Ouezzane",
        "code": "OUZ"
      },
      {
        "name": "الرباط",
        "code": "RAB"
      },
      {
        "name": "Rehamna",
        "code": "REH"
      },
      {
        "name": "Safi",
        "code": "SAF"
      },
      {
        "name": "Salé",
        "code": "SAL"
      },
      {
        "name": "Sefrou",
        "code": "SEF"
      },
      {
        "name": "Settat",
        "code": "SET"
      },
      {
        "name": "Sidi Bennour",
        "code": "SIB"
      },
      {
        "name": "Sidi Ifni",
        "code": "SIF"
      },
      {
        "name": "Sidi Kacem",
        "code": "SIK"
      },
      {
        "name": "Sidi Slimane",
        "code": "SIL"
      },
      {
        "name": "Skhirate-Témara",
        "code": "SKH"
      },
      {
        "name": "Sidi Youssef Ben Ali",
        "code": "SYB"
      },
      {
        "name": "Tarfaya (EH-partial)",
        "code": "TAF"
      },
      {
        "name": "Taourirt",
        "code": "TAI"
      },
      {
        "name": "Taounate",
        "code": "TAO"
      },
      {
        "name": "Taroudannt",
        "code": "TAR"
      },
      {
        "name": "Tata",
        "code": "TAT"
      },
      {
        "name": "Taza",
        "code": "TAZ"
      },
      {
        "name": "Tétouan*",
        "code": "TET"
      },
      {
        "name": "Tinghir",
        "code": "TIN"
      },
      {
        "name": "Tiznit",
        "code": "TIZ"
      },
      {
        "name": "Tanger-Assilah",
        "code": "TNG"
      },
      {
        "name": "Tan-Tan",
        "code": "TNT"
      },
      {
        "name": "Laayoune-Boujdour-Sakia El Hamra",
        "code": "X1~"
      },
      {
        "name": "Youssoufia",
        "code": "YUS"
      },
      {
        "name": "Zagora",
        "code": "ZAG"
      }
    ]
  },
  {
    "name": "Mozambique",
    "alpha3": "MOZ",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Niassa",
        "code": "A"
      },
      {
        "name": "Manica",
        "code": "B"
      },
      {
        "name": "Gaza",
        "code": "G"
      },
      {
        "name": "Inhambane",
        "code": "I"
      },
      {
        "name": "Maputo",
        "code": "L"
      },
      {
        "name": "Maputo City",
        "code": "MPM"
      },
      {
        "name": "Nampula",
        "code": "N"
      },
      {
        "name": "Cabo Delgado",
        "code": "P"
      },
      {
        "name": "Zambézia",
        "code": "Q"
      },
      {
        "name": "Sofala",
        "code": "S"
      },
      {
        "name": "Tete",
        "code": "T"
      }
    ]
  },
  {
    "name": "Myanmar",
    "alpha3": "MMR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Sagaing",
        "code": "01"
      },
      {
        "name": "Bago",
        "code": "02"
      },
      {
        "name": "Magway",
        "code": "03"
      },
      {
        "name": "Mandalay",
        "code": "04"
      },
      {
        "name": "Tanintharyi",
        "code": "05"
      },
      {
        "name": "Yangon",
        "code": "06"
      },
      {
        "name": "Ayeyarwady",
        "code": "07"
      },
      {
        "name": "Kachin",
        "code": "11"
      },
      {
        "name": "Kayah",
        "code": "12"
      },
      {
        "name": "Kayin",
        "code": "13"
      },
      {
        "name": "Chin",
        "code": "14"
      },
      {
        "name": "Mon",
        "code": "15"
      },
      {
        "name": "Rakhine",
        "code": "16"
      },
      {
        "name": "Shan",
        "code": "17"
      },
      {
        "name": "နေပြည်တော် ပြည်ထောင်စုနယ်မြေ",
        "code": "18"
      }
    ]
  },
  {
    "name": "Namibia",
    "alpha3": "NAM",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Zambezi",
        "code": "CA"
      },
      {
        "name": "Erongo",
        "code": "ER"
      },
      {
        "name": "Hardap",
        "code": "HA"
      },
      {
        "name": "Karas",
        "code": "KA"
      },
      {
        "name": "Kavango East",
        "code": "KE"
      },
      {
        "name": "Khomas",
        "code": "KH"
      },
      {
        "name": "Kunene",
        "code": "KU"
      },
      {
        "name": "Kavango West",
        "code": "KW"
      },
      {
        "name": "Otjozondjupa",
        "code": "OD"
      },
      {
        "name": "Omaheke",
        "code": "OH"
      },
      {
        "name": "Oshana",
        "code": "ON"
      },
      {
        "name": "Omusati",
        "code": "OS"
      },
      {
        "name": "Oshikoto",
        "code": "OT"
      },
      {
        "name": "Ohangwena",
        "code": "OW"
      }
    ]
  },
  {
    "name": "Nauru",
    "alpha3": "NRU",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Aiwo",
        "code": "01"
      },
      {
        "name": "Anabar",
        "code": "02"
      },
      {
        "name": "Anetan",
        "code": "03"
      },
      {
        "name": "Anibare",
        "code": "04"
      },
      {
        "name": "Baiti",
        "code": "05"
      },
      {
        "name": "Boe",
        "code": "06"
      },
      {
        "name": "Buada",
        "code": "07"
      },
      {
        "name": "Denigomodu",
        "code": "08"
      },
      {
        "name": "Ewa",
        "code": "09"
      },
      {
        "name": "Ijuw",
        "code": "10"
      },
      {
        "name": "Meneng",
        "code": "11"
      },
      {
        "name": "Nibok",
        "code": "12"
      },
      {
        "name": "Uaboe",
        "code": "13"
      },
      {
        "name": "Yaren",
        "code": "14"
      }
    ]
  },
  {
    "name": "Nepal",
    "alpha3": "NPL",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "मध्यमाञ्चल विकास क्षेत्र",
        "code": "1"
      },
      {
        "name": "मध्य-पश्चिमाञ्चल विकास क्षेत्र",
        "code": "2"
      },
      {
        "name": "पश्चिमाञ्चल विकास क्षेत्र",
        "code": "3"
      },
      {
        "name": "पूर्वाञ्चल विकास क्षेत्र",
        "code": "4"
      },
      {
        "name": "सुदूर-पश्चिमाञ्चल विकास क्षेत्र",
        "code": "5"
      },
      {
        "name": "Bagmati",
        "code": "BA"
      },
      {
        "name": "Bheri",
        "code": "BH"
      },
      {
        "name": "Dhawalagiri",
        "code": "DH"
      },
      {
        "name": "Gandaki",
        "code": "GA"
      },
      {
        "name": "Janakpur",
        "code": "JA"
      },
      {
        "name": "Karnali",
        "code": "KA"
      },
      {
        "name": "Kosi [Koshi]",
        "code": "KO"
      },
      {
        "name": "Lumbini",
        "code": "LU"
      },
      {
        "name": "Mahakali",
        "code": "MA"
      },
      {
        "name": "Mechi",
        "code": "ME"
      },
      {
        "name": "Narayani",
        "code": "NA"
      },
      {
        "name": "Province 1",
        "code": "P1"
      },
      {
        "name": "Province 2",
        "code": "P2"
      },
      {
        "name": "Province 3",
        "code": "P3"
      },
      {
        "name": "Gandaki²",
        "code": "P4"
      },
      {
        "name": "Province 5",
        "code": "P5"
      },
      {
        "name": "Karnali²",
        "code": "P6"
      },
      {
        "name": "Province 7",
        "code": "P7"
      },
      {
        "name": "Rapti",
        "code": "RA"
      },
      {
        "name": "Sagarmatha",
        "code": "SA"
      },
      {
        "name": "Seti",
        "code": "SE"
      }
    ]
  },
  {
    "name": "Netherlands",
    "alpha3": "NLD",
    "postal_code_format": "\\A\\d{4} ?[A-Z]{2}\\Z",
    "subdivisions": [
      {
        "name": "Aruba",
        "code": "AW"
      },
      {
        "name": "Bonaire",
        "code": "BQ1"
      },
      {
        "name": "Saba",
        "code": "BQ2"
      },
      {
        "name": "Sint Eustatius",
        "code": "BQ3"
      },
      {
        "name": "Curaçao",
        "code": "CW"
      },
      {
        "name": "Drenthe",
        "code": "DR"
      },
      {
        "name": "Flevoland",
        "code": "FL"
      },
      {
        "name": "Friesland",
        "code": "FR"
      },
      {
        "name": "Gelderland",
        "code": "GE"
      },
      {
        "name": "Groningen",
        "code": "GR"
      },
      {
        "name": "Limburg",
        "code": "LI"
      },
      {
        "name": "Noord-Brabant",
        "code": "NB"
      },
      {
        "name": "Noord-Holland",
        "code": "NH"
      },
      {
        "name": "Overijssel",
        "code": "OV"
      },
      {
        "name": "Sint Maarten",
        "code": "SX"
      },
      {
        "name": "Utrecht",
        "code": "UT"
      },
      {
        "name": "Zeeland",
        "code": "ZE"
      },
      {
        "name": "Zuid-Holland",
        "code": "ZH"
      }
    ]
  },
  {
    "name": "New Caledonia",
    "alpha3": "NCL",
    "postal_code_format": "\\A988\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "New Zealand",
    "alpha3": "NZL",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Auckland",
        "code": "AUK"
      },
      {
        "name": "Bay of Plenty",
        "code": "BOP"
      },
      {
        "name": "Canterbury",
        "code": "CAN"
      },
      {
        "name": "Chatham Islands Territory",
        "code": "CIT"
      },
      {
        "name": "Gisborne",
        "code": "GIS"
      },
      {
        "name": "Hawke's Bay",
        "code": "HKB"
      },
      {
        "name": "Marlborough",
        "code": "MBH"
      },
      {
        "name": "Manawatu-Wanganui",
        "code": "MWT"
      },
      {
        "name": "Nelson",
        "code": "NSN"
      },
      {
        "name": "Northland",
        "code": "NTL"
      },
      {
        "name": "Otago",
        "code": "OTA"
      },
      {
        "name": "Southland",
        "code": "STL"
      },
      {
        "name": "Tasman",
        "code": "TAS"
      },
      {
        "name": "Taranaki",
        "code": "TKI"
      },
      {
        "name": "Wellington",
        "code": "WGN"
      },
      {
        "name": "Waikato",
        "code": "WKO"
      },
      {
        "name": "West Coast",
        "code": "WTC"
      }
    ]
  },
  {
    "name": "Nicaragua",
    "alpha3": "NIC",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Atlántico Norte*",
        "code": "AN"
      },
      {
        "name": "Atlántico Sur*",
        "code": "AS"
      },
      {
        "name": "Boaco",
        "code": "BO"
      },
      {
        "name": "Carazo",
        "code": "CA"
      },
      {
        "name": "Chinandega",
        "code": "CI"
      },
      {
        "name": "Chontales",
        "code": "CO"
      },
      {
        "name": "Estelí",
        "code": "ES"
      },
      {
        "name": "Granada",
        "code": "GR"
      },
      {
        "name": "Jinotega",
        "code": "JI"
      },
      {
        "name": "León",
        "code": "LE"
      },
      {
        "name": "Madriz",
        "code": "MD"
      },
      {
        "name": "Managua",
        "code": "MN"
      },
      {
        "name": "Masaya",
        "code": "MS"
      },
      {
        "name": "Matagalpa",
        "code": "MT"
      },
      {
        "name": "Nueva Segovia",
        "code": "NS"
      },
      {
        "name": "Rivas",
        "code": "RI"
      },
      {
        "name": "Río San Juan",
        "code": "SJ"
      }
    ]
  },
  {
    "name": "Niger",
    "alpha3": "NER",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Agadez",
        "code": "1"
      },
      {
        "name": "Diffa",
        "code": "2"
      },
      {
        "name": "Dosso",
        "code": "3"
      },
      {
        "name": "Maradi",
        "code": "4"
      },
      {
        "name": "Tahoua",
        "code": "5"
      },
      {
        "name": "Tillabéri",
        "code": "6"
      },
      {
        "name": "Zinder",
        "code": "7"
      },
      {
        "name": "Niamey",
        "code": "8"
      }
    ]
  },
  {
    "name": "Nigeria",
    "alpha3": "NGA",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Abia",
        "code": "AB"
      },
      {
        "name": "Adamawa",
        "code": "AD"
      },
      {
        "name": "Akwa Ibom",
        "code": "AK"
      },
      {
        "name": "Anambra",
        "code": "AN"
      },
      {
        "name": "Bauchi",
        "code": "BA"
      },
      {
        "name": "Benue",
        "code": "BE"
      },
      {
        "name": "Borno",
        "code": "BO"
      },
      {
        "name": "Bayelsa",
        "code": "BY"
      },
      {
        "name": "Cross River",
        "code": "CR"
      },
      {
        "name": "Delta",
        "code": "DE"
      },
      {
        "name": "Ebonyi",
        "code": "EB"
      },
      {
        "name": "Edo",
        "code": "ED"
      },
      {
        "name": "Ekiti",
        "code": "EK"
      },
      {
        "name": "Enugu",
        "code": "EN"
      },
      {
        "name": "Abuja Capital Territory",
        "code": "FC"
      },
      {
        "name": "Gombe",
        "code": "GO"
      },
      {
        "name": "Imo",
        "code": "IM"
      },
      {
        "name": "Jigawa",
        "code": "JI"
      },
      {
        "name": "Kaduna",
        "code": "KD"
      },
      {
        "name": "Kebbi",
        "code": "KE"
      },
      {
        "name": "Kano",
        "code": "KN"
      },
      {
        "name": "Kogi",
        "code": "KO"
      },
      {
        "name": "Katsina",
        "code": "KT"
      },
      {
        "name": "Kwara",
        "code": "KW"
      },
      {
        "name": "Lagos",
        "code": "LA"
      },
      {
        "name": "Nassarawa",
        "code": "NA"
      },
      {
        "name": "Niger",
        "code": "NI"
      },
      {
        "name": "Ogun",
        "code": "OG"
      },
      {
        "name": "Ondo",
        "code": "ON"
      },
      {
        "name": "Osun",
        "code": "OS"
      },
      {
        "name": "Oyo",
        "code": "OY"
      },
      {
        "name": "Plateau",
        "code": "PL"
      },
      {
        "name": "Rivers",
        "code": "RI"
      },
      {
        "name": "Sokoto",
        "code": "SO"
      },
      {
        "name": "Taraba",
        "code": "TA"
      },
      {
        "name": "Yobe",
        "code": "YO"
      },
      {
        "name": "Zamfara",
        "code": "ZA"
      }
    ]
  },
  {
    "name": "Niue",
    "alpha3": "NIU",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Norfolk Island",
    "alpha3": "NFK",
    "postal_code_format": "\\A2899\\Z",
    "subdivisions": []
  },
  {
    "name": "North Macedonia",
    "alpha3": "MKD",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Veles",
        "code": "101"
      },
      {
        "name": "Gradsko",
        "code": "102"
      },
      {
        "name": "Demir Kapija",
        "code": "103"
      },
      {
        "name": "Kavadarci",
        "code": "104"
      },
      {
        "name": "Lozovo",
        "code": "105"
      },
      {
        "name": "Negotino",
        "code": "106"
      },
      {
        "name": "Rosoman",
        "code": "107"
      },
      {
        "name": "Sveti Nikole",
        "code": "108"
      },
      {
        "name": "Čaška",
        "code": "109"
      },
      {
        "name": "Berovo",
        "code": "201"
      },
      {
        "name": "Vinica",
        "code": "202"
      },
      {
        "name": "Delčevo",
        "code": "203"
      },
      {
        "name": "Zrnovci",
        "code": "204"
      },
      {
        "name": "Karbinci",
        "code": "205"
      },
      {
        "name": "Kočani",
        "code": "206"
      },
      {
        "name": "Makedonska Kamenica",
        "code": "207"
      },
      {
        "name": "Pehčevo",
        "code": "208"
      },
      {
        "name": "Probištip",
        "code": "209"
      },
      {
        "name": "Češinovo-Obleševo",
        "code": "210"
      },
      {
        "name": "Štip",
        "code": "211"
      },
      {
        "name": "Vevčani",
        "code": "301"
      },
      {
        "name": "Debar",
        "code": "303"
      },
      {
        "name": "Debarca",
        "code": "304"
      },
      {
        "name": "Kičevo",
        "code": "307"
      },
      {
        "name": "Makedonski Brod",
        "code": "308"
      },
      {
        "name": "Ohrid",
        "code": "310"
      },
      {
        "name": "Plasnica",
        "code": "311"
      },
      {
        "name": "Struga",
        "code": "312"
      },
      {
        "name": "Centar Župa",
        "code": "313"
      },
      {
        "name": "Bogdanci",
        "code": "401"
      },
      {
        "name": "Bosilovo",
        "code": "402"
      },
      {
        "name": "Valandovo",
        "code": "403"
      },
      {
        "name": "Vasilevo",
        "code": "404"
      },
      {
        "name": "Gevgelija",
        "code": "405"
      },
      {
        "name": "Dojran",
        "code": "406"
      },
      {
        "name": "Konče",
        "code": "407"
      },
      {
        "name": "Novo Selo",
        "code": "408"
      },
      {
        "name": "Radoviš",
        "code": "409"
      },
      {
        "name": "Strumica",
        "code": "410"
      },
      {
        "name": "Bitola",
        "code": "501"
      },
      {
        "name": "Demir Hisar",
        "code": "502"
      },
      {
        "name": "Dolneni",
        "code": "503"
      },
      {
        "name": "Krivogaštani",
        "code": "504"
      },
      {
        "name": "Kruševo",
        "code": "505"
      },
      {
        "name": "Mogila",
        "code": "506"
      },
      {
        "name": "Novaci",
        "code": "507"
      },
      {
        "name": "Prilep",
        "code": "508"
      },
      {
        "name": "Resen",
        "code": "509"
      },
      {
        "name": "Bogovinje",
        "code": "601"
      },
      {
        "name": "Brvenica",
        "code": "602"
      },
      {
        "name": "Vrapčište",
        "code": "603"
      },
      {
        "name": "Gostivar",
        "code": "604"
      },
      {
        "name": "Želino",
        "code": "605"
      },
      {
        "name": "Jegunovce",
        "code": "606"
      },
      {
        "name": "Mavrovo i Rostuša",
        "code": "607"
      },
      {
        "name": "Tearce",
        "code": "608"
      },
      {
        "name": "Tetovo",
        "code": "609"
      },
      {
        "name": "Kratovo",
        "code": "701"
      },
      {
        "name": "Kriva Palanka",
        "code": "702"
      },
      {
        "name": "Kumanovo",
        "code": "703"
      },
      {
        "name": "Lipkovo",
        "code": "704"
      },
      {
        "name": "Rankovce",
        "code": "705"
      },
      {
        "name": "Staro Nagoričane",
        "code": "706"
      },
      {
        "name": "Aerodrom †",
        "code": "801"
      },
      {
        "name": "Aračinovo",
        "code": "802"
      },
      {
        "name": "Butel †",
        "code": "803"
      },
      {
        "name": "Gazi Baba †",
        "code": "804"
      },
      {
        "name": "Gjorče Petrov †",
        "code": "805"
      },
      {
        "name": "Zelenikovo",
        "code": "806"
      },
      {
        "name": "Ilinden",
        "code": "807"
      },
      {
        "name": "Karpoš †",
        "code": "808"
      },
      {
        "name": "Kisela Voda †",
        "code": "809"
      },
      {
        "name": "Petrovec",
        "code": "810"
      },
      {
        "name": "Saraj †",
        "code": "811"
      },
      {
        "name": "Sopište",
        "code": "812"
      },
      {
        "name": "Studeničani",
        "code": "813"
      },
      {
        "name": "Centar †",
        "code": "814"
      },
      {
        "name": "Čair †",
        "code": "815"
      },
      {
        "name": "Čučer-Sandevo",
        "code": "816"
      },
      {
        "name": "Šuto Orizari †",
        "code": "817"
      }
    ]
  },
  {
    "name": "Northern Mariana Islands",
    "alpha3": "MNP",
    "postal_code_format": "\\A(9695[012])(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": []
  },
  {
    "name": "Norway",
    "alpha3": "NOR",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Oslo",
        "code": "03"
      },
      {
        "name": "Rogaland",
        "code": "11"
      },
      {
        "name": "Møre og Romsdal",
        "code": "15"
      },
      {
        "name": "Nordland",
        "code": "18"
      },
      {
        "name": "Svalbard (Arctic Region)",
        "code": "21"
      },
      {
        "name": "Jan Mayen (Arctic Region)",
        "code": "22"
      },
      {
        "name": "Viken",
        "code": "30"
      },
      {
        "name": "Innlandet",
        "code": "34"
      },
      {
        "name": "Vestfold og Telemark",
        "code": "38"
      },
      {
        "name": "Agder",
        "code": "42"
      },
      {
        "name": "Vestland",
        "code": "46"
      },
      {
        "name": "Trøndelag",
        "code": "50"
      },
      {
        "name": "Troms og Finnmark",
        "code": "54"
      }
    ]
  },
  {
    "name": "Oman",
    "alpha3": "OMN",
    "postal_code_format": "\\A(?:PC )?\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "محافظة جنوب الباطنة",
        "code": "BJ"
      },
      {
        "name": "محافظة شمال الباطنة",
        "code": "BS"
      },
      {
        "name": "محافظة البريمي",
        "code": "BU"
      },
      {
        "name": "Ad Dakhiliyah",
        "code": "DA"
      },
      {
        "name": "Masqat",
        "code": "MA"
      },
      {
        "name": "Musandam",
        "code": "MU"
      },
      {
        "name": "محافظة جنوب الشرقية",
        "code": "SJ"
      },
      {
        "name": "محافظة شمال الشرقية",
        "code": "SS"
      },
      {
        "name": "Al Wustá",
        "code": "WU"
      },
      {
        "name": "Adh Dhahirah",
        "code": "ZA"
      },
      {
        "name": "محافظة ظفار",
        "code": "ZU"
      }
    ]
  },
  {
    "name": "Pakistan",
    "alpha3": "PAK",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Baluchistan (en)",
        "code": "BA"
      },
      {
        "name": "Gilgit-Baltistan",
        "code": "GB"
      },
      {
        "name": "Islamabad",
        "code": "IS"
      },
      {
        "name": "Azad Kashmir",
        "code": "JK"
      },
      {
        "name": "Khyber Pakhtunkhwa",
        "code": "KP"
      },
      {
        "name": "Punjab",
        "code": "PB"
      },
      {
        "name": "Sind (en)",
        "code": "SD"
      }
    ]
  },
  {
    "name": "Palau",
    "alpha3": "PLW",
    "postal_code_format": "\\A(969(?:39|40))(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": [
      {
        "name": "Aimeliik",
        "code": "002"
      },
      {
        "name": "Airai",
        "code": "004"
      },
      {
        "name": "Angaur",
        "code": "010"
      },
      {
        "name": "Hatobohei",
        "code": "050"
      },
      {
        "name": "Kayangel",
        "code": "100"
      },
      {
        "name": "Koror",
        "code": "150"
      },
      {
        "name": "Melekeok",
        "code": "212"
      },
      {
        "name": "Ngaraard",
        "code": "214"
      },
      {
        "name": "Ngarchelong",
        "code": "218"
      },
      {
        "name": "Ngardmau",
        "code": "222"
      },
      {
        "name": "Ngatpang",
        "code": "224"
      },
      {
        "name": "Ngchesar",
        "code": "226"
      },
      {
        "name": "Ngeremlengui",
        "code": "227"
      },
      {
        "name": "Ngiwal",
        "code": "228"
      },
      {
        "name": "Peleliu",
        "code": "350"
      },
      {
        "name": "Sonsorol",
        "code": "370"
      }
    ]
  },
  {
    "name": "Palestine, State of",
    "alpha3": "PSE",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "محافظة بيت لحم",
        "code": "BTH"
      },
      {
        "name": "الوسطى",
        "code": "DEB"
      },
      {
        "name": "محافظة غزة",
        "code": "GZA"
      },
      {
        "name": "محافظة الخليل",
        "code": "HBN"
      },
      {
        "name": "القدس",
        "code": "JEM"
      },
      {
        "name": "جنين",
        "code": "JEN"
      },
      {
        "name": "أريحا",
        "code": "JRH"
      },
      {
        "name": "محافظة خان يونس",
        "code": "KYS"
      },
      {
        "name": "محافظة نابلس",
        "code": "NBS"
      },
      {
        "name": "شمال غزة",
        "code": "NGZ"
      },
      {
        "name": "محافظة قلقيلية",
        "code": "QQA"
      },
      {
        "name": "رام الله والبيرة",
        "code": "RBH"
      },
      {
        "name": "محافظة رفح الفلسطينية",
        "code": "RFH"
      },
      {
        "name": "محافظة سلفيت",
        "code": "SLT"
      },
      {
        "name": "محافظة طوباس",
        "code": "TBS"
      },
      {
        "name": "محافظة طولكرم",
        "code": "TKM"
      }
    ]
  },
  {
    "name": "Panama",
    "alpha3": "PAN",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bocas del Toro",
        "code": "1"
      },
      {
        "name": "Panamá Oeste",
        "code": "10"
      },
      {
        "name": "Coclé",
        "code": "2"
      },
      {
        "name": "Colón",
        "code": "3"
      },
      {
        "name": "Chiriquí",
        "code": "4"
      },
      {
        "name": "Darién",
        "code": "5"
      },
      {
        "name": "Herrera",
        "code": "6"
      },
      {
        "name": "Los Santos",
        "code": "7"
      },
      {
        "name": "Panamá",
        "code": "8"
      },
      {
        "name": "Veraguas",
        "code": "9"
      },
      {
        "name": "Emberá-Wounaan",
        "code": "EM"
      },
      {
        "name": "Guna Yala",
        "code": "KY"
      },
      {
        "name": "Ngäbe-Buglé",
        "code": "NB"
      }
    ]
  },
  {
    "name": "Papua New Guinea",
    "alpha3": "PNG",
    "postal_code_format": "\\A\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Chimbu",
        "code": "CPK"
      },
      {
        "name": "Central",
        "code": "CPM"
      },
      {
        "name": "East New Britain",
        "code": "EBR"
      },
      {
        "name": "Eastern Highlands",
        "code": "EHG"
      },
      {
        "name": "Enga",
        "code": "EPW"
      },
      {
        "name": "East Sepik",
        "code": "ESW"
      },
      {
        "name": "Gulf",
        "code": "GPK"
      },
      {
        "name": "Hela",
        "code": "HLA"
      },
      {
        "name": "Jiwaka",
        "code": "JWK"
      },
      {
        "name": "Milne Bay",
        "code": "MBA"
      },
      {
        "name": "Morobe",
        "code": "MPL"
      },
      {
        "name": "Madang",
        "code": "MPM"
      },
      {
        "name": "Manus",
        "code": "MRL"
      },
      {
        "name": "National Capital District (Port Moresby)",
        "code": "NCD"
      },
      {
        "name": "New Ireland",
        "code": "NIK"
      },
      {
        "name": "Northern",
        "code": "NPP"
      },
      {
        "name": "Bougainville",
        "code": "NSB"
      },
      {
        "name": "Sandaun [West Sepik]",
        "code": "SAN"
      },
      {
        "name": "Southern Highlands",
        "code": "SHM"
      },
      {
        "name": "West New Britain",
        "code": "WBK"
      },
      {
        "name": "Western Highlands",
        "code": "WHM"
      },
      {
        "name": "Western",
        "code": "WPD"
      }
    ]
  },
  {
    "name": "Paraguay",
    "alpha3": "PRY",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Concepción",
        "code": "1"
      },
      {
        "name": "Alto Paraná",
        "code": "10"
      },
      {
        "name": "Central",
        "code": "11"
      },
      {
        "name": "Ñeembucú",
        "code": "12"
      },
      {
        "name": "Amambay",
        "code": "13"
      },
      {
        "name": "Canindeyú",
        "code": "14"
      },
      {
        "name": "Presidente Hayes",
        "code": "15"
      },
      {
        "name": "Alto Paraguay",
        "code": "16"
      },
      {
        "name": "Boquerón",
        "code": "19"
      },
      {
        "name": "San Pedro",
        "code": "2"
      },
      {
        "name": "Cordillera",
        "code": "3"
      },
      {
        "name": "Guairá",
        "code": "4"
      },
      {
        "name": "Caaguazú",
        "code": "5"
      },
      {
        "name": "Caazapá",
        "code": "6"
      },
      {
        "name": "Itapúa",
        "code": "7"
      },
      {
        "name": "Misiones",
        "code": "8"
      },
      {
        "name": "Paraguarí",
        "code": "9"
      },
      {
        "name": "Asunción",
        "code": "ASU"
      }
    ]
  },
  {
    "name": "Peru",
    "alpha3": "PER",
    "postal_code_format": "\\A(?:LIMA \\d{1,2}|CALLAO 0?\\d)|[0-2]\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Amazonas",
        "code": "AMA"
      },
      {
        "name": "Ancash",
        "code": "ANC"
      },
      {
        "name": "Apurímac",
        "code": "APU"
      },
      {
        "name": "Arequipa",
        "code": "ARE"
      },
      {
        "name": "Ayacucho",
        "code": "AYA"
      },
      {
        "name": "Cajamarca",
        "code": "CAJ"
      },
      {
        "name": "El Callao",
        "code": "CAL"
      },
      {
        "name": "Cuzco [Cusco]",
        "code": "CUS"
      },
      {
        "name": "Huánuco",
        "code": "HUC"
      },
      {
        "name": "Huancavelica",
        "code": "HUV"
      },
      {
        "name": "Ica",
        "code": "ICA"
      },
      {
        "name": "Junín",
        "code": "JUN"
      },
      {
        "name": "La Libertad",
        "code": "LAL"
      },
      {
        "name": "Lambayeque",
        "code": "LAM"
      },
      {
        "name": "Lima",
        "code": "LIM"
      },
      {
        "name": "Lima Metropolitana",
        "code": "LMA"
      },
      {
        "name": "Loreto",
        "code": "LOR"
      },
      {
        "name": "Madre de Dios",
        "code": "MDD"
      },
      {
        "name": "Moquegua",
        "code": "MOQ"
      },
      {
        "name": "Pasco",
        "code": "PAS"
      },
      {
        "name": "Piura",
        "code": "PIU"
      },
      {
        "name": "Puno",
        "code": "PUN"
      },
      {
        "name": "San Martín",
        "code": "SAM"
      },
      {
        "name": "Tacna",
        "code": "TAC"
      },
      {
        "name": "Tumbes",
        "code": "TUM"
      },
      {
        "name": "Ucayali",
        "code": "UCA"
      }
    ]
  },
  {
    "name": "Philippines",
    "alpha3": "PHL",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "National Capital Region",
        "code": "00"
      },
      {
        "name": "Ilocos",
        "code": "01"
      },
      {
        "name": "Cagayan Valley",
        "code": "02"
      },
      {
        "name": "Central Luzon",
        "code": "03"
      },
      {
        "name": "Bicol",
        "code": "05"
      },
      {
        "name": "Western Visayas",
        "code": "06"
      },
      {
        "name": "Central Visayas",
        "code": "07"
      },
      {
        "name": "Eastern Visayas",
        "code": "08"
      },
      {
        "name": "Zamboanga Peninsula",
        "code": "09"
      },
      {
        "name": "Northern Mindanao",
        "code": "10"
      },
      {
        "name": "Davao",
        "code": "11"
      },
      {
        "name": "Soccsksargen",
        "code": "12"
      },
      {
        "name": "Caraga",
        "code": "13"
      },
      {
        "name": "Muslim Mindanao",
        "code": "14"
      },
      {
        "name": "Cordillera Administrative",
        "code": "15"
      },
      {
        "name": "Calabarzon",
        "code": "40"
      },
      {
        "name": "Mimaropa",
        "code": "41"
      },
      {
        "name": "Abra",
        "code": "ABR"
      },
      {
        "name": "Agusan del Norte",
        "code": "AGN"
      },
      {
        "name": "Agusan del Sur",
        "code": "AGS"
      },
      {
        "name": "Aklan",
        "code": "AKL"
      },
      {
        "name": "Albay",
        "code": "ALB"
      },
      {
        "name": "Antique",
        "code": "ANT"
      },
      {
        "name": "Apayao",
        "code": "APA"
      },
      {
        "name": "Aurora",
        "code": "AUR"
      },
      {
        "name": "Bataan",
        "code": "BAN"
      },
      {
        "name": "Basilan",
        "code": "BAS"
      },
      {
        "name": "Benguet",
        "code": "BEN"
      },
      {
        "name": "Biliran",
        "code": "BIL"
      },
      {
        "name": "Bohol",
        "code": "BOH"
      },
      {
        "name": "Batangas",
        "code": "BTG"
      },
      {
        "name": "Batanes",
        "code": "BTN"
      },
      {
        "name": "Bukidnon",
        "code": "BUK"
      },
      {
        "name": "Bulacan",
        "code": "BUL"
      },
      {
        "name": "Cagayan",
        "code": "CAG"
      },
      {
        "name": "Camiguin",
        "code": "CAM"
      },
      {
        "name": "Camarines Norte",
        "code": "CAN"
      },
      {
        "name": "Capiz",
        "code": "CAP"
      },
      {
        "name": "Camarines Sur",
        "code": "CAS"
      },
      {
        "name": "Catanduanes",
        "code": "CAT"
      },
      {
        "name": "Cavite",
        "code": "CAV"
      },
      {
        "name": "Cebu",
        "code": "CEB"
      },
      {
        "name": "Compostela Valley",
        "code": "COM"
      },
      {
        "name": "Davao Oriental",
        "code": "DAO"
      },
      {
        "name": "Davao del Sur",
        "code": "DAS"
      },
      {
        "name": "Davao del Norte",
        "code": "DAV"
      },
      {
        "name": "Dinagat Islands",
        "code": "DIN"
      },
      {
        "name": "Davao Occidental",
        "code": "DVO"
      },
      {
        "name": "Eastern Samar",
        "code": "EAS"
      },
      {
        "name": "Guimaras",
        "code": "GUI"
      },
      {
        "name": "Ifugao",
        "code": "IFU"
      },
      {
        "name": "Iloilo",
        "code": "ILI"
      },
      {
        "name": "Ilocos Norte",
        "code": "ILN"
      },
      {
        "name": "Ilocos Sur",
        "code": "ILS"
      },
      {
        "name": "Isabela",
        "code": "ISA"
      },
      {
        "name": "Kalinga",
        "code": "KAL"
      },
      {
        "name": "Laguna",
        "code": "LAG"
      },
      {
        "name": "Lanao del Norte",
        "code": "LAN"
      },
      {
        "name": "Lanao del Sur",
        "code": "LAS"
      },
      {
        "name": "Leyte",
        "code": "LEY"
      },
      {
        "name": "La Union",
        "code": "LUN"
      },
      {
        "name": "Marinduque",
        "code": "MAD"
      },
      {
        "name": "Maguindanao",
        "code": "MAG"
      },
      {
        "name": "Masbate",
        "code": "MAS"
      },
      {
        "name": "Mindoro Occidental",
        "code": "MDC"
      },
      {
        "name": "Mindoro Oriental",
        "code": "MDR"
      },
      {
        "name": "Mountain Province",
        "code": "MOU"
      },
      {
        "name": "Misamis Occidental",
        "code": "MSC"
      },
      {
        "name": "Misamis Oriental",
        "code": "MSR"
      },
      {
        "name": "North Cotabato",
        "code": "NCO"
      },
      {
        "name": "Negros Occidental",
        "code": "NEC"
      },
      {
        "name": "Negros Oriental",
        "code": "NER"
      },
      {
        "name": "Northern Samar",
        "code": "NSA"
      },
      {
        "name": "Nueva Ecija",
        "code": "NUE"
      },
      {
        "name": "Nueva Vizcaya",
        "code": "NUV"
      },
      {
        "name": "Pampanga",
        "code": "PAM"
      },
      {
        "name": "Pangasinan",
        "code": "PAN"
      },
      {
        "name": "Palawan",
        "code": "PLW"
      },
      {
        "name": "Quezon",
        "code": "QUE"
      },
      {
        "name": "Quirino",
        "code": "QUI"
      },
      {
        "name": "Rizal",
        "code": "RIZ"
      },
      {
        "name": "Romblon",
        "code": "ROM"
      },
      {
        "name": "Sarangani",
        "code": "SAR"
      },
      {
        "name": "South Cotabato",
        "code": "SCO"
      },
      {
        "name": "Siquijor",
        "code": "SIG"
      },
      {
        "name": "Southern Leyte",
        "code": "SLE"
      },
      {
        "name": "Sulu",
        "code": "SLU"
      },
      {
        "name": "Sorsogon",
        "code": "SOR"
      },
      {
        "name": "Sultan Kudarat",
        "code": "SUK"
      },
      {
        "name": "Surigao del Norte",
        "code": "SUN"
      },
      {
        "name": "Surigao del Sur",
        "code": "SUR"
      },
      {
        "name": "Tarlac",
        "code": "TAR"
      },
      {
        "name": "Tawi-Tawi",
        "code": "TAW"
      },
      {
        "name": "Western Samar",
        "code": "WSA"
      },
      {
        "name": "Zamboanga del Norte",
        "code": "ZAN"
      },
      {
        "name": "Zamboanga del Sur",
        "code": "ZAS"
      },
      {
        "name": "Zambales",
        "code": "ZMB"
      },
      {
        "name": "Zamboanga Sibuguey [Zamboanga Sibugay]",
        "code": "ZSI"
      }
    ]
  },
  {
    "name": "Pitcairn",
    "alpha3": "PCN",
    "postal_code_format": "\\APCRN 1ZZ\\Z",
    "subdivisions": []
  },
  {
    "name": "Poland",
    "alpha3": "POL",
    "postal_code_format": "\\A\\d{2}-\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Dolnośląskie",
        "code": "02"
      },
      {
        "name": "Kujawsko-pomorskie",
        "code": "04"
      },
      {
        "name": "Lubelskie",
        "code": "06"
      },
      {
        "name": "Lubuskie",
        "code": "08"
      },
      {
        "name": "Łódzkie",
        "code": "10"
      },
      {
        "name": "Małopolskie",
        "code": "12"
      },
      {
        "name": "Mazowieckie",
        "code": "14"
      },
      {
        "name": "Opolskie",
        "code": "16"
      },
      {
        "name": "Podkarpackie",
        "code": "18"
      },
      {
        "name": "Podlaskie",
        "code": "20"
      },
      {
        "name": "Pomorskie",
        "code": "22"
      },
      {
        "name": "Śląskie",
        "code": "24"
      },
      {
        "name": "Świętokrzyskie",
        "code": "26"
      },
      {
        "name": "Warmińsko-mazurskie",
        "code": "28"
      },
      {
        "name": "Wielkopolskie",
        "code": "30"
      },
      {
        "name": "Zachodniopomorskie",
        "code": "32"
      }
    ]
  },
  {
    "name": "Portugal",
    "alpha3": "PRT",
    "postal_code_format": "\\A\\d{4}-\\d{3}\\Z",
    "subdivisions": [
      {
        "name": "Aveiro",
        "code": "01"
      },
      {
        "name": "Beja",
        "code": "02"
      },
      {
        "name": "Braga",
        "code": "03"
      },
      {
        "name": "Bragança",
        "code": "04"
      },
      {
        "name": "Castelo Branco",
        "code": "05"
      },
      {
        "name": "Coimbra",
        "code": "06"
      },
      {
        "name": "Évora",
        "code": "07"
      },
      {
        "name": "Faro",
        "code": "08"
      },
      {
        "name": "Guarda",
        "code": "09"
      },
      {
        "name": "Leiria",
        "code": "10"
      },
      {
        "name": "Lisboa",
        "code": "11"
      },
      {
        "name": "Portalegre",
        "code": "12"
      },
      {
        "name": "Porto",
        "code": "13"
      },
      {
        "name": "Santarém",
        "code": "14"
      },
      {
        "name": "Setúbal",
        "code": "15"
      },
      {
        "name": "Viana do Castelo",
        "code": "16"
      },
      {
        "name": "Vila Real",
        "code": "17"
      },
      {
        "name": "Viseu",
        "code": "18"
      },
      {
        "name": "Açores",
        "code": "20"
      },
      {
        "name": "Madeira",
        "code": "30"
      }
    ]
  },
  {
    "name": "Puerto Rico",
    "alpha3": "PRI",
    "postal_code_format": "\\A(00[679]\\d{2})(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": []
  },
  {
    "name": "Qatar",
    "alpha3": "QAT",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Ad Dawhah",
        "code": "DA"
      },
      {
        "name": "Al Khawr",
        "code": "KH"
      },
      {
        "name": "Madinat ash Shamal",
        "code": "MS"
      },
      {
        "name": "Ar Rayyan",
        "code": "RA"
      },
      {
        "name": "الشحانية",
        "code": "SH"
      },
      {
        "name": "Umm Salal",
        "code": "US"
      },
      {
        "name": "Al Wakrah",
        "code": "WA"
      },
      {
        "name": "بلدية الضعاين",
        "code": "ZA"
      }
    ]
  },
  {
    "name": "Réunion",
    "alpha3": "REU",
    "postal_code_format": "\\A9[78]4\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Romania",
    "alpha3": "ROU",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Alba",
        "code": "AB"
      },
      {
        "name": "Arges",
        "code": "AG"
      },
      {
        "name": "Arad",
        "code": "AR"
      },
      {
        "name": "Bucuresti",
        "code": "B"
      },
      {
        "name": "Bacau",
        "code": "BC"
      },
      {
        "name": "Bihor",
        "code": "BH"
      },
      {
        "name": "Bistrita-Nasaud",
        "code": "BN"
      },
      {
        "name": "Braila",
        "code": "BR"
      },
      {
        "name": "Botosani",
        "code": "BT"
      },
      {
        "name": "Brasov",
        "code": "BV"
      },
      {
        "name": "Buzau",
        "code": "BZ"
      },
      {
        "name": "Cluj",
        "code": "CJ"
      },
      {
        "name": "Calarasi",
        "code": "CL"
      },
      {
        "name": "Caras-Severin",
        "code": "CS"
      },
      {
        "name": "Constanta",
        "code": "CT"
      },
      {
        "name": "Covasna",
        "code": "CV"
      },
      {
        "name": "Dâmbovita",
        "code": "DB"
      },
      {
        "name": "Dolj",
        "code": "DJ"
      },
      {
        "name": "Gorj",
        "code": "GJ"
      },
      {
        "name": "Galati",
        "code": "GL"
      },
      {
        "name": "Giurgiu",
        "code": "GR"
      },
      {
        "name": "Hunedoara",
        "code": "HD"
      },
      {
        "name": "Harghita",
        "code": "HR"
      },
      {
        "name": "Ilfov",
        "code": "IF"
      },
      {
        "name": "Ialomita",
        "code": "IL"
      },
      {
        "name": "Iasi",
        "code": "IS"
      },
      {
        "name": "Mehedinti",
        "code": "MH"
      },
      {
        "name": "Maramures",
        "code": "MM"
      },
      {
        "name": "Mures",
        "code": "MS"
      },
      {
        "name": "Neamt",
        "code": "NT"
      },
      {
        "name": "Olt",
        "code": "OT"
      },
      {
        "name": "Prahova",
        "code": "PH"
      },
      {
        "name": "Sibiu",
        "code": "SB"
      },
      {
        "name": "Salaj",
        "code": "SJ"
      },
      {
        "name": "Satu Mare",
        "code": "SM"
      },
      {
        "name": "Suceava",
        "code": "SV"
      },
      {
        "name": "Tulcea",
        "code": "TL"
      },
      {
        "name": "Timis",
        "code": "TM"
      },
      {
        "name": "Teleorman",
        "code": "TR"
      },
      {
        "name": "Vâlcea",
        "code": "VL"
      },
      {
        "name": "Vrancea",
        "code": "VN"
      },
      {
        "name": "Vaslui",
        "code": "VS"
      }
    ]
  },
  {
    "name": "Russian Federation",
    "alpha3": "RUS",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Adygeya, Respublika",
        "code": "AD"
      },
      {
        "name": "Altay, Respublika",
        "code": "AL"
      },
      {
        "name": "Altayskiy kray",
        "code": "ALT"
      },
      {
        "name": "Amurskaya oblast'",
        "code": "AMU"
      },
      {
        "name": "Arkhangel'skaya oblast'",
        "code": "ARK"
      },
      {
        "name": "Astrakhanskaya oblast'",
        "code": "AST"
      },
      {
        "name": "Bashkortostan, Respublika",
        "code": "BA"
      },
      {
        "name": "Belgorodskaya oblast'",
        "code": "BEL"
      },
      {
        "name": "Bryanskaya oblast'",
        "code": "BRY"
      },
      {
        "name": "Buryatiya, Respublika",
        "code": "BU"
      },
      {
        "name": "Chechenskaya Respublika",
        "code": "CE"
      },
      {
        "name": "Chelyabinskaya oblast'",
        "code": "CHE"
      },
      {
        "name": "Chukotskiy avtonomnyy okrug",
        "code": "CHU"
      },
      {
        "name": "Chuvashskaya Respublika",
        "code": "CU"
      },
      {
        "name": "Dagestan, Respublika",
        "code": "DA"
      },
      {
        "name": "Ingushskaya Respublika [Respublika Ingushetiya]",
        "code": "IN"
      },
      {
        "name": "Irkutskaya oblast'",
        "code": "IRK"
      },
      {
        "name": "Ivanovskaya oblast'",
        "code": "IVA"
      },
      {
        "name": "Kamchatskaya oblast'",
        "code": "KAM"
      },
      {
        "name": "Kabardino-Balkarskaya Respublika",
        "code": "KB"
      },
      {
        "name": "Karachayevo-Cherkesskaya Respublika",
        "code": "KC"
      },
      {
        "name": "Krasnodarskiy kray",
        "code": "KDA"
      },
      {
        "name": "Kemerovskaya oblast'",
        "code": "KEM"
      },
      {
        "name": "Kaliningradskaya oblast'",
        "code": "KGD"
      },
      {
        "name": "Kurganskaya oblast'",
        "code": "KGN"
      },
      {
        "name": "Khabarovskiy kray",
        "code": "KHA"
      },
      {
        "name": "Khanty-Mansiyskiy avtonomnyy okrug [Yugra]",
        "code": "KHM"
      },
      {
        "name": "Kirovskaya oblast'",
        "code": "KIR"
      },
      {
        "name": "Khakasiya, Respublika",
        "code": "KK"
      },
      {
        "name": "Kalmykiya, Respublika",
        "code": "KL"
      },
      {
        "name": "Kaluzhskaya oblast'",
        "code": "KLU"
      },
      {
        "name": "Komi, Respublika",
        "code": "KO"
      },
      {
        "name": "Kostromskaya oblast'",
        "code": "KOS"
      },
      {
        "name": "Kareliya, Respublika",
        "code": "KR"
      },
      {
        "name": "Kurskaya oblast'",
        "code": "KRS"
      },
      {
        "name": "Krasnoyarskiy kray",
        "code": "KYA"
      },
      {
        "name": "Leningradskaya oblast'",
        "code": "LEN"
      },
      {
        "name": "Lipetskaya oblast'",
        "code": "LIP"
      },
      {
        "name": "Magadanskaya oblast'",
        "code": "MAG"
      },
      {
        "name": "Mariy El, Respublika",
        "code": "ME"
      },
      {
        "name": "Mordoviya, Respublika",
        "code": "MO"
      },
      {
        "name": "Moskovskaya oblast'",
        "code": "MOS"
      },
      {
        "name": "Moskva",
        "code": "MOW"
      },
      {
        "name": "Murmanskaya oblast'",
        "code": "MUR"
      },
      {
        "name": "Nenetskiy avtonomnyy okrug",
        "code": "NEN"
      },
      {
        "name": "Novgorodskaya oblast'",
        "code": "NGR"
      },
      {
        "name": "Nizhegorodskaya oblast'",
        "code": "NIZ"
      },
      {
        "name": "Novosibirskaya oblast'",
        "code": "NVS"
      },
      {
        "name": "Omskaya oblast'",
        "code": "OMS"
      },
      {
        "name": "Orenburgskaya oblast'",
        "code": "ORE"
      },
      {
        "name": "Orlovskaya oblast'",
        "code": "ORL"
      },
      {
        "name": "Perm",
        "code": "PER"
      },
      {
        "name": "Penzenskaya oblast'",
        "code": "PNZ"
      },
      {
        "name": "Primorskiy kray",
        "code": "PRI"
      },
      {
        "name": "Pskovskaya oblast'",
        "code": "PSK"
      },
      {
        "name": "Rostovskaya oblast'",
        "code": "ROS"
      },
      {
        "name": "Ryazanskaya oblast'",
        "code": "RYA"
      },
      {
        "name": "Sakha, Respublika [Yakutiya]",
        "code": "SA"
      },
      {
        "name": "Sakhalinskaya oblast'",
        "code": "SAK"
      },
      {
        "name": "Samarskaya oblast'",
        "code": "SAM"
      },
      {
        "name": "Saratovskaya oblast'",
        "code": "SAR"
      },
      {
        "name": "Severnaya Osetiya, Respublika [Alaniya] [Respublika Severnaya Osetiya-Alaniya]",
        "code": "SE"
      },
      {
        "name": "Smolenskaya oblast'",
        "code": "SMO"
      },
      {
        "name": "Sankt-Peterburg",
        "code": "SPE"
      },
      {
        "name": "Stavropol'skiy kray",
        "code": "STA"
      },
      {
        "name": "Sverdlovskaya oblast'",
        "code": "SVE"
      },
      {
        "name": "Tatarstan, Respublika",
        "code": "TA"
      },
      {
        "name": "Tambovskaya oblast'",
        "code": "TAM"
      },
      {
        "name": "Tomskaya oblast'",
        "code": "TOM"
      },
      {
        "name": "Tul'skaya oblast'",
        "code": "TUL"
      },
      {
        "name": "Tverskaya oblast'",
        "code": "TVE"
      },
      {
        "name": "Tyva, Respublika [Tuva]",
        "code": "TY"
      },
      {
        "name": "Tyumenskaya oblast'",
        "code": "TYU"
      },
      {
        "name": "Udmurtskaya Respublika",
        "code": "UD"
      },
      {
        "name": "Ul'yanovskaya oblast'",
        "code": "ULY"
      },
      {
        "name": "Volgogradskaya oblast'",
        "code": "VGG"
      },
      {
        "name": "Vladimirskaya oblast'",
        "code": "VLA"
      },
      {
        "name": "Vologodskaya oblast'",
        "code": "VLG"
      },
      {
        "name": "Voronezhskaya oblast'",
        "code": "VOR"
      },
      {
        "name": "Yamalo-Nenetskiy avtonomnyy okrug",
        "code": "YAN"
      },
      {
        "name": "Yaroslavskaya oblast'",
        "code": "YAR"
      },
      {
        "name": "Yevreyskaya avtonomnaya oblast'",
        "code": "YEV"
      },
      {
        "name": "Zabaykal'skij kray",
        "code": "ZAB"
      }
    ]
  },
  {
    "name": "Rwanda",
    "alpha3": "RWA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Ville de Kigali",
        "code": "01"
      },
      {
        "name": "Est",
        "code": "02"
      },
      {
        "name": "Nord",
        "code": "03"
      },
      {
        "name": "Ouest",
        "code": "04"
      },
      {
        "name": "Sud",
        "code": "05"
      }
    ]
  },
  {
    "name": "Saint Barthélemy",
    "alpha3": "BLM",
    "postal_code_format": "\\A9[78][01]\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "alpha3": "SHN",
    "postal_code_format": "\\A(?:ASCN|STHL) 1ZZ\\Z",
    "subdivisions": [
      {
        "name": "Ascension",
        "code": "AC"
      },
      {
        "name": "Saint Helena",
        "code": "HL"
      },
      {
        "name": "Tristan da Cunha",
        "code": "TA"
      }
    ]
  },
  {
    "name": "Saint Kitts and Nevis",
    "alpha3": "KNA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Christ Church Nichola Town",
        "code": "01"
      },
      {
        "name": "Saint Anne Sandy Point",
        "code": "02"
      },
      {
        "name": "Saint George Basseterre",
        "code": "03"
      },
      {
        "name": "Saint George Gingerland",
        "code": "04"
      },
      {
        "name": "Saint James Windward",
        "code": "05"
      },
      {
        "name": "Saint John Capisterre",
        "code": "06"
      },
      {
        "name": "Saint John Figtree",
        "code": "07"
      },
      {
        "name": "Saint Mary Cayon",
        "code": "08"
      },
      {
        "name": "Saint Paul Capisterre",
        "code": "09"
      },
      {
        "name": "Saint Paul Charlestown",
        "code": "10"
      },
      {
        "name": "Saint Peter Basseterre",
        "code": "11"
      },
      {
        "name": "Saint Thomas Lowland",
        "code": "12"
      },
      {
        "name": "Saint Thomas Middle Island",
        "code": "13"
      },
      {
        "name": "Trinity Palmetto Point",
        "code": "15"
      },
      {
        "name": "Saint Kitts",
        "code": "K"
      },
      {
        "name": "Nevis",
        "code": "N"
      }
    ]
  },
  {
    "name": "Saint Lucia",
    "alpha3": "LCA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Anse la Raye",
        "code": "01"
      },
      {
        "name": "Castries",
        "code": "02"
      },
      {
        "name": "Choiseul",
        "code": "03"
      },
      {
        "name": "Dennery",
        "code": "05"
      },
      {
        "name": "Gros Islet",
        "code": "06"
      },
      {
        "name": "Laborie",
        "code": "07"
      },
      {
        "name": "Micoud",
        "code": "08"
      },
      {
        "name": "Soufrière",
        "code": "10"
      },
      {
        "name": "Vieux Fort",
        "code": "11"
      },
      {
        "name": "Canaries",
        "code": "12"
      }
    ]
  },
  {
    "name": "Saint Martin (French part)",
    "alpha3": "MAF",
    "postal_code_format": "\\A9[78][01]\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Saint Pierre and Miquelon",
    "alpha3": "SPM",
    "postal_code_format": "\\A9[78]5\\d{2}\\Z",
    "subdivisions": []
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "alpha3": "VCT",
    "postal_code_format": "\\AVC\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Charlotte",
        "code": "01"
      },
      {
        "name": "Saint Andrew",
        "code": "02"
      },
      {
        "name": "Saint David",
        "code": "03"
      },
      {
        "name": "Saint George",
        "code": "04"
      },
      {
        "name": "Saint Patrick",
        "code": "05"
      },
      {
        "name": "Grenadines",
        "code": "06"
      }
    ]
  },
  {
    "name": "Samoa",
    "alpha3": "WSM",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "A'ana",
        "code": "AA"
      },
      {
        "name": "Aiga-i-le-Tai",
        "code": "AL"
      },
      {
        "name": "Atua",
        "code": "AT"
      },
      {
        "name": "Fa'asaleleaga",
        "code": "FA"
      },
      {
        "name": "Gaga'emauga",
        "code": "GE"
      },
      {
        "name": "Gagaifomauga",
        "code": "GI"
      },
      {
        "name": "Palauli",
        "code": "PA"
      },
      {
        "name": "Satupa'itea",
        "code": "SA"
      },
      {
        "name": "Tuamasaga",
        "code": "TU"
      },
      {
        "name": "Va'a-o-Fonoti",
        "code": "VF"
      },
      {
        "name": "Vaisigano",
        "code": "VS"
      }
    ]
  },
  {
    "name": "San Marino",
    "alpha3": "SMR",
    "postal_code_format": "\\A4789\\d\\Z",
    "subdivisions": [
      {
        "name": "Acquaviva",
        "code": "01"
      },
      {
        "name": "Chiesanuova",
        "code": "02"
      },
      {
        "name": "Domagnano",
        "code": "03"
      },
      {
        "name": "Faetano",
        "code": "04"
      },
      {
        "name": "Fiorentino",
        "code": "05"
      },
      {
        "name": "Borgo Maggiore",
        "code": "06"
      },
      {
        "name": "San Marino",
        "code": "07"
      },
      {
        "name": "Montegiardino",
        "code": "08"
      },
      {
        "name": "Serravalle",
        "code": "09"
      }
    ]
  },
  {
    "name": "Sao Tome and Principe",
    "alpha3": "STP",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Água Grande",
        "code": "01"
      },
      {
        "name": "Cantagalo",
        "code": "02"
      },
      {
        "name": "Caué",
        "code": "03"
      },
      {
        "name": "Lembá",
        "code": "04"
      },
      {
        "name": "Lobata",
        "code": "05"
      },
      {
        "name": "Mé-Zóchi",
        "code": "06"
      },
      {
        "name": "Príncipe",
        "code": "P"
      }
    ]
  },
  {
    "name": "Saudi Arabia",
    "alpha3": "SAU",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Ar Riyāḑ",
        "code": "01"
      },
      {
        "name": "Makkah al Mukarramah",
        "code": "02"
      },
      {
        "name": "Al Madinah",
        "code": "03"
      },
      {
        "name": "Ash Sharqiyah",
        "code": "04"
      },
      {
        "name": "Al Qasim",
        "code": "05"
      },
      {
        "name": "Ḩā'il",
        "code": "06"
      },
      {
        "name": "Tabūk",
        "code": "07"
      },
      {
        "name": "Al Ḩudūd ash Shamālīyah",
        "code": "08"
      },
      {
        "name": "Jāzān",
        "code": "09"
      },
      {
        "name": "Najrān",
        "code": "10"
      },
      {
        "name": "Al Bāḩah",
        "code": "11"
      },
      {
        "name": "Al Jawf",
        "code": "12"
      },
      {
        "name": "'Asīr",
        "code": "14"
      }
    ]
  },
  {
    "name": "Senegal",
    "alpha3": "SEN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Diourbel",
        "code": "DB"
      },
      {
        "name": "Dakar",
        "code": "DK"
      },
      {
        "name": "Fatick",
        "code": "FK"
      },
      {
        "name": "Kaffrine",
        "code": "KA"
      },
      {
        "name": "Kolda",
        "code": "KD"
      },
      {
        "name": "Kédougou",
        "code": "KE"
      },
      {
        "name": "Kaolack",
        "code": "KL"
      },
      {
        "name": "Louga",
        "code": "LG"
      },
      {
        "name": "Matam",
        "code": "MT"
      },
      {
        "name": "Sédhiou",
        "code": "SE"
      },
      {
        "name": "Saint-Louis",
        "code": "SL"
      },
      {
        "name": "Tambacounda",
        "code": "TC"
      },
      {
        "name": "Thiès",
        "code": "TH"
      },
      {
        "name": "Ziguinchor",
        "code": "ZG"
      }
    ]
  },
  {
    "name": "Serbia",
    "alpha3": "SRB",
    "postal_code_format": "\\A\\d{5,6}\\Z",
    "subdivisions": [
      {
        "name": "Belgrade",
        "code": "00"
      },
      {
        "name": "Severna Backa",
        "code": "01"
      },
      {
        "name": "Srednji Banat",
        "code": "02"
      },
      {
        "name": "Severni Banat",
        "code": "03"
      },
      {
        "name": "Južni Banat",
        "code": "04"
      },
      {
        "name": "Zapadna Backa",
        "code": "05"
      },
      {
        "name": "Južna Backa",
        "code": "06"
      },
      {
        "name": "Srem",
        "code": "07"
      },
      {
        "name": "Macva",
        "code": "08"
      },
      {
        "name": "Kolubara",
        "code": "09"
      },
      {
        "name": "Podunavlje",
        "code": "10"
      },
      {
        "name": "Branicevo",
        "code": "11"
      },
      {
        "name": "Šumadija",
        "code": "12"
      },
      {
        "name": "Pomoravlje",
        "code": "13"
      },
      {
        "name": "Bor",
        "code": "14"
      },
      {
        "name": "Zajecar",
        "code": "15"
      },
      {
        "name": "Zlatibor",
        "code": "16"
      },
      {
        "name": "Moravica",
        "code": "17"
      },
      {
        "name": "Raška",
        "code": "18"
      },
      {
        "name": "Rasina",
        "code": "19"
      },
      {
        "name": "Nišava",
        "code": "20"
      },
      {
        "name": "Toplica",
        "code": "21"
      },
      {
        "name": "Pirot",
        "code": "22"
      },
      {
        "name": "Jablanica",
        "code": "23"
      },
      {
        "name": "Pcinja",
        "code": "24"
      },
      {
        "name": "Kosovo",
        "code": "25"
      },
      {
        "name": "Pec´",
        "code": "26"
      },
      {
        "name": "Prizren",
        "code": "27"
      },
      {
        "name": "Kosovska Mitrovica",
        "code": "28"
      },
      {
        "name": "Kosovo-Pomoravlje",
        "code": "29"
      },
      {
        "name": "Косово и Метохија",
        "code": "KM"
      },
      {
        "name": "Војводина",
        "code": "VO"
      }
    ]
  },
  {
    "name": "Seychelles",
    "alpha3": "SYC",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Anse aux Pins",
        "code": "01"
      },
      {
        "name": "Anse Boileau",
        "code": "02"
      },
      {
        "name": "Anse Étoile",
        "code": "03"
      },
      {
        "name": "Anse Louis",
        "code": "04"
      },
      {
        "name": "Anse Royale",
        "code": "05"
      },
      {
        "name": "Baie Lazare",
        "code": "06"
      },
      {
        "name": "Baie Sainte Anne",
        "code": "07"
      },
      {
        "name": "Beau Vallon",
        "code": "08"
      },
      {
        "name": "Bel Air",
        "code": "09"
      },
      {
        "name": "Bel Ombre",
        "code": "10"
      },
      {
        "name": "Cascade",
        "code": "11"
      },
      {
        "name": "Glacis",
        "code": "12"
      },
      {
        "name": "Grand' Anse (Mahé)",
        "code": "13"
      },
      {
        "name": "Grand' Anse (Praslin)",
        "code": "14"
      },
      {
        "name": "La Digue",
        "code": "15"
      },
      {
        "name": "La Rivière Anglaise",
        "code": "16"
      },
      {
        "name": "Mont Buxton",
        "code": "17"
      },
      {
        "name": "Mont Fleuri",
        "code": "18"
      },
      {
        "name": "Plaisance",
        "code": "19"
      },
      {
        "name": "Pointe La Rue",
        "code": "20"
      },
      {
        "name": "Port Glaud",
        "code": "21"
      },
      {
        "name": "Saint Louis",
        "code": "22"
      },
      {
        "name": "Takamaka",
        "code": "23"
      },
      {
        "name": "Les Mamelles",
        "code": "24"
      },
      {
        "name": "Roche Caïman",
        "code": "25"
      },
      {
        "name": "Ile Perseverance I",
        "code": "26"
      },
      {
        "name": "Ile Perseverance II",
        "code": "27"
      }
    ]
  },
  {
    "name": "Sierra Leone",
    "alpha3": "SLE",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Eastern",
        "code": "E"
      },
      {
        "name": "Northern",
        "code": "N"
      },
      {
        "name": "North Western",
        "code": "NW"
      },
      {
        "name": "Southern",
        "code": "S"
      },
      {
        "name": "Western Area (Freetown)",
        "code": "W"
      }
    ]
  },
  {
    "name": "Singapore",
    "alpha3": "SGP",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Central Singapore",
        "code": "01"
      },
      {
        "name": "North East",
        "code": "02"
      },
      {
        "name": "North West",
        "code": "03"
      },
      {
        "name": "South East",
        "code": "04"
      },
      {
        "name": "South West",
        "code": "05"
      }
    ]
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "alpha3": "SXM",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Slovakia",
    "alpha3": "SVK",
    "postal_code_format": "\\A\\d{3} ?\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Banskobystrický kraj",
        "code": "BC"
      },
      {
        "name": "Bratislavský kraj",
        "code": "BL"
      },
      {
        "name": "Košický kraj",
        "code": "KI"
      },
      {
        "name": "Nitriansky kraj",
        "code": "NI"
      },
      {
        "name": "Prešovský kraj",
        "code": "PV"
      },
      {
        "name": "Trnavský kraj",
        "code": "TA"
      },
      {
        "name": "Trenciansky kraj",
        "code": "TC"
      },
      {
        "name": "Žilinský kraj",
        "code": "ZI"
      }
    ]
  },
  {
    "name": "Slovenia",
    "alpha3": "SVN",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Ajdovšcina",
        "code": "001"
      },
      {
        "name": "Beltinci",
        "code": "002"
      },
      {
        "name": "Bled",
        "code": "003"
      },
      {
        "name": "Bohinj",
        "code": "004"
      },
      {
        "name": "Borovnica",
        "code": "005"
      },
      {
        "name": "Bovec",
        "code": "006"
      },
      {
        "name": "Brda",
        "code": "007"
      },
      {
        "name": "Brezovica",
        "code": "008"
      },
      {
        "name": "Brežice",
        "code": "009"
      },
      {
        "name": "Tišina",
        "code": "010"
      },
      {
        "name": "Celje",
        "code": "011"
      },
      {
        "name": "Cerklje na Gorenjskem",
        "code": "012"
      },
      {
        "name": "Cerknica",
        "code": "013"
      },
      {
        "name": "Cerkno",
        "code": "014"
      },
      {
        "name": "Crenšovci",
        "code": "015"
      },
      {
        "name": "Crna na Koroškem",
        "code": "016"
      },
      {
        "name": "Crnomelj",
        "code": "017"
      },
      {
        "name": "Destrnik",
        "code": "018"
      },
      {
        "name": "Divaca",
        "code": "019"
      },
      {
        "name": "Dobrepolje",
        "code": "020"
      },
      {
        "name": "Dobrova-Polhov Gradec",
        "code": "021"
      },
      {
        "name": "Dol pri Ljubljani",
        "code": "022"
      },
      {
        "name": "Domžale",
        "code": "023"
      },
      {
        "name": "Dornava",
        "code": "024"
      },
      {
        "name": "Dravograd",
        "code": "025"
      },
      {
        "name": "Duplek",
        "code": "026"
      },
      {
        "name": "Gorenja vas-Poljane",
        "code": "027"
      },
      {
        "name": "Gorišnica",
        "code": "028"
      },
      {
        "name": "Gornja Radgona",
        "code": "029"
      },
      {
        "name": "Gornji Grad",
        "code": "030"
      },
      {
        "name": "Gornji Petrovci",
        "code": "031"
      },
      {
        "name": "Grosuplje",
        "code": "032"
      },
      {
        "name": "Šalovci",
        "code": "033"
      },
      {
        "name": "Hrastnik",
        "code": "034"
      },
      {
        "name": "Hrpelje-Kozina",
        "code": "035"
      },
      {
        "name": "Idrija",
        "code": "036"
      },
      {
        "name": "Ig",
        "code": "037"
      },
      {
        "name": "Ilirska Bistrica",
        "code": "038"
      },
      {
        "name": "Ivancna Gorica",
        "code": "039"
      },
      {
        "name": "Izola/Isola",
        "code": "040"
      },
      {
        "name": "Jesenice",
        "code": "041"
      },
      {
        "name": "Juršinci",
        "code": "042"
      },
      {
        "name": "Kamnik",
        "code": "043"
      },
      {
        "name": "Kanal",
        "code": "044"
      },
      {
        "name": "Kidricevo",
        "code": "045"
      },
      {
        "name": "Kobarid",
        "code": "046"
      },
      {
        "name": "Kobilje",
        "code": "047"
      },
      {
        "name": "Kocevje",
        "code": "048"
      },
      {
        "name": "Komen",
        "code": "049"
      },
      {
        "name": "Koper/Capodistria",
        "code": "050"
      },
      {
        "name": "Kozje",
        "code": "051"
      },
      {
        "name": "Kranj",
        "code": "052"
      },
      {
        "name": "Kranjska Gora",
        "code": "053"
      },
      {
        "name": "Krško",
        "code": "054"
      },
      {
        "name": "Kungota",
        "code": "055"
      },
      {
        "name": "Kuzma",
        "code": "056"
      },
      {
        "name": "Laško",
        "code": "057"
      },
      {
        "name": "Lenart",
        "code": "058"
      },
      {
        "name": "Lendava/Lendva",
        "code": "059"
      },
      {
        "name": "Litija",
        "code": "060"
      },
      {
        "name": "Ljubljana",
        "code": "061"
      },
      {
        "name": "Ljubno",
        "code": "062"
      },
      {
        "name": "Ljutomer",
        "code": "063"
      },
      {
        "name": "Logatec",
        "code": "064"
      },
      {
        "name": "Loška dolina",
        "code": "065"
      },
      {
        "name": "Loški Potok",
        "code": "066"
      },
      {
        "name": "Luce",
        "code": "067"
      },
      {
        "name": "Lukovica",
        "code": "068"
      },
      {
        "name": "Majšperk",
        "code": "069"
      },
      {
        "name": "Maribor",
        "code": "070"
      },
      {
        "name": "Medvode",
        "code": "071"
      },
      {
        "name": "Mengeš",
        "code": "072"
      },
      {
        "name": "Metlika",
        "code": "073"
      },
      {
        "name": "Mežica",
        "code": "074"
      },
      {
        "name": "Miren-Kostanjevica",
        "code": "075"
      },
      {
        "name": "Mislinja",
        "code": "076"
      },
      {
        "name": "Moravce",
        "code": "077"
      },
      {
        "name": "Moravske Toplice",
        "code": "078"
      },
      {
        "name": "Mozirje",
        "code": "079"
      },
      {
        "name": "Murska Sobota",
        "code": "080"
      },
      {
        "name": "Muta",
        "code": "081"
      },
      {
        "name": "Naklo",
        "code": "082"
      },
      {
        "name": "Nazarje",
        "code": "083"
      },
      {
        "name": "Nova Gorica",
        "code": "084"
      },
      {
        "name": "Novo mesto",
        "code": "085"
      },
      {
        "name": "Odranci",
        "code": "086"
      },
      {
        "name": "Ormož",
        "code": "087"
      },
      {
        "name": "Osilnica",
        "code": "088"
      },
      {
        "name": "Pesnica",
        "code": "089"
      },
      {
        "name": "Piran/Pirano",
        "code": "090"
      },
      {
        "name": "Pivka",
        "code": "091"
      },
      {
        "name": "Podcetrtek",
        "code": "092"
      },
      {
        "name": "Podvelka",
        "code": "093"
      },
      {
        "name": "Postojna",
        "code": "094"
      },
      {
        "name": "Preddvor",
        "code": "095"
      },
      {
        "name": "Ptuj",
        "code": "096"
      },
      {
        "name": "Puconci",
        "code": "097"
      },
      {
        "name": "Race-Fram",
        "code": "098"
      },
      {
        "name": "Radece",
        "code": "099"
      },
      {
        "name": "Radenci",
        "code": "100"
      },
      {
        "name": "Radlje ob Dravi",
        "code": "101"
      },
      {
        "name": "Radovljica",
        "code": "102"
      },
      {
        "name": "Ravne na Koroškem",
        "code": "103"
      },
      {
        "name": "Ribnica",
        "code": "104"
      },
      {
        "name": "Rogašovci",
        "code": "105"
      },
      {
        "name": "Rogaška Slatina",
        "code": "106"
      },
      {
        "name": "Rogatec",
        "code": "107"
      },
      {
        "name": "Ruše",
        "code": "108"
      },
      {
        "name": "Semic",
        "code": "109"
      },
      {
        "name": "Sevnica",
        "code": "110"
      },
      {
        "name": "Sežana",
        "code": "111"
      },
      {
        "name": "Slovenj Gradec",
        "code": "112"
      },
      {
        "name": "Slovenska Bistrica",
        "code": "113"
      },
      {
        "name": "Slovenske Konjice",
        "code": "114"
      },
      {
        "name": "Starše",
        "code": "115"
      },
      {
        "name": "Sveti Jurij",
        "code": "116"
      },
      {
        "name": "Šencur",
        "code": "117"
      },
      {
        "name": "Šentilj",
        "code": "118"
      },
      {
        "name": "Šentjernej",
        "code": "119"
      },
      {
        "name": "Šentjur pri Celju",
        "code": "120"
      },
      {
        "name": "Škocjan",
        "code": "121"
      },
      {
        "name": "Škofja Loka",
        "code": "122"
      },
      {
        "name": "Škofljica",
        "code": "123"
      },
      {
        "name": "Šmarje pri Jelšah",
        "code": "124"
      },
      {
        "name": "Šmartno ob Paki",
        "code": "125"
      },
      {
        "name": "Šoštanj",
        "code": "126"
      },
      {
        "name": "Štore",
        "code": "127"
      },
      {
        "name": "Tolmin",
        "code": "128"
      },
      {
        "name": "Trbovlje",
        "code": "129"
      },
      {
        "name": "Trebnje",
        "code": "130"
      },
      {
        "name": "Tržic",
        "code": "131"
      },
      {
        "name": "Turnišce",
        "code": "132"
      },
      {
        "name": "Velenje",
        "code": "133"
      },
      {
        "name": "Velike Lašce",
        "code": "134"
      },
      {
        "name": "Videm",
        "code": "135"
      },
      {
        "name": "Vipava",
        "code": "136"
      },
      {
        "name": "Vitanje",
        "code": "137"
      },
      {
        "name": "Vodice",
        "code": "138"
      },
      {
        "name": "Vojnik",
        "code": "139"
      },
      {
        "name": "Vrhnika",
        "code": "140"
      },
      {
        "name": "Vuzenica",
        "code": "141"
      },
      {
        "name": "Zagorje ob Savi",
        "code": "142"
      },
      {
        "name": "Zavrc",
        "code": "143"
      },
      {
        "name": "Zrece",
        "code": "144"
      },
      {
        "name": "Železniki",
        "code": "146"
      },
      {
        "name": "Žiri",
        "code": "147"
      },
      {
        "name": "Benedikt",
        "code": "148"
      },
      {
        "name": "Bistrica ob Sotli",
        "code": "149"
      },
      {
        "name": "Bloke",
        "code": "150"
      },
      {
        "name": "Braslovce",
        "code": "151"
      },
      {
        "name": "Cankova",
        "code": "152"
      },
      {
        "name": "Cerkvenjak",
        "code": "153"
      },
      {
        "name": "Dobje",
        "code": "154"
      },
      {
        "name": "Dobrna",
        "code": "155"
      },
      {
        "name": "Dobrovnik/Dobronak",
        "code": "156"
      },
      {
        "name": "Dolenjske Toplice",
        "code": "157"
      },
      {
        "name": "Grad",
        "code": "158"
      },
      {
        "name": "Hajdina",
        "code": "159"
      },
      {
        "name": "Hoce-Slivnica",
        "code": "160"
      },
      {
        "name": "Hodoš/Hodos",
        "code": "161"
      },
      {
        "name": "Horjul",
        "code": "162"
      },
      {
        "name": "Jezersko",
        "code": "163"
      },
      {
        "name": "Komenda",
        "code": "164"
      },
      {
        "name": "Kostel",
        "code": "165"
      },
      {
        "name": "Križevci",
        "code": "166"
      },
      {
        "name": "Lovrenc na Pohorju",
        "code": "167"
      },
      {
        "name": "Markovci",
        "code": "168"
      },
      {
        "name": "Miklavž na Dravskem polju",
        "code": "169"
      },
      {
        "name": "Mirna Pec",
        "code": "170"
      },
      {
        "name": "Oplotnica",
        "code": "171"
      },
      {
        "name": "Podlehnik",
        "code": "172"
      },
      {
        "name": "Polzela",
        "code": "173"
      },
      {
        "name": "Prebold",
        "code": "174"
      },
      {
        "name": "Prevalje",
        "code": "175"
      },
      {
        "name": "Razkrižje",
        "code": "176"
      },
      {
        "name": "Ribnica na Pohorju",
        "code": "177"
      },
      {
        "name": "Selnica ob Dravi",
        "code": "178"
      },
      {
        "name": "Sodražica",
        "code": "179"
      },
      {
        "name": "Solcava",
        "code": "180"
      },
      {
        "name": "Sveta Ana",
        "code": "181"
      },
      {
        "name": "Sveti Andraž v Slovenskih goricah",
        "code": "182"
      },
      {
        "name": "Šempeter-Vrtojba",
        "code": "183"
      },
      {
        "name": "Tabor",
        "code": "184"
      },
      {
        "name": "Trnovska vas",
        "code": "185"
      },
      {
        "name": "Trzin",
        "code": "186"
      },
      {
        "name": "Velika Polana",
        "code": "187"
      },
      {
        "name": "Veržej",
        "code": "188"
      },
      {
        "name": "Vransko",
        "code": "189"
      },
      {
        "name": "Žalec",
        "code": "190"
      },
      {
        "name": "Žetale",
        "code": "191"
      },
      {
        "name": "Žirovnica",
        "code": "192"
      },
      {
        "name": "Žužemberk",
        "code": "193"
      },
      {
        "name": "Šmartno pri Litiji",
        "code": "194"
      },
      {
        "name": "Občina Apače",
        "code": "195"
      },
      {
        "name": "Občina Cirkulane",
        "code": "196"
      },
      {
        "name": "Občina Kostanjevica na Krki",
        "code": "197"
      },
      {
        "name": "Občina Makole",
        "code": "198"
      },
      {
        "name": "Občina Mokronog - Trebelno",
        "code": "199"
      },
      {
        "name": "Občina Poljčane",
        "code": "200"
      },
      {
        "name": "Občina Renče - Vogrsko",
        "code": "201"
      },
      {
        "name": "Občina Središče ob Dravi",
        "code": "202"
      },
      {
        "name": "Občina Straža",
        "code": "203"
      },
      {
        "name": "Občina Sveta Trojica v Slovenskih goricah",
        "code": "204"
      },
      {
        "name": "Občina Sveti Tomaž",
        "code": "205"
      },
      {
        "name": "Občina Šmarješke Toplice",
        "code": "206"
      },
      {
        "name": "Občina Gorje",
        "code": "207"
      },
      {
        "name": "Občina Log - Dragomer",
        "code": "208"
      },
      {
        "name": "Občina Rečica ob Savinji",
        "code": "209"
      },
      {
        "name": "Občina Sveti Jurij v Slovenskih goricah",
        "code": "210"
      },
      {
        "name": "Občina Šentrupert",
        "code": "211"
      },
      {
        "name": "Mirna",
        "code": "212"
      },
      {
        "name": "Občina Ankaran",
        "code": "213"
      }
    ]
  },
  {
    "name": "Solomon Islands",
    "alpha3": "SLB",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Central",
        "code": "CE"
      },
      {
        "name": "Choiseul",
        "code": "CH"
      },
      {
        "name": "Capital Territory (Honiara)",
        "code": "CT"
      },
      {
        "name": "Guadalcanal",
        "code": "GU"
      },
      {
        "name": "Isabel",
        "code": "IS"
      },
      {
        "name": "Makira",
        "code": "MK"
      },
      {
        "name": "Malaita",
        "code": "ML"
      },
      {
        "name": "Rennell and Bellona",
        "code": "RB"
      },
      {
        "name": "Temotu",
        "code": "TE"
      },
      {
        "name": "Western",
        "code": "WE"
      }
    ]
  },
  {
    "name": "Somalia",
    "alpha3": "SOM",
    "postal_code_format": "\\A[A-Z]{2} ?\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Awdal",
        "code": "AW"
      },
      {
        "name": "Bakool",
        "code": "BK"
      },
      {
        "name": "Banaadir",
        "code": "BN"
      },
      {
        "name": "Bari",
        "code": "BR"
      },
      {
        "name": "Bay",
        "code": "BY"
      },
      {
        "name": "Galguduud",
        "code": "GA"
      },
      {
        "name": "Gedo",
        "code": "GE"
      },
      {
        "name": "Hiiraan",
        "code": "HI"
      },
      {
        "name": "Jubbada Dhexe",
        "code": "JD"
      },
      {
        "name": "Jubbada Hoose",
        "code": "JH"
      },
      {
        "name": "Mudug",
        "code": "MU"
      },
      {
        "name": "Nugaal",
        "code": "NU"
      },
      {
        "name": "Sanaag",
        "code": "SA"
      },
      {
        "name": "Shabeellaha Dhexe",
        "code": "SD"
      },
      {
        "name": "Shabeellaha Hoose",
        "code": "SH"
      },
      {
        "name": "Sool",
        "code": "SO"
      },
      {
        "name": "Togdheer",
        "code": "TO"
      },
      {
        "name": "Woqooyi Galbeed",
        "code": "WO"
      }
    ]
  },
  {
    "name": "South Africa",
    "alpha3": "ZAF",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Eastern Cape",
        "code": "EC"
      },
      {
        "name": "Free State",
        "code": "FS"
      },
      {
        "name": "Gauteng",
        "code": "GP"
      },
      {
        "name": "Limpopo",
        "code": "LP"
      },
      {
        "name": "Mpumalanga",
        "code": "MP"
      },
      {
        "name": "Northern Cape",
        "code": "NC"
      },
      {
        "name": "North-West",
        "code": "NW"
      },
      {
        "name": "Western Cape",
        "code": "WC"
      },
      {
        "name": "KwaZulu-Natal",
        "code": "ZN"
      }
    ]
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "alpha3": "SGS",
    "postal_code_format": "\\ASIQQ 1ZZ\\Z",
    "subdivisions": []
  },
  {
    "name": "South Sudan",
    "alpha3": "SSD",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "شمال بحر الغزال",
        "code": "BN"
      },
      {
        "name": "غرب بحر الغزال",
        "code": "BW"
      },
      {
        "name": "وسط الاستوائية",
        "code": "EC"
      },
      {
        "name": "شرق الاستوائية",
        "code": "EE"
      },
      {
        "name": "غرب الاستوائية",
        "code": "EW"
      },
      {
        "name": "جونقلي",
        "code": "JG"
      },
      {
        "name": "البحيرات",
        "code": "LK"
      },
      {
        "name": "أعالي النيل",
        "code": "NU"
      },
      {
        "name": "الوحدة",
        "code": "UY"
      },
      {
        "name": "واراب",
        "code": "WR"
      }
    ]
  },
  {
    "name": "Spain",
    "alpha3": "ESP",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Alicante/Alacant",
        "code": "A"
      },
      {
        "name": "Albacete",
        "code": "AB"
      },
      {
        "name": "Almería",
        "code": "AL"
      },
      {
        "name": "Andalucía",
        "code": "AN"
      },
      {
        "name": "Aragón",
        "code": "AR"
      },
      {
        "name": "Principado de Asturias",
        "code": "AS"
      },
      {
        "name": "Ávila",
        "code": "AV"
      },
      {
        "name": "Barcelona",
        "code": "B"
      },
      {
        "name": "Badajoz",
        "code": "BA"
      },
      {
        "name": "Bizkaia",
        "code": "BI"
      },
      {
        "name": "Burgos",
        "code": "BU"
      },
      {
        "name": "Coruña, A",
        "code": "C"
      },
      {
        "name": "Cádiz",
        "code": "CA"
      },
      {
        "name": "Cantabria",
        "code": "CB"
      },
      {
        "name": "Cáceres",
        "code": "CC"
      },
      {
        "name": "Ceuta",
        "code": "CE"
      },
      {
        "name": "Castilla y León",
        "code": "CL"
      },
      {
        "name": "Castilla-La Mancha",
        "code": "CM"
      },
      {
        "name": "Canary Islands",
        "code": "CN"
      },
      {
        "name": "Córdoba",
        "code": "CO"
      },
      {
        "name": "Ciudad Real",
        "code": "CR"
      },
      {
        "name": "Castellón/Castelló",
        "code": "CS"
      },
      {
        "name": "Cataluña",
        "code": "CT"
      },
      {
        "name": "Cuenca",
        "code": "CU"
      },
      {
        "name": "Extremadura",
        "code": "EX"
      },
      {
        "name": "Galicia",
        "code": "GA"
      },
      {
        "name": "Palmas, Las",
        "code": "GC"
      },
      {
        "name": "Girona",
        "code": "GI"
      },
      {
        "name": "Granada",
        "code": "GR"
      },
      {
        "name": "Guadalajara",
        "code": "GU"
      },
      {
        "name": "Huelva",
        "code": "H"
      },
      {
        "name": "Huesca",
        "code": "HU"
      },
      {
        "name": "Islas Baleares",
        "code": "IB"
      },
      {
        "name": "Jaén",
        "code": "J"
      },
      {
        "name": "Lleida",
        "code": "L"
      },
      {
        "name": "León",
        "code": "LE"
      },
      {
        "name": "Rioja, La",
        "code": "LO"
      },
      {
        "name": "Lugo",
        "code": "LU"
      },
      {
        "name": "Madrid",
        "code": "M"
      },
      {
        "name": "Málaga",
        "code": "MA"
      },
      {
        "name": "Región de Murcia",
        "code": "MC"
      },
      {
        "name": "Comunidad de Madrid",
        "code": "MD"
      },
      {
        "name": "Melilla",
        "code": "ML"
      },
      {
        "name": "Murcia",
        "code": "MU"
      },
      {
        "name": "Navarra",
        "code": "NA"
      },
      {
        "name": "Navarra",
        "code": "NC"
      },
      {
        "name": "Asturias",
        "code": "O"
      },
      {
        "name": "Ourense",
        "code": "OR"
      },
      {
        "name": "Palencia",
        "code": "P"
      },
      {
        "name": "Balears, Illes",
        "code": "PM"
      },
      {
        "name": "Pontevedra",
        "code": "PO"
      },
      {
        "name": "País Vasco",
        "code": "PV"
      },
      {
        "name": "La Rioja",
        "code": "RI"
      },
      {
        "name": "Cantabria",
        "code": "S"
      },
      {
        "name": "Salamanca",
        "code": "SA"
      },
      {
        "name": "Sevilla",
        "code": "SE"
      },
      {
        "name": "Segovia",
        "code": "SG"
      },
      {
        "name": "Soria",
        "code": "SO"
      },
      {
        "name": "Gipuzkoa",
        "code": "SS"
      },
      {
        "name": "Tarragona",
        "code": "T"
      },
      {
        "name": "Teruel",
        "code": "TE"
      },
      {
        "name": "Santa Cruz de Tenerife",
        "code": "TF"
      },
      {
        "name": "Toledo",
        "code": "TO"
      },
      {
        "name": "Valencia/València",
        "code": "V"
      },
      {
        "name": "Valladolid",
        "code": "VA"
      },
      {
        "name": "Comunidad Valenciana",
        "code": "VC"
      },
      {
        "name": "Araba/Álava",
        "code": "VI"
      },
      {
        "name": "Zaragoza",
        "code": "Z"
      },
      {
        "name": "Zamora",
        "code": "ZA"
      }
    ]
  },
  {
    "name": "Sri Lanka",
    "alpha3": "LKA",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "බස්නාහිර පළාත, ශ් රී ලංකාව",
        "code": "1"
      },
      {
        "name": "Colombo",
        "code": "11"
      },
      {
        "name": "Gampaha",
        "code": "12"
      },
      {
        "name": "Kalutara",
        "code": "13"
      },
      {
        "name": "මධ්‍යම පළාත, ශ්‍රී ලංකාව",
        "code": "2"
      },
      {
        "name": "Kandy",
        "code": "21"
      },
      {
        "name": "Matale",
        "code": "22"
      },
      {
        "name": "Nuwara Eliya",
        "code": "23"
      },
      {
        "name": "දකුණු පළාත",
        "code": "3"
      },
      {
        "name": "Galle",
        "code": "31"
      },
      {
        "name": "Matara",
        "code": "32"
      },
      {
        "name": "Hambantota",
        "code": "33"
      },
      {
        "name": "උතුරු පළාත",
        "code": "4"
      },
      {
        "name": "Jaffna",
        "code": "41"
      },
      {
        "name": "Kilinochchi",
        "code": "42"
      },
      {
        "name": "Mannar",
        "code": "43"
      },
      {
        "name": "Vavuniya",
        "code": "44"
      },
      {
        "name": "Mullaittivu",
        "code": "45"
      },
      {
        "name": "නැගෙනහිර පළාත, ශ් රී ලංකාව",
        "code": "5"
      },
      {
        "name": "Batticaloa",
        "code": "51"
      },
      {
        "name": "Ampara",
        "code": "52"
      },
      {
        "name": "Trincomalee",
        "code": "53"
      },
      {
        "name": "වයඹ පළාත, ශ්‍රී ලංකාව",
        "code": "6"
      },
      {
        "name": "Kurunegala",
        "code": "61"
      },
      {
        "name": "Puttalam",
        "code": "62"
      },
      {
        "name": "උතුරු මැද පළාත",
        "code": "7"
      },
      {
        "name": "Anuradhapura",
        "code": "71"
      },
      {
        "name": "Polonnaruwa",
        "code": "72"
      },
      {
        "name": "ඌව පළාත",
        "code": "8"
      },
      {
        "name": "Badulla",
        "code": "81"
      },
      {
        "name": "Monaragala",
        "code": "82"
      },
      {
        "name": "සබරගමුව පළාත",
        "code": "9"
      },
      {
        "name": "Ratnapura",
        "code": "91"
      },
      {
        "name": "Kegalla",
        "code": "92"
      }
    ]
  },
  {
    "name": "Sudan",
    "alpha3": "SDN",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Wasaţ Dārfūr Zālinjay",
        "code": "DC"
      },
      {
        "name": "Sharq Dārfūr",
        "code": "DE"
      },
      {
        "name": "Shamāl Dārfūr",
        "code": "DN"
      },
      {
        "name": "Janūb Dārfūr",
        "code": "DS"
      },
      {
        "name": "Gharb Dārfūr",
        "code": "DW"
      },
      {
        "name": "Al Qaḑārif",
        "code": "GD"
      },
      {
        "name": "Gharb Kurdufān",
        "code": "GK"
      },
      {
        "name": "Al Jazīrah",
        "code": "GZ"
      },
      {
        "name": "Kassalā",
        "code": "KA"
      },
      {
        "name": "Al Kharţūm",
        "code": "KH"
      },
      {
        "name": "Shiamāl Kurdufān",
        "code": "KN"
      },
      {
        "name": "Janūb Kurdufān",
        "code": "KS"
      },
      {
        "name": "An Nīl al Azraq",
        "code": "NB"
      },
      {
        "name": "Ash Shamālīyah",
        "code": "NO"
      },
      {
        "name": "Nahr an Nīl",
        "code": "NR"
      },
      {
        "name": "An Nīl al Abyaḑ",
        "code": "NW"
      },
      {
        "name": "Al Baḩr al Aḩmar",
        "code": "RS"
      },
      {
        "name": "Sinnār",
        "code": "SI"
      }
    ]
  },
  {
    "name": "Suriname",
    "alpha3": "SUR",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Brokopondo",
        "code": "BR"
      },
      {
        "name": "Commewijne",
        "code": "CM"
      },
      {
        "name": "Coronie",
        "code": "CR"
      },
      {
        "name": "Marowijne",
        "code": "MA"
      },
      {
        "name": "Nickerie",
        "code": "NI"
      },
      {
        "name": "Paramaribo",
        "code": "PM"
      },
      {
        "name": "Para",
        "code": "PR"
      },
      {
        "name": "Saramacca",
        "code": "SA"
      },
      {
        "name": "Sipaliwini",
        "code": "SI"
      },
      {
        "name": "Wanica",
        "code": "WA"
      }
    ]
  },
  {
    "name": "Svalbard and Jan Mayen",
    "alpha3": "SJM",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": []
  },
  {
    "name": "Sweden",
    "alpha3": "SWE",
    "postal_code_format": "\\A\\d{3} ?\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Stockholms län",
        "code": "AB"
      },
      {
        "name": "Västerbottens län",
        "code": "AC"
      },
      {
        "name": "Norrbottens län",
        "code": "BD"
      },
      {
        "name": "Uppsala län",
        "code": "C"
      },
      {
        "name": "Södermanlands län",
        "code": "D"
      },
      {
        "name": "Östergötlands län",
        "code": "E"
      },
      {
        "name": "Jönköpings län",
        "code": "F"
      },
      {
        "name": "Kronobergs län",
        "code": "G"
      },
      {
        "name": "Kalmar län",
        "code": "H"
      },
      {
        "name": "Gotlands län",
        "code": "I"
      },
      {
        "name": "Blekinge län",
        "code": "K"
      },
      {
        "name": "Skåne län",
        "code": "M"
      },
      {
        "name": "Hallands län",
        "code": "N"
      },
      {
        "name": "Västra Götalands län",
        "code": "O"
      },
      {
        "name": "Värmlands län",
        "code": "S"
      },
      {
        "name": "Örebro län",
        "code": "T"
      },
      {
        "name": "Västmanlands län",
        "code": "U"
      },
      {
        "name": "Dalarnas län",
        "code": "W"
      },
      {
        "name": "Gävleborgs län",
        "code": "X"
      },
      {
        "name": "Västernorrlands län",
        "code": "Y"
      },
      {
        "name": "Jämtlands län",
        "code": "Z"
      }
    ]
  },
  {
    "name": "Switzerland",
    "alpha3": "CHE",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Aargau (de)",
        "code": "AG"
      },
      {
        "name": "Appenzell Innerrhoden (de)",
        "code": "AI"
      },
      {
        "name": "Appenzell Ausserrhoden (de)",
        "code": "AR"
      },
      {
        "name": "Bern (de)",
        "code": "BE"
      },
      {
        "name": "Basel-Landschaft (de)",
        "code": "BL"
      },
      {
        "name": "Basel-Stadt (de)",
        "code": "BS"
      },
      {
        "name": "Fribourg (fr)",
        "code": "FR"
      },
      {
        "name": "Genève (fr)",
        "code": "GE"
      },
      {
        "name": "Glarus (de)",
        "code": "GL"
      },
      {
        "name": "Graubünden (de)",
        "code": "GR"
      },
      {
        "name": "Jura (fr)",
        "code": "JU"
      },
      {
        "name": "Luzern (de)",
        "code": "LU"
      },
      {
        "name": "Neuchâtel (fr)",
        "code": "NE"
      },
      {
        "name": "Nidwalden (de)",
        "code": "NW"
      },
      {
        "name": "Obwalden (de)",
        "code": "OW"
      },
      {
        "name": "Sankt Gallen (de)",
        "code": "SG"
      },
      {
        "name": "Schaffhausen (de)",
        "code": "SH"
      },
      {
        "name": "Solothurn (de)",
        "code": "SO"
      },
      {
        "name": "Schwyz (de)",
        "code": "SZ"
      },
      {
        "name": "Thurgau (de)",
        "code": "TG"
      },
      {
        "name": "Ticino (it)",
        "code": "TI"
      },
      {
        "name": "Uri (de)",
        "code": "UR"
      },
      {
        "name": "Vaud (fr)",
        "code": "VD"
      },
      {
        "name": "Valais (fr)",
        "code": "VS"
      },
      {
        "name": "Zug (de)",
        "code": "ZG"
      },
      {
        "name": "Zürich (de)",
        "code": "ZH"
      }
    ]
  },
  {
    "name": "Syrian Arab Republic",
    "alpha3": "SYR",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Dimashq",
        "code": "DI"
      },
      {
        "name": "Dar'ā",
        "code": "DR"
      },
      {
        "name": "Dayr az Zawr",
        "code": "DY"
      },
      {
        "name": "Al Ḩasakah",
        "code": "HA"
      },
      {
        "name": "Ḩimş",
        "code": "HI"
      },
      {
        "name": "Ḩalab",
        "code": "HL"
      },
      {
        "name": "Ḩamāh",
        "code": "HM"
      },
      {
        "name": "Idlib",
        "code": "ID"
      },
      {
        "name": "Al Ladhiqiyah",
        "code": "LA"
      },
      {
        "name": "Al Qunaytirah",
        "code": "QU"
      },
      {
        "name": "Ar Raqqah",
        "code": "RA"
      },
      {
        "name": "Rif Dimashq",
        "code": "RD"
      },
      {
        "name": "As Suwayda'",
        "code": "SU"
      },
      {
        "name": "Tartus",
        "code": "TA"
      }
    ]
  },
  {
    "name": "Taiwan, Province of China",
    "alpha3": "TWN",
    "postal_code_format": "\\A\\d{3}(?:\\d{2,3})?\\Z",
    "subdivisions": [
      {
        "name": "Changhua",
        "code": "CHA"
      },
      {
        "name": "Chiayi Municipality",
        "code": "CYI"
      },
      {
        "name": "Chiayi",
        "code": "CYQ"
      },
      {
        "name": "Hsinchu",
        "code": "HSQ"
      },
      {
        "name": "Hsinchu Municipality",
        "code": "HSZ"
      },
      {
        "name": "Hualien",
        "code": "HUA"
      },
      {
        "name": "Ilan",
        "code": "ILA"
      },
      {
        "name": "Keelung Municipality",
        "code": "KEE"
      },
      {
        "name": "Kaohsiung Special Municipality",
        "code": "KHH"
      },
      {
        "name": "金門縣",
        "code": "KIN"
      },
      {
        "name": "連江縣",
        "code": "LIE"
      },
      {
        "name": "Miaoli",
        "code": "MIA"
      },
      {
        "name": "Nantou",
        "code": "NAN"
      },
      {
        "name": "New Taipei",
        "code": "NWT"
      },
      {
        "name": "Penghu",
        "code": "PEN"
      },
      {
        "name": "Pingtung",
        "code": "PIF"
      },
      {
        "name": "Taoyuan",
        "code": "TAO"
      },
      {
        "name": "Tainan Municipality",
        "code": "TNN"
      },
      {
        "name": "Taipei Special Municipality",
        "code": "TPE"
      },
      {
        "name": "Taitung",
        "code": "TTT"
      },
      {
        "name": "Taichung Municipality",
        "code": "TXG"
      },
      {
        "name": "Yunlin",
        "code": "YUN"
      }
    ]
  },
  {
    "name": "Tajikistan",
    "alpha3": "TJK",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Dushanbe",
        "code": "DU"
      },
      {
        "name": "Gorno-Badakhshan",
        "code": "GB"
      },
      {
        "name": "Khatlon",
        "code": "KT"
      },
      {
        "name": "Nohiyahoi Tobei Jumhurí",
        "code": "RA"
      },
      {
        "name": "Sughd",
        "code": "SU"
      }
    ]
  },
  {
    "name": "Tanzania, United Republic of",
    "alpha3": "TZA",
    "postal_code_format": "\\A\\d{4,5}\\Z",
    "subdivisions": [
      {
        "name": "Arusha",
        "code": "01"
      },
      {
        "name": "Dar es Salaam",
        "code": "02"
      },
      {
        "name": "Dodoma",
        "code": "03"
      },
      {
        "name": "Iringa",
        "code": "04"
      },
      {
        "name": "Kagera",
        "code": "05"
      },
      {
        "name": "Kaskazini Pemba",
        "code": "06"
      },
      {
        "name": "Kaskazini Unguja",
        "code": "07"
      },
      {
        "name": "Kigoma",
        "code": "08"
      },
      {
        "name": "Kilimanjaro",
        "code": "09"
      },
      {
        "name": "Kusini Pemba",
        "code": "10"
      },
      {
        "name": "Kusini Unguja",
        "code": "11"
      },
      {
        "name": "Lindi",
        "code": "12"
      },
      {
        "name": "Mara",
        "code": "13"
      },
      {
        "name": "Mbeya",
        "code": "14"
      },
      {
        "name": "Mjini Magharibi",
        "code": "15"
      },
      {
        "name": "Morogoro",
        "code": "16"
      },
      {
        "name": "Mtwara",
        "code": "17"
      },
      {
        "name": "Mwanza",
        "code": "18"
      },
      {
        "name": "Pwani",
        "code": "19"
      },
      {
        "name": "Rukwa",
        "code": "20"
      },
      {
        "name": "Ruvuma",
        "code": "21"
      },
      {
        "name": "Shinyanga",
        "code": "22"
      },
      {
        "name": "Singida",
        "code": "23"
      },
      {
        "name": "Tabora",
        "code": "24"
      },
      {
        "name": "Tanga",
        "code": "25"
      },
      {
        "name": "Manyara",
        "code": "26"
      },
      {
        "name": "Mkoa wa Geita",
        "code": "27"
      },
      {
        "name": "Mkoa wa Katavi",
        "code": "28"
      },
      {
        "name": "Mkoa wa Njombe",
        "code": "29"
      },
      {
        "name": "Mkoa wa Simiyu",
        "code": "30"
      },
      {
        "name": "Songwe",
        "code": "31"
      }
    ]
  },
  {
    "name": "Thailand",
    "alpha3": "THA",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Krung Thep Maha Nakhon [Bangkok]",
        "code": "10"
      },
      {
        "name": "Samut Prakan",
        "code": "11"
      },
      {
        "name": "Nonthaburi",
        "code": "12"
      },
      {
        "name": "Pathum Thani",
        "code": "13"
      },
      {
        "name": "Phra Nakhon Si Ayutthaya",
        "code": "14"
      },
      {
        "name": "Ang Thong",
        "code": "15"
      },
      {
        "name": "Lop Buri",
        "code": "16"
      },
      {
        "name": "Sing Buri",
        "code": "17"
      },
      {
        "name": "Chai Nat",
        "code": "18"
      },
      {
        "name": "Saraburi",
        "code": "19"
      },
      {
        "name": "Chon Buri",
        "code": "20"
      },
      {
        "name": "Rayong",
        "code": "21"
      },
      {
        "name": "Chanthaburi",
        "code": "22"
      },
      {
        "name": "Trat",
        "code": "23"
      },
      {
        "name": "Chachoengsao",
        "code": "24"
      },
      {
        "name": "Prachin Buri",
        "code": "25"
      },
      {
        "name": "Nakhon Nayok",
        "code": "26"
      },
      {
        "name": "Sa Kaeo",
        "code": "27"
      },
      {
        "name": "Nakhon Ratchasima",
        "code": "30"
      },
      {
        "name": "Buri Ram",
        "code": "31"
      },
      {
        "name": "Surin",
        "code": "32"
      },
      {
        "name": "Si Sa Ket",
        "code": "33"
      },
      {
        "name": "Ubon Ratchathani",
        "code": "34"
      },
      {
        "name": "Yasothon",
        "code": "35"
      },
      {
        "name": "Chaiyaphum",
        "code": "36"
      },
      {
        "name": "Amnat Charoen",
        "code": "37"
      },
      {
        "name": "จังหวัดบึงกาฬ",
        "code": "38"
      },
      {
        "name": "Nong Bua Lam Phu",
        "code": "39"
      },
      {
        "name": "Khon Kaen",
        "code": "40"
      },
      {
        "name": "Udon Thani",
        "code": "41"
      },
      {
        "name": "Loei",
        "code": "42"
      },
      {
        "name": "Nong Khai",
        "code": "43"
      },
      {
        "name": "Maha Sarakham",
        "code": "44"
      },
      {
        "name": "Roi Et",
        "code": "45"
      },
      {
        "name": "Kalasin",
        "code": "46"
      },
      {
        "name": "Sakon Nakhon",
        "code": "47"
      },
      {
        "name": "Nakhon Phanom",
        "code": "48"
      },
      {
        "name": "Mukdahan",
        "code": "49"
      },
      {
        "name": "Chiang Mai",
        "code": "50"
      },
      {
        "name": "Lamphun",
        "code": "51"
      },
      {
        "name": "Lampang",
        "code": "52"
      },
      {
        "name": "Uttaradit",
        "code": "53"
      },
      {
        "name": "Phrae",
        "code": "54"
      },
      {
        "name": "Nan",
        "code": "55"
      },
      {
        "name": "Phayao",
        "code": "56"
      },
      {
        "name": "Chiang Rai",
        "code": "57"
      },
      {
        "name": "Mae Hong Son",
        "code": "58"
      },
      {
        "name": "Nakhon Sawan",
        "code": "60"
      },
      {
        "name": "Uthai Thani",
        "code": "61"
      },
      {
        "name": "Kamphaeng Phet",
        "code": "62"
      },
      {
        "name": "Tak",
        "code": "63"
      },
      {
        "name": "Sukhothai",
        "code": "64"
      },
      {
        "name": "Phitsanulok",
        "code": "65"
      },
      {
        "name": "Phichit",
        "code": "66"
      },
      {
        "name": "Phetchabun",
        "code": "67"
      },
      {
        "name": "Ratchaburi",
        "code": "70"
      },
      {
        "name": "Kanchanaburi",
        "code": "71"
      },
      {
        "name": "Suphan Buri",
        "code": "72"
      },
      {
        "name": "Nakhon Pathom",
        "code": "73"
      },
      {
        "name": "Samut Sakhon",
        "code": "74"
      },
      {
        "name": "Samut Songkhram",
        "code": "75"
      },
      {
        "name": "Phetchaburi",
        "code": "76"
      },
      {
        "name": "Prachuap Khiri Khan",
        "code": "77"
      },
      {
        "name": "Nakhon Si Thammarat",
        "code": "80"
      },
      {
        "name": "Krabi",
        "code": "81"
      },
      {
        "name": "Phangnga",
        "code": "82"
      },
      {
        "name": "Phuket",
        "code": "83"
      },
      {
        "name": "Surat Thani",
        "code": "84"
      },
      {
        "name": "Ranong",
        "code": "85"
      },
      {
        "name": "Chumphon",
        "code": "86"
      },
      {
        "name": "Songkhla",
        "code": "90"
      },
      {
        "name": "Satun",
        "code": "91"
      },
      {
        "name": "Trang",
        "code": "92"
      },
      {
        "name": "Phatthalung",
        "code": "93"
      },
      {
        "name": "Pattani",
        "code": "94"
      },
      {
        "name": "Yala",
        "code": "95"
      },
      {
        "name": "Narathiwat",
        "code": "96"
      },
      {
        "name": "Phatthaya",
        "code": "S"
      }
    ]
  },
  {
    "name": "Timor-Leste",
    "alpha3": "TLS",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Aileu",
        "code": "AL"
      },
      {
        "name": "Ainaro",
        "code": "AN"
      },
      {
        "name": "Baucau",
        "code": "BA"
      },
      {
        "name": "Bobonaro",
        "code": "BO"
      },
      {
        "name": "Cova Lima",
        "code": "CO"
      },
      {
        "name": "Dili",
        "code": "DI"
      },
      {
        "name": "Ermera",
        "code": "ER"
      },
      {
        "name": "Lautem",
        "code": "LA"
      },
      {
        "name": "Liquiça",
        "code": "LI"
      },
      {
        "name": "Manufahi",
        "code": "MF"
      },
      {
        "name": "Manatuto",
        "code": "MT"
      },
      {
        "name": "Oecussi",
        "code": "OE"
      },
      {
        "name": "Viqueque",
        "code": "VI"
      }
    ]
  },
  {
    "name": "Togo",
    "alpha3": "TGO",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Centre",
        "code": "C"
      },
      {
        "name": "Kara",
        "code": "K"
      },
      {
        "name": "Maritime (Région)",
        "code": "M"
      },
      {
        "name": "Plateaux",
        "code": "P"
      },
      {
        "name": "Savannes",
        "code": "S"
      }
    ]
  },
  {
    "name": "Tokelau",
    "alpha3": "TKL",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Tonga",
    "alpha3": "TON",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "'Eua",
        "code": "01"
      },
      {
        "name": "Ha'apai",
        "code": "02"
      },
      {
        "name": "Niuas",
        "code": "03"
      },
      {
        "name": "Tongatapu",
        "code": "04"
      },
      {
        "name": "Vava'u",
        "code": "05"
      }
    ]
  },
  {
    "name": "Trinidad and Tobago",
    "alpha3": "TTO",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Arima",
        "code": "ARI"
      },
      {
        "name": "Chaguanas",
        "code": "CHA"
      },
      {
        "name": "Couva-Tabaquite-Talparo",
        "code": "CTT"
      },
      {
        "name": "Diego Martin",
        "code": "DMN"
      },
      {
        "name": "Mayaro-Rio Claro",
        "code": "MRC"
      },
      {
        "name": "Penal-Debe",
        "code": "PED"
      },
      {
        "name": "Port of Spain",
        "code": "POS"
      },
      {
        "name": "Princes Town",
        "code": "PRT"
      },
      {
        "name": "Point Fortin",
        "code": "PTF"
      },
      {
        "name": "San Fernando",
        "code": "SFO"
      },
      {
        "name": "Sangre Grande",
        "code": "SGE"
      },
      {
        "name": "Siparia",
        "code": "SIP"
      },
      {
        "name": "San Juan-Laventille",
        "code": "SJL"
      },
      {
        "name": "Tobago",
        "code": "TOB"
      },
      {
        "name": "Tunapuna-Piarco",
        "code": "TUP"
      }
    ]
  },
  {
    "name": "Tunisia",
    "alpha3": "TUN",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Tunis",
        "code": "11"
      },
      {
        "name": "L'Ariana",
        "code": "12"
      },
      {
        "name": "Ben Arous",
        "code": "13"
      },
      {
        "name": "La Manouba",
        "code": "14"
      },
      {
        "name": "Nabeul",
        "code": "21"
      },
      {
        "name": "Zaghouan",
        "code": "22"
      },
      {
        "name": "Bizerte",
        "code": "23"
      },
      {
        "name": "Béja",
        "code": "31"
      },
      {
        "name": "Jendouba",
        "code": "32"
      },
      {
        "name": "Le Kef",
        "code": "33"
      },
      {
        "name": "Siliana",
        "code": "34"
      },
      {
        "name": "Kairouan",
        "code": "41"
      },
      {
        "name": "Kasserine",
        "code": "42"
      },
      {
        "name": "Sidi Bouzid",
        "code": "43"
      },
      {
        "name": "Sousse",
        "code": "51"
      },
      {
        "name": "Monastir",
        "code": "52"
      },
      {
        "name": "Mahdia",
        "code": "53"
      },
      {
        "name": "Sfax",
        "code": "61"
      },
      {
        "name": "Gafsa",
        "code": "71"
      },
      {
        "name": "Tozeur",
        "code": "72"
      },
      {
        "name": "Kebili",
        "code": "73"
      },
      {
        "name": "Gabès",
        "code": "81"
      },
      {
        "name": "Medenine",
        "code": "82"
      },
      {
        "name": "Tataouine",
        "code": "83"
      }
    ]
  },
  {
    "name": "Türkiye",
    "alpha3": "TUR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Adana",
        "code": "01"
      },
      {
        "name": "Adıyaman",
        "code": "02"
      },
      {
        "name": "Afyon",
        "code": "03"
      },
      {
        "name": "Ağrı",
        "code": "04"
      },
      {
        "name": "Amasya",
        "code": "05"
      },
      {
        "name": "Ankara",
        "code": "06"
      },
      {
        "name": "Antalya",
        "code": "07"
      },
      {
        "name": "Artvin",
        "code": "08"
      },
      {
        "name": "Aydın",
        "code": "09"
      },
      {
        "name": "Balıkesir",
        "code": "10"
      },
      {
        "name": "Bilecik",
        "code": "11"
      },
      {
        "name": "Bingöl",
        "code": "12"
      },
      {
        "name": "Bitlis",
        "code": "13"
      },
      {
        "name": "Bolu",
        "code": "14"
      },
      {
        "name": "Burdur",
        "code": "15"
      },
      {
        "name": "Bursa",
        "code": "16"
      },
      {
        "name": "Çanakkale",
        "code": "17"
      },
      {
        "name": "Çankırı",
        "code": "18"
      },
      {
        "name": "Çorum",
        "code": "19"
      },
      {
        "name": "Denizli",
        "code": "20"
      },
      {
        "name": "Diyarbakır",
        "code": "21"
      },
      {
        "name": "Edirne",
        "code": "22"
      },
      {
        "name": "Elazığ",
        "code": "23"
      },
      {
        "name": "Erzincan",
        "code": "24"
      },
      {
        "name": "Erzurum",
        "code": "25"
      },
      {
        "name": "Eskişehir",
        "code": "26"
      },
      {
        "name": "Gaziantep",
        "code": "27"
      },
      {
        "name": "Giresun",
        "code": "28"
      },
      {
        "name": "Gümüşhane",
        "code": "29"
      },
      {
        "name": "Hakkâri",
        "code": "30"
      },
      {
        "name": "Hatay",
        "code": "31"
      },
      {
        "name": "Isparta",
        "code": "32"
      },
      {
        "name": "İçel",
        "code": "33"
      },
      {
        "name": "İstanbul",
        "code": "34"
      },
      {
        "name": "İzmir",
        "code": "35"
      },
      {
        "name": "Kars",
        "code": "36"
      },
      {
        "name": "Kastamonu",
        "code": "37"
      },
      {
        "name": "Kayseri",
        "code": "38"
      },
      {
        "name": "Kırklareli",
        "code": "39"
      },
      {
        "name": "Kırşehir",
        "code": "40"
      },
      {
        "name": "Kocaeli",
        "code": "41"
      },
      {
        "name": "Konya",
        "code": "42"
      },
      {
        "name": "Kütahya",
        "code": "43"
      },
      {
        "name": "Malatya",
        "code": "44"
      },
      {
        "name": "Manisa",
        "code": "45"
      },
      {
        "name": "Kahramanmaraş",
        "code": "46"
      },
      {
        "name": "Mardin",
        "code": "47"
      },
      {
        "name": "Muğla",
        "code": "48"
      },
      {
        "name": "Muş",
        "code": "49"
      },
      {
        "name": "Nevşehir",
        "code": "50"
      },
      {
        "name": "Niğde",
        "code": "51"
      },
      {
        "name": "Ordu",
        "code": "52"
      },
      {
        "name": "Rize",
        "code": "53"
      },
      {
        "name": "Sakarya",
        "code": "54"
      },
      {
        "name": "Samsun",
        "code": "55"
      },
      {
        "name": "Siirt",
        "code": "56"
      },
      {
        "name": "Sinop",
        "code": "57"
      },
      {
        "name": "Sivas",
        "code": "58"
      },
      {
        "name": "Tekirdağ",
        "code": "59"
      },
      {
        "name": "Tokat",
        "code": "60"
      },
      {
        "name": "Trabzon",
        "code": "61"
      },
      {
        "name": "Tunceli",
        "code": "62"
      },
      {
        "name": "Şanlıurfa",
        "code": "63"
      },
      {
        "name": "Uşak",
        "code": "64"
      },
      {
        "name": "Van",
        "code": "65"
      },
      {
        "name": "Yozgat",
        "code": "66"
      },
      {
        "name": "Zonguldak",
        "code": "67"
      },
      {
        "name": "Aksaray",
        "code": "68"
      },
      {
        "name": "Bayburt",
        "code": "69"
      },
      {
        "name": "Karaman",
        "code": "70"
      },
      {
        "name": "Kırıkkale",
        "code": "71"
      },
      {
        "name": "Batman",
        "code": "72"
      },
      {
        "name": "Şırnak",
        "code": "73"
      },
      {
        "name": "Bartın",
        "code": "74"
      },
      {
        "name": "Ardahan",
        "code": "75"
      },
      {
        "name": "Iğdır",
        "code": "76"
      },
      {
        "name": "Yalova",
        "code": "77"
      },
      {
        "name": "Karabük",
        "code": "78"
      },
      {
        "name": "Kilis",
        "code": "79"
      },
      {
        "name": "Osmaniye",
        "code": "80"
      },
      {
        "name": "Düzce",
        "code": "81"
      }
    ]
  },
  {
    "name": "Turkmenistan",
    "alpha3": "TKM",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Ahal",
        "code": "A"
      },
      {
        "name": "Balkan",
        "code": "B"
      },
      {
        "name": "Dasoguz",
        "code": "D"
      },
      {
        "name": "Lebap",
        "code": "L"
      },
      {
        "name": "Mary",
        "code": "M"
      },
      {
        "name": "Aşgabat",
        "code": "S"
      }
    ]
  },
  {
    "name": "Turks and Caicos Islands",
    "alpha3": "TCA",
    "postal_code_format": "\\ATKCA 1ZZ\\Z",
    "subdivisions": []
  },
  {
    "name": "Tuvalu",
    "alpha3": "TUV",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Funafuti",
        "code": "FUN"
      },
      {
        "name": "Niutao",
        "code": "NIT"
      },
      {
        "name": "Nukufetau",
        "code": "NKF"
      },
      {
        "name": "Nukulaelae",
        "code": "NKL"
      },
      {
        "name": "Nanumea",
        "code": "NMA"
      },
      {
        "name": "Nanumanga",
        "code": "NMG"
      },
      {
        "name": "Nui",
        "code": "NUI"
      },
      {
        "name": "Vaitupu",
        "code": "VAI"
      }
    ]
  },
  {
    "name": "Uganda",
    "alpha3": "UGA",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Kalangala",
        "code": "101"
      },
      {
        "name": "Kampala",
        "code": "102"
      },
      {
        "name": "Kiboga",
        "code": "103"
      },
      {
        "name": "Luwero",
        "code": "104"
      },
      {
        "name": "Masaka",
        "code": "105"
      },
      {
        "name": "Mpigi",
        "code": "106"
      },
      {
        "name": "Mubende",
        "code": "107"
      },
      {
        "name": "Mukono",
        "code": "108"
      },
      {
        "name": "Nakasongola",
        "code": "109"
      },
      {
        "name": "Rakai",
        "code": "110"
      },
      {
        "name": "Sembabule",
        "code": "111"
      },
      {
        "name": "Kayunga",
        "code": "112"
      },
      {
        "name": "Wakiso",
        "code": "113"
      },
      {
        "name": "Mityana",
        "code": "114"
      },
      {
        "name": "Nakaseke",
        "code": "115"
      },
      {
        "name": "Lyantonde",
        "code": "116"
      },
      {
        "name": "Buikwe",
        "code": "117"
      },
      {
        "name": "Bukomansibi",
        "code": "118"
      },
      {
        "name": "Butambala",
        "code": "119"
      },
      {
        "name": "Buvuma",
        "code": "120"
      },
      {
        "name": "Gomba",
        "code": "121"
      },
      {
        "name": "Kalungu",
        "code": "122"
      },
      {
        "name": "Kyankwanzi",
        "code": "123"
      },
      {
        "name": "Lwengo",
        "code": "124"
      },
      {
        "name": "Kyotera",
        "code": "125"
      },
      {
        "name": "Kasanda",
        "code": "126"
      },
      {
        "name": "Bugiri",
        "code": "201"
      },
      {
        "name": "Busia",
        "code": "202"
      },
      {
        "name": "Iganga",
        "code": "203"
      },
      {
        "name": "Jinja",
        "code": "204"
      },
      {
        "name": "Kamuli",
        "code": "205"
      },
      {
        "name": "Kapchorwa",
        "code": "206"
      },
      {
        "name": "Katakwi",
        "code": "207"
      },
      {
        "name": "Kumi",
        "code": "208"
      },
      {
        "name": "Mbale",
        "code": "209"
      },
      {
        "name": "Pallisa",
        "code": "210"
      },
      {
        "name": "Soroti",
        "code": "211"
      },
      {
        "name": "Tororo",
        "code": "212"
      },
      {
        "name": "Kaberamaido",
        "code": "213"
      },
      {
        "name": "Mayuge",
        "code": "214"
      },
      {
        "name": "Sironko",
        "code": "215"
      },
      {
        "name": "Amuria",
        "code": "216"
      },
      {
        "name": "Budaka",
        "code": "217"
      },
      {
        "name": "Bukwa",
        "code": "218"
      },
      {
        "name": "Butaleja",
        "code": "219"
      },
      {
        "name": "Kaliro",
        "code": "220"
      },
      {
        "name": "Manafwa",
        "code": "221"
      },
      {
        "name": "Namutumba",
        "code": "222"
      },
      {
        "name": "Bududa",
        "code": "223"
      },
      {
        "name": "Bukedea",
        "code": "224"
      },
      {
        "name": "Bulambuli",
        "code": "225"
      },
      {
        "name": "Buyende",
        "code": "226"
      },
      {
        "name": "Kibuku",
        "code": "227"
      },
      {
        "name": "Kween",
        "code": "228"
      },
      {
        "name": "Luuka",
        "code": "229"
      },
      {
        "name": "Namayingo",
        "code": "230"
      },
      {
        "name": "Ngora",
        "code": "231"
      },
      {
        "name": "Serere",
        "code": "232"
      },
      {
        "name": "Butebo",
        "code": "233"
      },
      {
        "name": "Namisindwa",
        "code": "234"
      },
      {
        "name": "Bugweri",
        "code": "235"
      },
      {
        "name": "Kapelebyong",
        "code": "236"
      },
      {
        "name": "Kalaki",
        "code": "237"
      },
      {
        "name": "Adjumani",
        "code": "301"
      },
      {
        "name": "Apac",
        "code": "302"
      },
      {
        "name": "Arua",
        "code": "303"
      },
      {
        "name": "Gulu",
        "code": "304"
      },
      {
        "name": "Kitgum",
        "code": "305"
      },
      {
        "name": "Kotido",
        "code": "306"
      },
      {
        "name": "Lira",
        "code": "307"
      },
      {
        "name": "Moroto",
        "code": "308"
      },
      {
        "name": "Moyo",
        "code": "309"
      },
      {
        "name": "Nebbi",
        "code": "310"
      },
      {
        "name": "Nakapiripirit",
        "code": "311"
      },
      {
        "name": "Pader",
        "code": "312"
      },
      {
        "name": "Yumbe",
        "code": "313"
      },
      {
        "name": "Amolatar",
        "code": "314"
      },
      {
        "name": "Kaabong",
        "code": "315"
      },
      {
        "name": "Koboko",
        "code": "316"
      },
      {
        "name": "Abim",
        "code": "317"
      },
      {
        "name": "Dokolo",
        "code": "318"
      },
      {
        "name": "Amuru",
        "code": "319"
      },
      {
        "name": "Maracha",
        "code": "320"
      },
      {
        "name": "Oyam",
        "code": "321"
      },
      {
        "name": "Agago",
        "code": "322"
      },
      {
        "name": "Alebtong",
        "code": "323"
      },
      {
        "name": "Amudat",
        "code": "324"
      },
      {
        "name": "Kole",
        "code": "325"
      },
      {
        "name": "Lamwo",
        "code": "326"
      },
      {
        "name": "Napak",
        "code": "327"
      },
      {
        "name": "Nwoya",
        "code": "328"
      },
      {
        "name": "Otuke",
        "code": "329"
      },
      {
        "name": "Zombo²",
        "code": "330"
      },
      {
        "name": "Zombo",
        "code": "331"
      },
      {
        "name": "Pakwach",
        "code": "332"
      },
      {
        "name": "Kwania",
        "code": "333"
      },
      {
        "name": "Nabilatuk",
        "code": "334"
      },
      {
        "name": "Karenga",
        "code": "335"
      },
      {
        "name": "Madi-Okollo",
        "code": "336"
      },
      {
        "name": "Obongi",
        "code": "337"
      },
      {
        "name": "Bundibugyo",
        "code": "401"
      },
      {
        "name": "Bushenyi",
        "code": "402"
      },
      {
        "name": "Hoima",
        "code": "403"
      },
      {
        "name": "Kabale",
        "code": "404"
      },
      {
        "name": "Kabarole",
        "code": "405"
      },
      {
        "name": "Kasese",
        "code": "406"
      },
      {
        "name": "Kibaale",
        "code": "407"
      },
      {
        "name": "Kisoro",
        "code": "408"
      },
      {
        "name": "Masindi",
        "code": "409"
      },
      {
        "name": "Mbarara",
        "code": "410"
      },
      {
        "name": "Ntungamo",
        "code": "411"
      },
      {
        "name": "Rukungiri",
        "code": "412"
      },
      {
        "name": "Kamwenge",
        "code": "413"
      },
      {
        "name": "Kanungu",
        "code": "414"
      },
      {
        "name": "Kyenjojo",
        "code": "415"
      },
      {
        "name": "Ibanda",
        "code": "416"
      },
      {
        "name": "Isingiro",
        "code": "417"
      },
      {
        "name": "Kiruhura",
        "code": "418"
      },
      {
        "name": "Buliisa",
        "code": "419"
      },
      {
        "name": "Buhweju",
        "code": "420"
      },
      {
        "name": "Kiryandongo",
        "code": "421"
      },
      {
        "name": "Kyegegwa",
        "code": "422"
      },
      {
        "name": "Mitooma",
        "code": "423"
      },
      {
        "name": "Ntoroko",
        "code": "424"
      },
      {
        "name": "Rubirizi",
        "code": "425"
      },
      {
        "name": "Sheema",
        "code": "426"
      },
      {
        "name": "Kagadi",
        "code": "427"
      },
      {
        "name": "Kakumiro",
        "code": "428"
      },
      {
        "name": "Rubanda",
        "code": "429"
      },
      {
        "name": "Bunyangabu",
        "code": "430"
      },
      {
        "name": "Rukiga",
        "code": "431"
      },
      {
        "name": "Kikuube",
        "code": "432"
      },
      {
        "name": "Kazo",
        "code": "433"
      },
      {
        "name": "Kitagwenda",
        "code": "434"
      },
      {
        "name": "Rwampara",
        "code": "435"
      },
      {
        "name": "Central",
        "code": "C"
      },
      {
        "name": "Eastern",
        "code": "E"
      },
      {
        "name": "Northern",
        "code": "N"
      },
      {
        "name": "Western",
        "code": "W"
      }
    ]
  },
  {
    "name": "Ukraine",
    "alpha3": "UKR",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Vinnyts'ka Oblast'",
        "code": "05"
      },
      {
        "name": "Volyns'ka Oblast'",
        "code": "07"
      },
      {
        "name": "Luhans'ka Oblast'",
        "code": "09"
      },
      {
        "name": "Dnipropetrovs'ka Oblast'",
        "code": "12"
      },
      {
        "name": "Donets'ka Oblast'",
        "code": "14"
      },
      {
        "name": "Zhytomyrs'ka Oblast'",
        "code": "18"
      },
      {
        "name": "Zakarpats'ka Oblast'",
        "code": "21"
      },
      {
        "name": "Zaporiz'ka Oblast'",
        "code": "23"
      },
      {
        "name": "Ivano-Frankivs'ka Oblast'",
        "code": "26"
      },
      {
        "name": "Kyïv",
        "code": "30"
      },
      {
        "name": "Kyïvs'ka Oblast'",
        "code": "32"
      },
      {
        "name": "Kirovohrads'ka Oblast'",
        "code": "35"
      },
      {
        "name": "Sevastopol'",
        "code": "40"
      },
      {
        "name": "Respublika Krym",
        "code": "43"
      },
      {
        "name": "L'vivs'ka Oblast'",
        "code": "46"
      },
      {
        "name": "Mykolaïvs'ka Oblast'",
        "code": "48"
      },
      {
        "name": "Odes'ka Oblast'",
        "code": "51"
      },
      {
        "name": "Poltavs'ka Oblast'",
        "code": "53"
      },
      {
        "name": "Rivnens'ka Oblast'",
        "code": "56"
      },
      {
        "name": "Sums'ka Oblast'",
        "code": "59"
      },
      {
        "name": "Ternopil's'ka Oblast'",
        "code": "61"
      },
      {
        "name": "Kharkivs'ka Oblast'",
        "code": "63"
      },
      {
        "name": "Khersons'ka Oblast'",
        "code": "65"
      },
      {
        "name": "Khmel'nyts'ka Oblast'",
        "code": "68"
      },
      {
        "name": "Cherkas'ka Oblast'",
        "code": "71"
      },
      {
        "name": "Chernihivs'ka Oblast'",
        "code": "74"
      },
      {
        "name": "Chernivets'ka Oblast'",
        "code": "77"
      }
    ]
  },
  {
    "name": "United Arab Emirates",
    "alpha3": "ARE",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "'Ajmān",
        "code": "AJ"
      },
      {
        "name": "Abū Z̧aby [Abu Dhabi]",
        "code": "AZ"
      },
      {
        "name": "Dubayy",
        "code": "DU"
      },
      {
        "name": "Al Fujayrah",
        "code": "FU"
      },
      {
        "name": "Ra's al Khaymah",
        "code": "RK"
      },
      {
        "name": "Ash Shariqah [Sharjah]",
        "code": "SH"
      },
      {
        "name": "Umm al Qaywayn",
        "code": "UQ"
      }
    ]
  },
  {
    "name": "United Kingdom of Great Britain and Northern Ireland",
    "alpha3": "GBR",
    "postal_code_format": "\\AGIR ?0AA|(?:(?:AB|AL|B|BA|BB|BD|BF|BH|BL|BN|BR|BS|BT|BX|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(?:\\d[\\dA-Z]? ?\\d[ABD-HJLN-UW-Z]{2}))|BFPO ?\\d{1,4}\\Z",
    "subdivisions": [
      {
        "name": "Armagh City, Banbridge and Craigavon",
        "code": "ABC"
      },
      {
        "name": "Aberdeenshire",
        "code": "ABD"
      },
      {
        "name": "Aberdeen City",
        "code": "ABE"
      },
      {
        "name": "Argyll and Bute",
        "code": "AGB"
      },
      {
        "name": "Isle of Anglesey [Sir Ynys Môn GB-YNM]",
        "code": "AGY"
      },
      {
        "name": "Ards and North Down",
        "code": "AND"
      },
      {
        "name": "Antrim and Newtownabbey",
        "code": "ANN"
      },
      {
        "name": "Angus",
        "code": "ANS"
      },
      {
        "name": "Bath and North East Somerset",
        "code": "BAS"
      },
      {
        "name": "Blackburn with Darwen",
        "code": "BBD"
      },
      {
        "name": "Bournemouth, Christchurch and Poole",
        "code": "BCP"
      },
      {
        "name": "Bedfordshire",
        "code": "BDF"
      },
      {
        "name": "Barking and Dagenham",
        "code": "BDG"
      },
      {
        "name": "Brent",
        "code": "BEN"
      },
      {
        "name": "Bexley",
        "code": "BEX"
      },
      {
        "name": "Belfast",
        "code": "BFS"
      },
      {
        "name": "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
        "code": "BGE"
      },
      {
        "name": "Blaenau Gwent",
        "code": "BGW"
      },
      {
        "name": "Birmingham",
        "code": "BIR"
      },
      {
        "name": "Buckinghamshire",
        "code": "BKM"
      },
      {
        "name": "Barnet",
        "code": "BNE"
      },
      {
        "name": "Brighton and Hove",
        "code": "BNH"
      },
      {
        "name": "Barnsley",
        "code": "BNS"
      },
      {
        "name": "Bolton",
        "code": "BOL"
      },
      {
        "name": "Blackpool",
        "code": "BPL"
      },
      {
        "name": "Bracknell Forest",
        "code": "BRC"
      },
      {
        "name": "Bradford",
        "code": "BRD"
      },
      {
        "name": "Bromley",
        "code": "BRY"
      },
      {
        "name": "Bristol, City of",
        "code": "BST"
      },
      {
        "name": "Bury",
        "code": "BUR"
      },
      {
        "name": "Cambridgeshire",
        "code": "CAM"
      },
      {
        "name": "Caerphilly [Caerffili GB-CAF]",
        "code": "CAY"
      },
      {
        "name": "Central Bedfordshire",
        "code": "CBF"
      },
      {
        "name": "Causeway Coast and Glens",
        "code": "CCG"
      },
      {
        "name": "Ceredigion [Sir Ceredigion]",
        "code": "CGN"
      },
      {
        "name": "Cheshire East",
        "code": "CHE"
      },
      {
        "name": "Cheshire West and Chester",
        "code": "CHW"
      },
      {
        "name": "Calderdale",
        "code": "CLD"
      },
      {
        "name": "Clackmannanshire",
        "code": "CLK"
      },
      {
        "name": "Cumbria",
        "code": "CMA"
      },
      {
        "name": "Camden",
        "code": "CMD"
      },
      {
        "name": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
        "code": "CMN"
      },
      {
        "name": "Cornwall",
        "code": "CON"
      },
      {
        "name": "Coventry",
        "code": "COV"
      },
      {
        "name": "Cardiff [Caerdydd GB-CRD]",
        "code": "CRF"
      },
      {
        "name": "Croydon",
        "code": "CRY"
      },
      {
        "name": "Conwy",
        "code": "CWY"
      },
      {
        "name": "Darlington",
        "code": "DAL"
      },
      {
        "name": "Derbyshire",
        "code": "DBY"
      },
      {
        "name": "Denbighshire [Sir Ddinbych GB-DDB]",
        "code": "DEN"
      },
      {
        "name": "Derby",
        "code": "DER"
      },
      {
        "name": "Devon",
        "code": "DEV"
      },
      {
        "name": "Dumfries and Galloway",
        "code": "DGY"
      },
      {
        "name": "Doncaster",
        "code": "DNC"
      },
      {
        "name": "Dundee City",
        "code": "DND"
      },
      {
        "name": "Dorset",
        "code": "DOR"
      },
      {
        "name": "Derry and Strabane",
        "code": "DRS"
      },
      {
        "name": "Dudley",
        "code": "DUD"
      },
      {
        "name": "Durham",
        "code": "DUR"
      },
      {
        "name": "Ealing",
        "code": "EAL"
      },
      {
        "name": "East Ayrshire",
        "code": "EAY"
      },
      {
        "name": "Edinburgh, City of",
        "code": "EDH"
      },
      {
        "name": "East Dunbartonshire",
        "code": "EDU"
      },
      {
        "name": "East Lothian",
        "code": "ELN"
      },
      {
        "name": "Eilean Siar",
        "code": "ELS"
      },
      {
        "name": "Enfield",
        "code": "ENF"
      },
      {
        "name": "England",
        "code": "ENG"
      },
      {
        "name": "East Renfrewshire",
        "code": "ERW"
      },
      {
        "name": "East Riding of Yorkshire",
        "code": "ERY"
      },
      {
        "name": "Essex",
        "code": "ESS"
      },
      {
        "name": "East Sussex",
        "code": "ESX"
      },
      {
        "name": "Falkirk",
        "code": "FAL"
      },
      {
        "name": "Fife",
        "code": "FIF"
      },
      {
        "name": "Flintshire [Sir y Fflint GB-FFL]",
        "code": "FLN"
      },
      {
        "name": "Fermanagh and Omagh",
        "code": "FMO"
      },
      {
        "name": "Gateshead",
        "code": "GAT"
      },
      {
        "name": "Glasgow City",
        "code": "GLG"
      },
      {
        "name": "Gloucestershire",
        "code": "GLS"
      },
      {
        "name": "Greenwich",
        "code": "GRE"
      },
      {
        "name": "Gwynedd",
        "code": "GWN"
      },
      {
        "name": "Halton",
        "code": "HAL"
      },
      {
        "name": "Hampshire",
        "code": "HAM"
      },
      {
        "name": "Havering",
        "code": "HAV"
      },
      {
        "name": "Hackney",
        "code": "HCK"
      },
      {
        "name": "Herefordshire, County of",
        "code": "HEF"
      },
      {
        "name": "Hillingdon",
        "code": "HIL"
      },
      {
        "name": "Highland",
        "code": "HLD"
      },
      {
        "name": "Hammersmith and Fulham",
        "code": "HMF"
      },
      {
        "name": "Hounslow",
        "code": "HNS"
      },
      {
        "name": "Hartlepool",
        "code": "HPL"
      },
      {
        "name": "Hertfordshire",
        "code": "HRT"
      },
      {
        "name": "Harrow",
        "code": "HRW"
      },
      {
        "name": "Haringey",
        "code": "HRY"
      },
      {
        "name": "Isles of Scilly",
        "code": "IOS"
      },
      {
        "name": "Isle of Wight",
        "code": "IOW"
      },
      {
        "name": "Islington",
        "code": "ISL"
      },
      {
        "name": "Inverclyde",
        "code": "IVC"
      },
      {
        "name": "Kensington and Chelsea",
        "code": "KEC"
      },
      {
        "name": "Kent",
        "code": "KEN"
      },
      {
        "name": "Kingston upon Hull, City of",
        "code": "KHL"
      },
      {
        "name": "Kirklees",
        "code": "KIR"
      },
      {
        "name": "Kingston upon Thames",
        "code": "KTT"
      },
      {
        "name": "Knowsley",
        "code": "KWL"
      },
      {
        "name": "Lancashire",
        "code": "LAN"
      },
      {
        "name": "Lisburn and Castlereagh",
        "code": "LBC"
      },
      {
        "name": "Lambeth",
        "code": "LBH"
      },
      {
        "name": "Leicester",
        "code": "LCE"
      },
      {
        "name": "Leeds",
        "code": "LDS"
      },
      {
        "name": "Leicestershire",
        "code": "LEC"
      },
      {
        "name": "Lewisham",
        "code": "LEW"
      },
      {
        "name": "Lincolnshire",
        "code": "LIN"
      },
      {
        "name": "Liverpool",
        "code": "LIV"
      },
      {
        "name": "London, City of",
        "code": "LND"
      },
      {
        "name": "Luton",
        "code": "LUT"
      },
      {
        "name": "Manchester",
        "code": "MAN"
      },
      {
        "name": "Middlesbrough",
        "code": "MDB"
      },
      {
        "name": "Medway",
        "code": "MDW"
      },
      {
        "name": "Mid and East Antrim",
        "code": "MEA"
      },
      {
        "name": "Milton Keynes",
        "code": "MIK"
      },
      {
        "name": "Midlothian",
        "code": "MLN"
      },
      {
        "name": "Monmouthshire [Sir Fynwy GB-FYN]",
        "code": "MON"
      },
      {
        "name": "Merton",
        "code": "MRT"
      },
      {
        "name": "Moray",
        "code": "MRY"
      },
      {
        "name": "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
        "code": "MTY"
      },
      {
        "name": "Mid Ulster",
        "code": "MUL"
      },
      {
        "name": "North Ayrshire",
        "code": "NAY"
      },
      {
        "name": "Northumberland",
        "code": "NBL"
      },
      {
        "name": "North East Lincolnshire",
        "code": "NEL"
      },
      {
        "name": "Newcastle upon Tyne",
        "code": "NET"
      },
      {
        "name": "Norfolk",
        "code": "NFK"
      },
      {
        "name": "Nottingham",
        "code": "NGM"
      },
      {
        "name": "Northern Ireland",
        "code": "NIR"
      },
      {
        "name": "North Lanarkshire",
        "code": "NLK"
      },
      {
        "name": "North Lincolnshire",
        "code": "NLN"
      },
      {
        "name": "Newry, Mourne and Down",
        "code": "NMD"
      },
      {
        "name": "North Somerset",
        "code": "NSM"
      },
      {
        "name": "Northamptonshire",
        "code": "NTH"
      },
      {
        "name": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
        "code": "NTL"
      },
      {
        "name": "Nottinghamshire",
        "code": "NTT"
      },
      {
        "name": "North Tyneside",
        "code": "NTY"
      },
      {
        "name": "Newham",
        "code": "NWM"
      },
      {
        "name": "Newport [Casnewydd GB-CNW]",
        "code": "NWP"
      },
      {
        "name": "North Yorkshire",
        "code": "NYK"
      },
      {
        "name": "Oldham",
        "code": "OLD"
      },
      {
        "name": "Orkney Islands",
        "code": "ORK"
      },
      {
        "name": "Oxfordshire",
        "code": "OXF"
      },
      {
        "name": "Pembrokeshire [Sir Benfro GB-BNF]",
        "code": "PEM"
      },
      {
        "name": "Perth and Kinross",
        "code": "PKN"
      },
      {
        "name": "Plymouth",
        "code": "PLY"
      },
      {
        "name": "Portsmouth",
        "code": "POR"
      },
      {
        "name": "Powys",
        "code": "POW"
      },
      {
        "name": "Peterborough",
        "code": "PTE"
      },
      {
        "name": "Redcar and Cleveland",
        "code": "RCC"
      },
      {
        "name": "Rochdale",
        "code": "RCH"
      },
      {
        "name": "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]",
        "code": "RCT"
      },
      {
        "name": "Redbridge",
        "code": "RDB"
      },
      {
        "name": "Reading",
        "code": "RDG"
      },
      {
        "name": "Renfrewshire",
        "code": "RFW"
      },
      {
        "name": "Richmond upon Thames",
        "code": "RIC"
      },
      {
        "name": "Rotherham",
        "code": "ROT"
      },
      {
        "name": "Rutland",
        "code": "RUT"
      },
      {
        "name": "Sandwell",
        "code": "SAW"
      },
      {
        "name": "South Ayrshire",
        "code": "SAY"
      },
      {
        "name": "Scottish Borders, The",
        "code": "SCB"
      },
      {
        "name": "Scotland",
        "code": "SCT"
      },
      {
        "name": "Suffolk",
        "code": "SFK"
      },
      {
        "name": "Sefton",
        "code": "SFT"
      },
      {
        "name": "South Gloucestershire",
        "code": "SGC"
      },
      {
        "name": "Sheffield",
        "code": "SHF"
      },
      {
        "name": "St. Helens",
        "code": "SHN"
      },
      {
        "name": "Shropshire",
        "code": "SHR"
      },
      {
        "name": "Stockport",
        "code": "SKP"
      },
      {
        "name": "Salford",
        "code": "SLF"
      },
      {
        "name": "Slough",
        "code": "SLG"
      },
      {
        "name": "South Lanarkshire",
        "code": "SLK"
      },
      {
        "name": "Sunderland",
        "code": "SND"
      },
      {
        "name": "Solihull",
        "code": "SOL"
      },
      {
        "name": "Somerset",
        "code": "SOM"
      },
      {
        "name": "Southend-on-Sea",
        "code": "SOS"
      },
      {
        "name": "Surrey",
        "code": "SRY"
      },
      {
        "name": "Stoke-on-Trent",
        "code": "STE"
      },
      {
        "name": "Stirling",
        "code": "STG"
      },
      {
        "name": "Southampton",
        "code": "STH"
      },
      {
        "name": "Sutton",
        "code": "STN"
      },
      {
        "name": "Staffordshire",
        "code": "STS"
      },
      {
        "name": "Stockton-on-Tees",
        "code": "STT"
      },
      {
        "name": "South Tyneside",
        "code": "STY"
      },
      {
        "name": "Swansea [Abertawe GB-ATA]",
        "code": "SWA"
      },
      {
        "name": "Swindon",
        "code": "SWD"
      },
      {
        "name": "Southwark",
        "code": "SWK"
      },
      {
        "name": "Tameside",
        "code": "TAM"
      },
      {
        "name": "Telford and Wrekin",
        "code": "TFW"
      },
      {
        "name": "Thurrock",
        "code": "THR"
      },
      {
        "name": "Torbay",
        "code": "TOB"
      },
      {
        "name": "Torfaen [Tor-faen]",
        "code": "TOF"
      },
      {
        "name": "Trafford",
        "code": "TRF"
      },
      {
        "name": "Tower Hamlets",
        "code": "TWH"
      },
      {
        "name": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
        "code": "VGL"
      },
      {
        "name": "Warwickshire",
        "code": "WAR"
      },
      {
        "name": "West Berkshire",
        "code": "WBK"
      },
      {
        "name": "West Dunbartonshire",
        "code": "WDU"
      },
      {
        "name": "Waltham Forest",
        "code": "WFT"
      },
      {
        "name": "Wigan",
        "code": "WGN"
      },
      {
        "name": "Wiltshire",
        "code": "WIL"
      },
      {
        "name": "Wakefield",
        "code": "WKF"
      },
      {
        "name": "Walsall",
        "code": "WLL"
      },
      {
        "name": "West Lothian",
        "code": "WLN"
      },
      {
        "name": "Wales",
        "code": "WLS"
      },
      {
        "name": "Wolverhampton",
        "code": "WLV"
      },
      {
        "name": "Wandsworth",
        "code": "WND"
      },
      {
        "name": "Windsor and Maidenhead",
        "code": "WNM"
      },
      {
        "name": "Wokingham",
        "code": "WOK"
      },
      {
        "name": "Worcestershire",
        "code": "WOR"
      },
      {
        "name": "Wirral",
        "code": "WRL"
      },
      {
        "name": "Warrington",
        "code": "WRT"
      },
      {
        "name": "Wrexham [Wrecsam GB-WRC]",
        "code": "WRX"
      },
      {
        "name": "Westminster",
        "code": "WSM"
      },
      {
        "name": "West Sussex",
        "code": "WSX"
      },
      {
        "name": "York",
        "code": "YOR"
      },
      {
        "name": "Shetland Islands",
        "code": "ZET"
      }
    ]
  },
  {
    "name": "United States of America",
    "alpha3": "USA",
    "postal_code_format": "\\A(\\d{5})(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": [
      {
        "name": "Alaska",
        "code": "AK"
      },
      {
        "name": "Alabama",
        "code": "AL"
      },
      {
        "name": "Arkansas",
        "code": "AR"
      },
      {
        "name": "American Samoa",
        "code": "AS"
      },
      {
        "name": "Arizona",
        "code": "AZ"
      },
      {
        "name": "California",
        "code": "CA"
      },
      {
        "name": "Colorado",
        "code": "CO"
      },
      {
        "name": "Connecticut",
        "code": "CT"
      },
      {
        "name": "District of Columbia",
        "code": "DC"
      },
      {
        "name": "Delaware",
        "code": "DE"
      },
      {
        "name": "Florida",
        "code": "FL"
      },
      {
        "name": "Georgia",
        "code": "GA"
      },
      {
        "name": "Guam",
        "code": "GU"
      },
      {
        "name": "Hawaii",
        "code": "HI"
      },
      {
        "name": "Iowa",
        "code": "IA"
      },
      {
        "name": "Idaho",
        "code": "ID"
      },
      {
        "name": "Illinois",
        "code": "IL"
      },
      {
        "name": "Indiana",
        "code": "IN"
      },
      {
        "name": "Kansas",
        "code": "KS"
      },
      {
        "name": "Kentucky",
        "code": "KY"
      },
      {
        "name": "Louisiana",
        "code": "LA"
      },
      {
        "name": "Massachusetts",
        "code": "MA"
      },
      {
        "name": "Maryland",
        "code": "MD"
      },
      {
        "name": "Maine",
        "code": "ME"
      },
      {
        "name": "Michigan",
        "code": "MI"
      },
      {
        "name": "Minnesota",
        "code": "MN"
      },
      {
        "name": "Missouri",
        "code": "MO"
      },
      {
        "name": "Northern Mariana Islands",
        "code": "MP"
      },
      {
        "name": "Mississippi",
        "code": "MS"
      },
      {
        "name": "Montana",
        "code": "MT"
      },
      {
        "name": "North Carolina",
        "code": "NC"
      },
      {
        "name": "North Dakota",
        "code": "ND"
      },
      {
        "name": "Nebraska",
        "code": "NE"
      },
      {
        "name": "New Hampshire",
        "code": "NH"
      },
      {
        "name": "New Jersey",
        "code": "NJ"
      },
      {
        "name": "New Mexico",
        "code": "NM"
      },
      {
        "name": "Nevada",
        "code": "NV"
      },
      {
        "name": "New York",
        "code": "NY"
      },
      {
        "name": "Ohio",
        "code": "OH"
      },
      {
        "name": "Oklahoma",
        "code": "OK"
      },
      {
        "name": "Oregon",
        "code": "OR"
      },
      {
        "name": "Pennsylvania",
        "code": "PA"
      },
      {
        "name": "Puerto Rico",
        "code": "PR"
      },
      {
        "name": "Rhode Island",
        "code": "RI"
      },
      {
        "name": "South Carolina",
        "code": "SC"
      },
      {
        "name": "South Dakota",
        "code": "SD"
      },
      {
        "name": "Tennessee",
        "code": "TN"
      },
      {
        "name": "Texas",
        "code": "TX"
      },
      {
        "name": "United States Minor Outlying Islands",
        "code": "UM"
      },
      {
        "name": "Utah",
        "code": "UT"
      },
      {
        "name": "Virginia",
        "code": "VA"
      },
      {
        "name": "Virgin Islands, U.S.",
        "code": "VI"
      },
      {
        "name": "Vermont",
        "code": "VT"
      },
      {
        "name": "Washington",
        "code": "WA"
      },
      {
        "name": "Wisconsin",
        "code": "WI"
      },
      {
        "name": "West Virginia",
        "code": "WV"
      },
      {
        "name": "Wyoming",
        "code": "WY"
      }
    ]
  },
  {
    "name": "United States Minor Outlying Islands",
    "alpha3": "UMI",
    "postal_code_format": "\\A96898\\Z",
    "subdivisions": [
      {
        "name": "Johnston Atoll",
        "code": "67"
      },
      {
        "name": "Midway Islands",
        "code": "71"
      },
      {
        "name": "Navassa Island",
        "code": "76"
      },
      {
        "name": "Wake Island",
        "code": "79"
      },
      {
        "name": "Baker Island",
        "code": "81"
      },
      {
        "name": "Howland Island",
        "code": "84"
      },
      {
        "name": "Jarvis Island",
        "code": "86"
      },
      {
        "name": "Kingman Reef",
        "code": "89"
      },
      {
        "name": "Palmyra Atoll",
        "code": "95"
      }
    ]
  },
  {
    "name": "Uruguay",
    "alpha3": "URY",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Artigas",
        "code": "AR"
      },
      {
        "name": "Canelones",
        "code": "CA"
      },
      {
        "name": "Cerro Lago",
        "code": "CL"
      },
      {
        "name": "Colonia",
        "code": "CO"
      },
      {
        "name": "Durazno",
        "code": "DU"
      },
      {
        "name": "Florida",
        "code": "FD"
      },
      {
        "name": "Flores",
        "code": "FS"
      },
      {
        "name": "Lavalleja",
        "code": "LA"
      },
      {
        "name": "Maldonado",
        "code": "MA"
      },
      {
        "name": "Montevideo",
        "code": "MO"
      },
      {
        "name": "Paysandú",
        "code": "PA"
      },
      {
        "name": "Río Negro",
        "code": "RN"
      },
      {
        "name": "Rocha",
        "code": "RO"
      },
      {
        "name": "Rivera",
        "code": "RV"
      },
      {
        "name": "Salto",
        "code": "SA"
      },
      {
        "name": "San José",
        "code": "SJ"
      },
      {
        "name": "Soriano",
        "code": "SO"
      },
      {
        "name": "Tacuarembó",
        "code": "TA"
      },
      {
        "name": "Treinta y Tres",
        "code": "TT"
      }
    ]
  },
  {
    "name": "Uzbekistan",
    "alpha3": "UZB",
    "postal_code_format": "\\A\\d{6}\\Z",
    "subdivisions": [
      {
        "name": "Andijon",
        "code": "AN"
      },
      {
        "name": "Buxoro",
        "code": "BU"
      },
      {
        "name": "Farg‘ona",
        "code": "FA"
      },
      {
        "name": "Jizzax",
        "code": "JI"
      },
      {
        "name": "Namangan",
        "code": "NG"
      },
      {
        "name": "Navoiy",
        "code": "NW"
      },
      {
        "name": "Qashqadaryo",
        "code": "QA"
      },
      {
        "name": "Qoraqalpog‘iston Respublikasi",
        "code": "QR"
      },
      {
        "name": "Samarqand",
        "code": "SA"
      },
      {
        "name": "Sirdaryo",
        "code": "SI"
      },
      {
        "name": "Surxondaryo",
        "code": "SU"
      },
      {
        "name": "Toshkent City",
        "code": "TK"
      },
      {
        "name": "Toshkent",
        "code": "TO"
      },
      {
        "name": "Xorazm",
        "code": "XO"
      }
    ]
  },
  {
    "name": "Vanuatu",
    "alpha3": "VUT",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Malampa",
        "code": "MAP"
      },
      {
        "name": "Pénama",
        "code": "PAM"
      },
      {
        "name": "Sanma",
        "code": "SAM"
      },
      {
        "name": "Shéfa",
        "code": "SEE"
      },
      {
        "name": "Taféa",
        "code": "TAE"
      },
      {
        "name": "Torba",
        "code": "TOB"
      }
    ]
  },
  {
    "name": "Venezuela (Bolivarian Republic of)",
    "alpha3": "VEN",
    "postal_code_format": "\\A\\d{4}\\Z",
    "subdivisions": [
      {
        "name": "Distrito Federal",
        "code": "A"
      },
      {
        "name": "Anzoátegui",
        "code": "B"
      },
      {
        "name": "Apure",
        "code": "C"
      },
      {
        "name": "Aragua",
        "code": "D"
      },
      {
        "name": "Barinas",
        "code": "E"
      },
      {
        "name": "Bolívar",
        "code": "F"
      },
      {
        "name": "Carabobo",
        "code": "G"
      },
      {
        "name": "Cojedes",
        "code": "H"
      },
      {
        "name": "Falcón",
        "code": "I"
      },
      {
        "name": "Guárico",
        "code": "J"
      },
      {
        "name": "Lara",
        "code": "K"
      },
      {
        "name": "Mérida",
        "code": "L"
      },
      {
        "name": "Miranda",
        "code": "M"
      },
      {
        "name": "Monagas",
        "code": "N"
      },
      {
        "name": "Nueva Esparta",
        "code": "O"
      },
      {
        "name": "Portuguesa",
        "code": "P"
      },
      {
        "name": "Sucre",
        "code": "R"
      },
      {
        "name": "Táchira",
        "code": "S"
      },
      {
        "name": "Trujillo",
        "code": "T"
      },
      {
        "name": "Yaracuy",
        "code": "U"
      },
      {
        "name": "Zulia",
        "code": "V"
      },
      {
        "name": "Dependencias Federales",
        "code": "W"
      },
      {
        "name": "Vargas",
        "code": "X"
      },
      {
        "name": "Delta Amacuro",
        "code": "Y"
      },
      {
        "name": "Amazonas",
        "code": "Z"
      }
    ]
  },
  {
    "name": "Viet Nam",
    "alpha3": "VNM",
    "postal_code_format": "\\A\\d{5}\\d?\\Z",
    "subdivisions": [
      {
        "name": "Lai Chau",
        "code": "01"
      },
      {
        "name": "Lao Cai",
        "code": "02"
      },
      {
        "name": "Ha Giang",
        "code": "03"
      },
      {
        "name": "Cao Bang",
        "code": "04"
      },
      {
        "name": "Son La",
        "code": "05"
      },
      {
        "name": "Yen Bai",
        "code": "06"
      },
      {
        "name": "Tuyen Quang",
        "code": "07"
      },
      {
        "name": "Lang Son",
        "code": "09"
      },
      {
        "name": "Quang Ninh",
        "code": "13"
      },
      {
        "name": "Hoa Binh",
        "code": "14"
      },
      {
        "name": "Ninh Binh",
        "code": "18"
      },
      {
        "name": "Thai Binh",
        "code": "20"
      },
      {
        "name": "Thanh Hoa",
        "code": "21"
      },
      {
        "name": "Nghe An",
        "code": "22"
      },
      {
        "name": "Ha Tinh",
        "code": "23"
      },
      {
        "name": "Quang Binh",
        "code": "24"
      },
      {
        "name": "Quang Tri",
        "code": "25"
      },
      {
        "name": "Thua Thien-Hue",
        "code": "26"
      },
      {
        "name": "Quang Nam",
        "code": "27"
      },
      {
        "name": "Kon Tum",
        "code": "28"
      },
      {
        "name": "Quang Ngai",
        "code": "29"
      },
      {
        "name": "Gia Lai",
        "code": "30"
      },
      {
        "name": "Binh Dinh",
        "code": "31"
      },
      {
        "name": "Phu Yen",
        "code": "32"
      },
      {
        "name": "Dac Lac",
        "code": "33"
      },
      {
        "name": "Khanh Hoa",
        "code": "34"
      },
      {
        "name": "Lam Dong",
        "code": "35"
      },
      {
        "name": "Ninh Thuan",
        "code": "36"
      },
      {
        "name": "Tay Ninh",
        "code": "37"
      },
      {
        "name": "Dong Nai",
        "code": "39"
      },
      {
        "name": "Binh Thuan",
        "code": "40"
      },
      {
        "name": "Long An",
        "code": "41"
      },
      {
        "name": "Ba Ria - Vung Tau",
        "code": "43"
      },
      {
        "name": "An Giang",
        "code": "44"
      },
      {
        "name": "Dong Thap",
        "code": "45"
      },
      {
        "name": "Tien Giang",
        "code": "46"
      },
      {
        "name": "Kien Giang",
        "code": "47"
      },
      {
        "name": "Vinh Long",
        "code": "49"
      },
      {
        "name": "Ben Tre",
        "code": "50"
      },
      {
        "name": "Tra Vinh",
        "code": "51"
      },
      {
        "name": "Soc Trang",
        "code": "52"
      },
      {
        "name": "Bac Can",
        "code": "53"
      },
      {
        "name": "Bac Giang",
        "code": "54"
      },
      {
        "name": "Bac Lieu",
        "code": "55"
      },
      {
        "name": "Bac Ninh",
        "code": "56"
      },
      {
        "name": "Binh Duong",
        "code": "57"
      },
      {
        "name": "Binh Phuoc",
        "code": "58"
      },
      {
        "name": "Ca Mau",
        "code": "59"
      },
      {
        "name": "Hai Duong",
        "code": "61"
      },
      {
        "name": "Ha Nam",
        "code": "63"
      },
      {
        "name": "Hung Yen",
        "code": "66"
      },
      {
        "name": "Nam Dinh",
        "code": "67"
      },
      {
        "name": "Phu Tho",
        "code": "68"
      },
      {
        "name": "Thai Nguyen",
        "code": "69"
      },
      {
        "name": "Vinh Phuc",
        "code": "70"
      },
      {
        "name": "Dien Bien",
        "code": "71"
      },
      {
        "name": "Dak Nong",
        "code": "72"
      },
      {
        "name": "Hau Giang",
        "code": "73"
      },
      {
        "name": "Can Tho",
        "code": "CT"
      },
      {
        "name": "Da Nang",
        "code": "DN"
      },
      {
        "name": "Ha Noi",
        "code": "HN"
      },
      {
        "name": "Hai Phong",
        "code": "HP"
      },
      {
        "name": "Ho Chi Minh",
        "code": "SG"
      }
    ]
  },
  {
    "name": "Virgin Islands (British)",
    "alpha3": "VGB",
    "postal_code_format": "\\AVG\\d{4}\\Z",
    "subdivisions": []
  },
  {
    "name": "Virgin Islands (U.S.)",
    "alpha3": "VIR",
    "postal_code_format": "\\A(008(?:(?:[0-4]\\d)|(?:5[01])))(?:[ \\-](\\d{4}))?\\Z",
    "subdivisions": []
  },
  {
    "name": "Wallis and Futuna",
    "alpha3": "WLF",
    "postal_code_format": "\\A986\\d{2}\\Z",
    "subdivisions": [
      {
        "name": "Alo",
        "code": "AL"
      },
      {
        "name": "Sigave",
        "code": "SG"
      },
      {
        "name": "Uvea",
        "code": "UV"
      }
    ]
  },
  {
    "name": "Western Sahara",
    "alpha3": "ESH",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": []
  },
  {
    "name": "Kosovo",
    "alpha3": "XKX",
    "postal_code_format": null,
    "subdivisions": []
  },
  {
    "name": "Yemen",
    "alpha3": "YEM",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Abyan",
        "code": "AB"
      },
      {
        "name": "ʿAdan",
        "code": "AD"
      },
      {
        "name": "'Amran",
        "code": "AM"
      },
      {
        "name": "Al Bayḑā’",
        "code": "BA"
      },
      {
        "name": "Ad¸ D¸ali'",
        "code": "DA"
      },
      {
        "name": "Dhamar",
        "code": "DH"
      },
      {
        "name": "Hadramawt",
        "code": "HD"
      },
      {
        "name": "Hajjah",
        "code": "HJ"
      },
      {
        "name": "Al Ḩudaydah",
        "code": "HU"
      },
      {
        "name": "Ibb",
        "code": "IB"
      },
      {
        "name": "Al Jawf",
        "code": "JA"
      },
      {
        "name": "Laḩij",
        "code": "LA"
      },
      {
        "name": "Ma'rib",
        "code": "MA"
      },
      {
        "name": "Al Mahrah",
        "code": "MR"
      },
      {
        "name": "Al Mahwit",
        "code": "MW"
      },
      {
        "name": "محافظة ريمة",
        "code": "RA"
      },
      {
        "name": "صنعاء",
        "code": "SA"
      },
      {
        "name": "Sa`dah",
        "code": "SD"
      },
      {
        "name": "Shabwah",
        "code": "SH"
      },
      {
        "name": "Sanʿā",
        "code": "SN"
      },
      {
        "name": "محافظة أرخبيل سقطرى",
        "code": "SU"
      },
      {
        "name": "Taʿizz",
        "code": "TA"
      }
    ]
  },
  {
    "name": "Zambia",
    "alpha3": "ZMB",
    "postal_code_format": "\\A\\d{5}\\Z",
    "subdivisions": [
      {
        "name": "Western",
        "code": "01"
      },
      {
        "name": "Central",
        "code": "02"
      },
      {
        "name": "Eastern",
        "code": "03"
      },
      {
        "name": "Luapula",
        "code": "04"
      },
      {
        "name": "Northern",
        "code": "05"
      },
      {
        "name": "North-Western",
        "code": "06"
      },
      {
        "name": "Southern",
        "code": "07"
      },
      {
        "name": "Copperbelt",
        "code": "08"
      },
      {
        "name": "Lusaka",
        "code": "09"
      },
      {
        "name": "Muchinga",
        "code": "10"
      }
    ]
  },
  {
    "name": "Zimbabwe",
    "alpha3": "ZWE",
    "postal_code_format": null,
    "subdivisions": [
      {
        "name": "Bulawayo",
        "code": "BU"
      },
      {
        "name": "Harare",
        "code": "HA"
      },
      {
        "name": "Manicaland",
        "code": "MA"
      },
      {
        "name": "Mashonaland Central",
        "code": "MC"
      },
      {
        "name": "Mashonaland East",
        "code": "ME"
      },
      {
        "name": "Midlands",
        "code": "MI"
      },
      {
        "name": "Matabeleland North",
        "code": "MN"
      },
      {
        "name": "Matabeleland South",
        "code": "MS"
      },
      {
        "name": "Masvingo",
        "code": "MV"
      },
      {
        "name": "Mashonaland West",
        "code": "MW"
      }
    ]
  }
]
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import Table from './Table'

export const Permissions = [
  {
    resource: 'Products',
    description: {
      normal: 'The user cannot see or interact with products.',
      read: 'The user can view product details like Name, Price and Date of Creation.',
      write: 'The user can view, create, edit, and delete products.'
    }
  },
  {
    resource: 'PaymentLinks',
    description: {
      normal: 'The user has no access to payment links.',
      read: 'The user can view existing payment links, products they are linked to and payment methods accepted.',
      write: 'The user can view, create, modify, and delete payment links.'
    }
  },
  {
    resource: 'Invoices',
    description: {
      normal: 'The user has no access to invoices.',
      read: 'The user can view invoice details, including status and payment history.',
      write: 'The user can view, generate, send and delete invoices.'
    }
  },
  {
    resource: 'CheckoutSessions',
    description: {
      normal: 'The user has no access to checkout sessions.',
      read: 'The user can view the status of checkout sessions and when they were initiated.',
      write: 'The user can view, create, modify, and terminate checkout sessions.'
    }
  },
  {
    resource: 'PaymentSessions',
    description: {
      normal: 'The user has no access to payment sessions.',
      read: 'The user can view the status of payment sessions and when they were initiated.',
      write: 'The user can view, create, modify, and terminate payment sessions.'
    }
  },
  {
    resource: 'Customers',
    description: {
      normal: 'The user has no access to customer data.',
      read: 'The user can view customer profiles, total spend and other details.',
      write: 'The user can view, create and delete customer profiles.'
    }
  },
  {
    resource: 'Webhooks',
    description: {
      normal: 'The user has no access to webhooks.',
      read: 'The user can view webhook logs and configurations.',
      write: 'The user can create, edit, and delete webhooks.'
    }
  },
  {
    resource: 'Events',
    readOnly: true,
    description: {
      normal: 'The user does not have access to download and export data from the dashboard.',
      read: 'The user can download and export dashboard data such as Balance, Payments Received, Payouts Made and Withdrawals.'
    }
  },
  {
    resource: 'Balance',
    readOnly: true,
    description: {
      normal: 'The user has no access to see the balance of the funds in the dashboard.',
      read: 'The user can see available balances.'
    }
  },
  {
    resource: 'IncompletePayments',
    writeOnly: true,
    description: {
      normal: 'The user has no access to view incomplete payments.',
      write: 'The user has permissions to retry, follow up and cancel incomplete payments.'
    }
  },
  {
    resource: 'Refunds',
    description: {
      normal: 'The user has no access to refund data.',
      read: 'The user can view refund requests and their status.',
      write: 'The user can initiate, approve, and cancel refunds.'
    }
  },
  {
    resource: 'Withdrawal',
    description: {
      normal: 'The user has no access to withdrawals.',
      read: 'The user can view withdrawal requests, their statuses and other associated data.',
      write: 'The user can initiate withdrawal requests and access all associated data within the dashboard.'
    }
  },
  {
    resource: 'WithdrawalAccount',
    description: {
      normal: 'The user has no access to withdrawal accounts.',
      read: 'The user can view registered crypto wallets and bank accounts used for withdrawals.',
      write: 'The user can add, edit, and remove fiat and crypto withdrawal accounts.'
    }
  },
  {
    resource: 'Automation',
    description: {
      normal: 'The user has no access to automation settings.',
      read: 'The user can view existing automation rules.',
      write: 'The user can create, modify, and disable automations like withdrawals.'
    }
  },
  {
    resource: 'Subscriptions',
    description: {
      normal: 'The user has no access to subscription data.',
      read: 'The user can view active subscriptions.',
      write: 'The user can view, manage and cancel active subscriptions.'
    }
  },
  {
    resource: 'DiscountCodes',
    description: {
      normal: 'The user has no access to discount codes.',
      read: 'The user can view active and expired discount codes.',
      write: 'The user can view, create and delete discount codes.'
    }
  },
  {
    resource: 'APITokens',
    description: {
      normal: 'The user has no access to API tokens.',
      read: 'The user can view API tokens and usage logs.',
      write: 'The user can view, generate, revoke, and manage API tokens.'
    }
  },
  {
    resource: 'Organization',
    writeOnly: true,
    description: {
      normal: 'The user has no access to organization settings.',
      write: 'The user can modify organization details and settings like branding and currency denominations.'
    }
  },
  {
    resource: 'Roles',
    description: {
      normal: 'The user has no access to roles.',
      read: 'The user can view existing roles and assigned permissions.',
      write: 'The user can create, edit, assign, and delete roles.'
    }
  },
  {
    resource: 'Team',
    description: {
      normal: 'The user has no access to team members.',
      read: 'The user can view team members and their roles.',
      write: 'The user can invite, remove, and update roles of team members.'
    }
  },
  {
    resource: 'Payouts',
    description: {
      normal: 'The ability to read and/or create draft payouts to be confirmed.',
      read: 'The user will be able to read all payouts.',
      write: 'The user will be able to read all payouts and create draft payouts.'
    }
  },
  {
    resource: 'ConfirmPayouts',
    writeOnly: true,
    description: {
      normal: 'The user has no access to confirm payouts.',
      write: 'The user can view pending payouts and approve or reject payout confirmations.'
    }
  }
]

const PermissionTogglet = styled.div<{ $selected?: boolean }>`
  border-right: 1px solid ${RADOM_COLORS.GRAY_LIGHTEST};
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease all;
  color: ${RADOM_COLORS.GRAY_DARKER};

  ${({ $selected }) => $selected && `
    font-weight: 600;
    color: ${RADOM_COLORS.NEW_GRAY_1};
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  `}

  &:active {
    opacity: 0.5;
  }

  &:last-child {
    border-right: 0;
  }

`

enum PermissionType {
  None,
  Read,
  Write
}

interface PermissionToggleProps {
  readOnly?: boolean
  writeOnly?: boolean
  onChange: (permissions: PermissionType) => void
}

const PermissionToggle = ({ readOnly, writeOnly, onChange }: PermissionToggleProps): ReactElement => {
  const [selectedPermission, setSelectedPermission] = useState(PermissionType.None)

  useEffect(() => {
    onChange(selectedPermission)
  }, [selectedPermission])

  return <div style={{
    display: 'flex',
    fontSize: 14,
    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
    boxShadow: `0 1px 1px ${RADOM_COLORS.GRAY_LIGHTEST}`,
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: 5,
    overflow: 'hidden'
  }}>
    <PermissionTogglet
      onClick={() => setSelectedPermission(PermissionType.None)}
      $selected={selectedPermission === PermissionType.None}>
        None
    </PermissionTogglet>

    {
      !writeOnly &&
      <PermissionTogglet
        onClick={() => setSelectedPermission(PermissionType.Read)}
        $selected={selectedPermission === PermissionType.Read}>
          Read
      </PermissionTogglet>
    }

    {
      !readOnly &&
      <PermissionTogglet
        onClick={() => setSelectedPermission(PermissionType.Write)}
        $selected={selectedPermission === PermissionType.Write}>
          Write
      </PermissionTogglet>
    }
  </div>
}

interface PermissionSelectorProps {
  permissions: any
  onPermissionsChange: (permissions: any) => void
}

const PermissionSelector = (props: PermissionSelectorProps): ReactElement => {
  const [permissions, setPermissions] = useState(
    props.permissions ||
    Permissions.reduce((t, c) => {
      if (!c.writeOnly) {
        t[`Read${c.resource}`] = false
      }

      if (!c.readOnly) {
        t[`Write${c.resource}`] = false
      }

      return t
    }, {})
  )

  const [descriptions, setDescriptions] = useState(
    Permissions.reduce((acc, curr) => {
      acc[curr.resource] = 'None' // 初始化每一行的 description
      return acc
    }, {})
  )

  useEffect(() => {
    props.onPermissionsChange(permissions)
  }, [permissions])

  const handlePermissionChange = (resource: string, permission: PermissionType): void => {
    // Find the corresponding permission object
    const permissionObj = Permissions.find(p => p.resource === resource)

    // Determine the appropriate description
    const description =
      permission === PermissionType.None
        ? permissionObj?.description?.normal || ''
        : permissionObj?.description
          ? (permission === PermissionType.Read ? permissionObj.description.read : permissionObj.description.write) ||
            permissionObj.description.normal
          : (permission === PermissionType.Read ? `Read: ${resource}` : `Write: ${resource}`)

    // Update descriptions state
    setDescriptions((prev) => ({
      ...prev,
      [resource]: description
    }))

    // Update permissions state while respecting readOnly and writeOnly flags
    setPermissions((prev) => ({
      ...prev,
      ...(permissionObj?.writeOnly ? {} : { [`Read${resource}`]: permission === PermissionType.Read || permission === PermissionType.Write }),
      ...(permissionObj?.readOnly ? {} : { [`Write${resource}`]: permission === PermissionType.Write })
    }))
  }

  return (
    <Table
      headers={[
        <div
          key="resource"
          style={{ opacity: 0.3, textTransform: 'uppercase', fontSize: 12 }}>
          Resource
        </div>,
        <div
          key="permissions"
          style={{ opacity: 0.3, textTransform: 'uppercase', fontSize: 12 }}>
          Permissions
        </div>
      ]}
      isLoading={false}
      disableHoverStyle
      items={Permissions.map((p) => [
        <div
          key={p.resource}
          style={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-start'
          }}
        >
          <div>
            {p.resource}
            {p.description && (
              <div style={{ marginTop: 3, color: RADOM_COLORS.GRAY_DARKER }}>
                {descriptions[p.resource]}
              </div>
            )}
          </div>

        </div>,
        <PermissionToggle
          key={p.resource}
          readOnly={p.readOnly}
          writeOnly={p.writeOnly}
          onChange={(permission) => handlePermissionChange(p.resource, permission)}
        />
      ])}
    />

  )
}

export default PermissionSelector

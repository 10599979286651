import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { RADOM_COLORS } from '../../util/Constants'
import { Chevron } from '../../icons/Chevron'
import { PrimaryButton } from '../../components/Button'
import FileInput from '../../components/FileInput'
import { errorToast } from '../../util/Util'
import Spinner from '../../components/Spinner'

interface IProps {
  file?: any
  onFile: (file: File) => void
  onRemove: () => void
}

const PDFFileUpload = (props: IProps) => {
  const [maxPages, setMaxPages] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(0)

  if (!props.file) {
    return <FileInput
      value={props.file}
      onChange={evt => {
        if (!evt.target.files || evt.target.files.length <= 0) {
          return;
        }

        const file = evt.target.files[0]
        if (file.type != 'application/pdf') {
          errorToast('Invalid file type. Please upload a PDF file.')
        } else {
          props.onFile(file)
        }
      }}
    />
  }

  return <div style={{ width: 304, border: `1px solid ${RADOM_COLORS.GRAY_DARK}`, borderRadius: 5 }}>
    <div
      style={{
        background: RADOM_COLORS.GRAY_DARK,
        padding: '8px 10px',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <span>
        {maxPages} pages
      </span>
      <PrimaryButton
        type='button'
        onClick={props.onRemove}
        style={{
          textTransform: 'initial',
          fontSize: 14,
          padding: '5px 14px',
          background: RADOM_COLORS.GRAY_DARKEST
        }}>Remove</PrimaryButton>
    </div>
    <Document
      loading={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300 }}>
        <Spinner />
      </div>}
      file={props.file}
      onLoadSuccess={(a) => {
        setMaxPages(a.numPages)
      }}>
      <Page
        width={300}
        pageIndex={pageIndex}
        renderTextLayer={false}
        renderAnnotationLayer={false} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div
          style={{
            background: RADOM_COLORS.GRAY_DARK,
            padding: '5px 10px',
            textTransform: 'uppercase',
            borderTopLeftRadius: 5
          }}>
          Page {pageIndex + 1}
        </div>
        <div
          onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: RADOM_COLORS.GRAY_DARKEST,
            padding: '5px 10px',
            borderLeft: '1px solid white',
            borderRight: '1px solid white'
          }}>
          <Chevron fill='white' style={{ transform: 'rotate(90deg)' }} />
        </div>
        <div
          onClick={() => setPageIndex(Math.min(maxPages - 1, pageIndex + 1))}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: RADOM_COLORS.GRAY_DARKEST,
            padding: '5px 10px',
            borderBottomRightRadius: 5
          }}>
          <Chevron fill='white' style={{ transform: 'rotate(-90deg)' }} />
        </div>
      </div>
    </Document>
  </div>
}

export default PDFFileUpload
import { observer } from 'mobx-react'
import React, { ReactElement, ReactNode, useEffect, useReducer, useState } from 'react'
import { Container } from '../../components/Animations'
import { PrimaryButton } from '../../components/Button'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import PageHeader from '../../components/PageHeader'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import Table from '../../components/Table'
import TimeText from '../../components/TimeText'
import Dots from '../../icons/Dots'
import Radom, { DepositAccount, DepositAccountDetails, WithdrawalAccount, WithdrawalAccountDetails } from '../../state/Radom'
import { getMethod } from '../../util/Managed'
import { infoToast, successToast, useTestMode } from '../../util/Util'
import Tabs from '../../components/Tabs'
import CreateWithdrawalAccountModal from './CreateWithdrawalAccountModal'
import CreateDepositAccountModal from './CreateDepositAccountModal'
import { useLocation, useNavigate } from 'react-router-dom'
import Pagination from '../../components/Pagination'
import CopyButton from '../../components/CopyButton'

export const formatWithdrawalAccountType = (accountDetails: WithdrawalAccountDetails): ReactNode => {
  if (accountDetails.Crypto) {
    return <PaymentMethodDisplay method={{ network: accountDetails.Crypto.network }} />
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.iban) {
      return 'Fiat / IBAN'
    }
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.us) {
      return 'Fiat / US'
    }
  }

  return ''
}

export const formatWithdrawalAccount = (accountDetails: WithdrawalAccountDetails): string => {
  if (accountDetails.Crypto) {
    return accountDetails.Crypto.address
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.iban) {
      return accountDetails.Fiat.accountType.iban.iban
    }
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.us) {
      return accountDetails.Fiat.accountType.us.accountNumber
    }
  }

  return ''
}

export const formatDepositAccountType = (accountDetails: DepositAccountDetails): ReactNode => {
  if (accountDetails.Crypto) {

    return <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      {
        accountDetails.Crypto.depositMethods.slice(0, 3).map(m =>

          <PaymentMethodDisplay
            key={m.network + m.token}
            method={{ network: m.network, token: m.token }}
            excludeName
            disableTooltip={false} />
        )
      }
      {accountDetails.Crypto.depositMethods.length > 3 && `+${accountDetails.Crypto.depositMethods.length - 3}`}
    </div>
  }

  return ''
}

export const formatDepositAccount = (accountDetails: DepositAccountDetails): string => {
  if (accountDetails.Crypto) {
    return accountDetails.Crypto.address
  }

  return ''
}

const Accounts = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCreateWithdrawalModalOpen, setIsCreateWithdrawalModalOpen] = useState(false)
  const [isCreateDepositModalOpen, setIsCreateDepositModalOpen] = useState(false)
  const [isAccountVerificationModalOpen, setIsAccountVerificationModalOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const [selectedTab, setSelectedTab] = useState<string>(location.pathname.toLowerCase().includes('deposit') ? 'Deposit' : 'Withdrawal')

  const [withdrawalAccounts, setWithdrawalAccounts] = useState<WithdrawalAccount[]>([])
  const [depositAccounts, setDepositAccounts] = useState<DepositAccount[]>([])

  const [testMode] = useTestMode()

  const [closeDropdownFns, updateCloseDropdownFns] = useReducer((p, n) => ({ ...p, ...n }), {})

  const fetchWithdrawalAccounts = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const accounts = await Radom.listWithdrawalAccounts()
      setWithdrawalAccounts(accounts)
    } catch (err) {

    }
    setIsLoading(false)
  }


  const pageSize = 20
  const [curPage, setCurPage] = useState(0)
  const [totalDepositAccounts, setTotalDepositAccounts] = useState(0)

  const fetchDepositAccounts = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const res = await Radom.listDepositAccounts(testMode, curPage * pageSize, pageSize)
      setTotalDepositAccounts(res.totalCount)
      setDepositAccounts(res.data)
    } catch (err) {

    }
    setIsLoading(false)
  }


  useEffect(() => {
    setDepositAccounts([])
    fetchDepositAccounts()
  }, [curPage])

  useEffect(() => {
    if (location.pathname.toLowerCase().includes('deposit')) {
      setSelectedTab('Deposit')
    } else {
      setSelectedTab('Withdrawal')
    }
  }, [location])

  useEffect(() => {
    navigate(`/accounts/${selectedTab.toLowerCase()}`)
    setCurPage(0)

    if (selectedTab == 'Withdrawal') {
      fetchWithdrawalAccounts()
    } else {
      fetchDepositAccounts()
    }
  }, [selectedTab, testMode])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <PageHeader title='Accounts' />
          <PrimaryButton style={{ gap: 8 }} onClick={() => {
            if (selectedTab == 'Withdrawal') {
              setIsCreateWithdrawalModalOpen(true)
            } else {
              setIsCreateDepositModalOpen(true)
            }
          }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create {selectedTab.toLowerCase()} account</span>
          </PrimaryButton>
        </div>

        <Tabs
          selectedTab={selectedTab}
          tabs={['Withdrawal', 'Deposit']}
          onTabChange={(tab) => { setSelectedTab(tab) }} />
      </div>

      {
        selectedTab == 'Withdrawal' &&
        <Table
          borderless
          headers={['Account', 'Type', 'Details', 'Created at', ''].map(t => <span key={t}>{t}</span>)}
          items={
            withdrawalAccounts
              .filter(t => testMode
                ? getMethod(t.accountDetails.Crypto?.network ?? '').isTestnet
                : !getMethod(t.accountDetails.Crypto?.network ?? '').isTestnet
              ).map(a => [
                <div key={0} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <span>{a.name}</span>
                  <span style={{ opacity: 0.5, fontSize: 14 }}>{a.id}</span>
                </div>,
                <div key={2}>
                  {formatWithdrawalAccountType(a.accountDetails)}
                </div>,
                <div key={3}>
                  {formatWithdrawalAccount(a.accountDetails)}
                </div>,
                <TimeText key="txTime" date={new Date(a.createdAt)} />,
                <div key={a.id} onClick={e => e.stopPropagation()}>

                  <Dropdown
                    noChevron
                    onCloseFn={f => updateCloseDropdownFns({ [a.id]: f })}
                    selectedContent={<Dots width={15} />}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem onClick={() => {
                          navigator.clipboard.writeText(a.id)
                          infoToast('Account ID copied to clipboard')
                          closeDropdownFns[a.id]()
                        }}>Copy account ID</DropdownItem>
                        <ConfirmButtonModal
                          title='Delete account'
                          description='Are you sure you want to delete this account?'
                          action='Delete account'
                          onConfirm={async () => {
                            Radom.deleteWithdrawalAccount(a.id)
                              .then(async () => await fetchWithdrawalAccounts())
                              .then(() => successToast('Account deleted'))
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Delete account</DropdownItem>}
                        />
                      </div>

                    }
                  />
                </div>
              ])
          }
          emptyPlaceholderText='No accounts'
          isLoading={isLoading} />
      }

      {
        selectedTab == 'Deposit' && <>
          <Table
            borderless
            headers={['Account', 'Type', 'Details', 'Created at', ''].map(t => <span key={t}>{t}</span>)}
            items={
              depositAccounts
                .filter(t => testMode
                  ? t.accountDetails.Crypto?.depositMethods?.some(t => getMethod(t.network).isTestnet)
                  : !t.accountDetails.Crypto?.depositMethods?.some(t => getMethod(t.network).isTestnet)
                ).map(a => [
                  <div key={0} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <span>{a.name}</span>
                    <span style={{ opacity: 0.5, fontSize: 14 }}>{a.id}</span>
                  </div>,
                  <div key={2}>
                    {formatDepositAccountType(a.accountDetails)}
                  </div>,
                  <div key={3} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    {formatDepositAccount(a.accountDetails)}
                    <CopyButton text={a.accountDetails.Crypto?.address ?? ''} />
                  </div>,
                  <TimeText key="txTime" date={new Date(a.createdAt)} />,
                  <div key={a.id} onClick={e => e.stopPropagation()}>

                    <Dropdown
                      noChevron
                      onCloseFn={f => updateCloseDropdownFns({ [a.id]: f })}
                      selectedContent={<Dots width={15} />}
                      dropdownContent={
                        <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <DropdownItem onClick={() => {
                            navigator.clipboard.writeText(a.id)
                            infoToast('Account ID copied to clipboard')
                            closeDropdownFns[a.id]()
                          }}>Copy account ID</DropdownItem>
                          <ConfirmButtonModal
                            title='Archive account'
                            description='Are you sure you want to archive this account?'
                            action='Archive account'
                            onConfirm={async () => {
                              Radom.deleteWithdrawalAccount(a.id)
                                .then(async () => await fetchDepositAccounts())
                                .then(() => successToast('Account archived'))
                            }}
                            ActionComponent={props => <DropdownItem {...props}>Archive account</DropdownItem>}
                          />
                        </div>
                      }
                    />
                  </div>
                ])
            }
            emptyPlaceholderText='No accounts'
            isLoading={isLoading} />

          <Pagination
            curPage={curPage}
            numPages={Math.ceil(totalDepositAccounts / pageSize)}
            onPageClick={page => setCurPage(page)}
          />
        </>
      }

      {
        isCreateWithdrawalModalOpen &&
        <CreateWithdrawalAccountModal
          title='Create withdrawal account'
          visible={isCreateWithdrawalModalOpen}
          onClose={() => setIsCreateWithdrawalModalOpen(false)}
          onAccountCreated={() => {
            setIsCreateWithdrawalModalOpen(false)
            fetchWithdrawalAccounts()
          }} />
      }

      {
        isCreateDepositModalOpen &&
        <CreateDepositAccountModal
          title='Create deposit account'
          visible={isCreateDepositModalOpen}
          onClose={() => setIsCreateDepositModalOpen(false)}
          onAccountCreated={() => {
            setIsCreateDepositModalOpen(false)
            fetchDepositAccounts()
          }} />
      }

    </div>
  </Container>
})

export default Accounts

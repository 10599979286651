import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/integrations'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { configure } from 'mobx'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'
import { IS_PROD } from './util/Constants'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'npm:pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

if (IS_PROD) {
  Sentry.init({
    dsn: 'https://8c4091539db99310da4c04d681bba9dd@o4506811525824512.ingest.sentry.io/4506811639267328',
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

console.info(`
██████╗  █████╗ ██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔══██╗██╔══██╗██╔═══██╗████╗ ████║
██████╔╝███████║██║  ██║██║   ██║██╔████╔██║
██╔══██╗██╔══██║██║  ██║██║   ██║██║╚██╔╝██║
██║  ██║██║  ██║██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚═╝     ╚═╝
Welcome to Radom!
Build: ${process.env.RADOM_BUILD_VERSION}
`)

configure({
  enforceActions: 'never'
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app')!)
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>
)

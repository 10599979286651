import React, { type ReactElement, useMemo, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import FileInput from '../../components/FileInput'
import { InputLabel, OptionalLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import type { Address, BusinessAddress as BusinessAddressState } from '../../state/Radom'
import { errorToast } from '../../util/Util'
import { AddressForm } from './AddressForm'
import PdfFileUpload from './PDFFileUpload'

interface IProps {
  state: BusinessAddressState & { proofOfAddressFile?: File, proofOfAddressUrl?: string }
  isSubmitting: boolean
  onUpdate: (value: BusinessAddressState) => void
  onSubmit: () => void
}

const BusinessAddress = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const isValid = useMemo(() => ({
    streetLine: state.physicalAddress?.streetLine1 !== '',
    city: state.physicalAddress?.city !== '',
    subdivision: state.physicalAddress?.subdivision !== '',
    state: state.physicalAddress?.country !== '',
    proofOfAddressFile: !!state.proofOfAddressFile || !!state.proofOfAddress,
  }), [state])

  const isFormValid = useMemo(() => Object.values(isValid).every((value) => value), [isValid])

  return (<div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14, paddingBottom: 40 }}>
    <div style={{ marginBottom: 20 }}>
      <PageHeader title="Tell us where you're located" subTitle="Share your business's registered and physical addresses" />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <span style={{ fontSize: 20 }}>Registered address</span>
      <AddressForm state={state.registeredAddress} onUpdate={(t) => {
        onUpdate({ ...state, registeredAddress: t })
      }} />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
      <span style={{ fontSize: 20 }}>Operating address</span>
      <AddressForm state={state.physicalAddress} onUpdate={(t) => {
        onUpdate({ ...state, physicalAddress: t })
      }} />
    </div>

    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 10 }}>
        <InputLabel>
          Proof of address
        </InputLabel>
        <OptionalLabel>PDF</OptionalLabel>
      </div>
      <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
        <PdfFileUpload
          file={state.proofOfAddressUrl ?? state.proofOfAddressFile}
          onFile={f => onUpdate({ ...state, proofOfAddressFile: f })}
          onRemove={() => {
            onUpdate({ ...state, proofOfAddressUrl: undefined, proofOfAddressFile: undefined })
          }} />
      </div>
    </div>

    <PrimaryButton
      disabled={!isFormValid}
      isLoading={isSubmitting}
      onClick={onSubmit}>
      Continue
    </PrimaryButton>
  </div>
  )
}

export default BusinessAddress

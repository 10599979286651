import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DefaultSeller from '../images/DefaultSeller.png'
import ErrorIcon from '../images/Error.png'
import Radom, { IOrganization, VerificationStatus } from '../state/Radom'
import User from '../state/User'
import { RADOM_COLORS } from '../util/Constants'
import { errorToast } from '../util/Util'
import { PrimaryButton, SecondaryButton } from './Button'
import Dropdown, { DropdownItem } from './Dropdown'
import { InputLabel } from './Input'
import Modal from './Modal'

const VerificationBanner = observer((): React.ReactElement => {
  const navigate = useNavigate()

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [parentOrg, setParentOrg] = useState<string>()
  const [orgs, setOrgs] = useState<IOrganization[]>([])

  useEffect(() => {
    loadOrgs()
  }, [])

  const loadOrgs = async (): Promise<void> => {
    const orgIds = await Radom.listOrganizations()

    const orgs = await Promise.all(orgIds.sort()
      .map(async o => await Radom.getOrganizationById(o)))

    setOrgs(orgs)
  }

  const verifyOrganizationWithParent = async (): Promise<void> => {
    if (!parentOrg) {
      return
    }

    try {
      setIsVerifyLoading(true)
      await Radom.verifyOrganizationWithParent({ parentOrganizationId: parentOrg })
      window.location.reload()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }

    setIsVerifyLoading(false)
  }

  const renderBanner = (verificationStatus: VerificationStatus): React.ReactElement => {
    if (verificationStatus === 'PendingVerificationSubmission') {
      return (
        <div style={{
          border: '1px solid',
          borderColor: RADOM_COLORS.RED300,
          backgroundColor: RADOM_COLORS.RED50,
          padding: '15px 20px',
          borderRadius: 10,
          fontSize: 14,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10
        }}>
          <img src={ErrorIcon} style={{ height: 35, width: 35 }} />
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 0, color: RADOM_COLORS.RED900 }}>
            <span style={{ fontWeight: 600 }}>Verification pending</span>
            <span>
            Your organization has an ongoing verification process.
            </span>
          </div>
          <SecondaryButton
            onClick={async () => {
              navigate('/verify')
            }}
            style={{
              fontSize: 14,
              backgroundColor: 'transparent',
              borderColor: RADOM_COLORS.RED900,
              border: '1px solid',
              fontWeight: 500,
              color: RADOM_COLORS.RED900
            }}>
            Verify
          </SecondaryButton>
        </div>
      )
    }

    if (verificationStatus === 'PendingApproval') {
      return (
        <div style={{
          border: '1px solid',
          borderColor: RADOM_COLORS.RED300,
          backgroundColor: RADOM_COLORS.RED50,
          padding: '15px 20px',
          borderRadius: 10,
          fontSize: 14,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10
        }}>
          <img src={ErrorIcon} style={{ height: 35, width: 35 }} />
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 0, color: RADOM_COLORS.RED900 }}>
            <span style={{ fontWeight: 600 }}>Pending verification approval</span>
            <span>
            We are working on approving your organization verification.
            </span>
          </div>
        </div>
      )
    }

    if (verificationStatus === 'Rejected') {
      return (
        <div style={{
          border: '1px solid',
          borderColor: RADOM_COLORS.RED300,
          backgroundColor: RADOM_COLORS.RED50,
          padding: '15px 20px',
          borderRadius: 10,
          fontSize: 14,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10
        }}>
          <img src={ErrorIcon} style={{ height: 35, width: 35 }} />
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 0, color: RADOM_COLORS.RED900 }}>
            <span style={{ fontWeight: 600 }}>Verification rejected</span>
            <span>
            Your verification was rejected. We are unable to support your business at this time.
            </span>
          </div>
        </div>
      )
    }

    return (
      <div style={{
        border: '1px solid',
        borderColor: RADOM_COLORS.RED300,
        backgroundColor: RADOM_COLORS.RED50,
        padding: '15px 20px',
        borderRadius: 10,
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10
      }}>
        <img src={ErrorIcon} style={{ height: 35, width: 35 }} />
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 0, color: RADOM_COLORS.RED900 }}>
          <span style={{ fontWeight: 600 }}>Organization unverified</span>
          <span>
          Your organization is unverified. To enable production payments, please verify your organization. <span
              onClick={() => { setIsVerificationModalOpen(true) }}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}>Is this a child organization?</span>
          </span>
        </div>
        <SecondaryButton
          onClick={async () => {
            navigate('/verify')
          }}
          style={{
            fontSize: 14,
            backgroundColor: 'transparent',
            borderColor: RADOM_COLORS.RED900,
            border: '1px solid',
            fontWeight: 500,
            color: RADOM_COLORS.RED900
          }}>
          Verify
        </SecondaryButton>
      </div>
    )
  }

  if (!User.organization) {
    return <></>
  }

  if (User.organization?.verificationStatus === 'Verified') {
    return <></>
  }

  return <>
    {renderBanner(User.organization.verificationStatus)}
    <Modal visible={isVerificationModalOpen}
      innerStyles={{ fontSize: 14 }}
      title='Organization verification'
      onClose={() => setIsVerificationModalOpen(false)}
    >
      <div style={{
        padding: '0 30px 30px 30px',
        minWidth: 500,
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        gap: 30
      }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>Parent organization</InputLabel>
            <Dropdown
              disabled={isVerifyLoading}
              selectedContent={
                <div key={parentOrg}>
                  { orgs.filter(o => o.verificationStatus === 'Verified').length <= 0
                    ? 'No verified organizations'
                    : parentOrg || 'Select organization'}
                </div>
              }
              dropdownContent={<div>
                {orgs
                  .filter(o => o.verificationStatus === 'Verified')
                  .filter(o => o.organizationId !== parentOrg)
                  .map(t =>
                    <DropdownItem
                      onClick={() => setParentOrg(t.organizationId)}
                      key={t.organizationId}
                      style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <img
                        src={t.logoUrl || DefaultSeller}
                        style={{
                          width: 'auto',
                          maxHeight: 30,
                          maxWidth: 100,
                          filter: 'invert(30%) contrast(180%) saturate(130%)'
                        }}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                        {t.name && <span>{t.name}</span> }
                        <span style={{ opacity: 0.7 }}>{t.organizationId}</span>
                      </div>
                    </DropdownItem>
                  )}
              </div>
              } />
          </div>

        </div>

        <PrimaryButton
          onClick={async () => await verifyOrganizationWithParent()}
          disabled={!parentOrg}
          isLoading={isVerifyLoading}>
              Set parent organization
        </PrimaryButton>

      </div>
    </Modal>
  </>
})

export default VerificationBanner

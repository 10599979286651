import React, { ReactElement, useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import Radom, { Deposit } from '../../state/Radom'
import TimeText from '../../components/TimeText'
import Treasury from '../../state/Treasury'
import { observer } from 'mobx-react'
import { useTestMode } from '../../util/Util'
import { Container } from '../../components/Animations'
import Pagination from '../../components/Pagination'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import { getMethod } from '../../util/Managed'
import { useNavigate } from 'react-router-dom'

const Deposits = observer((): ReactElement => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const pageSize = 20
  const [curPage, setCurPage] = useState(0)
  const [deposits, setDeposits] = useState([] as Deposit[])
  const [totalDepositsSize, setTotalDepositsSize] = useState<number>(0)
  const [testMode] = useTestMode()


  const loadDeposits = async (): Promise<void> => {
    setIsLoading(true)
    Treasury.loadPriceQuotes()
    Radom.listDeposits(testMode, curPage * pageSize, pageSize)
      .then(res => {
        setIsLoading(false)
        setDeposits(res.data)
        setTotalDepositsSize(res.totalCount)
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    loadDeposits()
  }, [testMode, curPage])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Deposits' subTitle='View your deposits' />
      </div>
      <Table
        onClickRow={(i) => navigate(`/deposit/${deposits[i].id}`)}
        borderless
        headers={['Account', 'Amount', 'Deposited at'].map(h => <span key={h}>{h}</span>)}
        items={
          deposits
            .map(w => {
              return [
                <div key="1" style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <span>{w.depositAccountName}</span>
                  <span style={{ opacity: 0.5, fontSize: 14 }}>{w.depositAccountId}</span>
                </div>,
                <div key="amount" style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  {
                    w.depositMethod.Crypto && <>

                      <PaymentMethodDisplay
                        style={{ marginRight: 5 }}
                        method={{ network: w.depositMethod.Crypto.network, token: w.depositMethod.Crypto.token }}
                        excludeName
                        disableTooltip={false} />
                      <span>{w.netAmount}</span>
                      <span>{getMethod(w.depositMethod.Crypto.network, w.depositMethod.Crypto.token).ticker}</span>
                    </>
                  }
                </div>,
                <div key="5"><TimeText date={new Date(w.depositedAt)} /></div>,
              ]
            })
        }
        isLoading={isLoading}
        emptyPlaceholderText='No deposits yet'
      />

      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalDepositsSize / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }

    </div>
  </Container>
})

export default Deposits

import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import { errorToast, formatCurrency, successToast } from '../../util/Util'
import { PrimaryButton } from '../../components/Button'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import Radom, { Payout } from '../../state/Radom'
import { RADOM_COLORS } from '../../util/Constants'
import LoadingBar from '../../components/LoadingBar'
import { StatusBadge } from '../CheckoutSessions'
import { camelCase } from 'lodash'
import { ExternalLinkText } from '../Events'
import { formatExplorerUrl } from '../../util/Chains'
import LinkExternal from '../../components/LinkExternal'

interface CreatePayoutProps extends ModalProps {
  payoutId: string
  onPayoutConfirmed: () => void
}

const PayoutModal = observer(({ onPayoutConfirmed, payoutId, ...props }: CreatePayoutProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [payout, setPayout] = useState<Payout>()

  const getPayout = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const payout = await Radom.getPayout(payoutId)
      setPayout(payout)
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const confirmPayout = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await Radom.confirmPayout(payoutId)
      successToast('Successfully submitted payout')
      onPayoutConfirmed()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const quotePayout = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await Radom.quotePayout(payoutId)
      successToast('Successfully reactivated payout')
      onPayoutConfirmed()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getPayout()
  }, [])

  return <Modal title='Payout' {...props} innerStyles={{ minWidth: 550 }}>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px',
      fontSize: 14
    }}>

      {
        payout &&
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 20,
          borderRadius: 15,
          fontSize: 14,
          marginBottom: 40,
          padding: '0 10px'
        }}>
          <div style={{
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>Status</span>
            <StatusBadge status={camelCase(payout.status)}>{payout.status}</StatusBadge>
          </div>

          <div style={{
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>Total payout</span>
            <span>{formatCurrency(payout?.totalPayoutUsd, 'USD')}</span>
          </div>

          <div style={{
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>Gas fee</span>
            <span>{formatCurrency(payout?.estimatedFeeUsd, 'USD')}</span>
          </div>

          <div style={{
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>Deduct fees</span>
            <span>{payout.deductFees ? 'Yes' : 'No'}</span>
          </div>

          <div style={{
            gap: 5,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>Email receipts</span>
            <span>{payout.sendEmailReceipts ? 'Yes' : 'No'}</span>
          </div>
        </div>
      }

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(130px, auto) 100px 1fr',
        alignItems: 'center',
        columnGap: 20,
        rowGap: 10,
        padding: '0 20px'
      }}>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Asset</div>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Amount</div>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Payout address</div>
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(130px, auto) 100px 1fr min-content',
        alignItems: 'center',
        columnGap: 20,
        rowGap: 10,
        maxHeight: '50vh',
        overflow: 'scroll',
        borderRadius: 15,
        padding: 20,
        border: `1px solid ${RADOM_COLORS.GRAY9}`
      }}>
        <div style={{ display: 'contents', overflow: 'hidden' }}>
          {
            isLoading && !payout && Array(10).fill(0).map(() => <>
              <LoadingBar style={{ height: 30, width: '100%' }} />
              <LoadingBar style={{ height: 30, width: '100%' }} />
              <LoadingBar style={{ height: 30, width: '100%' }} />
              <LoadingBar style={{ height: 30, width: '100%' }} />
            </>
            )
          }
          {
            payout?.payouts.map((p, i) => {
              if (!p.payoutType.crypto) {
                return null
              }
              const cryptoPayout = p.payoutType.crypto

              return <>
                <div key={`${i}-m`}><PaymentMethodDisplay method={{ network: cryptoPayout.network, token: cryptoPayout.token }} /></div>
                <div key={`${i}-a`} style={{
                  textOverflow: 'ellipsis',
                  overflow: 'auto'
                }}>{cryptoPayout.amount}</div>
                <div key={`${i}-aa`} style={{
                  textOverflow: 'ellipsis',
                  overflow: 'auto',
                  whiteSpace: 'pre'
                }}>{cryptoPayout.payoutAddress}</div>
                <div>
                  {
                    p.payoutEntryReceipt?.crypto &&
                    <ExternalLinkText
                      target="_blank" rel="noreferrer"
                      href={formatExplorerUrl(
                        cryptoPayout.network,
                        'tx',
                        p.payoutEntryReceipt.crypto.txHash
                      )}>
                      View
                      <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                    </ExternalLinkText>
                  }
                </div>
              </>
            })
          }
        </div>

      </div>
    </div>

    <div style={{
      padding: 30,
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 15
    }}>

      {
        payout?.status === 'AwaitingConfirmation' && <>

          <PrimaryButton
            isLoading={isLoading}
            style={{ width: '100%' }}
            onClick={() => {
              confirmPayout()
            }}>
            Confirm payout
          </PrimaryButton>

        </>
      }

      {
        payout?.status === 'Expired' && <>

          <PrimaryButton
            isLoading={isLoading}
            style={{ width: '100%' }}
            onClick={() => {
              quotePayout()
            }}>
            Reactivate payout
          </PrimaryButton>
        </>
      }
    </div>

  </Modal>
})

export default PayoutModal

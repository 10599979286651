import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

const Container = styled.div`
  font-size: 12px;
  line-height: 20px;
`

const Steps = styled.ul`
  background-color: ${RADOM_COLORS.GRAY_DARK};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  list-style-type: none;
  padding: 0;
  border-radius: 20px;
  margin: 0;
  width: 4px;
  position: sticky;
  top: 100px;
  user-select: none;
`

const Step = styled.li<{ index: number, active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  position: relative;
  text-align: center;

  ${({ index, active }) => active
  ? `
      &:before {
          content: '${index}';
          border-radius: 50%;
          background-color: ${RADOM_COLORS.BLUE4};
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 22px;
          min-width: 22px;
          transition: 0.2s ease all;
      }

      span {
          color: ${RADOM_COLORS.BLUE4};
          position: absolute;
          left: 24px;
          white-space: nowrap;
          font-weight: bold;
      }`
  : `
      cursor: pointer;

      &:before {
          content: '${index}';
          border-radius: 50%;
          background-color: ${RADOM_COLORS.GRAY4};
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 22px;
          min-width: 22px;
          transition: 0.2s ease all;
      }

      span {
          color: ${RADOM_COLORS.GRAY4};
          position: absolute;
          left: 24px;
          white-space: nowrap;
      }`
  }
`

interface IProps {
  steps: Array<{ id: string, label: string }>
  currentStep: string
  onStepClick: (stepId: string) => void
}

const ProgressIndicator = ({ steps, currentStep, ...props }: IProps): ReactElement => (
  <Container>
    <Steps>
      {steps.map((step, index) => (
        <Step
          key={step.id}
          index={index + 1}
          active={currentStep === step.label}
          onClick={() => props.onStepClick(step.id)}
        >
          <span>{step.label}</span>
        </Step>
      ))}
    </Steps>
  </Container>
)

export default ProgressIndicator

import React, { ReactElement } from "react"
import Copy from "../icons/Copy"
import User from "../state/User"
import { RADOM_COLORS } from "../util/Constants"
import { successToast, errorToast } from "../util/Util"
import { IconButton } from "./Button"

interface IProps {
  text: string
}

export const CopyButton = (props: IProps): ReactElement => {
  return <IconButton style={{ padding: 0 }}>
    <Copy style={{ width: 15, stroke: RADOM_COLORS.GRAY_DARKER }} onClick={(e) => {
      e.stopPropagation()
      navigator.clipboard
        .writeText(props.text)
        .then(() => {
          successToast(
            'Copied to clipboard'
          )
        })
        .catch((err) => {
          console.error('copy failed', err)
          errorToast(
            'Failed to copy'
          )
        })
    }} />
  </IconButton>
}

export default CopyButton
import { makeAutoObservable } from 'mobx'
import Radom, { ManagedEvent, ManagedEventDepositData, ManagedEventNewSubscription, ManagedEventSubscriptionCancelled, ManagedEventSubscriptionExpired, ManagedEventWithdrawalData } from './Radom'
import { ManagedPaymentMethod, getMethod, prodNetworks, testNetworks } from '../util/Managed'
import { startOfDay, subDays } from 'date-fns'

export type PaymentGateway = 'Self-custodial' | 'Managed'

interface BaseEvent {
  gateway: PaymentGateway
  date: Date
}

interface LabelledEvent extends BaseEvent {
  url: string
  name: string
  label: string
}

export enum AssociatedEntityType {
  PaymentLink,
  CheckoutSession,
  Invoice,
  PaymentSession,
  Subscription,
  DonationLink
}

interface AssociatedEntity {
  id: string
  entityType: AssociatedEntityType
  link: string
}

export interface ManagedPaymentEvent extends LabelledEvent {
  id: string
  gateway: 'Managed'
  name: 'PaymentEvent' | 'RecurringPaymentEvent' | 'IncompletePaymentEvent' | 'SubscriptionPaymentEvent'
  seller: string
  amount: number
  paymentMethod: ManagedPaymentMethod
  associatedEntity: AssociatedEntity
}

export interface Withdrawal extends LabelledEvent {
  gateway: 'Managed'
  name: 'Withdrawal'
  data: ManagedEventWithdrawalData
  managedMethod: ManagedPaymentMethod
}

export interface Deposit extends LabelledEvent {
  gateway: 'Managed'
  name: 'Deposit'
  data: ManagedEventDepositData
}

export interface Refund extends LabelledEvent {
  gateway: 'Managed'
  name: 'Refund'
  data: ManagedEventWithdrawalData
  managedMethod: ManagedPaymentMethod
}

export interface ManagedNewSubscriptionEvent extends LabelledEvent {
  gateway: 'Managed'
  name: 'NewSubscription'
  data: ManagedEventNewSubscription
  managedMethod: ManagedPaymentMethod
}

export interface ManagedSubscriptionCancelledEvent extends LabelledEvent {
  gateway: 'Managed'
  name: 'SubscriptionCancelled'
  data: ManagedEventSubscriptionCancelled
  managedMethod: ManagedPaymentMethod
}

export interface ManagedSubscriptionExpiredEvent extends LabelledEvent {
  gateway: 'Managed'
  name: 'SubscriptionExpired'
  data: ManagedEventSubscriptionExpired
  managedMethod: ManagedPaymentMethod
}

export type RadomEvent = ManagedPaymentEvent | Withdrawal |
  ManagedNewSubscriptionEvent | ManagedSubscriptionCancelledEvent | Refund | ManagedSubscriptionExpiredEvent | Deposit

export const parseManagedEvent = (managedEvent: ManagedEvent): RadomEvent | null => {
  switch (managedEvent.eventType) {
    case 'incompletePayment':
    case 'recurringPayment':
    case 'subscriptionPayment':
    case 'payment': {
      let associatedEntityId = ''
      let associatedEntityType = AssociatedEntityType['Payment session']
      let associatedEntityLink = ''

      const seller = ''
      const url = `/payment/${managedEvent.id}`

      const eventData = managedEvent.eventData
      const payment = eventData.payment || eventData.recurringPayment || eventData.incompletePayment ||
        eventData.subscriptionPayment

      const paymentMethod = getMethod(payment.paymentMethod.network, payment.paymentMethod.token)

      const { checkoutSession, paymentLink, invoice, paymentSession, subscription, donationLink } = payment.radomData

      if (checkoutSession) {
        // Get checkout session
        associatedEntityId = checkoutSession.checkoutSessionId
        associatedEntityType = AssociatedEntityType.CheckoutSession
        associatedEntityLink = `/checkout_session/${checkoutSession.checkoutSessionId}`
      }

      if (paymentLink) {
        // Get payment link
        associatedEntityId = paymentLink.paymentLinkOrderId
        associatedEntityType = AssociatedEntityType.PaymentLink
        associatedEntityLink = `/payment_links/${paymentLink.paymentLinkId}`
      }

      if (invoice) {
        // Get payment link
        associatedEntityId = invoice.invoiceId
        associatedEntityType = AssociatedEntityType.Invoice
        associatedEntityLink = `/invoices/${invoice.invoiceId}`
      }

      if (paymentSession) {
        // Get payment session
        associatedEntityId = paymentSession.paymentSessionId
        associatedEntityType = AssociatedEntityType.PaymentSession
        associatedEntityLink = ''
      }

      if (subscription) {
        associatedEntityId = subscription.subscriptionId
        associatedEntityType = AssociatedEntityType.Subscription
        associatedEntityLink = `/subscription/${subscription.subscriptionId}`
      }

      if (donationLink) {
        associatedEntityId = donationLink.donationLinkOrderId
        associatedEntityType = AssociatedEntityType.DonationLink
        associatedEntityLink = `/donation_link/${donationLink.donationLinkId}`
      }

      let name: any, label: any
      if (managedEvent.eventData.payment) {
        name = 'PaymentEvent'
        label = 'Payment'
      }
      if (managedEvent.eventData.recurringPayment) {
        name = 'RecurringPaymentEvent'
        label = 'Recurring payment'
      }
      if (managedEvent.eventData.incompletePayment) {
        name = 'IncompletePaymentEvent'
        label = 'Incomplete payment'
      }
      if (managedEvent.eventData.subscriptionPayment) {
        name = 'SubscriptionPaymentEvent'
        label = 'Subscription payment'
      }

      return {
        id: managedEvent.id,
        name,
        gateway: 'Managed',
        paymentMethod,
        date: new Date(managedEvent.eventDate),
        label,
        url,
        amount: Number(payment.amount),
        seller,
        associatedEntity: {
          id: associatedEntityId,
          entityType: associatedEntityType,
          link: associatedEntityLink
        }
      }
    }
    case 'withdrawal': {
      const withdrawal = managedEvent.eventData.withdrawal
      return {
        gateway: 'Managed',
        name: 'Withdrawal',
        date: new Date(managedEvent.eventDate),
        label: 'Withdrawal',
        data: withdrawal,
        url: '/withdrawals',
        managedMethod: getMethod(withdrawal.network, withdrawal.token)
      }
    }
    case 'newSubscription': {
      return {
        gateway: 'Managed',
        name: 'NewSubscription',
        date: new Date(managedEvent.eventDate),
        label: 'New subscription',
        data: managedEvent.eventData.newSubscription,
        url: `/subscription/${managedEvent.eventData.newSubscription.subscriptionId}`,
        managedMethod: getMethod(
          managedEvent.eventData.newSubscription.network,
          managedEvent.eventData.newSubscription.token
        )
      }
    }
    case 'subscriptionCancelled': {
      return {
        gateway: 'Managed',
        name: 'SubscriptionCancelled',
        date: new Date(managedEvent.eventDate),
        label: 'Subscription cancelled',
        data: managedEvent.eventData.subscriptionCancelled,
        url: `/subscription/${managedEvent.eventData.subscriptionCancelled.subscriptionId}`,
        managedMethod: getMethod(
          managedEvent.eventData.subscriptionCancelled.network,
          managedEvent.eventData.subscriptionCancelled.token
        )
      }
    }
    case 'subscriptionExpired': {
      return {
        gateway: 'Managed',
        name: 'SubscriptionExpired',
        date: new Date(managedEvent.eventDate),
        label: 'Subscription expired',
        data: managedEvent.eventData.subscriptionExpired,
        url: `/subscription/${managedEvent.eventData.subscriptionExpired.subscriptionId}`,
        managedMethod: getMethod(
          managedEvent.eventData.subscriptionExpired.network,
          managedEvent.eventData.subscriptionExpired.token
        )
      }
    }
    case 'refund': {
      const refund = managedEvent.eventData.refund
      return {
        gateway: 'Managed',
        name: 'Refund',
        date: new Date(managedEvent.eventDate),
        label: 'Refund',
        data: refund,
        url: '/',
        managedMethod: getMethod(refund.network, refund.token)
      }
    }
    case 'deposit': {
      const deposit = managedEvent.eventData.deposit
      return {
        gateway: 'Managed',
        name: 'Deposit',
        date: new Date(managedEvent.eventDate),
        label: 'Deposit',
        data: deposit,
        url: `/deposit/${deposit.depositId}`,
      }
    }
  }

  return null
}

class Events {
  useRadomForEvents = true
  isLoading: boolean = false

  latestBlockScanned: { [chainID: number]: number } = {}
  computeEventsPromise: Promise<any>
  numWorkersAvailable: any = {}
  parallelFetchLogs: any = {}

  managedEvents: RadomEvent[] = []

  constructor() {
    makeAutoObservable(this)
  }

  loadEvents = async (testMode: boolean, numDays: number = 14): Promise<void> => {
    return await this.getEventsFromRadom(testMode, numDays)
  }

  private readonly getEventsFromRadom = async (testMode, numDays: number): Promise<void> => {
    if (this.isLoading) {
      return
    }

    this.isLoading = true

    if (Radom.isLoggedIn) {
      try {
        const managedEvents = await Radom.listManagedEvents(
          testMode ? testNetworks : prodNetworks,
          startOfDay(subDays(new Date(), numDays)).toISOString(),
          new Date().toISOString()
        )
        this.managedEvents = managedEvents.data.map(e => parseManagedEvent(e)).filter(e => !!e) as RadomEvent[]
      } catch (err) {
        console.error('Failed to parse managed events: ', err)
      }
    }

    this.isLoading = false
  }

  get allEvents(): RadomEvent[] {
    const events = [...this.managedEvents]
    events.sort((a, b) => b.date.getTime() - a.date.getTime())
    return events
  }
}

export default new Events()

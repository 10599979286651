import React, { useEffect, useRef } from 'react'
import { IconButton } from './Button'
import { Desktop } from '../icons/Desktop'
import { Mobile } from '../icons/Mobile'
import Shield from '../icons/Shield'
import { RADOM_COLORS } from '../util/Constants'

interface IProps {
  previewWidth: string
  onWidthChange: (width: string) => void
  iframeLink: string
  dontScale?: boolean
  presale?: boolean
  iframeTitle?: string
}

const Preview = (props: IProps): React.ReactElement => {
  const iframeRef = useRef(null)
  const iframeTitle = props.iframeTitle ?? 'pay.radom.com'

  useEffect(() => {
    if (iframeRef.current) {
      (iframeRef.current as any).contentWindow.location.replace(props.iframeLink)
    }
  }, [props.iframeLink])

  return <div style={{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
    background: RADOM_COLORS.GRAY8
  }}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1,
      height: props.presale ? '100%' : '80%',
      width: props.previewWidth,
      transition: '0.2s ease all'
    }}>
      <div style={{
        borderRadius: 10,
        width: '100%',
        height: '100%',
        boxShadow: props.presale ? 'initial' : 'rgb(155 158 177 / 10%) 0px 0px 10px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 900
      }}>
        {
          !props.presale &&
          <>
            <div style={{
              width: '100%',
              height: 40,
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
              position: 'relative',
              borderBottom: `1px solid ${RADOM_COLORS.GRAY8}`
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5
              }}>
                <div style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: RADOM_COLORS.GRAY4,
                  opacity: 0.5
                }} />
                <div style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: RADOM_COLORS.GRAY4,
                  opacity: 0.5
                }} />
                <div style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: RADOM_COLORS.GRAY4,
                  opacity: 0.5
                }} />
              </div>
              <div style={{
                display: 'flex',
                gap: 5,
                fontSize: 12,
                alignItems: 'center'
              }}>
                <Shield height={18} width={18} style={{ marginTop: -2 }} />
                <div style={{ userSelect: 'none' }}>
                  {props.previewWidth === '90%' && <span style={{ opacity: 0.5 }}>https://</span> }
                  <span>{iframeTitle}</span>
                </div>
              </div>
              <div style={{
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: 5,
                overflow: 'hidden'
              }}>
                <IconButton style={{
                  fontSize: 14,
                  padding: '5px 10px',
                  background: props.previewWidth === '350px' ? RADOM_COLORS.GRAY_LIGHTEST : RADOM_COLORS.GRAY_DARK,
                  borderRadius: 0
                }}
                onClick={() => props.onWidthChange('90%')}>
                  <Desktop
                    stroke={props.previewWidth === '350px' ? RADOM_COLORS.GRAY_DARKER : RADOM_COLORS.GRAY_DARKEST}
                    width={15}
                    height={15} />
                </IconButton>
                <IconButton style={{
                  fontSize: 14,
                  padding: '5px 10px',
                  background: props.previewWidth === '350px' ? RADOM_COLORS.GRAY_DARK : RADOM_COLORS.GRAY_LIGHTEST,
                  borderRadius: 0
                }}
                onClick={() => props.onWidthChange('350px')}>
                  <Mobile
                    stroke={props.previewWidth === '350px' ? RADOM_COLORS.GRAY_DARKEST : RADOM_COLORS.GRAY_DARKER }
                    width={15}
                    height={15} />
                </IconButton>
              </div>
            </div>
            <div style={{
              width: '100%',
              flexGrow: 1,
              backgroundColor: 'white'
            }}>
              <iframe
                ref={iframeRef}
                style={{
                  border: 0,
                  transformOrigin: '0 0',
                  width: props.dontScale ? '100%' : '140%',
                  height: props.dontScale ? '100%' : '140%',
                  transform: props.dontScale ? 'initial' : 'scale(0.715)'
                }} />
            </div>
          </>
        }
        {
          props.presale &&
          <iframe
            ref={iframeRef}
            style={{
              border: 0,
              width: '100%',
              height: '100%'
            }} />
        }
      </div>
    </div>
  </div>
}

export default Preview

import React, { useMemo, useState, ReactElement } from 'react'
import { observer } from 'mobx-react'
import Modal, { ModalProps } from '../../components/Modal'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { TextInputWithLabel, InputLabel } from '../../components/Input'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import SearchMultiselect from '../../components/SearchMultiselect'
import { Country } from 'country-state-city'
import DateSelector from '../../components/DateSelector'
interface EUR_Account {
  iban: string
  bic: string
}

interface USD_Account {
  account_number: string
  routing_number: string
  bank_name: string
}

enum CurrencyType {
  EUR = 'EUR',
  USD = 'USD'
}

enum AccountType {
  Individual = 'Individual',
  Business = 'Business'
}

interface PayoutEntry {
  id: string
  network: string
  token?: string
  amount?: string
  payoutAddress?: string
  name?: string
  dateOfBirth?: string
  residenceCountry?: string
  email?: string
  payout_currency?: CurrencyType
  payment_rail?: string
  accountType?: AccountType
  address?: string
  eur_account?: EUR_Account
  usd_account?: USD_Account
  companyNumber?: string
}

type EditPayoutModalProps = ModalProps & {
  payout: PayoutEntry
  onClose: () => void
  onSave: (updatedPayout: PayoutEntry) => void
}
const EditPayoutModal = observer((props: EditPayoutModalProps): ReactElement => {
  const { payout, onClose, onSave } = props
  const [editedPayout, setEditedPayout] = useState<PayoutEntry>({ ...payout })
  const [closeModalFn, setCloseModalFn] = useState(() => () => {})

  const handleInputChange = (field: keyof PayoutEntry) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPayout((prev) => ({ ...prev, [field]: e.target.value }))
  }

  const countries = useMemo(() => Country
    .getAllCountries()
    .map((country) => ({
      key: country.isoCode,
      value: country.name
    })),
  [])

  return (
    <Modal visible={true} onClose={onClose} title="Edit payout entry">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 30, paddingTop: 0 }}>
        <TextInputWithLabel
          label="Amount"
          required
          value={editedPayout.amount || ''}
          onChange={handleInputChange('amount')}
        />
        {
          editedPayout.network &&
          <TextInputWithLabel
            label="Payout Address"
            required
            value={editedPayout.payoutAddress || ''}
            onChange={handleInputChange('payoutAddress')}
          />
        }
        {
          editedPayout.accountType &&
          <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>
              <span>Account owner type</span>
            </InputLabel>
            <Dropdown
              selectedContent={
                editedPayout.accountType
              }
              onCloseFn={_closeFn => setCloseModalFn(() => _closeFn)}
              dropdownContent={
                [AccountType.Business, AccountType.Individual].map((type) => (
                  <DropdownItem
                    key={type}
                    onClick={() => {
                      setEditedPayout((prev) => ({ ...prev, accountType: type }))
                      closeModalFn()
                    }}>
                    {type === AccountType.Business ? 'Business' : 'Individual'}
                  </DropdownItem>
                ))
              }
            />
          </div>
        }
        {
          editedPayout.eur_account &&
          <>
            <TextInputWithLabel
              label="IBAN"
              required
              value={editedPayout.eur_account.iban || ''}
              onChange={(e) => {
                setEditedPayout((prev) => ({
                  ...prev,
                  eur_account: {
                    ...prev.eur_account,
                    iban: (e.target as HTMLInputElement).value,
                    bic: prev.eur_account?.bic || ''
                  }
                }))
              }}
            />

            <TextInputWithLabel
              label="BIC"
              required
              value={editedPayout.eur_account.bic || ''}
              onChange={(e) => {
                setEditedPayout((prev) => ({
                  ...prev,
                  eur_account: {
                    ...prev.eur_account,
                    bic: (e.target as HTMLInputElement).value,
                    iban: prev.eur_account?.iban || ''
                  }
                }))
              }}
            />
          </>
        }

        {editedPayout.usd_account &&
          <>

            <TextInputWithLabel
              label="Account Number"
              required
              value={editedPayout.usd_account.account_number || ''}
              onChange={(e) => {
                setEditedPayout((prev) => ({
                  ...prev,
                  usd_account: {
                    ...prev.usd_account,
                    account_number: (e.target as HTMLInputElement).value,
                    routing_number: prev.usd_account?.routing_number || '',
                    bank_name: prev.usd_account?.bank_name || ''
                  }
                }))
              }}
            />

            <TextInputWithLabel
              label="Routing Number"
              required
              value={editedPayout.usd_account.routing_number || ''}
              onChange={(e) => {
                setEditedPayout((prev) => ({
                  ...prev,
                  usd_account: {
                    ...prev.usd_account,
                    routing_number: (e.target as HTMLInputElement).value,
                    account_number: prev.usd_account?.account_number || '',
                    bank_name: prev.usd_account?.bank_name || ''
                  }
                }))
              }}
            />

            <TextInputWithLabel
              label="Bank Name"
              required
              value={editedPayout.usd_account.bank_name || ''}
              onChange={(e) => {
                setEditedPayout((prev) => ({
                  ...prev,
                  usd_account: {
                    ...prev.usd_account,
                    bank_name: (e.target as HTMLInputElement).value,
                    routing_number: prev.usd_account?.routing_number || '',
                    account_number: prev.usd_account?.account_number || ''
                  }
                }))
              }}
            />
          </>
        }

        <TextInputWithLabel
          label="Beneficiary Name"
          required
          value={editedPayout.name || ''}
          onChange={handleInputChange('name')}
        />
        {
          editedPayout.payout_currency &&
          <TextInputWithLabel
            label="Beneficiary Address"
            required
            value={editedPayout.address || ''}
            onChange={handleInputChange('address')}
          />
        }

        {
          editedPayout.accountType === AccountType.Individual &&
          <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', gap: 10 }}>
            <InputLabel>
              <span>Date of birth</span>
            </InputLabel>
            <DateSelector
              value={editedPayout.dateOfBirth ? new Date(editedPayout.dateOfBirth) : undefined}
              onChange={(date) => {
                setEditedPayout((prev) => ({ ...prev, dateOfBirth: date?.toLocaleDateString() || '' }))
              }}
            />
          </div>
        }
        {
          editedPayout.accountType === AccountType.Business &&
          <TextInputWithLabel
            label="Company Number"
            required
            value={editedPayout.companyNumber || ''}
            onChange={handleInputChange('companyNumber')}
          />
        }
        <InputLabel>
          <span>Residence</span>
        </InputLabel>
        <SearchMultiselect
          isSingleSelect
          placeholder='Select residence'
          dropdownPlaceholder="No countries found"
          itemArray={countries}
          selectedItems={editedPayout.residenceCountry
            ? [{ key: editedPayout.residenceCountry, value: editedPayout.residenceCountry }]
            : []}
          setSelectedItems={(residence) => {
            setEditedPayout((prev) => ({ ...prev, residenceCountry: residence[0].value }))
          }}
          keyExtractor={(item) => item.value}
          labelExtractor={(item) => item.value}
        />

        <TextInputWithLabel
          label="Email"
          required
          value={editedPayout.email || ''}
          onChange={handleInputChange('email')}
        />

        <TextInputWithLabel
          label="Address"
          required
          value={editedPayout.address || ''}
          onChange={handleInputChange('address')}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, gap: 10 }}>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => onSave(editedPayout)}>Save</PrimaryButton>
        </div>
      </div>
    </Modal>
  )
})

export default EditPayoutModal

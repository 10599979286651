import React, { ReactElement } from 'react'

function Withdrawal(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="0.5" width="48" height="48" rx="8" fill="#2A63FF" />
    <g clip-path="url(#clip0_402_271)">
      <path d="M24.0003 31.5001L24.0003 12.5002" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M33.0003 21.5001L24.0003 12.5002L15.0003 21.5001" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.0003 37.1826H35.0003" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_402_271">
        <rect width="32" height="31.9998" fill="white" transform="translate(8.00012 8.50018)" />
      </clipPath>
    </defs>
  </svg>
}

export default Withdrawal

import { observer } from 'mobx-react'
import React, { ReactElement, useState } from 'react'

import { PrimaryButton, SecondaryButton } from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import Modal, { ModalProps } from '../../components/Modal'
import Radom, { Address, FiatAccountDetails } from '../../state/Radom'
import User from '../../state/User'
import { RADOM_COLORS } from '../../util/Constants'
import { ManagedPaymentMethod, getPaymentMethods } from '../../util/Managed'
import { errorToast, successToast, useTestMode } from '../../util/Util'
import { AddressForm } from '../Verify/AddressForm'

type AccountModalProps = ModalProps & {
  onAccountCreated: () => void
}

enum AccountType {
  Crypto,
  Fiat
}

enum FiatAccountType {
  US = 'US',
  IBAN = 'EU (IBAN)',
  SWIFT = 'SWIFT',
}

enum AccountOwnerType {
  Individual = 'Individual',
  Business = 'Business',
}

const CreateAccountModal = observer(({ ...props }: AccountModalProps): ReactElement => {
  const [accountName, setAccountName] = useState('')
  const [accountType, setAccountType] = useState<AccountType>(AccountType.Crypto)

  // Crypto options
  const [selectedCryptoType, setSelectedCryptoType] = useState<ManagedPaymentMethod>()
  const [cryptoAddress, setCryptoAddress] = useState<string>()

  // Fiat options
  const [accountOwnerType, setAccountOwnerType] = useState<AccountOwnerType>()
  const [accountOwnerName, setAccountOwnerName] = useState<string>()
  const [fiatAccountType, setFiatAccountType] = useState<FiatAccountType>()
  const [accountOwnerAddress, setAccountOwnerAddress] = useState<Address>({} as any)
  const [iban, setIban] = useState<string>()
  const [bic, setBic] = useState<string>()
  const [bankName, setBankName] = useState<string>()
  const [accountNumber, setAccountNumber] = useState<string>()
  const [routingNumber, setRoutingNumber] = useState<string>()

  const [isLoading, setIsLoading] = useState(false)
  const [testMode] = useTestMode()

  const createCryptoAccount = async (): Promise<void> => {
    if (!selectedCryptoType || !cryptoAddress) {
      return
    }

    setIsLoading(true)
    try {
      await Radom.createWithdrawalAccount({
        name: accountName,
        accountDetails: {
          Crypto: {
            network: selectedCryptoType?.name,
            address: cryptoAddress
          }
        }
      })
      props.onAccountCreated()
      successToast('Withdrawal account created')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const createFiatAccount = async (): Promise<void> => {
    if (
      !fiatAccountType ||
      !accountOwnerType ||
      !accountOwnerName ||
      !accountOwnerAddress.country ||
      !accountOwnerAddress.city ||
      !accountOwnerAddress.streetLine1
    ) {
      errorToast('')
      return
    }

    if (fiatAccountType === FiatAccountType.IBAN && (!iban || !bic)) {
      return
    }

    if (fiatAccountType === FiatAccountType.US && (!accountName || !routingNumber || !bankName)) {
      return
    }

    let accountType: FiatAccountDetails | undefined
    if (fiatAccountType === FiatAccountType.IBAN && iban && bic) {
      accountType = {
        iban: {
          iban,
          bic,
          addressV2: accountOwnerAddress
        }
      }
    } else if (fiatAccountType === FiatAccountType.US && accountNumber && routingNumber && bankName) {
      accountType = {
        us: {
          accountNumber,
          routingNumber,
          bankName,
          addressV2: accountOwnerAddress
        }
      }
    }

    if (!accountType) {
      return
    }

    setIsLoading(true)
    try {
      await Radom.createWithdrawalAccount({
        name: accountName,
        accountDetails: {
          Fiat: {
            accountOwnerName,
            accountOwnerType,
            accountType
          }
        }
      })
      props.onAccountCreated()
      successToast('Withdrawal account created')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const createAccount = async (): Promise<void> => {
    if (accountType === AccountType.Crypto) {
      return await createCryptoAccount()
    } else {
      return await createFiatAccount()
    }
  }

  const renderCryptoForm = (): ReactElement => (
    <>
      <TextInputWithLabel
        label='Name'
        placeholder='Enter a name for this account'
        disabled={isLoading}
        required
        value={accountName}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountName(value)
        }}
      />
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
        <InputLabel>Network</InputLabel>
        <Dropdown
          disabled={isLoading}
          selectedContent={
            selectedCryptoType
              ? <div style={{
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
                gap: 10
              }}>
                <img
                  src={selectedCryptoType.logo}
                  style={{
                    height: 20,
                    width: 'auto',
                    maxWidth: 20
                  }} />
                {selectedCryptoType.name}
              </div>
              : <span style={{ fontSize: 14 }}>Select network</span>
          }
          dropdownContent={
            getPaymentMethods()
              .filter(t => !t.hostChain)
              .filter(t => t.name !== selectedCryptoType?.name)
              .filter(t => !t.isDisabled)
              .filter(t => testMode ? t?.isTestnet : !t?.isTestnet)
              .filter(t => !t.isFiat)
              .map(t =>
                <DropdownItem
                  key={t.name}
                  onClick={() => {
                    setSelectedCryptoType(t)
                  }}
                  style={{
                    fontSize: 14,
                    alignItems: 'center',
                    display: 'flex',
                    gap: 10
                  }}>
                  <img
                    src={t.logo}
                    style={{
                      height: 20,
                      width: 'auto',
                      maxWidth: 20
                    }} />
                  {t.name}
                </DropdownItem>
              )
          } />
      </div>

      <TextInputWithLabel
        label={'Address'}
        placeholder={'Enter address'}
        disabled={isLoading}
        required
        value={cryptoAddress}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setCryptoAddress(value)
        }}
      />
    </>
  )

  const renderBankForm = (): ReactElement => (
    <>
      <TextInputWithLabel
        label='Account nickname'
        placeholder='Enter a nickname for this account'
        disabled={isLoading}
        required
        value={accountName}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountName(value)
        }}
      />

      <div style={{ display: 'flex', gap: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <InputLabel>Account owner type</InputLabel>
          <Dropdown
            key={accountOwnerType}
            disabled={isLoading}
            selectedContent={
              accountOwnerType
                ? <div style={{
                  fontSize: 14,
                  alignItems: 'center',
                  display: 'flex',
                  gap: 10
                }}>
                  {accountOwnerType}
                </div>
                : <span style={{ fontSize: 14 }}>Account owner type</span>
            }
            dropdownContent={
              [AccountOwnerType.Individual, AccountOwnerType.Business].map(c =>
                <DropdownItem
                  key={c}
                  onClick={() => {
                    setAccountOwnerType(c)
                  }}
                  style={{
                    fontSize: 14,
                    alignItems: 'center',
                    display: 'flex',
                    gap: 10
                  }}>

                  {c}
                </DropdownItem>
              )
            } />
        </div>

        {
          accountOwnerType &&

          <TextInputWithLabel
            style={{ flexGrow: 1 }}
            label={`${accountOwnerType} name`}
            placeholder={`Enter ${accountOwnerType?.toLowerCase()}'s name`}
            disabled={isLoading}
            required
            value={accountOwnerName}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setAccountOwnerName(value)
            }}
          />
        }
      </div>

      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
        <InputLabel>Account type</InputLabel>
        <Dropdown
          key={fiatAccountType}
          disabled={isLoading}
          selectedContent={
            fiatAccountType
              ? <div style={{
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
                gap: 10
              }}>
                {fiatAccountType}
              </div>
              : <span style={{ fontSize: 14 }}>Select bank account type</span>
          }
          dropdownContent={
            [FiatAccountType.IBAN, FiatAccountType.US].map(c =>
              <DropdownItem
                key={c}
                onClick={() => {
                  setFiatAccountType(c)
                }}
                style={{
                  fontSize: 14,
                  alignItems: 'center',
                  display: 'flex',
                  gap: 10
                }}>

                {c}
              </DropdownItem>
            )
          } />
      </div>

      {
        fiatAccountType === FiatAccountType.IBAN && <>
          <TextInputWithLabel
            label={'IBAN'}
            placeholder={'Enter IBAN'}
            disabled={isLoading}
            required
            value={iban}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setIban(value)
            }}
          />

          <TextInputWithLabel
            label={'BIC'}
            placeholder={'Enter BIC'}
            disabled={isLoading}
            required
            value={bic}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setBic(value)
            }}
          />
        </>
      }

      {
        fiatAccountType === FiatAccountType.US && <>
          <TextInputWithLabel
            label={'Bank name'}
            placeholder={'Enter bank name, e.g. Chase'}
            disabled={isLoading}
            required
            value={bankName}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setBankName(value)
            }}
          />

          <TextInputWithLabel
            label={'Account number'}
            placeholder={'Enter account number'}
            disabled={isLoading}
            required
            value={accountNumber}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setAccountNumber(value)
            }}
          />

          <TextInputWithLabel
            label={'Routing number'}
            placeholder={'Enter routing number'}
            disabled={isLoading}
            required
            value={routingNumber}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value
              setRoutingNumber(value)
            }}
          />
        </>
      }

      {/* <TextInputWithLabel
        label={'Account owner address'}
        placeholder={'Enter account owner address'}
        disabled={isLoading}
        required
        value={accountOwnerAddress}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountOwnerAddress(value)
        }}
      /> */}

      <AddressForm state={accountOwnerAddress} onUpdate={(s) => setAccountOwnerAddress(s)} />
    </>
  )

  const renderFiatBanner = (): ReactElement => {
    if (User.organization?.verificationStatus !== 'Verified') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your organization is unverified</span>
                <span style={{ opacity: 0.5 }}>
                  Your organization is unverified. To enable production payments, please verify your organization.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return <Modal {...props} innerStyles={{ width: 550 }}>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px'
    }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Account type</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => setAccountType(AccountType.Crypto)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: accountType === AccountType.Crypto ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={accountType === AccountType.Crypto} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Crypto</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  Transfer to a crypto address
                </span>
              </div>
            </SecondaryButton>

            <SecondaryButton
              disabled={isLoading}
              onClick={() => setAccountType(AccountType.Fiat)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: accountType === AccountType.Fiat ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={accountType === AccountType.Fiat} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Fiat</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  Offramp to a bank account
                </span>
              </div>
            </SecondaryButton>

          </div>
        </div>

        {accountType === AccountType.Crypto && renderCryptoForm()}

        {
          accountType === AccountType.Fiat &&
          User.organization?.verificationStatus === 'Verified' &&
          renderBankForm()
        }
      </div>
    </div>

    {
      accountType === AccountType.Crypto && <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}>
        <PrimaryButton
          disabled={!accountName || !selectedCryptoType || !cryptoAddress}
          isLoading={isLoading}
          style={{ width: '100%' }}
          onClick={() => {
            createAccount()
          }}>
          Create account
        </PrimaryButton>
      </div>
    }

    {
      accountType === AccountType.Fiat &&
      User.organization?.verificationStatus === 'Verified' &&
      <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: 'sticky',
        bottom: 0,
        background: 'linear-gradient(transparent, white 20%)'
      }}>
        <PrimaryButton
          disabled={
            !fiatAccountType ||
            !accountOwnerType ||
            !accountOwnerName ||
            !accountOwnerAddress.country ||
            !accountOwnerAddress.city ||
            !accountOwnerAddress.streetLine1 ||
            (fiatAccountType === FiatAccountType.IBAN && (!iban || !bic)) ||
            (fiatAccountType === FiatAccountType.US && (!accountNumber || !routingNumber || !bankName))
          }
          isLoading={isLoading}
          style={{ width: '100%' }}
          onClick={() => {
            createAccount()
          }}>
          Create account
        </PrimaryButton>
      </div>
    }

    {accountType === AccountType.Fiat && renderFiatBanner()}
  </Modal>
})

export default CreateAccountModal

import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useMemo, useReducer, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Container } from '../components/Animations'
import ProgressIndicator from '../components/ProgressIndicator'
import { Chevron } from '../icons/Chevron'
import NavbarController from '../state/NavbarController'
import Radom, { BusinessAddress, BusinessTypes, IVerification, LegalAndTaxDetails, Ubo, UpdateVerificationPayload } from '../state/Radom'
import User from '../state/User'
import { RADOM_COLORS } from '../util/Constants'
import { errorToast } from '../util/Util'
import BusinessAddressView from './Verify/BusinessAddress'
import BusinessBasics from './Verify/BusinessBasics'
import Documents from './Verify/Documents'
import Ubos, { UboForm } from './Verify/Ubos'
import LegalAndTaxDetailsView from './Verify/LegalAndTaxDetails'

enum Steps {
  businessBasics = 'Business Basics',
  businessAddress = 'Business Address',
  legalAndTaxDetails = 'Legal & Tax Details',
  // financialInformation = 'Financial Information',
  // regulatoryCompliance = 'Regulatory Compliance',
  documents = 'Documents',
  ubos = 'Ultimate Beneficial Owners'
}

const DEFAULT_UBO: Ubo = {
  firstName: '',
  lastName: '',
  birthDate: '',
  email: '',
  phone: '',
  taxIdentificationNumber: '',
  residentialAddress: {
    streetLine1: '',
    city: '',
    subdivision: '',
    postalCode: '',
    country: ''
  },
  hasOwnership: false,
  hasControl: false,
  isSigner: false,
  govIdImage: '',
  govIdImageFile: undefined,
  proofOfAddress: '',
  proofOfAddressFile: undefined
}

const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  font-size: 16px;
  padding: 20px 0 20px 20px;
  border-bottom: 1px solid ${RADOM_COLORS.GRAY_DARK};
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
`

const Verify = observer((): ReactElement => {
  const navigate = useNavigate()

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  const [verification, setVerification] = useState<IVerification | null>(null)
  const [step, setStep] = useState(Steps.businessBasics)
  const [isDirty, setIsDirty] = useState({
    [Steps.businessBasics]: false,
    [Steps.businessAddress]: false,
    // [Steps.legalAndTaxDetails]: false,
    // [Steps.financialInformation]: false,
    // [Steps.regulatoryCompliance]: false,
    [Steps.ubos]: false,
    [Steps.documents]: false
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [businessBasicsForm, updateBusinessBasicsForm] = useReducer((prev, next) => { return { ...prev, ...next } }, {
    name: '',
    description: '',
    businessType: BusinessTypes.cooperative,
    businessIndustry: [],
    website: '',
    email: ''
  })
  const [businessAddressForm, updateBusinessAddressForm] = useState<BusinessAddress>({
    registeredAddress: {
      streetLine1: '',
      city: '',
      subdivision: '',
      country: ''
    },
    physicalAddress: {
      streetLine1: '',
      city: '',
      subdivision: '',
      country: ''
    },
    proofOfAddress: ''
  })
  const [legalAndTaxDetailsForm, updateLegalAndTaxDetailsForm] = useState<LegalAndTaxDetails>({
    hasMaterialIntermediaryOwnership: false,
  })
  const [ubo, updateUbo] = useReducer((prev, next) => { return { ...prev, ...next } }, DEFAULT_UBO)
  const [ubos, setUbos] = useState<UboForm[]>([])
  const [editUboIndex, setEditUboIndex] = useState(-1)
  const [documentsForm, updateDocumentsForm] = useReducer((prev, next) => { return { ...prev, ...next } }, {
    formationDocument: undefined,
    formationDocumentFile: undefined,
    ownershipDocument: undefined,
    ownershipDocumentFile: undefined
  })

  const steps = useMemo(() => Object.entries(Steps).map(([key, value]) => ({ id: key, label: value })), [])

  const isStepDirty = useMemo(() => isDirty[step], [isDirty, step])

  const isBusinessBasicsValid = useMemo(() => {
    return !!businessBasicsForm.name &&
      !!businessBasicsForm.description &&
      !!businessBasicsForm.businessType &&
      !!businessBasicsForm.website &&
      !!businessBasicsForm.email
  }, [businessBasicsForm])

  const isBusinessAddressValid = useMemo(() => {
    return !!businessAddressForm.registeredAddress &&
      !!businessAddressForm.physicalAddress
  }, [businessAddressForm])

  const isDocumentsValid = useMemo(() => {
    return (!!documentsForm.formationDocument || !!documentsForm.formationDocumentFile) &&
      (!!documentsForm.ownershipDocument || !!documentsForm.ownershipDocumentFile)
  }, [documentsForm])

  const isUbosValid = useMemo(() => ubos.length > 0, [ubos])

  const canSubmit = useMemo(() => {
    return isBusinessBasicsValid &&
      isBusinessAddressValid &&
      isDocumentsValid &&
      isUbosValid
  },
    [isBusinessBasicsValid, isBusinessAddressValid, isDocumentsValid, isUbosValid])

  useEffect(() => {
    Radom.getVerification().then((v) => {
      let verification = v ?? {};

      setVerification(verification)

      updateBusinessBasicsForm({
        name: verification.name,
        description: verification.description,
        businessType: verification.businessType,
        businessIndustry: verification.businessIndustry,
        website: verification.website,
        email: verification.email
      })

      updateBusinessAddressForm({
        registeredAddress: verification.registeredAddress ?? {},
        physicalAddress: verification.physicalAddress ?? {},
        proofOfAddress: verification.proofOfAddress,
        proofOfAddressUrl: verification.proofOfAddressUrl
      })

      updateLegalAndTaxDetailsForm({
        hasMaterialIntermediaryOwnership: verification.hasMaterialIntermediaryOwnership ?? false,
        accountPurpose: verification.accountPurpose,
        sourceOfFunds: verification.sourceOfFunds,
        taxIdentificationNumberType: verification.taxIdentificationNumberType,
        taxIdentificationNumber: verification.taxIdentificationNumber
      })

      updateDocumentsForm({
        formationDocument: verification.formationDocument,
        formationDocumentUrl: verification.formationDocumentUrl,
        ownershipDocument: verification.ownershipDocument,
        ownershipDocumentUrl: verification.ownershipDocumentUrl
      })

      setUbos(verification.associatedPersons ?? [])
    })
  }, [])

  const onUpdate = (value: any): void => {
    setIsDirty({ ...isDirty, [step]: true })

    switch (step) {
      case Steps.businessBasics:
        updateBusinessBasicsForm(value)
        break

      case Steps.businessAddress:
        updateBusinessAddressForm(value)
        break

      case Steps.legalAndTaxDetails:
        updateLegalAndTaxDetailsForm(value)
        break

      // case Steps.financialInformation:
      //   updateFinancialInformationForm(value)
      //   break

      // case Steps.regulatoryCompliance:
      //   updateRegulatoryComplianceForm(value)
      //   break

      case Steps.ubos:
        updateUbo(value)
        break

      case Steps.documents:
        updateDocumentsForm(value)
        break
    }
  }

  const updateUbos = async (ubos: UboForm[]): Promise<void> => {
    setIsSubmitting(true)
    const payload: Ubo[] = []

    for (const ubo of ubos) {
      let govIdImage = ubo.govIdImage
      let proofOfAddress = ubo.proofOfAddress

      if (ubo.govIdImageFile) {
        const formData = new FormData()
        formData.append('type', ubo.govIdImageFile?.type)
        formData.append('filename', ubo.govIdImageFile?.name)
        formData.append('content', ubo.govIdImageFile as Blob)

        govIdImage = await Radom.uploadVerificationImage(formData)
      }

      if (ubo.proofOfAddressFile) {
        const formData = new FormData()
        formData.append('type', ubo.proofOfAddressFile.type)
        formData.append('filename', ubo.proofOfAddressFile.name)
        formData.append('content', ubo.proofOfAddressFile)

        proofOfAddress = await Radom.uploadVerificationImage(formData)
      }

      payload.push({
        ...ubo,
        govIdImage,
        proofOfAddress
      })
    }

    await Radom.updateVerification(payload)

    setUbos(ubos)


    setIsSubmitting(false)
    setIsDirty({ ...isDirty, [step]: true })
  }

  const onSubmit = async (payload: UpdateVerificationPayload, step: Steps): Promise<void> => {
    try {
      if (isStepDirty) {
        setIsSubmitting(true)
        await Radom.updateVerification(payload)
        setIsSubmitting(false)
        setIsDirty({ ...isDirty, [step]: false })
      }

      setStep(step)
    } catch (error) {
      errorToast('Something went wrong. Please try again.')
      setIsSubmitting(false)
      setIsDirty({ ...isDirty, [step]: true })
    }
  }

  const renderStep = (): ReactElement => {
    switch (step) {
      case Steps.businessBasics:
        return <BusinessBasics
          state={businessBasicsForm}
          isSubmitting={isSubmitting}
          onUpdate={onUpdate}
          onSubmit={async () => {
            await onSubmit(businessBasicsForm, Steps.businessAddress)
          }}
        />

      case Steps.businessAddress:
        return <BusinessAddressView
          state={businessAddressForm}
          isSubmitting={isSubmitting}
          onUpdate={onUpdate}
          onSubmit={async () => {
            let proofOfAddress = businessAddressForm.proofOfAddress

            if (businessAddressForm.proofOfAddressFile) {
              setIsSubmitting(true)
              const formData = new FormData()
              formData.append('type', businessAddressForm.proofOfAddressFile.type)
              formData.append('filename', businessAddressForm.proofOfAddressFile.name)
              formData.append('content', businessAddressForm.proofOfAddressFile)

              try {
                proofOfAddress = await Radom.uploadVerificationImage(formData)
              } catch (err) {
                errorToast('Failed to upload verification data')
                setIsSubmitting(false)
                return
              }
            }

            await onSubmit({ ...businessAddressForm, proofOfAddress }, Steps.legalAndTaxDetails)
          }}
        />

      case Steps.legalAndTaxDetails:
        return <LegalAndTaxDetailsView
          countryCode={businessAddressForm.registeredAddress.country}
          state={legalAndTaxDetailsForm}
          isSubmitting={isSubmitting}
          onUpdate={onUpdate}
          onSubmit={async () => await onSubmit(legalAndTaxDetailsForm, Steps.documents)}
        />

      // case Steps.financialInformation:
      //   return <FinancialInformation
      //     state={financialInformationForm}
      //     isSubmitting={isSubmitting}
      //     onUpdate={onUpdate}
      //     onSubmit={async () => {
      //       let payload = {
      //         sourceOfFunds: financialInformationForm.sourceOfFunds,
      //         statementOfFunds: financialInformationForm.statementOfFunds
      //       }

      //       if (isStepDirty) {
      //         setIsSubmitting(true)
      //         const formData = new FormData()
      //         formData.append('type', 'Document')
      //         formData.append('content', financialInformationForm.statementOfFundsFile)

      //         const statementOfFunds = await Radom.uploadVerificationImage(verification?.id as string, formData)

      //         payload = {
      //           sourceOfFunds: financialInformationForm.sourceOfFunds,
      //           statementOfFunds
      //         }
      //       }

      //       await onSubmit(payload, Steps.regulatoryCompliance)
      //     }}
      //   />

      // case Steps.regulatoryCompliance:
      //   return <RegulatoryCompliance
      //     state={regulatoryComplianceForm}
      //     isSubmitting={isSubmitting}
      //     onUpdate={onUpdate}
      //     onSubmit={async () => await onSubmit(regulatoryComplianceForm, Steps.ubos)}
      //   />

      case Steps.documents:
        return <Documents
          state={documentsForm}
          isSubmitting={isSubmitting}
          onUpdate={onUpdate}
          onSubmit={async () => {
            if (isStepDirty) {
              try {
                setIsSubmitting(true)

                if (documentsForm.formationDocumentFile) {
                  const formationDocumentData = new FormData()
                  formationDocumentData.append('type', documentsForm.formationDocumentFile.type)
                  formationDocumentData.append('filename', documentsForm.formationDocumentFile.name)
                  formationDocumentData.append('content', documentsForm.formationDocumentFile)

                  const formationDocument = await Radom.uploadVerificationImage(formationDocumentData)

                  await onSubmit({ formationDocument }, Steps.ubos)
                }

                if (documentsForm.ownershipDocumentFile) {
                  const ownershipDocumentData = new FormData()
                  ownershipDocumentData.append('type', documentsForm.ownershipDocumentFile.type)
                  ownershipDocumentData.append('filename', documentsForm.ownershipDocumentFile.name)
                  ownershipDocumentData.append('content', documentsForm.ownershipDocumentFile)

                  const ownershipDocument = await Radom.uploadVerificationImage(ownershipDocumentData)

                  await onSubmit({ ownershipDocument }, Steps.ubos)
                }
              } catch (error) {
                errorToast('Something went wrong. Please try again.')
                setIsSubmitting(false)
                setIsDirty({ ...isDirty, [step]: false })
                updateDocumentsForm({
                  formationDocumentFile: undefined,
                  ownershipDocumentFile: undefined
                })
              }
            } else {
              setStep(Steps.ubos)
            }
          }}
        />

      case Steps.ubos:
        return <Ubos
          state={{ ubos, ubo }}
          isSubmitting={isSubmitting}
          canSubmit={canSubmit}
          onUpdate={onUpdate}
          onAdd={async (ubo) => {
            let nextUbos: UboForm[] = []
            if (editUboIndex > -1) {
              nextUbos = [
                ...ubos.slice(0, editUboIndex),
                ubo,
                ...ubos.slice(editUboIndex + 1)
              ]
            } else {
              nextUbos = [...ubos, ubo]
            }

            try {
              await updateUbos(nextUbos)
              updateUbo(DEFAULT_UBO)
            } catch (err) {
              setIsSubmitting(false)
              errorToast("Something went wrong")
              console.error(err)
              throw err
            }
          }}
          onEdit={(index) => {
            setEditUboIndex(index)
            updateUbo(ubos[index])
          }}
          onStopEdit={() => {
            setEditUboIndex(-1)
            updateUbo(DEFAULT_UBO)
          }}
          onRemove={async (index) => {
            const newUbos = ubos.slice()
            newUbos.splice(index, 1)
            await updateUbos(newUbos)
          }}
          onSubmit={async () => {
            try {
              await Radom.updateVerification()
              await User.getOrganization(true)
              navigate('/')
            } catch (error) {
              errorToast('Something went wrong. Please try again.')
              setIsSubmitting(false)
              setIsDirty({ ...isDirty, [step]: true })
            }
          }}
        />

      default:
        return <></>
    }
  }

  const verificationStatus = User.organization?.verificationStatus
  if (verificationStatus === 'Verified' || verificationStatus === 'Rejected' || verificationStatus === 'PendingApproval') {
    return <Navigate to='/' replace />
  }

  return <Container style={{ display: 'block', padding: 0 }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Navigation>
        <Chevron
          style={{
            fill: RADOM_COLORS.GRAY_DARKEST,
            height: '10px',
            width: '10px',
            transform: 'rotate(90deg)',
            cursor: 'pointer'
          }}
          onClick={() => navigate('/')}
        />
        <span>Verify organization</span>
      </Navigation>
      <div style={{ display: 'flex', padding: '0 40px', gap: 240, width: '100%' }}>
        <ProgressIndicator steps={steps} currentStep={step} onStepClick={(stepId) => setStep(Steps[stepId])} />
        <div style={{ width: '500px' }}>
          {renderStep()}
        </div>
      </div>
    </div>
  </Container>
})

export default Verify

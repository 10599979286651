import React from 'react'

export default function PaymentIcon(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.603745" width="48" height="48" rx="8" fill="#2A63FF" />
    <g clip-path="url(#clip0_407_107)">
      <path d="M21.0411 38.25C25.632 38.25 29.3536 31.8701 29.3536 24C29.3536 16.1299 25.632 9.75 21.0411 9.75C16.4503 9.75 12.7286 16.1299 12.7286 24C12.7286 31.8701 16.4503 38.25 21.0411 38.25Z" stroke="white" stroke-width="3.35294" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.0411 9.75H28.1661C32.7573 9.75 36.4786 16.1328 36.4786 24C36.4786 31.8672 32.7573 38.25 28.1661 38.25H21.0411" stroke="white" stroke-width="3.35294" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M29.237 15.5H34.362" stroke="white" stroke-width="3.35294" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M29.3536 24H36.4786" stroke="white" stroke-width="3.35294" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M28.237 32.5H34.362" stroke="white" stroke-width="3.35294" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_407_107">
        <rect width="38" height="38" fill="white" transform="translate(5.60374 5)" />
      </clipPath>
    </defs>
  </svg>

}

import React from 'react'

const Order = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.60376" width="48" height="48" rx="8" fill="#2A63FF" />
    <g clip-path="url(#clip0_402_278)">
      <path d="M14.6038 14C14.6038 14 18.3538 10.25 24.6038 10.25C33.3538 10.25 38.3538 19 38.3538 19" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M34.6038 34C34.6038 34 30.8538 37.75 24.6038 37.75C15.8538 37.75 10.8538 29 10.8538 29" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M30.8538 19H38.3538V11.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.3538 29H10.8538V36.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_402_278">
        <rect width="40" height="40" fill="white" transform="translate(4.60376 4)" />
      </clipPath>
    </defs>
  </svg>

}

export default Order

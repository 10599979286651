import React, {
  ReactElement,
  useEffect,
  useState
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Preview from '../../components/Preview'
import NavbarController from '../../state/NavbarController'
import { PAY_BASE_URL } from '../../util/Constants'
import { errorToast, successToast } from '../../util/Util'
import Radom, { IDonationLink } from '../../state/Radom'
import LoadingBar from '../../components/LoadingBar'
import DonationLinkForm from './DonationLinkForm'

export const EditDonationLink = (): ReactElement => {
  const { donationLinkId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  const [donationLink, setDonationLink] = useState<IDonationLink>()

  useEffect(() => {
    const fetchDonationLink = async (): Promise<void> => {
      try {
        const donationLink = await Radom.getDonationLink(donationLinkId ?? '')
        setDonationLink(donationLink)
      } catch (err) {
        errorToast('Failed to load donation link data')
        navigate('/donation_links')
      }
      setInitialLoading(false)
    }

    if (donationLinkId) {
      fetchDonationLink()
    } else {
      errorToast('Invalid donation link ID')
      navigate('/donation_links')
    }
  }, [donationLinkId, navigate])

  const handleUpdateDonationLink = async (
    donationLink: IDonationLink,
    logoImageFile,
    bannerImageFile
  ): Promise<void> => {
    setLoading(true)
    try {
      await Radom.updateDonationLink(donationLink)

      if (logoImageFile) {
        const formData = new FormData()
        formData.append('entityId', donationLink.id)
        formData.append('imageType', 'donationLinkAvatar')
        formData.append('image', logoImageFile)
        await Radom.uploadImage(formData)
      }

      if (bannerImageFile) {
        const formData = new FormData()
        formData.append('entityId', donationLink.id)
        formData.append('imageType', 'donationLinkBackground')
        formData.append('image', bannerImageFile)
        await Radom.uploadImage(formData)
      }

      successToast('Successfully updated donation link')
      navigate('/donation_links')
    } catch (err) {
      console.error(err)
      errorToast(
        err.response
          ? err.response.data.error || err.response.data
          : err.message
      )
    }
    setLoading(false)
  }

  const [previewData, setPreviewData] = useState<string>()

  const onChange = (
    donationLink: IDonationLink,
    logoImgBytes,
    bannerImgBytes
  ): void => {
    const data = {
      ...donationLink,
      imageUrl: logoImgBytes,
      bannerImageUrl: bannerImgBytes
    }
    setPreviewData(
      Buffer.from(JSON.stringify(data), 'utf8').toString('base64')
    )
  }

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  const [previewWidth, setPreviewWidth] = useState('90%')

  if (initialLoading) {
    return <LoadingBar />
  }

  return (
    <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
      <DonationLinkForm
        actionText='Update donation link'
        initialData={donationLink}
        onChange={onChange}
        onSubmit={handleUpdateDonationLink}
        loading={loading}
      />

      <Preview
        iframeTitle="donate.radom.com"
        iframeLink={`${PAY_BASE_URL}/preview/donation#${previewData}`}
        previewWidth={previewWidth}
        onWidthChange={setPreviewWidth}
      />
    </div>
  )
}

export default EditDonationLink

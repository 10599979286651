export const BusinessTaxNumberTypes = {
    "ABW": [
        {
            "value": "cn",
            "description": "Census Number"
        }
    ],
    "AFG": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "AGO": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "ALB": [
        {
            "value": "nipt",
            "description": "Numri i Identifikimit për Personin e Tatueshëm"
        }
    ],
    "AND": [
        {
            "value": "nrt",
            "description": "Número de Registre Tributari"
        }
    ],
    "ARE": [
        {
            "value": "trn",
            "description": "Tax Registration Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "ARG": [
        {
            "value": "cuit",
            "description": "Clave Única de Identificación Tributaria"
        }
    ],
    "ARM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "AUS": [
        {
            "value": "abn",
            "description": "Australian Business Number"
        },
        {
            "value": "acn",
            "description": "Australian Company Number"
        },
        {
            "value": "arbn",
            "description": "Australian Registered Body Number"
        }
    ],
    "AUT": [
        {
            "value": "fn",
            "description": "Firmenbuchnummer"
        },
        {
            "value": "uid",
            "description": "Umsatzsteuer-Identifikationsnummer"
        }
    ],
    "AZE": [
        {
            "value": "voen",
            "description": "Vergi Ödəyicisinin Eyniləşdirmə Nömrəsi"
        }
    ],
    "BEL": [
        {
            "value": "bce",
            "description": "Banque-Carrefour des Entreprises"
        },
        {
            "value": "kbo",
            "description": "Kruispuntbank van Ondernemingen"
        }
    ],
    "BGD": [
        {
            "value": "bin",
            "description": "Business Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "BGR": [
        {
            "value": "uic",
            "description": "Unified Identification Code"
        }
    ],
    "BHR": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "BHS": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "BIH": [
        {
            "value": "id_broj",
            "description": "ID Broj (Identification Number)"
        }
    ],
    "BLZ": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "BMU": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "BOL": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        }
    ],
    "BRA": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "cnpj",
            "description": "Cadastro Nacional da Pessoa Jurídica"
        }
    ],
    "BRB": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "BWA": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "CAN": [
        {
            "value": "bin",
            "description": "Business Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "CHE": [
        {
            "value": "uid",
            "description": "Umsatzsteuer-Identifikationsnummer"
        },
        {
            "value": "uid",
            "description": "Unternehmens-Identifikationsnummer"
        }
    ],
    "CHL": [
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "CHN": [
        {
            "value": "usc",
            "description": "Unified Social Credit Code"
        }
    ],
    "CIV": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "CMR": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        },
        {
            "value": "rccm",
            "description": "Registre du Commerce et du Crédit Mobilier"
        }
    ],
    "COL": [
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        },
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "COM": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "CRI": [
        {
            "value": "cedula_juridica",
            "description": "Cédula Jurídica"
        }
    ],
    "CUW": [
        {
            "value": "crib",
            "description": "Central Registratie en Informatiebureau"
        }
    ],
    "CXR": [
        {
            "value": "abn",
            "description": "Australian Business Number"
        }
    ],
    "CYM": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "CYP": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "CZE": [
        {
            "value": "ico",
            "description": "Identifikační Číslo Organizace (Organization Identification Number)"
        }
    ],
    "DEU": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        },
        {
            "value": "ust_idnr",
            "description": "Umsatzsteuer-Identifikationsnummer"
        }
    ],
    "DMA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "DNK": [
        {
            "value": "cvr",
            "description": "Central Virksomhedsregister (Central Business Register)"
        }
    ],
    "DOM": [
        {
            "value": "rnc",
            "description": "Registro Nacional de Contribuyentes (National Taxpayer Registry)"
        }
    ],
    "DZA": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "ECU": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "EGY": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "ESP": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        },
        {
            "value": "cif",
            "description": "Código de Identificación Fiscal (Fiscal Identification Code)"
        }
    ],
    "EST": [
        {
            "value": "rc",
            "description": "Register Code"
        }
    ],
    "ETH": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "FIN": [
        {
            "value": "y_tunnus",
            "description": "Business ID"
        }
    ],
    "FJI": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "FRA": [
        {
            "value": "siren",
            "description": "Système d'Identification du Répertoire des Entreprises (Enterprise Identification System)"
        },
        {
            "value": "siret",
            "description": "Système d'Identification du Répertoire des Établissements (Establishment Identification System)"
        }
    ],
    "GBR": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "GEO": [
        {
            "value": "ic",
            "description": "Identification Code"
        }
    ],
    "GHA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "GIB": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "GRC": [
        {
            "value": "aom",
            "description": "Αριθμός Οικονομικής Μονάδας (Economic Unit Number)"
        }
    ],
    "GTM": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        }
    ],
    "HKG": [
        {
            "value": "brn",
            "description": "Business Registration Number"
        }
    ],
    "HND": [
        {
            "value": "rtn",
            "description": "Registro Tributario Nacional (National Tax Registry)"
        }
    ],
    "HRV": [
        {
            "value": "oib",
            "description": "Osobni Identifikacijski Broj (Personal Identification Number)"
        }
    ],
    "HTI": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "HUN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "IDN": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "npwp",
            "description": "Nomor Pokok Wajib Pajak (Taxpayer Identification Number)"
        }
    ],
    "IMN": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "IND": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "gstin",
            "description": "Goods and Services Tax Identification Number"
        },
        {
            "value": "cin",
            "description": "Corporate Identification Number"
        },
        {
            "value": "pan",
            "description": "Personal Account Number"
        }
    ],
    "IRL": [
        {
            "value": "cro",
            "description": "Company Registration Office Number"
        }
    ],
    "IRQ": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ISL": [
        {
            "value": "id",
            "description": "Identification Number"
        }
    ],
    "ISR": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "ITA": [
        {
            "value": "partita_iva",
            "description": "Partita IVA"
        },
        {
            "value": "cf",
            "description": "Codice Fiscale"
        }
    ],
    "JAM": [
        {
            "value": "trn",
            "description": "Tax Registration Number"
        }
    ],
    "JOR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "JPN": [
        {
            "value": "hn",
            "description": "Hojin Number (Corporate Number)"
        }
    ],
    "KAZ": [
        {
            "value": "bin",
            "description": "Business Identification Number"
        }
    ],
    "KEN": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "pin",
            "description": "Personal Identification Number"
        }
    ],
    "KGZ": [
        {
            "value": "inn",
            "description": "Идентификационный Номер Налогоплательщика (Taxpayer Identification Number)"
        }
    ],
    "KHM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "KNA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "KOR": [
        {
            "value": "brn",
            "description": "Business Registration Number"
        }
    ],
    "KWT": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "LAO": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LBN": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "LBR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LCA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LKA": [
        {
            "value": "brn",
            "description": "Business Registration Number"
        }
    ],
    "LTU": [
        {
            "value": "cc",
            "description": "Company Code"
        }
    ],
    "LUX": [
        {
            "value": "matricule",
            "description": "Matricule Fiscale"
        }
    ],
    "LVA": [
        {
            "value": "rn",
            "description": "Reģistrācijas Numurs (Registration Number)"
        }
    ],
    "MAR": [
        {
            "value": "if",
            "description": "Identifiant Fiscal (Tax Identifier)"
        }
    ],
    "MCO": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "MDA": [
        {
            "value": "idno",
            "description": "Identification Number of Organization"
        }
    ],
    "MDG": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "MEX": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "rfc",
            "description": "Registro Federal de Contribuyentes (Federal Taxpayer Registry)"
        }
    ],
    "MHL": [
        {
            "value": "en",
            "description": "Entity Number"
        }
    ],
    "MKD": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "MLT": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "MNE": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "MOZ": [
        {
            "value": "pib",
            "description": "Personal Identification of the Business"
        }
    ],
    "MRT": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "MUS": [
        {
            "value": "brn",
            "description": "Business Registration Number"
        }
    ],
    "MWI": [
        {
            "value": "tpin",
            "description": "Taxpayer Identification Number"
        }
    ],
    "MYS": [
        {
            "value": "gstin",
            "description": "Goods and Services Tax Identification Number"
        },
        {
            "value": "ssm",
            "description": "Suruhanjaya Syarikat Malaysia"
        }
    ],
    "NAM": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "NGA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "NIC": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "NLD": [
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        },
        {
            "value": "kvk",
            "description": "Kamer van Koophandel"
        }
    ],
    "NOR": [
        {
            "value": "orgnr",
            "description": "Organisasjonsnummer"
        }
    ],
    "NPL": [
        {
            "value": "pan",
            "description": "Personal Account Number"
        }
    ],
    "NZL": [
        {
            "value": "gst",
            "description": "Goods and Services Tax Number"
        }
    ],
    "OMN": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "PAN": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "PER": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "PHL": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "PAK": [
        {
            "value": "ntn",
            "description": "National Tax Number"
        }
    ],
    "POL": [
        {
            "value": "nip",
            "description": "Numer Identyfikacji Podatkowej"
        },
        {
            "value": "krs",
            "description": "Krajowy Rejestr Sądowy"
        }
    ],
    "PRT": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "QAT": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "ROU": [
        {
            "value": "cui",
            "description": "Cod Unic de Înregistrare"
        }
    ],
    "RUS": [
        {
            "value": "inn",
            "description": "Идентификационный Номер Налогоплательщика (Taxpayer Identification Number)"
        },
        {
            "value": "ogrn",
            "description": "Primary State Registration Number"
        }
    ],
    "SAU": [
        {
            "value": "crn",
            "description": "Company/Commercial Registration Number"
        }
    ],
    "SEN": [
        {
            "value": "ninea",
            "description": "Numéro d’Identification Nationale des Entreprises et des Associations"
        }
    ],
    "SGP": [
        {
            "value": "uen",
            "description": "Unique Entity Number"
        }
    ],
    "SVK": [
        {
            "value": "ico",
            "description": "Identifikační Číslo Organizace (Organization Identification Number)"
        }
    ],
    "SVN": [
        {
            "value": "maticna",
            "description": "Matična Številka"
        }
    ],
    "ZAF": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "vat",
            "description": "Value Added Tax Number"
        }
    ],
    "SWE": [
        {
            "value": "orgnr",
            "description": "Organisasjonsnummer"
        }
    ],
    "THA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "TUN": [
        {
            "value": "matricule",
            "description": "Matricule Fiscale"
        }
    ],
    "TUR": [
        {
            "value": "vkn",
            "description": "Vergi Kimlik Numarası"
        }
    ],
    "UGA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "UKR": [
        {
            "value": "edrpou",
            "description": "ЄДРПОУ (Unified State Register of Enterprises and Organizations of Ukraine)"
        }
    ],
    "USA": [
        {
            "value": "ein",
            "description": "Employer Identification Number"
        }
    ],
    "PRI": [
        {
            "value": "ein",
            "description": "Employer Identification Number"
        }
    ],
    "URY": [
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "UZB": [
        {
            "value": "inn",
            "description": "Идентификационный Номер Налогоплательщика (Taxpayer Identification Number)"
        }
    ],
    "VEN": [
        {
            "value": "rif",
            "description": "Registro de Información Fiscal"
        }
    ],
    "VNM": [
        {
            "value": "mst",
            "description": "Mã số thuế (Tax Code)"
        }
    ],
    "YEM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ZMB": [
        {
            "value": "tpin",
            "description": "Taxpayer Identification Number"
        }
    ],
    "ZWE": [
        {
            "value": "bp",
            "description": "Business Partner Number"
        }
    ]
}

export const IndividualTaxNumberTypes = {
    "ABW": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "AFG": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "AGO": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "ALB": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "AND": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "ARE": [
        {
            "value": "emirates_id",
            "description": "National Identity Card"
        }
    ],
    "ARG": [
        {
            "value": "cuil",
            "description": "Código Único de Identificación Laboral"
        },
        {
            "value": "cdi",
            "description": "Código de Identificación"
        }
    ],
    "ARM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "AUS": [
        {
            "value": "tfn",
            "description": "Tax File Number"
        }
    ],
    "AUT": [
        {
            "value": "si",
            "description": "Social Insurance Number"
        }
    ],
    "AZE": [
        {
            "value": "voen",
            "description": "State Taxpayer Identification Number"
        }
    ],
    "BEL": [
        {
            "value": "nrn",
            "description": "National Register Number"
        }
    ],
    "BGD": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "BGR": [
        {
            "value": "ucn",
            "description": "Unified Civil Number"
        }
    ],
    "BHR": [
        {
            "value": "cpr",
            "description": "Central Population Registry Number"
        }
    ],
    "BHS": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "BIH": [
        {
            "value": "jmbg",
            "description": "Unique Master Citizen Number"
        }
    ],
    "BLZ": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "BMU": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "BOL": [
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        }
    ],
    "BRA": [
        {
            "value": "cpf",
            "description": "Cadastro de Pessoas Físicas"
        }
    ],
    "BRB": [
        {
            "value": "nrn",
            "description": "National Registration Number"
        }
    ],
    "BWA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "CAN": [
        {
            "value": "sin",
            "description": "Social Insurance Number"
        }
    ],
    "CHE": [
        {
            "value": "avs",
            "description": "AHV Number"
        },
        {
            "value": "ahv",
            "description": "Old Age and Survivors Insurance Number"
        }
    ],
    "CHL": [
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "CHN": [
        {
            "value": "ricn",
            "description": "Resident Identity Card Number"
        }
    ],
    "CIV": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "CMR": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "COL": [
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        },
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "COM": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "CRI": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "CUW": [
        {
            "value": "crib",
            "description": "Chamber of Commerce Registration Number"
        }
    ],
    "CXR": [
        {
            "value": "tfn",
            "description": "Tax File Number"
        }
    ],
    "CYM": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "CYP": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "CZE": [
        {
            "value": "rc",
            "description": "Residence Code Number"
        }
    ],
    "DEU": [
        {
            "value": "steuer_id",
            "description": "Steueridentifikationsnummer (Tax Identification Number)"
        }
    ],
    "DMA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "DNK": [
        {
            "value": "cpr",
            "description": "Central Person Register Number"
        }
    ],
    "DOM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "DZA": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "ECU": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "EGY": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ESP": [
        {
            "value": "nif",
            "description": "Número de Identificación Fiscal"
        },
        {
            "value": "nie",
            "description": "Número de Identificación de Extranjeros"
        }
    ],
    "EST": [
        {
            "value": "ik",
            "description": "Individual Code"
        }
    ],
    "ETH": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "FIN": [
        {
            "value": "hetu",
            "description": "Finnish Personal Identity Code"
        }
    ],
    "FJI": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "FRA": [
        {
            "value": "spi",
            "description": "Social Security Number"
        }
    ],
    "GBR": [
        {
            "value": "nino",
            "description": "National Insurance Number"
        },
        {
            "value": "utr",
            "description": "Unique Taxpayer Reference Number"
        }
    ],
    "GEO": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "GHA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "GIB": [
        {
            "value": "crc",
            "description": "Company Registration Number"
        }
    ],
    "GRC": [
        {
            "value": "aom",
            "description": "Αριθμός Μητρώου (Social Security Number)"
        }
    ],
    "GTM": [
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        }
    ],
    "HKG": [
        {
            "value": "hkid",
            "description": "Hong Kong Identity Card Number"
        }
    ],
    "HND": [
        {
            "value": "rtn",
            "description": "Registro Tributario Nacional"
        }
    ],
    "HRV": [
        {
            "value": "oib",
            "description": "Personal Identification Number"
        }
    ],
    "HTI": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "HUN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "IDN": [
        {
            "value": "npwp",
            "description": "Nomor Pokok Wajib Pajak"
        }
    ],
    "IMN": [
        {
            "value": "nino",
            "description": "National Insurance Number"
        }
    ],
    "IND": [
        {
            "value": "pan",
            "description": "Permanent Account Number"
        }
    ],
    "IRL": [
        {
            "value": "ppsn",
            "description": "Personal Public Service Number"
        }
    ],
    "IRQ": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ISL": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ISR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ITA": [
        {
            "value": "cf",
            "description": "Codice Fiscale (Tax Code)"
        }
    ],
    "JAM": [
        {
            "value": "trn",
            "description": "Taxpayer Registration Number"
        }
    ],
    "JOR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "JPN": [
        {
            "value": "mn",
            "description": "My Number (Individual Number)"
        }
    ],
    "KAZ": [
        {
            "value": "iin",
            "description": "Individual Identification Number"
        }
    ],
    "KEN": [
        {
            "value": "pin",
            "description": "Personal Identification Number"
        }
    ],
    "KGZ": [
        {
            "value": "inn",
            "description": "Individual Taxpayer Number"
        }
    ],
    "KHM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "KNA": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "KOR": [
        {
            "value": "rrn",
            "description": "Resident Registration Number"
        }
    ],
    "KWT": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LAO": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LBN": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "LBR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LCA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "LKA": [
        {
            "value": "nic",
            "description": "National Identity Card Number"
        }
    ],
    "LTU": [
        {
            "value": "ak",
            "description": "Personal Code"
        }
    ],
    "LUX": [
        {
            "value": "matricule",
            "description": "Matricule Number (Social Security Number)"
        }
    ],
    "LVA": [
        {
            "value": "pk",
            "description": "Person’s Code"
        }
    ],
    "MAR": [
        {
            "value": "if",
            "description": "Identification Fiscal"
        }
    ],
    "MCO": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "MDA": [
        {
            "value": "idnp",
            "description": "Identification Number of the Person"
        }
    ],
    "MDG": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "MEX": [
        {
            "value": "rfc",
            "description": "Registro Federal de Contribuyentes"
        },
        {
            "value": "CURP",
            "description": "Clave Única de Registro de Población"
        },
        {
            "value": "INE",
            "description": "Instituto Nacional Electoral"
        }
    ],
    "MHL": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "MKD": [
        {
            "value": "embg",
            "description": "Unique Identification Number"
        }
    ],
    "MLT": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "MNE": [
        {
            "value": "jmbg",
            "description": "Unique Master Citizen Number"
        }
    ],
    "MOZ": [
        {
            "value": "nuit",
            "description": "Número Único de Identificação Tributária"
        }
    ],
    "MRT": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "MUS": [
        {
            "value": "nicn",
            "description": "National Identity Card Number"
        }
    ],
    "MWI": [
        {
            "value": "tpin",
            "description": "Taxpayer Identification Number"
        }
    ],
    "MYS": [
        {
            "value": "itr",
            "description": "Income Tax Reference Number"
        }
    ],
    "NAM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "NGA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "nin",
            "description": "National Identification Number"
        },
        {
            "value": "bvn",
            "description": "Bank Verification Number"
        }
    ],
    "NIC": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "NLD": [
        {
            "value": "bsn",
            "description": "Burgerservicenummer (Citizen Service Number)"
        }
    ],
    "NOR": [
        {
            "value": "fn",
            "description": "Fødselsnummer (Personal Identification Number)"
        }
    ],
    "NPL": [
        {
            "value": "pan",
            "description": "Permanent Account Number"
        }
    ],
    "NZL": [
        {
            "value": "ird",
            "description": "Inland Revenue Department Number"
        }
    ],
    "OMN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "PAK": [
        {
            "value": "ntn",
            "description": "National Tax Number"
        }
    ],
    "PAN": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "PER": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "PHL": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "POL": [
        {
            "value": "pesel",
            "description": "Personal Identification Number"
        }
    ],
    "PRI": [
        {
            "value": "ssn",
            "description": "Social Security Number"
        }
    ],
    "PRT": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "PRY": [
        {
            "value": "ruc",
            "description": "Registro Único de Contribuyentes"
        }
    ],
    "QAT": [
        {
            "value": "qid",
            "description": "Qatar ID"
        }
    ],
    "ROU": [
        {
            "value": "cnp",
            "description": "Cod Numeric Personal"
        }
    ],
    "RWA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SAU": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        },
        {
            "value": "rp",
            "description": "Iqama (Residency Permit)"
        }
    ],
    "SEN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SGP": [
        {
            "value": "nric",
            "description": "National Registration Identity Card"
        },
        {
            "value": "fin",
            "description": "Foreign Identification Number"
        }
    ],
    "SLV": [
        {
            "value": "nit",
            "description": "Número de Identificación Tributaria"
        }
    ],
    "SOM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SRB": [
        {
            "value": "jmbg",
            "description": "Unique Master Citizen Number"
        }
    ],
    "SUR": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SVK": [
        {
            "value": "rc",
            "description": "Rodné Číslo (Personal Identification Number)"
        }
    ],
    "SVN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SWE": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "SYC": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "TCA": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "TGO": [
        {
            "value": "nif",
            "description": "Número de Identificação Fiscal"
        }
    ],
    "THA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "TTO": [
        {
            "value": "bir",
            "description": "Business Identification Number"
        }
    ],
    "TUN": [
        {
            "value": "mf",
            "description": "Matricule Fiscal"
        }
    ],
    "TUR": [
        {
            "value": "tckn",
            "description": "Turkish Citizenship Number"
        }
    ],
    "TWN": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "TZA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "UGA": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "UKR": [
        {
            "value": "rnokpp",
            "description": "Registration Number of the Taxpayer"
        }
    ],
    "URY": [
        {
            "value": "rut",
            "description": "Registro Único Tributario"
        }
    ],
    "USA": [
        {
            "value": "ssn",
            "description": "Social Security Number"
        },
        {
            "value": "itin",
            "description": "Individual Taxpayer Identification Number"
        }
    ],
    "UZB": [
        {
            "value": "inn",
            "description": "Individual Identification Number"
        }
    ],
    "VCT": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "VEN": [
        {
            "value": "rif",
            "description": "Registro de Información Fiscal"
        }
    ],
    "VGB": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "VNM": [
        {
            "value": "mst",
            "description": "Mã Số Thuế (Tax Code)"
        }
    ],
    "VUT": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "WSM": [
        {
            "value": "other",
            "description": "Please provide a description of the document being provided"
        }
    ],
    "YEM": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ],
    "ZAF": [
        {
            "value": "itr",
            "description": "Income Tax Reference Number"
        }
    ],
    "ZMB": [
        {
            "value": "tpin",
            "description": "Taxpayer Identification Number"
        }
    ],
    "ZWE": [
        {
            "value": "tin",
            "description": "Tax Identification Number"
        }
    ]
}

export const IndividualGovernmentIdTypes = [
    {
        "value": "passport",
        "description": "Passport"
    },
    {
        "value": "drivers_license",
        "description": "Drivers license"
    },
    {
        "value": "matriculate_id",
        "description": "Matriculate ID"
    },
    {
        "value": "military_id",
        "description": "Military ID"
    },
    {
        "value": "permanent_residency_id",
        "description": "Permanent Residency ID"
    },
    {
        "value": "state_or_provincial_id",
        "description": "State or provincial ID"
    },
    {
        "value": "visa",
        "description": "Visa"
    }
]
export enum Industry {
    "Soybean Farming" = 111110,
    "Oilseed Except Soybean Farming" = 111120,
    "Dry Pea And Bean Farming" = 111130,
    "Wheat Farming" = 111140,
    "Corn Farming" = 111150,
    "Rice Farming" = 111160,
    "Oilseed And Grain Combination Farming" = 111191,
    "All Other Grain Farming" = 111199,
    "Potato Farming" = 111211,
    "Other Vegetable Except Potato And Melon Farming" = 111219,
    "Orange Groves" = 111310,
    "Citrus Except Orange Groves" = 111320,
    "Apple Orchards" = 111331,
    "Grape Vineyards" = 111332,
    "Strawberry Farming" = 111333,
    "Berry Except Strawberry Farming" = 111334,
    "Tree Nut Farming" = 111335,
    "Fruit And Tree Nut Combination Farming" = 111336,
    "Other Noncitrus Fruit Farming" = 111339,
    "Mushroom Production" = 111411,
    "Other Food Crops Grown Under Cover" = 111419,
    "Nursery And Tree Production" = 111421,
    "Floriculture Production" = 111422,
    "Tobacco Farming" = 111910,
    "Cotton Farming" = 111920,
    "Sugarcane Farming" = 111930,
    "Hay Farming" = 111940,
    "Sugar Beet Farming" = 111991,
    "Peanut Farming" = 111992,
    "All Other Miscellaneous Crop Farming" = 111998,
    "Beef Cattle Ranching And Farming" = 112111,
    "Cattle Feedlots" = 112112,
    "Dairy Cattle And Milk Production" = 112120,
    "Hog And Pig Farming" = 112210,
    "Chicken Egg Production" = 112310,
    "Broilers And Other Meat Type Chicken Production" = 112320,
    "Turkey Production" = 112330,
    "Poultry Hatcheries" = 112340,
    "Other Poultry Production" = 112390,
    "Sheep Farming" = 112410,
    "Goat Farming" = 112420,
    "Finfish Farming And Fish Hatcheries" = 112511,
    "Shellfish Farming" = 112512,
    "Other Aquaculture" = 112519,
    "Apiculture" = 112910,
    "Horses And Other Equine Production" = 112920,
    "Fur Bearing Animal And Rabbit Production" = 112930,
    "All Other Animal Production" = 112990,
    "Timber Tract Operations" = 113110,
    "Forest Nurseries And Gathering Of Forest Products" = 113210,
    "Logging" = 113310,
    "Finfish Fishing" = 114111,
    "Shellfish Fishing" = 114112,
    "Other Marine Fishing" = 114119,
    "Hunting And Trapping" = 114210,
    "Cotton Ginning" = 115111,
    "Soil Preparation Planting And Cultivating" = 115112,
    "Crop Harvesting Primarilyby Machine" = 115113,
    "Postharvest Crop Activities Except Cotton Ginning" = 115114,
    "Farm Labor Contractors And Crew Leaders" = 115115,
    "Farm Management Services" = 115116,
    "Support Activities For Animal Production" = 115210,
    "Support Activities For Forestry" = 115310,
    "Crude Petroleum Extraction" = 211120,
    "Natural Gas Extraction" = 211130,
    "Surface Coal Mining" = 212114,
    "Underground Coal Mining" = 212115,
    "Iron Ore Mining" = 212210,
    "Gold Ore And Silver Ore Mining" = 212220,
    "Copper Nickel Lead And Zinc Mining" = 212230,
    "Other Metal Ore Mining" = 212290,
    "Dimension Stone Mining And Quarrying" = 212311,
    "Crushed And Broken Limestone Mining And Quarrying" = 212312,
    "Crushed And Broken Granite Mining And Quarrying" = 212313,
    "Other Crushed And Broken Stone Mining And Quarrying" = 212319,
    "Construction Sand And Gravel Mining" = 212321,
    "Industrial Sand Mining" = 212322,
    "Kaolin Clay And Ceramic And Refractory Minerals Mining" = 212323,
    "Other Nonmetallic Mineral Mining And Quarrying" = 212390,
    "Drilling Oil And Gas Wells" = 213111,
    "Support Activities For Oil And Gas Operations" = 213112,
    "Support Activities For Coal Mining" = 213113,
    "Support Activities For Metal Mining" = 213114,
    "Support Activities For Nonmetallic Minerals Except Fuels Mining" = 213115,
    "Hydroelectric Power Generation" = 221111,
    "Fossil Fuel Electric Power Generation" = 221112,
    "Nuclear Electric Power Generation" = 221113,
    "Solar Electric Power Generation" = 221114,
    "Wind Electric Power Generation" = 221115,
    "Geothermal Electric Power Generation" = 221116,
    "Biomass Electric Power Generation" = 221117,
    "Other Electric Power Generation" = 221118,
    "Electric Bulk Power Transmission And Control" = 221121,
    "Electric Power Distribution" = 221122,
    "Natural Gas Distribution" = 221210,
    "Water Supply And Irrigation Systems" = 221310,
    "Sewage Treatment Facilities" = 221320,
    "Steam And Air Conditioning Supply" = 221330,
    "New Single Family Housing Construction Except For Sale Builders" = 236115,
    "New Multifamily Housing Construction Except For Sale Builders" = 236116,
    "New Housing For Sale Builders" = 236117,
    "Residential Remodelers" = 236118,
    "Industrial Building Construction" = 236210,
    "Commercial And Institutional Building Construction" = 236220,
    "Water And Sewer Line And Related Structures Construction" = 237110,
    "Oil And Gas Pipeline And Related Structures Construction" = 237120,
    "Power And Communication Line And Related Structures Construction" = 237130,
    "Land Subdivision" = 237210,
    "Highway Street And Bridge Construction" = 237310,
    "Other Heavy And Civil Engineering Construction" = 237990,
    "Residential Poured Concrete Foundation And Structure Contractors" = 238111,
    "Non Residential Poured Concrete Foundation And Structure Contractors" = 238112,
    "Residential Structural Steel And Precast Concrete Contractors" = 238121,
    "Non Residential Structural Steel And Precast Concrete Contractors" = 238122,
    "Residential Framing Contractors" = 238131,
    "Non Residential Framing Contractors" = 238132,
    "Residential Masonry Contractors" = 238141,
    "Non Residential Masonry Contractors" = 238142,
    "Residential Glass And Glazing Contractors" = 238151,
    "Non Residential Glass And Glazing Contractors" = 238152,
    "Residential Roofing Contractors" = 238161,
    "Non Residential Roofing Contractors" = 238162,
    "Residential Siding Contractors" = 238171,
    "Non Residential Siding Contractors" = 238172,
    "Residential Other Foundation Structure And Building Exterior Contractors" = 238191,
    "Non Residential Other Foundation Structure And Building Exterior Contractors" = 238192,
    "Residential Electrical Contractors And Other Wiring Installation Contractors" = 238211,
    "Non Residential Electrical Contractors And Other Wiring Installation Contractors" = 238212,
    "Residential Plumbing Heating And Air Conditioning Contractors" = 238221,
    "Non Residential Plumbing Heating And Air Conditioning Contractors" = 238222,
    "Residential Other Building Equipment Contractors" = 238291,
    "Non Residential Other Building Equipment Contractors" = 238292,
    "Residential Drywall And Insulation Contractors" = 238311,
    "Non Residential Drywall And Insulation Contractors" = 238312,
    "Residential Painting And Wall Covering Contractors" = 238321,
    "Non Residential Painting And Wall Covering Contractors" = 238322,
    "Residential Flooring Contractors" = 238331,
    "Non Residential Flooring Contractors" = 238332,
    "Residential Tile And Terrazzo Contractors" = 238341,
    "Non Residential Tile And Terrazzo Contractors" = 238342,
    "Residential Finish Carpentry Contractors" = 238351,
    "Non Residential Finish Carpentry Contractors" = 238352,
    "Residential Other Building Finishing Contractors" = 238391,
    "Non Residential Other Building Finishing Contractors" = 238392,
    "Residential Site Preparation Contractors" = 238911,
    "Non Residential Site Preparation Contractors" = 238912,
    "Residential All Other Specialty Trade Contractors" = 238991,
    "Non Residential All Other Specialty Trade Contractors" = 238992,
    "Dog And Cat Food Manufacturing" = 311111,
    "Other Animal Food Manufacturing" = 311119,
    "Flour Milling" = 311211,
    "Rice Milling" = 311212,
    "Malt Manufacturing" = 311213,
    "Wet Corn Milling And Starch Manufacturing" = 311221,
    "Soybean And Other Oilseed Processing" = 311224,
    "Fats And Oils Refining And Blending" = 311225,
    "Breakfast Cereal Manufacturing" = 311230,
    "Beet Sugar Manufacturing" = 311313,
    "Cane Sugar Manufacturing" = 311314,
    "Nonchocolate Confectionery Manufacturing" = 311340,
    "Chocolate And Confectionery Manufacturing From Cacao Beans" = 311351,
    "Confectionery Manufacturing From Purchased Chocolate" = 311352,
    "Frozen Fruit Juice And Vegetable Manufacturing" = 311411,
    "Frozen Specialty Food Manufacturing" = 311412,
    "Fruit And Vegetable Canning" = 311421,
    "Specialty Canning" = 311422,
    "Dried And Dehydrated Food Manufacturing" = 311423,
    "Fluid Milk Manufacturing" = 311511,
    "Creamery Butter Manufacturing" = 311512,
    "Cheese Manufacturing" = 311513,
    "Dry Condensed And Evaporated Dairy Product Manufacturing" = 311514,
    "Ice Cream And Frozen Dessert Manufacturing" = 311520,
    "Animal Except Poultry Slaughtering" = 311611,
    "Meat Processed From Carcasses" = 311612,
    "Rendering And Meat Byproduct Processing" = 311613,
    "Poultry Processing" = 311615,
    "Seafood Product Preparation And Packaging" = 311710,
    "Retail Bakeries" = 311811,
    "Commercial Bakeries" = 311812,
    "Frozen Cakes Pies And Other Pastries Manufacturing" = 311813,
    "Cookie And Cracker Manufacturing" = 311821,
    "Dry Pasta Dough And Flour Mixes Manufacturing From Purchased Flour" = 311824,
    "Tortilla Manufacturing" = 311830,
    "Roasted Nuts And Peanut Butter Manufacturing" = 311911,
    "Other Snack Food Manufacturing" = 311919,
    "Coffee And Tea Manufacturing" = 311920,
    "Flavoring Syrup And Concentrate Manufacturing" = 311930,
    "Mayonnaise Dressing And Other Prepared Sauce Manufacturing" = 311941,
    "Spice And Extract Manufacturing" = 311942,
    "Perishable Prepared Food Manufacturing" = 311991,
    "All Other Miscellaneous Food Manufacturing" = 311999,
    "Soft Drink Manufacturing" = 312111,
    "Bottled Water Manufacturing" = 312112,
    "Ice Manufacturing" = 312113,
    "Breweries" = 312120,
    "Wineries" = 312130,
    "Distilleries" = 312140,
    "Tobacco Manufacturing" = 312230,
    "Fiber Yarn And Thread Mills" = 313110,
    "Broadwoven Fabric Mills" = 313210,
    "Narrow Fabric Mills And Schiffli Machine Embroidery" = 313220,
    "Nonwoven Fabric Mills" = 313230,
    "Knit Fabric Mills" = 313240,
    "Textile And Fabric Finishing Mills" = 313310,
    "Fabric Coating Mills" = 313320,
    "Carpet And Rug Mills" = 314110,
    "Curtain And Linen Mills" = 314120,
    "Textile Bag And Canvas Mills" = 314910,
    "Rope Cordage Twine Tire Cord And Tire Fabric Mills" = 314994,
    "All Other Miscellaneous Textile Product Mills" = 314999,
    "Apparel Knitting Mills" = 315120,
    "Cut And Sew Apparel Contractors" = 315210,
    "Cut And Sew Apparel Manufacturing Except Contractors" = 315250,
    "Apparel Accessories And Other Apparel Manufacturing" = 315990,
    "Leather And Hide Tanning And Finishing" = 316110,
    "Footwear Manufacturing" = 316210,
    "Other Leather And Allied Product Manufacturing" = 316990,
    "Sawmills" = 321113,
    "Wood Preservation" = 321114,
    "Hardwood Veneer And Plywood Manufacturing" = 321211,
    "Softwood Veneer And Plywood Manufacturing" = 321212,
    "Engineered Wood Member Manufacturing" = 321215,
    "Reconstituted Wood Product Manufacturing" = 321219,
    "Wood Window And Door Manufacturing" = 321911,
    "Cut Stock Resawing Lumber And Planing" = 321912,
    "Other Millwork Including Flooring" = 321918,
    "Wood Container And Pallet Manufacturing" = 321920,
    "Manufactured Home Mobile Home Manufacturing" = 321991,
    "Prefabricated Wood Building Manufacturing" = 321992,
    "All Other Miscellaneous Wood Product Manufacturing" = 321999,
    "Pulp Mills" = 322110,
    "Paper Mills" = 322120,
    "Paperboard Mills" = 322130,
    "Corrugated And Solid Fiber Box Manufacturing" = 322211,
    "Folding Paperboard Box Manufacturing" = 322212,
    "Other Paperboard Container Manufacturing" = 322219,
    "Paper Bag And Coated And Treated Paper Manufacturing" = 322220,
    "Stationery Product Manufacturing" = 322230,
    "Sanitary Paper Product Manufacturing" = 322291,
    "All Other Converted Paper Product Manufacturing" = 322299,
    "Commercial Printing Except Screen And Books" = 323111,
    "Commercial Screen Printing" = 323113,
    "Books Printing" = 323117,
    "Support Activities For Printing" = 323120,
    "Petroleum Refineries" = 324110,
    "Asphalt Paving Mixture And Block Manufacturing" = 324121,
    "Asphalt Shingle And Coating Materials Manufacturing" = 324122,
    "Petroleum Lubricating Oil And Grease Manufacturing" = 324191,
    "All Other Petroleum And Coal Products Manufacturing" = 324199,
    "Petrochemical Manufacturing" = 325110,
    "Industrial Gas Manufacturing" = 325120,
    "Synthetic Dye And Pigment Manufacturing" = 325130,
    "Other Basic Inorganic Chemical Manufacturing" = 325180,
    "Ethyl Alcohol Manufacturing" = 325193,
    "Cyclic Crude Intermediate And Gum And Wood Chemical Manufacturing" = 325194,
    "All Other Basic Organic Chemical Manufacturing" = 325199,
    "Plastics Material And Resin Manufacturing" = 325211,
    "Synthetic Rubber Manufacturing" = 325212,
    "Artificial And Synthetic Fibers And Filaments Manufacturing" = 325220,
    "Nitrogenous Fertilizer Manufacturing" = 325311,
    "Phosphatic Fertilizer Manufacturing" = 325312,
    "Fertilizer Mixing Only Manufacturing" = 325314,
    "Compost Manufacturing" = 325315,
    "Pesticide And Other Agricultural Chemical Manufacturing" = 325320,
    "Medicinal And Botanical Manufacturing" = 325411,
    "Pharmaceutical Preparation Manufacturing" = 325412,
    "In Vitro Diagnostic Substance Manufacturing" = 325413,
    "Biological Product Except Diagnostic Manufacturing" = 325414,
    "Paint And Coating Manufacturing" = 325510,
    "Adhesive Manufacturing" = 325520,
    "Soap And Other Detergent Manufacturing" = 325611,
    "Polish And Other Sanitation Good Manufacturing" = 325612,
    "Surface Active Agent Manufacturing" = 325613,
    "Toilet Preparation Manufacturing" = 325620,
    "Printing Ink Manufacturing" = 325910,
    "Explosives Manufacturing" = 325920,
    "Custom Compounding Of Purchased Resins" = 325991,
    "Photographic Film Paper Plate Chemical And Copy Toner Manufacturing" = 325992,
    "All Other Miscellaneous Chemical Product And Preparation Manufacturing" = 325998,
    "Plastics Bag And Pouch Manufacturing" = 326111,
    "Plastics Packaging Film And Sheet Including Laminated Manufacturing" = 326112,
    "Unlaminated Plastics Film And Sheet Except Packaging Manufacturing" = 326113,
    "Unlaminated Plastics Profile Shape Manufacturing" = 326121,
    "Plastics Pipe And Pipe Fitting Manufacturing" = 326122,
    "Laminated Plastics Plate Sheet Except Packaging And Shape Manufacturing" = 326130,
    "Polystyrene Foam Product Manufacturing" = 326140,
    "Urethane And Other Foam Product Except Polystyrene Manufacturing" = 326150,
    "Plastics Bottle Manufacturing" = 326160,
    "Plastics Plumbing Fixture Manufacturing" = 326191,
    "All Other Plastics Product Manufacturing" = 326199,
    "Tire Manufacturing Except Retreading" = 326211,
    "Tire Retreading" = 326212,
    "Rubber And Plastics Hoses And Belting Manufacturing" = 326220,
    "Rubber Product Manufacturing For Mechanical Use" = 326291,
    "All Other Rubber Product Manufacturing" = 326299,
    "Pottery Ceramics And Plumbing Fixture Manufacturing" = 327110,
    "Clay Building Material And Refractories Manufacturing" = 327120,
    "Flat Glass Manufacturing" = 327211,
    "Other Pressed And Blown Glass And Glassware Manufacturing" = 327212,
    "Glass Container Manufacturing" = 327213,
    "Glass Product Manufacturing Made Of Purchased Glass" = 327215,
    "Cement Manufacturing" = 327310,
    "Ready Mix Concrete Manufacturing" = 327320,
    "Concrete Block And Brick Manufacturing" = 327331,
    "Concrete Pipe Manufacturing" = 327332,
    "Other Concrete Product Manufacturing" = 327390,
    "Lime Manufacturing" = 327410,
    "Gypsum Product Manufacturing" = 327420,
    "Abrasive Product Manufacturing" = 327910,
    "Cut Stone And Stone Product Manufacturing" = 327991,
    "Ground Or Treated Mineral And Earth Manufacturing" = 327992,
    "Mineral Wool Manufacturing" = 327993,
    "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing" = 327999,
    "Iron And Steel Mills And Ferroalloy Manufacturing" = 331110,
    "Iron And Steel Pipe And Tube Manufacturing From Purchased Steel" = 331210,
    "Rolled Steel Shape Manufacturing" = 331221,
    "Steel Wire Drawing" = 331222,
    "Alumina Refining And Primary Aluminum Production" = 331313,
    "Secondary Smelting And Alloying Of Aluminum" = 331314,
    "Aluminum Sheet Plate And Foil Manufacturing" = 331315,
    "Other Aluminum Rolling Drawing And Extruding" = 331318,
    "Nonferrous Metal Except Aluminum Smelting And Refining" = 331410,
    "Copper Rolling Drawing Extruding And Alloying" = 331420,
    "Nonferrous Metal Except Copper And Aluminum Rolling Drawing And Extruding" = 331491,
    "Secondary Smelting Refining And Alloying Of Nonferrous Metal Except Copper And Aluminum" = 331492,
    "Iron Foundries" = 331511,
    "Steel Investment Foundries" = 331512,
    "Steel Foundries Except Investment" = 331513,
    "Nonferrous Metal Die Casting Foundries" = 331523,
    "Aluminum Foundries Except Die Casting" = 331524,
    "Other Nonferrous Metal Foundries Except Die Casting" = 331529,
    "Iron And Steel Forging" = 332111,
    "Nonferrous Forging" = 332112,
    "Custom Roll Forming" = 332114,
    "Powder Metallurgy Part Manufacturing" = 332117,
    "Metal Crown Closure And Other Metal Stamping Except Automotive" = 332119,
    "Metal Kitchen Cookware Utensil Cutlery And Flatware Except Precious Manufacturing" = 332215,
    "Saw Blade And Handtool Manufacturing" = 332216,
    "Prefabricated Metal Building And Component Manufacturing" = 332311,
    "Fabricated Structural Metal Manufacturing" = 332312,
    "Plate Work Manufacturing" = 332313,
    "Metal Window And Door Manufacturing" = 332321,
    "Sheet Metal Work Manufacturing" = 332322,
    "Ornamental And Architectural Metal Work Manufacturing" = 332323,
    "Power Boiler And Heat Exchanger Manufacturing" = 332410,
    "Metal Tank Heavy Gauge Manufacturing" = 332420,
    "Metal Can Manufacturing" = 332431,
    "Other Metal Container Manufacturing" = 332439,
    "Hardware Manufacturing" = 332510,
    "Spring Manufacturing" = 332613,
    "Other Fabricated Wire Product Manufacturing" = 332618,
    "Machine Shops" = 332710,
    "Precision Turned Product Manufacturing" = 332721,
    "Bolt Nut Screw Rivet And Washer Manufacturing" = 332722,
    "Metal Heat Treating" = 332811,
    "Metal Coating Engraving Except Jewelry And Silverware And Allied Servicesto Manufacturers" = 332812,
    "Electroplating Plating Polishing Anodizing And Coloring" = 332813,
    "Industrial Valve Manufacturing" = 332911,
    "Fluid Power Valve And Hose Fitting Manufacturing" = 332912,
    "Plumbing Fixture Fitting And Trim Manufacturing" = 332913,
    "Other Metal Valve And Pipe Fitting Manufacturing" = 332919,
    "Ball And Roller Bearing Manufacturing" = 332991,
    "Small Arms Ammunition Manufacturing" = 332992,
    "Ammunition Except Small Arms Manufacturing" = 332993,
    "Small Arms Ordnance And Ordnance Accessories Manufacturing" = 332994,
    "Fabricated Pipe And Pipe Fitting Manufacturing" = 332996,
    "All Other Miscellaneous Fabricated Metal Product Manufacturing" = 332999,
    "Farm Machinery And Equipment Manufacturing" = 333111,
    "Lawn And Garden Tractor And Home Lawn And Garden Equipment Manufacturing" = 333112,
    "Construction Machinery Manufacturing" = 333120,
    "Mining Machinery And Equipment Manufacturing" = 333131,
    "Oil And Gas Field Machinery And Equipment Manufacturing" = 333132,
    "Food Product Machinery Manufacturing" = 333241,
    "Semiconductor Machinery Manufacturing" = 333242,
    "Sawmill Woodworking And Paper Machinery Manufacturing" = 333243,
    "All Other Industrial Machinery Manufacturing" = 333248,
    "Commercial And Service Industry Machinery Manufacturing" = 333310,
    "Industrial And Commercial Fan And Blower And Air Purification Equipment Manufacturing" = 333413,
    "Heating Equipment Except Warm Air Furnaces Manufacturing" = 333414,
    "Air Conditioning And Warm Air Heating Equipment And Commercial And Industrial Refrigeration Equipment Manufacturing" = 333415,
    "Industrial Mold Manufacturing" = 333511,
    "Special Die And Tool Die Set Jig And Fixture Manufacturing" = 333514,
    "Cutting Tool And Machine Tool Accessory Manufacturing" = 333515,
    "Machine Tool Manufacturing" = 333517,
    "Rolling Mill And Other Metalworking Machinery Manufacturing" = 333519,
    "Turbine And Turbine Generator Set Units Manufacturing" = 333611,
    "Speed Changer Industrial High Speed Drive And Gear Manufacturing" = 333612,
    "Mechanical Power Transmission Equipment Manufacturing" = 333613,
    "Other Engine Equipment Manufacturing" = 333618,
    "Air And Gas Compressor Manufacturing" = 333912,
    "Measuring Dispensing And Other Pumping Equipment Manufacturing" = 333914,
    "Elevator And Moving Stairway Manufacturing" = 333921,
    "Conveyor And Conveying Equipment Manufacturing" = 333922,
    "Overhead Traveling Crane Hoist And Monorail System Manufacturing" = 333923,
    "Industrial Truck Tractor Trailer And Stacker Machinery Manufacturing" = 333924,
    "Power Driven Handtool Manufacturing" = 333991,
    "Welding And Soldering Equipment Manufacturing" = 333992,
    "Packaging Machinery Manufacturing" = 333993,
    "Industrial Process Furnace And Oven Manufacturing" = 333994,
    "Fluid Power Cylinder And Actuator Manufacturing" = 333995,
    "Fluid Power Pump And Motor Manufacturing" = 333996,
    "All Other Miscellaneous General Purpose Machinery Manufacturing" = 333998,
    "Electronic Computer Manufacturing" = 334111,
    "Computer Storage Device Manufacturing" = 334112,
    "Computer Terminal And Other Computer Peripheral Equipment Manufacturing" = 334118,
    "Telephone Apparatus Manufacturing" = 334210,
    "Radio And Television Broadcasting And Wireless Communications Equipment Manufacturing" = 334220,
    "Other Communications Equipment Manufacturing" = 334290,
    "Audio And Video Equipment Manufacturing" = 334310,
    "Bare Printed Circuit Board Manufacturing" = 334412,
    "Semiconductor And Related Device Manufacturing" = 334413,
    "Capacitor Resistor Coil Transformer And Other Inductor Manufacturing" = 334416,
    "Electronic Connector Manufacturing" = 334417,
    "Printed Circuit Assembly Electronic Assembly Manufacturing" = 334418,
    "Other Electronic Component Manufacturing" = 334419,
    "Electromedical And Electrotherapeutic Apparatus Manufacturing" = 334510,
    "Search Detection Navigation Guidance Aeronautical And Nautical System And Instrument Manufacturing" = 334511,
    "Automatic Environmental Control Manufacturing For Residential Commercial And Appliance Use" = 334512,
    "Instruments And Related Products Manufacturing For Measuring Displaying And Controlling Industrial Process Variables" = 334513,
    "Totalizing Fluid Meter And Counting Device Manufacturing" = 334514,
    "Instrument Manufacturing For Measuring And Testing Electricity And Electrical Signals" = 334515,
    "Analytical Laboratory Instrument Manufacturing" = 334516,
    "Irradiation Apparatus Manufacturing" = 334517,
    "Other Measuring And Controlling Device Manufacturing" = 334519,
    "Manufacturing And Reproducing Magnetic And Optical Media" = 334610,
    "Residential Electric Lighting Fixture Manufacturing" = 335131,
    "Commercial Industrial And Institutional Electric Lighting Fixture Manufacturing" = 335132,
    "Electric Lamp Bulb And Other Lighting Equipment Manufacturing" = 335139,
    "Small Electrical Appliance Manufacturing" = 335210,
    "Major Household Appliance Manufacturing" = 335220,
    "Power Distribution And Specialty Transformer Manufacturing" = 335311,
    "Motor And Generator Manufacturing" = 335312,
    "Switchgear And Switchboard Apparatus Manufacturing" = 335313,
    "Relay And Industrial Control Manufacturing" = 335314,
    "Battery Manufacturing" = 335910,
    "Fiber Optic Cable Manufacturing" = 335921,
    "Other Communication And Energy Wire Manufacturing" = 335929,
    "Current Carrying Wiring Device Manufacturing" = 335931,
    "Noncurrent Carrying Wiring Device Manufacturing" = 335932,
    "Carbon And Graphite Product Manufacturing" = 335991,
    "All Other Miscellaneous Electrical Equipment And Component Manufacturing" = 335999,
    "Automobile And Light Duty Motor Vehicle Manufacturing" = 336110,
    "Heavy Duty Truck Manufacturing" = 336120,
    "Motor Vehicle Body Manufacturing" = 336211,
    "Truck Trailer Manufacturing" = 336212,
    "Motor Home Manufacturing" = 336213,
    "Travel Trailer And Camper Manufacturing" = 336214,
    "Motor Vehicle Gasoline Engine And Engine Parts Manufacturing" = 336310,
    "Motor Vehicle Electrical And Electronic Equipment Manufacturing" = 336320,
    "Motor Vehicle Steering And Suspension Components Except Spring Manufacturing" = 336330,
    "Motor Vehicle Brake System Manufacturing" = 336340,
    "Motor Vehicle Transmission And Power Train Parts Manufacturing" = 336350,
    "Motor Vehicle Seating And Interior Trim Manufacturing" = 336360,
    "Motor Vehicle Metal Stamping" = 336370,
    "Other Motor Vehicle Parts Manufacturing" = 336390,
    "Aircraft Manufacturing" = 336411,
    "Aircraft Engine And Engine Parts Manufacturing" = 336412,
    "Other Aircraft Parts And Auxiliary Equipment Manufacturing" = 336413,
    "Guided Missile And Space Vehicle Manufacturing" = 336414,
    "Guided Missile And Space Vehicle Propulsion Unit And Propulsion Unit Parts Manufacturing" = 336415,
    "Other Guided Missile And Space Vehicle Parts And Auxiliary Equipment Manufacturing" = 336419,
    "Railroad Rolling Stock Manufacturing" = 336510,
    "Ship Building And Repairing" = 336611,
    "Boat Building" = 336612,
    "Motorcycle Bicycle And Parts Manufacturing" = 336991,
    "Military Armored Vehicle Tank And Tank Component Manufacturing" = 336992,
    "All Other Transportation Equipment Manufacturing" = 336999,
    "Wood Kitchen Cabinet And Countertop Manufacturing" = 337110,
    "Upholstered Household Furniture Manufacturing" = 337121,
    "Nonupholstered Wood Household Furniture Manufacturing" = 337122,
    "Household Furniture Except Wood And Upholstered Manufacturing" = 337126,
    "Institutional Furniture Manufacturing" = 337127,
    "Wood Office Furniture Manufacturing" = 337211,
    "Custom Architectural Woodwork And Millwork Manufacturing" = 337212,
    "Office Furniture Except Wood Manufacturing" = 337214,
    "Showcase Partition Shelving And Locker Manufacturing" = 337215,
    "Mattress Manufacturing" = 337910,
    "Blind And Shade Manufacturing" = 337920,
    "Surgical And Medical Instrument Manufacturing" = 339112,
    "Surgical Appliance And Supplies Manufacturing" = 339113,
    "Dental Equipment And Supplies Manufacturing" = 339114,
    "Ophthalmic Goods Manufacturing" = 339115,
    "Dental Laboratories" = 339116,
    "Jewelry And Silverware Manufacturing" = 339910,
    "Sporting And Athletic Goods Manufacturing" = 339920,
    "Doll Toy And Game Manufacturing" = 339930,
    "Office Supplies Except Paper Manufacturing" = 339940,
    "Sign Manufacturing" = 339950,
    "Gasket Packing And Sealing Device Manufacturing" = 339991,
    "Musical Instrument Manufacturing" = 339992,
    "Fastener Button Needle And Pin Manufacturing" = 339993,
    "Broom Brush And Mop Manufacturing" = 339994,
    "Burial Casket Manufacturing" = 339995,
    "All Other Miscellaneous Manufacturing" = 339999,
    "Automobile And Other Motor Vehicle Merchant Wholesalers" = 423110,
    "Motor Vehicle Supplies And New Parts Merchant Wholesalers" = 423120,
    "Tire And Tube Merchant Wholesalers" = 423130,
    "Motor Vehicle Parts Used Merchant Wholesalers" = 423140,
    "Furniture Merchant Wholesalers" = 423210,
    "Home Furnishing Merchant Wholesalers" = 423220,
    "Lumber Plywood Millwork And Wood Panel Merchant Wholesalers" = 423310,
    "Brick Stone And Related Construction Material Merchant Wholesalers" = 423320,
    "Roofing Siding And Insulation Material Merchant Wholesalers" = 423330,
    "Other Construction Material Merchant Wholesalers" = 423390,
    "Photographic Equipment And Supplies Merchant Wholesalers" = 423410,
    "Office Equipment Merchant Wholesalers" = 423420,
    "Computer And Computer Peripheral Equipment And Software Merchant Wholesalers" = 423430,
    "Other Commercial Equipment Merchant Wholesalers" = 423440,
    "Medical Dental And Hospital Equipment And Supplies Merchant Wholesalers" = 423450,
    "Ophthalmic Goods Merchant Wholesalers" = 423460,
    "Other Professional Equipment And Supplies Merchant Wholesalers" = 423490,
    "Metal Service Centers And Other Metal Merchant Wholesalers" = 423510,
    "Coal And Other Mineral And Ore Merchant Wholesalers" = 423520,
    "Electrical Apparatus And Equipment Wiring Supplies And Related Equipment Merchant Wholesalers" = 423610,
    "Household Appliances Electric Housewares And Consumer Electronics Merchant Wholesalers" = 423620,
    "Other Electronic Parts And Equipment Merchant Wholesalers" = 423690,
    "Hardware Merchant Wholesalers" = 423710,
    "Plumbing And Heating Equipment And Supplies Hydronics Merchant Wholesalers" = 423720,
    "Warm Air Heating And Air Conditioning Equipment And Supplies Merchant Wholesalers" = 423730,
    "Refrigeration Equipment And Supplies Merchant Wholesalers" = 423740,
    "Construction And Mining Except Oil Well Machinery And Equipment Merchant Wholesalers" = 423810,
    "Farm And Garden Machinery And Equipment Merchant Wholesalers" = 423820,
    "Industrial Machinery And Equipment Merchant Wholesalers" = 423830,
    "Industrial Supplies Merchant Wholesalers" = 423840,
    "Service Establishment Equipment And Supplies Merchant Wholesalers" = 423850,
    "Transportation Equipment And Supplies Except Motor Vehicle Merchant Wholesalers" = 423860,
    "Sporting And Recreational Goods And Supplies Merchant Wholesalers" = 423910,
    "Toy And Hobby Goods And Supplies Merchant Wholesalers" = 423920,
    "Recyclable Material Merchant Wholesalers" = 423930,
    "Jewelry Watch Precious Stone And Precious Metal Merchant Wholesalers" = 423940,
    "Other Miscellaneous Durable Goods Merchant Wholesalers" = 423990,
    "Printing And Writing Paper Merchant Wholesalers" = 424110,
    "Stationery And Office Supplies Merchant Wholesalers" = 424120,
    "Industrial And Personal Service Paper Merchant Wholesalers" = 424130,
    "Drugs And Druggists Sundries Merchant Wholesalers" = 424210,
    "Piece Goods Notions And Other Dry Goods Merchant Wholesalers" = 424310,
    "Footwear Merchant Wholesalers" = 424340,
    "Clothing And Clothing Accessories Merchant Wholesalers" = 424350,
    "General Line Grocery Merchant Wholesalers" = 424410,
    "Packaged Frozen Food Merchant Wholesalers" = 424420,
    "Dairy Product Except Dried Or Canned Merchant Wholesalers" = 424430,
    "Poultry And Poultry Product Merchant Wholesalers" = 424440,
    "Confectionery Merchant Wholesalers" = 424450,
    "Fish And Seafood Merchant Wholesalers" = 424460,
    "Meat And Meat Product Merchant Wholesalers" = 424470,
    "Fresh Fruit And Vegetable Merchant Wholesalers" = 424480,
    "Other Grocery And Related Products Merchant Wholesalers" = 424490,
    "Grain And Field Bean Merchant Wholesalers" = 424510,
    "Livestock Merchant Wholesalers" = 424520,
    "Other Farm Product Raw Material Merchant Wholesalers" = 424590,
    "Plastics Materials And Basic Forms And Shapes Merchant Wholesalers" = 424610,
    "Other Chemical And Allied Products Merchant Wholesalers" = 424690,
    "Petroleum Bulk Stations And Terminals" = 424710,
    "Petroleum And Petroleum Products Merchant Wholesalers Except Bulk Stations And Terminals" = 424720,
    "Beer And Ale Merchant Wholesalers" = 424810,
    "Wine And Distilled Alcoholic Beverage Merchant Wholesalers" = 424820,
    "Farm Supplies Merchant Wholesalers" = 424910,
    "Book Periodical And Newspaper Merchant Wholesalers" = 424920,
    "Flower Nursery Stock And Florists Supplies Merchant Wholesalers" = 424930,
    "Tobacco Product And Electronic Cigarette Merchant Wholesalers" = 424940,
    "Paint Varnish And Supplies Merchant Wholesalers" = 424950,
    "Other Miscellaneous Nondurable Goods Merchant Wholesalers" = 424990,
    "Wholesale Trade Agents And Brokers" = 425120,
    "New Car Dealers" = 441110,
    "Used Car Dealers" = 441120,
    "Recreational Vehicle Dealers" = 441210,
    "Boat Dealers" = 441222,
    "Motorcycle ATV And All Other Motor Vehicle Dealers" = 441227,
    "Automotive Parts And Accessories Retailers" = 441330,
    "Tire Dealers" = 441340,
    "Home Centers" = 444110,
    "Paint And Wallpaper Retailers" = 444120,
    "Hardware Retailers" = 444140,
    "Other Building Material Dealers" = 444180,
    "Outdoor Power Equipment Retailers" = 444230,
    "Nursery Garden Center And Farm Supply Retailers" = 444240,
    "Supermarkets And Other Grocery Retailers Except Convenience Retailers" = 445110,
    "Convenience Retailers" = 445131,
    "Vending Machine Operators" = 445132,
    "Fruit And Vegetable Retailers" = 445230,
    "Meat Retailers" = 445240,
    "Fish And Seafood Retailers" = 445250,
    "Baked Goods Retailers" = 445291,
    "Confectionery And Nut Retailers" = 445292,
    "All Other Specialty Food Retailers" = 445298,
    "Beer Wine And Liquor Retailers" = 445320,
    "Furniture Retailers" = 449110,
    "Floor Covering Retailers" = 449121,
    "Window Treatment Retailers" = 449122,
    "All Other Home Furnishings Retailers" = 449129,
    "Electronics And Appliance Retailers" = 449210,
    "Mail Order Houses" = 454113,
    "Other Direct Selling Establishments" = 454390,
    "Department Stores" = 455110,
    "Warehouse Clubs And Supercenters" = 455211,
    "All Other General Merchandise Retailers" = 455219,
    "Pharmacies And Drug Retailers" = 456110,
    "Cosmetics Beauty Supplies And Perfume Retailers" = 456120,
    "Optical Goods Retailers" = 456130,
    "Food Health Supplement Retailers" = 456191,
    "All Other Health And Personal Care Retailers" = 456199,
    "Gasoline Stations With Convenience Stores" = 457110,
    "Other Gasoline Stations" = 457120,
    "Fuel Dealers" = 457210,
    "Clothing And Clothing Accessories Retailers" = 458110,
    "Shoe Retailers" = 458210,
    "Jewelry Retailers" = 458310,
    "Luggage And Leather Goods Retailers" = 458320,
    "Sporting Goods Retailers" = 459110,
    "Hobby Toy And Game Retailers" = 459120,
    "Sewing Needlework And Piece Goods Retailers" = 459130,
    "Musical Instrument And Supplies Retailers" = 459140,
    "Book Retailers And News Dealers" = 459210,
    "Florists" = 459310,
    "Office Supplies And Stationery Retailers" = 459410,
    "Gift Novelty And Souvenir Retailers" = 459420,
    "Used Merchandise Retailers" = 459510,
    "Pet And Pet Supplies Retailers" = 459910,
    "Art Dealers" = 459920,
    "Manufactured Mobile Home Dealers" = 459930,
    "Tobacco Electronic Cigarette And Other Smoking Supplies Retailers" = 459991,
    "All Other Miscellaneous Retailers" = 459999,
    "Scheduled Passenger Air Transportation" = 481111,
    "Scheduled Freight Air Transportation" = 481112,
    "Nonscheduled Chartered Passenger Air Transportation" = 481211,
    "Nonscheduled Chartered Freight Air Transportation" = 481212,
    "Other Nonscheduled Air Transportation" = 481219,
    "Line Haul Railroads" = 482111,
    "Short Line Railroads" = 482112,
    "Deep Sea Freight Transportation" = 483111,
    "Deep Sea Passenger Transportation" = 483112,
    "Coastal And Great Lakes Freight Transportation" = 483113,
    "Coastal And Great Lakes Passenger Transportation" = 483114,
    "Inland Water Freight Transportation" = 483211,
    "Inland Water Passenger Transportation" = 483212,
    "General Freight Trucking Local" = 484110,
    "General Freight Trucking Long Distance Truckload" = 484121,
    "General Freight Trucking Long Distance Less Than Truckload" = 484122,
    "Used Household And Office Goods Moving" = 484210,
    "Specialized Freight Except Used Goods Trucking Local" = 484220,
    "Specialized Freight Except Used Goods Trucking Long Distance" = 484230,
    "Mixed Mode Transit Systems" = 485111,
    "Commuter Rail Systems" = 485112,
    "Bus And Other Motor Vehicle Transit Systems" = 485113,
    "Other Urban Transit Systems" = 485119,
    "Interurban And Rural Bus Transportation" = 485210,
    "Taxi And Ridesharing Services" = 485310,
    "Limousine Service" = 485320,
    "School And Employee Bus Transportation" = 485410,
    "Charter Bus Industry" = 485510,
    "Special Needs Transportation" = 485991,
    "All Other Transit And Ground Passenger Transportation" = 485999,
    "Pipeline Transportation Of Crude Oil" = 486110,
    "Pipeline Transportation Of Natural Gas" = 486210,
    "Pipeline Transportation Of Refined Petroleum Products" = 486910,
    "All Other Pipeline Transportation" = 486990,
    "Scenic And Sightseeing Transportation Land" = 487110,
    "Scenic And Sightseeing Transportation Water" = 487210,
    "Scenic And Sightseeing Transportation Other" = 487990,
    "Air Traffic Control" = 488111,
    "Other Airport Operations" = 488119,
    "Other Support Activities For Air Transportation" = 488190,
    "Support Activities For Rail Transportation" = 488210,
    "Port And Harbor Operations" = 488310,
    "Marine Cargo Handling" = 488320,
    "Navigational Services To Shipping" = 488330,
    "Other Support Activities For Water Transportation" = 488390,
    "Motor Vehicle Towing" = 488410,
    "Other Support Activities For Road Transportation" = 488490,
    "Freight Transportation Arrangement" = 488510,
    "Packing And Crating" = 488991,
    "All Other Support Activities For Transportation" = 488999,
    "Postal Service" = 491110,
    "Couriers And Express Delivery Services" = 492110,
    "Local Messengers And Local Delivery" = 492210,
    "General Warehousing And Storage" = 493110,
    "Refrigerated Warehousing And Storage" = 493120,
    "Farm Product Warehousing And Storage" = 493130,
    "Other Warehousing And Storage" = 493190,
    "Motion Picture And Video Production" = 512110,
    "Motion Picture And Video Distribution" = 512120,
    "Motion Picture Theaters Except Drive Ins" = 512131,
    "Drive In Motion Picture Theaters" = 512132,
    "Teleproduction And Other Postproduction Services" = 512191,
    "Other Motion Picture And Video Industries" = 512199,
    "Music Publishers" = 512230,
    "Sound Recording Studios" = 512240,
    "Record Production And Distribution" = 512250,
    "Other Sound Recording Industries" = 512290,
    "Newspaper Publishers" = 513110,
    "Periodical Publishers" = 513120,
    "Book Publishers" = 513130,
    "Directory And Mailing List Publishers" = 513140,
    "Greeting Card Publishers" = 513191,
    "All Other Publishers" = 513199,
    "Software Publishers" = 513210,
    "Cable And Other Subscription Programming" = 515210,
    "Radio Broadcasting Stations" = 516110,
    "Television Broadcasting Stations" = 516120,
    "Media Streaming Distribution Services Social Networks And Other Media Networks And Content Providers" = 516210,
    "Wired Telecommunications Carriers" = 517111,
    "Wireless Telecommunications Carriers Except Satellite" = 517112,
    "Telecommunications Resellers" = 517121,
    "Satellite Telecommunications" = 517410,
    "All Other Telecommunications" = 517810,
    "Computing Infrastructure Providers Data Processing Web Hosting And Related Services" = 518210,
    "Libraries And Archives" = 519210,
    "Web Search Portals And All Other Information Services" = 519290,
    "Monetary Authorities Central Bank" = 521110,
    "Commercial Banking" = 522110,
    "Credit Unions" = 522130,
    "Savings Institutions And Other Depository Credit Intermediation" = 522180,
    "Credit Card Issuing" = 522210,
    "Sales Financing" = 522220,
    "Consumer Lending" = 522291,
    "Real Estate Credit" = 522292,
    "All Other Nondepository Credit Intermediation" = 522298,
    "International Secondary Market And All Other Nondepository Credit Intermediation" = 522299,
    "Mortgage And Nonmortgage Loan Brokers" = 522310,
    "Financial Transactions Processing Reserve And Clearinghouse Activities" = 522320,
    "Other Activities Related To Credit Intermediation" = 522390,
    "Investment Banking And Securities Intermediation" = 523150,
    "Commodity Contracts Intermediation" = 523160,
    "Securities And Commodity Exchanges" = 523210,
    "Miscellaneous Intermediation" = 523910,
    "Portfolio Management And Investment Advice" = 523940,
    "Trust Fiduciary And Custody Activities" = 523991,
    "Miscellaneous Financial Investment Activities" = 523999,
    "Direct Life Insurance Carriers" = 524113,
    "Direct Health And Medical Insurance Carriers" = 524114,
    "Direct Property And Casualty Insurance Carriers" = 524126,
    "Direct Title Insurance Carriers" = 524127,
    "Other Direct Insurance Except Life Health And Medical Carriers" = 524128,
    "Reinsurance Carriers" = 524130,
    "Insurance Agencies And Brokerages" = 524210,
    "Claims Adjusting" = 524291,
    "Pharmacy Benefit Management And Other Third Party Administration Of Insurance And Pension Funds" = 524292,
    "All Other Insurance Related Activities" = 524298,
    "Pension Funds" = 525110,
    "Health And Welfare Funds" = 525120,
    "Other Insurance Funds" = 525190,
    "Open End Investment Funds" = 525910,
    "Trusts Estates And Agency Accounts" = 525920,
    "Other Financial Vehicles" = 525990,
    "Lessors Of Residential Buildings And Dwellings" = 531110,
    "Lessors Of Nonresidential Buildings Except Miniwarehouses" = 531120,
    "Lessors Of Miniwarehouses And Self Storage Units" = 531130,
    "Lessors Of Other Real Estate Property" = 531190,
    "Offices Of Real Estate Agents And Brokers" = 531210,
    "Residential Property Managers" = 531311,
    "Nonresidential Property Managers" = 531312,
    "Offices Of Real Estate Appraisers" = 531320,
    "Other Activities Related To Real Estate" = 531390,
    "Passenger Car Rental" = 532111,
    "Passenger Car Leasing" = 532112,
    "Truck Utility Trailer And RV Recreational Vehicle Rental And Leasing" = 532120,
    "Consumer Electronics And Appliances Rental" = 532210,
    "Formal Wear And Costume Rental" = 532281,
    "Video Tape And Disc Rental" = 532282,
    "Home Health Equipment Rental" = 532283,
    "Recreational Goods Rental" = 532284,
    "All Other Consumer Goods Rental" = 532289,
    "General Rental Centers" = 532310,
    "Commercial Air Rail And Water Transportation Equipment Rental And Leasing" = 532411,
    "Construction Mining And Forestry Machinery And Equipment Rental And Leasing" = 532412,
    "Office Machinery And Equipment Rental And Leasing" = 532420,
    "Other Commercial And Industrial Machinery And Equipment Rental And Leasing" = 532490,
    "Lessors Of Nonfinancial Intangible Assets Except Copyrighted Works" = 533110,
    "Offices Of Lawyers" = 541110,
    "Title Abstract And Settlement Offices" = 541191,
    "All Other Legal Services" = 541199,
    "Offices Of Certified Public Accountants" = 541211,
    "Tax Preparation Services" = 541213,
    "Payroll Services" = 541214,
    "Other Accounting Services" = 541219,
    "Architectural Services" = 541310,
    "Landscape Architectural Services" = 541320,
    "Engineering Services" = 541330,
    "Drafting Services" = 541340,
    "Building Inspection Services" = 541350,
    "Geophysical Surveying And Mapping Services" = 541360,
    "Surveying And Mapping Except Geophysical Services" = 541370,
    "Testing Laboratories And Services" = 541380,
    "Interior Design Services" = 541410,
    "Industrial Design Services" = 541420,
    "Graphic Design Services" = 541430,
    "Other Specialized Design Services" = 541490,
    "Custom Computer Programming Services" = 541511,
    "Computer Systems Design Services" = 541512,
    "Computer Facilities Management Services" = 541513,
    "Other Computer Related Services" = 541519,
    "Administrative Management And General Management Consulting Services" = 541611,
    "Human Resources Consulting Services" = 541612,
    "Marketing Consulting Services" = 541613,
    "Process Physical Distribution And Logistics Consulting Services" = 541614,
    "Other Management Consulting Services" = 541618,
    "Environmental Consulting Services" = 541620,
    "Other Scientific And Technical Consulting Services" = 541690,
    "Research And Development In Nanotechnology" = 541713,
    "Research And Development In Biotechnology Except Nanobiotechnology" = 541714,
    "Research And Development In The Physical Engineering And Life Sciences Except Nanotechnology And Biotechnology" = 541715,
    "Research And Development In The Social Sciences And Humanities" = 541720,
    "Advertising Agencies" = 541810,
    "Public Relations Agencies" = 541820,
    "Media Buying Agencies" = 541830,
    "Media Representatives" = 541840,
    "Indoor And Outdoor Display Advertising" = 541850,
    "Direct Mail Advertising" = 541860,
    "Advertising Material Distribution Services" = 541870,
    "Other Services Related To Advertising" = 541890,
    "Marketing Research And Public Opinion Polling" = 541910,
    "Photography Studios Portrait" = 541921,
    "Commercial Photography" = 541922,
    "Translation And Interpretation Services" = 541930,
    "Veterinary Services" = 541940,
    "All Other Professional Scientific And Technical Services" = 541990,
    "Offices Of Bank Holding Companies" = 551111,
    "Offices Of Other Holding Companies" = 551112,
    "Corporate Subsidiary And Regional Managing Offices" = 551114,
    "Office Administrative Services" = 561110,
    "Facilities Support Services" = 561210,
    "Employment Placement Agencies" = 561311,
    "Executive Search Services" = 561312,
    "Temporary Help Services" = 561320,
    "Professional Employer Organizations" = 561330,
    "Document Preparation Services" = 561410,
    "Telephone Answering Services" = 561421,
    "Telemarketing Bureaus And Other Contact Centers" = 561422,
    "Private Mail Centers" = 561431,
    "Other Business Service Centers Including Copy Shops" = 561439,
    "Collection Agencies" = 561440,
    "Credit Bureaus" = 561450,
    "Repossession Services" = 561491,
    "Court Reporting And Stenotype Services" = 561492,
    "All Other Business Support Services" = 561499,
    "Travel Agencies" = 561510,
    "Tour Operators" = 561520,
    "Convention And Visitors Bureaus" = 561591,
    "All Other Travel Arrangement And Reservation Services" = 561599,
    "Investigation And Personal Background Check Services" = 561611,
    "Security Guards And Patrol Services" = 561612,
    "Armored Car Services" = 561613,
    "Security Systems Services Except Locksmiths" = 561621,
    "Locksmiths" = 561622,
    "Exterminating And Pest Control Services" = 561710,
    "Janitorial Services" = 561720,
    "Landscaping Services" = 561730,
    "Carpet And Upholstery Cleaning Services" = 561740,
    "Other Services To Buildings And Dwellings" = 561790,
    "Packaging And Labeling Services" = 561910,
    "Convention And Trade Show Organizers" = 561920,
    "All Other Support Services" = 561990,
    "Solid Waste Collection" = 562111,
    "Hazardous Waste Collection" = 562112,
    "Other Waste Collection" = 562119,
    "Hazardous Waste Treatment And Disposal" = 562211,
    "Solid Waste Landfill" = 562212,
    "Solid Waste Combustors And Incinerators" = 562213,
    "Other Nonhazardous Waste Treatment And Disposal" = 562219,
    "Remediation Services" = 562910,
    "Materials Recovery Facilities" = 562920,
    "Septic Tank And Related Services" = 562991,
    "All Other Miscellaneous Waste Management Services" = 562998,
    "Elementary And Secondary Schools" = 611110,
    "Junior Colleges" = 611210,
    "Colleges Universities And Professional Schools" = 611310,
    "Business And Secretarial Schools" = 611410,
    "Computer Training" = 611420,
    "Professional And Management Development Training" = 611430,
    "Cosmetology And Barber Schools" = 611511,
    "Flight Training" = 611512,
    "Apprenticeship Training" = 611513,
    "Other Technical And Trade Schools" = 611519,
    "Fine Arts Schools" = 611610,
    "Sports And Recreation Instruction" = 611620,
    "Language Schools" = 611630,
    "Exam Preparation And Tutoring" = 611691,
    "Automobile Driving Schools" = 611692,
    "All Other Miscellaneous Schools And Instruction" = 611699,
    "Educational Support Services" = 611710,
    "Offices Of Physicians Except Mental Health Specialists" = 621111,
    "Offices Of Physicians Mental Health Specialists" = 621112,
    "Offices Of Dentists" = 621210,
    "Offices Of Chiropractors" = 621310,
    "Offices Of Optometrists" = 621320,
    "Offices Of Mental Health Practitioners Except Physicians" = 621330,
    "Offices Of Physical Occupational And Speech Therapists And Audiologists" = 621340,
    "Offices Of Podiatrists" = 621391,
    "Offices Of All Other Miscellaneous Health Practitioners" = 621399,
    "Family Planning Centers" = 621410,
    "Outpatient Mental Health And Substance Abuse Centers" = 621420,
    "HMO Medical Centers" = 621491,
    "Kidney Dialysis Centers" = 621492,
    "Freestanding Ambulatory Surgical And Emergency Centers" = 621493,
    "All Other Outpatient Care Centers" = 621498,
    "Medical Laboratories" = 621511,
    "Diagnostic Imaging Centers" = 621512,
    "Home Health Care Services" = 621610,
    "Ambulance Services" = 621910,
    "Blood And Organ Banks" = 621991,
    "All Other Miscellaneous Ambulatory Health Care Services" = 621999,
    "General Medical And Surgical Hospitals" = 622110,
    "Psychiatric And Substance Abuse Hospitals" = 622210,
    "Specialty Except Psychiatric And Substance Abuse Hospitals" = 622310,
    "Nursing Care Facilities Skilled Nursing Facilities" = 623110,
    "Residential Intellectual And Developmental Disability Facilities" = 623210,
    "Residential Mental Health And Substance Abuse Facilities" = 623220,
    "Continuing Care Retirement Communities" = 623311,
    "Assisted Living Facilities For The Elderly" = 623312,
    "Other Residential Care Facilities" = 623990,
    "Child And Youth Services" = 624110,
    "Services For The Elderly And Persons With Disabilities" = 624120,
    "Other Individual And Family Services" = 624190,
    "Community Food Services" = 624210,
    "Temporary Shelters" = 624221,
    "Other Community Housing Services" = 624229,
    "Emergency And Other Relief Services" = 624230,
    "Vocational Rehabilitation Services" = 624310,
    "Child Care Services" = 624410,
    "Theater Companies And Dinner Theaters" = 711110,
    "Dance Companies" = 711120,
    "Musical Groups And Artists" = 711130,
    "Other Performing Arts Companies" = 711190,
    "Sports Teams And Clubs" = 711211,
    "Racetracks" = 711212,
    "Other Spectator Sports" = 711219,
    "Promoters Of Performing Arts Sports And Similar Events With Facilities" = 711310,
    "Promoters Of Performing Arts Sports And Similar Events Without Facilities" = 711320,
    "Agents And Managers For Artists Athletes Entertainers And Other Public Figures" = 711410,
    "Independent Artists Writers And Performers" = 711510,
    "Museums" = 712110,
    "Historical Sites" = 712120,
    "Zoos And Botanical Gardens" = 712130,
    "Nature Parks And Other Similar Institutions" = 712190,
    "Amusement And Theme Parks" = 713110,
    "Amusement Arcades" = 713120,
    "Casinos Except Casino Hotels" = 713210,
    "Other Gambling Industries" = 713290,
    "Golf Courses And Country Clubs" = 713910,
    "Skiing Facilities" = 713920,
    "Marinas" = 713930,
    "Fitness And Recreational Sports Centers" = 713940,
    "Bowling Centers" = 713950,
    "All Other Amusement And Recreation Industries" = 713990,
    "Hotels Except Casino Hotels And Motels" = 721110,
    "Casino Hotels" = 721120,
    "Bedand Breakfast Inns" = 721191,
    "All Other Traveler Accommodation" = 721199,
    "RV Recreational Vehicle Parks And Campgrounds" = 721211,
    "Recreational And Vacation Camps Except Campgrounds" = 721214,
    "Rooming And Boarding Houses Dormitories And Workers Camps" = 721310,
    "Food Service Contractors" = 722310,
    "Caterers" = 722320,
    "Mobile Food Services" = 722330,
    "Drinking Places Alcoholic Beverages" = 722410,
    "Full Service Restaurants" = 722511,
    "Limited Service Restaurants" = 722513,
    "Cafeterias Grill Buffets And Buffets" = 722514,
    "Snack And Nonalcoholic Beverage Bars" = 722515,
    "General Automotive Repair" = 811111,
    "Specialized Automotive Repair" = 811114,
    "Automotive Body Paint And Interior Repair And Maintenance" = 811121,
    "Automotive Glass Replacement Shops" = 811122,
    "Automotive Oil Change And Lubrication Shops" = 811191,
    "Car Washes" = 811192,
    "All Other Automotive Repair And Maintenance" = 811198,
    "Electronic And Precision Equipment Repair And Maintenance" = 811210,
    "Commercial And Industrial Machinery And Equipment Except Automotive And Electronic Repair And Maintenance" = 811310,
    "Home And Garden Equipment Repair And Maintenance" = 811411,
    "Appliance Repair And Maintenance" = 811412,
    "Reupholstery And Furniture Repair" = 811420,
    "Footwear And Leather Goods Repair" = 811430,
    "Other Personal And Household Goods Repair And Maintenance" = 811490,
    "Barber Shops" = 812111,
    "Beauty Salons" = 812112,
    "Nail Salons" = 812113,
    "Diet And Weight Reducing Centers" = 812191,
    "Other Personal Care Services" = 812199,
    "Funeral Homes And Funeral Services" = 812210,
    "Cemeteries And Crematories" = 812220,
    "Coin Operated Laundries And Drycleaners" = 812310,
    "Drycleaning And Laundry Services Except Coin Operated" = 812320,
    "Linen Supply" = 812331,
    "Industrial Launderers" = 812332,
    "Pet Care Except Veterinary Services" = 812910,
    "Photofinishing Laboratories Except One Hour" = 812921,
    "One Hour Photofinishing" = 812922,
    "Parking Lots And Garages" = 812930,
    "All Other Personal Services" = 812990,
    "Religious Organizations" = 813110,
    "Grantmaking Foundations" = 813211,
    "Voluntary Health Organizations" = 813212,
    "Other Grantmaking And Giving Services" = 813219,
    "Human Rights Organizations" = 813311,
    "Environment Conservation And Wildlife Organizations" = 813312,
    "Other Social Advocacy Organizations" = 813319,
    "Civic And Social Organizations" = 813410,
    "Business Associations" = 813910,
    "Professional Organizations" = 813920,
    "Labor Unions And Similar Labor Organizations" = 813930,
    "Political Organizations" = 813940,
    "Other Similar Organizations Except Business Professional Labor And Political Organizations" = 813990,
    "Private Households" = 814110,
    "Executive Offices" = 921110,
    "Legislative Bodies" = 921120,
    "Public Finance Activities" = 921130,
    "Executive And Legislative Offices Combined" = 921140,
    "American Indian And Alaska Native Tribal Governments" = 921150,
    "Other General Government Support" = 921190,
    "Courts" = 922110,
    "Police Protection" = 922120,
    "Legal Counsel And Prosecution" = 922130,
    "Correctional Institutions" = 922140,
    "Parole Offices And Probation Offices" = 922150,
    "Fire Protection" = 922160,
    "Other Justice Public Order And Safety Activities" = 922190,
    "Administration Of Education Programs" = 923110,
    "Administration Of Public Health Programs" = 923120,
    "Administration Of Human Resource Programs Except Education Public Health And Veterans Affairs Programs" = 923130,
    "Administration Of Veterans Affairs" = 923140,
    "Administration Of Air And Water Resource And Solid Waste Management Programs" = 924110,
    "Administration Of Conservation Programs" = 924120,
    "Administration Of Housing Programs" = 925110,
    "Administration Of Urban Planning And Community And Rural Development" = 925120,
    "Administration Of General Economic Programs" = 926110,
    "Regulation And Administration Of Transportation Programs" = 926120,
    "Regulation And Administration Of Communications Electric Gas And Other Utilities" = 926130,
    "Regulation Of Agricultural Marketing And Commodities" = 926140,
    "Regulation Licensing And Inspection Of Miscellaneous Commercial Sectors" = 926150,
    "Space Research And Technology" = 927110,
    "National Security" = 928110,
    "International Affairs" = 928120,
    "Unclassified" = 999999,
}
import React, { useEffect, useState } from "react"
import { RADOM_COLORS } from "../util/Constants"
import styled from "styled-components"

interface IProps {
  selectedTab: string,
  tabs: string[]
  onTabChange: (tab: string) => void
}


const Tab = styled.div<{ $selected?: boolean }>`
  cursor: pointer;
  padding: 10px 20px;
  background: ${({ $selected }) => $selected ? 'rgb(239 240 248 / 60%)' : 'transparent'};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  ${({ $selected }) => $selected && 'font-weight: 500;'}
  ${({ $selected }) => !$selected && 'opacity: 0.5;'}
  transition: 0.2s ease all;
  user-select: none;
`

const Tabs = (props: IProps): React.ReactElement => {
  return <div style={{
    borderBottom: `1px solid ${RADOM_COLORS.GRAY9}`,
    fontSize: 14,
    display: 'flex',
    gap: 10
  }}>

    {
      props.tabs.map(t =>
        <Tab key={t} $selected={t == props.selectedTab} onClick={() => props.onTabChange(t)}>
          {t}
        </Tab>
      )
    }

  </div>
}

export default Tabs
